import { useDispatch } from 'react-redux';
import { serviceActions } from '../store/slices/service-slice';
import { Service } from '../models/_index';
import { ServiceType, SubServiceType } from '../models/Service';

class ServiceViewModel {

    dispatch = useDispatch();

    public getServices = async () => {
        const response = await Service.getServices();
        if (response.status === 200)
            this.dispatch(serviceActions.updateList(response.data));
    }

    public selectService = (service: ServiceType) => {
        this.dispatch(serviceActions.updateSelected(service));
    }

    public removeSelectedService = () => {
        this.dispatch(serviceActions.removeSelected());
    }

    public selectSubService = (subService: SubServiceType) => {
        this.dispatch(serviceActions.updateSelectedSubService(subService));
    }

    public removeSelectedSubService = () => {
        this.dispatch(serviceActions.removeSelectedSubService());
    }

    public searchSubService = async (serviceSlug: string, subServiceSlug: string) => {
        const response = await Service.searchSubService(serviceSlug, subServiceSlug);
        if (response.status === 200)
            this.dispatch(serviceActions.updateSubService(response.data));
    }
}

export default ServiceViewModel;