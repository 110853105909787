import { Badge, Button, Card, CardHeader, Center, Heading, Spinner, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useDisclosure } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { AppointmentViewModel } from "../../../view-models/_index";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { StripeModal } from "../../../components/modals/_index";
import { AcceptQuoteModal } from "../../../components/modals/_index";
import { AcceptQuoteModalDataType } from "../../../models/Appointment";

const GetAppointmentStatus = (status: string) => {
    switch (status) {
        case 'PE':
            return <Badge w={'24'} justifyContent={'center'} variant='solid' colorScheme={'yellow'} whiteSpace={'nowrap'} px={'2'} py={'1'} rounded={'6'}><Center>En attente</Center></Badge>
        case 'AC':
            return <Badge w={'24'} variant='solid' colorScheme={'blue'} whiteSpace={'normal'} px={'2'} py={'1'} rounded={'6'}><Center>Validé</Center></Badge>
        case 'CO':
            return <Badge w={'24'} variant='solid' colorScheme={'green'} whiteSpace={'normal'} px={'2'} py={'1'} rounded={'6'}><Center>Terminé</Center></Badge>
        case 'CA':
            return <Badge w={'24'} variant='solid' colorScheme={'red'} whiteSpace={'normal'} px={'2'} py={'1'} rounded={'6'}><Center>Annulé</Center></Badge>
        default:
            return <Badge w={'24'} fontSize={'10'} variant='solid' colorScheme={'red'} whiteSpace={'normal'} px={'2'} py={'1'} rounded={'6'}><Center>L'attente du paiement</Center></Badge>
    }
}

const UserAppointmentPage: React.FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const getUserAppointments = (new AppointmentViewModel()).getUserAppointments;
    const getAppointmentClientSecret = (new AppointmentViewModel()).getAppointmentClientSecret;
    const userAppointments = useSelector((state: RootState) => state.appointment.list)
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { isOpen: isAcceptQuoteOpen, onOpen: onAcceptQuoteOpen, onClose: onAcceptQuoteClose } = useDisclosure()
    const { isOpen: isStripeOpen, onOpen: onStripeOpen, onClose: onStripeClose } = useDisclosure()
    const clientSecret = useSelector((state: RootState) => state.appointment.clientSecret);
    const [selectedAppointmentId, setSelectedAppointmentId] = useState<number>(0);
    const [acceptQuoteModalData, setAcceptQuoteModalData] = useState<AcceptQuoteModalDataType>({
        vehicle: '',
        distance: '',
        conveyingPrice: 0,
        service: '',
        servicePrice: 0,
        totalPrice: 0,
    });

    useEffect(() => {
        const initConveyings = async () => {
            setIsLoading(true);
            await getUserAppointments();
            setIsLoading(false);
        }

        initConveyings();
    }, []);

    useEffect(() => {
        if (clientSecret)
            onStripeOpen();
    }, [clientSecret]);

    const onClickHandler = async (appointmentId: number, data: AcceptQuoteModalDataType) => {
        setSelectedAppointmentId(appointmentId);
        setAcceptQuoteModalData(data);
        onAcceptQuoteOpen();
    }

    const onAcceptHandler = async () => {
        setIsLoading(true);
        onAcceptQuoteClose();

        const response = await getAppointmentClientSecret(selectedAppointmentId);
        if (response.status === 'error')
            setSelectedAppointmentId(0);

        setIsLoading(false);
    }

    return <>
        <StripeModal isStripeOpen={isStripeOpen} onStripeClose={onStripeClose} clientSecret={clientSecret!} successRoute={process.env.REACT_APP_URL + "garage/reservation/" + selectedAppointmentId + "/complete"} type={'appointment'} />
        <AcceptQuoteModal isOpen={isAcceptQuoteOpen} onClose={onAcceptQuoteClose} isLoading={isLoading} data={acceptQuoteModalData} onAcceptHandler={onAcceptHandler} />
        <Card>
            <CardHeader>
                <Heading fontSize={'20'} px={'4'} as={'h1'} color={'gray.600'}>Votre liste de services</Heading>
            </CardHeader>
        </Card>
        <Card mt={'4'}>
            {isLoading ?
                <>
                    <Center py={12}>
                        <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
                    </Center>
                </>
                :
                <>
                    <TableContainer fontSize={'14'} py={'4'}>
                        <Table variant='simple' whiteSpace={'normal'}>
                            <Thead>
                                <Tr textAlign={'center'} borderBottom={'2px'} borderColor={'RGBA(0, 0, 0, 0.16)'}>
                                    <Th textAlign={'center'}>Action</Th>
                                    <Th textAlign={'center'}>Status</Th>
                                    <Th textAlign={'center'}>Date <Text whiteSpace={'nowrap'}> de départ</Text></Th>
                                    <Th textAlign={'center'}>Service</Th>
                                    <Th textAlign={'center'}>Prix <Text fontSize={'xx-small'} whiteSpace={'nowrap'}>(Service + convoyage)</Text></Th>
                                    {/* <Th textAlign={'center'}>Rest <Text whiteSpace={'nowrap'}> à payer</Text></Th> */}
                                    <Th textAlign={'center'}>Garage</Th>
                                    <Th textAlign={'center'}>Vehicle</Th>
                                    <Th textAlign={'center'}>Adresse de départ</Th>
                                    <Th textAlign={'center'}>Adresse d'arrivée</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {userAppointments && userAppointments.appointments?.map((appointment, key) => {
                                    return (
                                        <Tr key={key}>
                                            <Td textAlign={'center'}>
                                                {appointment.servicePrice > 0 &&
                                                    <>
                                                        <Button colorScheme='teal' fontSize={'12'} onClick={() => {
                                                            onClickHandler(appointment.id, {
                                                                vehicle: appointment.vehicle,
                                                                distance: appointment.distance,
                                                                conveyingPrice: appointment.conveyingPrice,
                                                                service: appointment.service,
                                                                servicePrice: appointment.servicePrice,
                                                                totalPrice: appointment.finalPrice,
                                                            })
                                                        }}>
                                                            Accepter <br /> le devis
                                                        </Button>
                                                    </>
                                                }
                                            </Td>
                                            <Td textAlign={'center'}>{GetAppointmentStatus(appointment.status)}</Td>
                                            <Td textAlign={'center'} minW={'150px'}>{appointment.departureDate}</Td>
                                            <Td textAlign={'center'}>{appointment.service}</Td>
                                            <Td textAlign={'center'}>{appointment.servicePrice > 0 && appointment.finalPrice}</Td>
                                            {/* <Td textAlign={'center'}>{appointment.leftToPay}</Td> */}
                                            <Td textAlign={'center'} minW={'150px'}>{appointment.vendor}</Td>
                                            <Td textAlign={'center'}>{appointment.vehicle}</Td>
                                            <Td textAlign={'center'} minW={'200px'}>{appointment.departureAddress}</Td>
                                            <Td textAlign={'center'} minW={'200px'}>{appointment.arrivalAddress}</Td>
                                        </Tr>
                                    )
                                })}
                            </Tbody>
                        </Table>
                    </TableContainer>
                </>
            }
        </Card>
    </>
}

export default UserAppointmentPage;