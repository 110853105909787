import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { StripeForm } from '../forms/_index';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!);

const StripeElements: React.FC<{ clientSecret: string, successRoute: string, type: string }> = (props) => {
    const options = {
        clientSecret: props.clientSecret,
    };

    return (
        <Elements stripe={stripePromise} options={options}>
            <StripeForm successRoute={props.successRoute} type={props.type} />
        </Elements>
    );
};

export default StripeElements;