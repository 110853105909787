import React, { useEffect } from 'react'
import { LayoutSection, PrivacyPolicySection } from './components/_index'

const PrivacyPolicyPage: React.FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <LayoutSection >
                <PrivacyPolicySection />
            </LayoutSection>
        </>
    )
}

export default PrivacyPolicyPage