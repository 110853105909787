import { Box, Container, SimpleGrid } from '@chakra-ui/react'
import SectionHeader from '../../../../components/sections/SectionHeader'
import { CityType } from '../../../../models/City'
import CityCard from './CityCard'

const CitiesSection: React.FC<{ cities: CityType[] }> = ({ cities }) => {
    return (
        <Box mt={12} bg={'#F6FAFC'} py={12}>
            <SectionHeader title={`Nos garages dans le département ${Object.values(cities)[0]?.name}`} sectionName='Villes' />

            <Container
                maxW={'80%'}
                w={{ lg: '1000px' }}
                py={10}
                px={{ lg: 12 }}
            >
                <Box py={6}>
                    <SimpleGrid templateColumns={{ lg: '2fr 2fr 2fr 2fr' }} spacing={6}>
                        {cities.map((city) => (<CityCard city={city} />))}
                    </SimpleGrid>
                </Box>
            </Container>
        </Box>

    )
}

export default CitiesSection