import React from 'react'
import DadycarAlert from '../../../../../../../components/sectors/V2/DadycarAlert'
import { Text } from '@chakra-ui/react'

const AlertPrice = () => {
  return (
    <DadycarAlert type={'alert'}>
        <Text color='#0F61AC' fontSize={'sm'}> <Text as={'span'} fontWeight={700} >Compris dans le prix </Text> <br /> <br /> Assurance tout risque (voir les détail de l’assurance), péages, carburant. Conditions d’annulation : Si votre véhicule n’est pas encore affecté à un transporteur camion :  Plus de 48h (hors dimanche et jours fériés) avant la date limite de livraison : Annulation gratuite  Entre 24 et 48h avant la date limite de livraison : Remboursement de 50% du prix de la commande  Moins de 24h avant la date limite de livraison : Aucun remboursement
 <br /> <br />

Si votre véhicule est déjà affecté à un transporteur camion :  Plus de 48h (hors dimanche et jours fériés) avant la date de d’enlèvement : Annulation gratuite  Entre 24 et 48h avant la date d’enlèvement : Remboursement de 50% du prix de la commande  Moins de 24h avant la date d’enlèvement : Aucun remboursement <br /> <br /> <Text as={'span'} fontWeight={700}>Conditions de transport :</Text>
 <br /> <br /> Vous vous engagez à mettre à disposition un véhicule assuré et dans l’état de fonctionnement mentionné. Veuillez noter qu’un véhicule “”en panne”” doit néanmoins posséder ses 4 roues, une direction et des freins en état de marche. Dans le cas contraire, il s’agit d’un véhicule “”accidenté”” et vous devez revenir à l’étape précédente pour modifier l’état du véhicule. Un état des lieux au départ et à l’arrivée sera effectué. Pour des raisons de sécurité et car le véhicule ne sera pas vérouillé, veuillez ne rien stocker dans le véhicule.</Text>
    </DadycarAlert>
  )
}

export default AlertPrice