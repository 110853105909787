import { Image } from '@chakra-ui/image'
import { Box, Center, Container, SimpleGrid, Stack, Text } from '@chakra-ui/layout'
import filesIcon from '../../../../assets/images/dadycar-files.webp'
import calendarIcon from '../../../../assets/images/dadycar-calendar-2.webp'
import analyzingIcon from '../../../../assets/images/dadycar-analyzing.webp'
import networkingIcon from '../../../../assets/images/dadycar-networking.webp'
import stopwatchIcon from '../../../../assets/images/dadycar-stopwatch.webp'
import laptopIcon from '../../../../assets/images/dadycar-laptop.webp'
import dotBg2 from '../../../../assets/images/dadycar-dots-bg-2.webp'

const ServicesSection: React.FC = () => {
    const data = [{
        image: filesIcon,
        title: '',
        text: "Des devis calculés automatiquement 24h/7j"
    }, {
        image: calendarIcon,
        title: '',
        text: "Une forte diminution du nombre de rdv non honorésnon honorés et d’appels inutiles"
    }, {
        image: analyzingIcon,
        title: "",
        text: "Une augmentation de votre chiffre d’affaires"
    }, {
        image: networkingIcon,
        title: "",
        text: "Vous tirez parti des milliers de clients qui effectuent des devis"
    }, {
        image: stopwatchIcon,
        title: "",
        text: "Vous gagnez du temps, et obtenez + de rdv"
    }, {
        image: laptopIcon,
        title: "",
        text: "Vous gardez la main sur vos tarifs et votre activité"
    }]
    return (
        <Box
            bg={'#F6FAFC'}
            backgroundImage={dotBg2}
            backgroundSize={{ lg: 'cover' }}
            backgroundRepeat={'no-repeat'}
            backgroundPosition={{ base: 'top', lg: 'center' }}
            py={8}>
            <Container
                maxW={'80%'}
                px={10}
                py={{ base: 0, lg: 12 }}
                w={{ lg: '1000px' }}
                bg={'white'}
                boxShadow={'md'}
                borderRadius={'2xl'}
            >
                <Box>
                    <SimpleGrid templateColumns={{ base: '2fr', lg: '2fr 2fr 2fr' }} spacing={12}>
                        {data.map((item, idx) => (
                            <Stack key={idx}>
                                <Center>
                                    <Image src={item.image} h={'70px'} />
                                </Center>
                                <Center>
                                    <Text color={'#0F82EA'}>{item.title}</Text>
                                </Center>
                                <Center py={5}>
                                    <Text textAlign={'center'}>{item.text}</Text>
                                </Center>
                            </Stack>
                        ))}
                    </SimpleGrid>
                </Box>
            </Container>
        </Box>

    )
}

export default ServicesSection;