import HttpClient from "../services/HttpClient";

type DepartmentType = {
    id: number,
    code: number,
    name: string,
    slug: string,
    regionName: string,
    region_code: number
}

class Department {

    public static getDepartments = async (regionCode: number) => {
        return HttpClient.get('/administrative/regions/' + regionCode + '/departments');
    }

    public static searchDepartments = async (regionSlug: string) => {
        return HttpClient.get('/administrative/regions/slug/' + regionSlug + '/departments');
    }
}

export { type DepartmentType };
export default Department;