import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Box,
    Flex,
  } from '@chakra-ui/react'

const HistoriqueData = () => {
  return (
    <TableContainer pb={4}>
        <Table style={{borderCollapse:"separate", borderSpacing:"0 1em"}}>
            <Thead>
            <Tr>
                <Th>Immatricule</Th>
                <Th>Client</Th>
                <Th>Véhicule</Th>
                <Th>Date</Th>
                <Th>Prix (TTC)</Th>
                <Th>Status</Th>
                <Th>Actions</Th>
            </Tr>
            </Thead>
            <Tbody gap={4}>
            <Tr bg={'white'} >
                <Td fontSize={'14px'} fontWeight={500} color={'#222D50'}>eq-287-rp</Td>
                <Td fontSize={'14px'} fontWeight={500} color={'#222D50'}>Jean Patrick</Td>
                <Td fontSize={'14px'} fontWeight={500} color={'#222D50'}>BERLINGO 1.6 Blue HDi 100</Td>
                <Td fontSize={'14px'} fontWeight={500} color={'#222D50'}>25/02/2024</Td>
                <Td fontSize={'14px'} fontWeight={600} color={'#0F82EA'} >369€</Td>
                <Td>
                    <Flex justifyContent={'center'} alignItems={'center'} bg={'#07D0C11A'} rounded={'6px'} px={'6px'} py={'8px'} fontSize={'12px'} color={'#07D0C1'} fontWeight={500}>
                        Accepted
                    </Flex>
                </Td>
                <Td>Menu Here</Td>
            </Tr>
            <Tr bg={'white'}>
                <Td fontSize={'14px'} fontWeight={500} color={'#222D50'}>eq-287-rp</Td>
                <Td fontSize={'14px'} fontWeight={500} color={'#222D50'}>Jean Patrick</Td>
                <Td fontSize={'14px'} fontWeight={500} color={'#222D50'}>BERLINGO 1.6 Blue HDi 100</Td>
                <Td fontSize={'14px'} fontWeight={500} color={'#222D50'}>25/02/2024</Td>
                <Td fontSize={'14px'} fontWeight={600} color={'#0F82EA'} >369€</Td>
                <Td>
                    <Flex justifyContent={'center'} alignItems={'center'} bg={'#07D0C11A'} rounded={'6px'} px={'6px'} py={'8px'} fontSize={'12px'} color={'#07D0C1'} fontWeight={500}>
                        Accepted
                    </Flex>
                </Td>
                <Td>Menu here</Td>
            </Tr>
            <Tr bg={'white'} >
                <Td fontSize={'14px'} fontWeight={500} color={'#222D50'}>eq-287-rp</Td>
                <Td fontSize={'14px'} fontWeight={500} color={'#222D50'}>Jean Patrick</Td>
                <Td fontSize={'14px'} fontWeight={500} color={'#222D50'}>BERLINGO 1.6 Blue HDi 100</Td>
                <Td fontSize={'14px'} fontWeight={500} color={'#222D50'}>25/02/2024</Td>
                <Td fontSize={'14px'} fontWeight={600} color={'#0F82EA'} >369€</Td>
                <Td>
                    <Flex justifyContent={'center'} alignItems={'center'} bg={'#07D0C11A'} rounded={'6px'} px={'6px'} py={'8px'} fontSize={'12px'} color={'#07D0C1'} fontWeight={500}>
                        Accepted
                    </Flex>
                </Td>
                <Td>Menu here</Td>
            </Tr>
            <Tr bg={'white'} >
                <Td fontSize={'14px'} fontWeight={500} color={'#222D50'}>eq-287-rp</Td>
                <Td fontSize={'14px'} fontWeight={500} color={'#222D50'}>Jean Patrick</Td>
                <Td fontSize={'14px'} fontWeight={500} color={'#222D50'}>BERLINGO 1.6 Blue HDi 100</Td>
                <Td fontSize={'14px'} fontWeight={500} color={'#222D50'}>25/02/2024</Td>
                <Td fontSize={'14px'} fontWeight={600} color={'#0F82EA'} >369€</Td>
                <Td>
                    <Flex justifyContent={'center'} alignItems={'center'} bg={'#07D0C11A'} rounded={'6px'} px={'6px'} py={'8px'} fontSize={'12px'} color={'#07D0C1'} fontWeight={500}>
                        Accepted
                    </Flex>
                </Td>
                <Td>Menu here</Td>
            </Tr>
            <Tr bg={'white'} >
                <Td fontSize={'14px'} fontWeight={500} color={'#222D50'}>eq-287-rp</Td>
                <Td fontSize={'14px'} fontWeight={500} color={'#222D50'}>Jean Patrick</Td>
                <Td fontSize={'14px'} fontWeight={500} color={'#222D50'}>BERLINGO 1.6 Blue HDi 100</Td>
                <Td fontSize={'14px'} fontWeight={500} color={'#222D50'}>25/02/2024</Td>
                <Td fontSize={'14px'} fontWeight={600} color={'#0F82EA'} >369€</Td>
                <Td>
                    <Flex justifyContent={'center'} alignItems={'center'} bg={'#07D0C11A'} rounded={'6px'} px={'6px'} py={'8px'} fontSize={'12px'} color={'#07D0C1'} fontWeight={500}>
                        Accepted
                    </Flex>
                </Td>
                <Td>Menu here</Td>
            </Tr>
            </Tbody>
        </Table>
    </TableContainer>
  )
}

export default HistoriqueData