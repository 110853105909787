import { Box, Center, Flex, Heading, SimpleGrid, Stack, Text } from '@chakra-ui/react'
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../store';

const PriceCard: React.FC = () => {
    const conveyingDistance = useSelector((state: RootState) => state.distancePrice.conveyingDistance)
    const conveyingDepartureAddress = useSelector((state: RootState) => state.address.conveyingDepartureAddress)
    const conveyingReturnAddress = useSelector((state: RootState) => state.address.conveyingReturnAddress)
    const vehicle = useSelector((state: RootState) => state.vehicle.value)
    const mark = useSelector((state: RootState) => state.mark.selected)
    const model = useSelector((state: RootState) => state.model.selected)

    const format2Decimals = (num: any) => {
        const stringNum = num.toString();
        return stringNum.substring(0, stringNum.indexOf('.') + 3);
    }

    return (
        <Stack bg={'white'} borderRadius={'21px'} boxShadow={'sm'} borderWidth={'1px'}>
            <Flex position={'relative'} justifyContent={{ base: 'center', lg: 'unset' }}>
                <SimpleGrid padding={5} templateColumns={'1fr'} spacing={4}>
                    <Stack>
                        <Heading fontWeight={700} color={'#0F82EA'}  fontSize={'22px'}>Récapitulatif de la demande</Heading>
                    </Stack>
                    <Stack>
                        <SimpleGrid templateColumns={'2fr 4fr'} spacing={4}>
                            <Box >
                                <Text color={'#68696A'} fontSize={'18px'}>Véhicule :</Text>
                            </Box>
                            <Box>
                                <Text fontSize={'16px'} fontWeight={600} color='#222D50'>{vehicle ? vehicle.mark + ' ' + vehicle.model : mark?.name + ' ' + model?.name}</Text>
                            </Box>
                        </SimpleGrid>
                    </Stack>
                    <Stack>
                        <SimpleGrid templateColumns={'2fr 4fr'} spacing={4}>
                            <Box >
                                <Text color={'#68696A'} fontSize={'18px'}>Transport :</Text>
                            </Box>
                            <Box>
                                <Text fontSize={'16px'} fontWeight={600} color='#222D50'>Convoyeur partenaires Daycar</Text>
                            </Box>
                        </SimpleGrid>
                    </Stack>
                    <Stack>
                        <SimpleGrid templateColumns={'2fr 4fr'} spacing={4}>
                                <Box >
                                    <Text color={'#68696A'} fontSize={'18px'} >Point d'arrivé :</Text>
                                </Box>
                                <Box>
                                    <Text fontSize={'16px'} fontWeight={600} color='#222D50'>{conveyingReturnAddress?.label}</Text>
                                </Box>
                        </SimpleGrid>
                    </Stack>
                    <Stack>
                        <SimpleGrid templateColumns={'2fr 4fr'} spacing={4}>
                            <Box >
                                <Text color={'#68696A'} fontSize={'18px'}>Point depart :</Text>
                            </Box>
                            <Box>
                                <Text fontSize={'16px'} fontWeight={600} color='#222D50'>{conveyingDepartureAddress?.label}</Text>
                            </Box>
                        </SimpleGrid>
                    </Stack>
                    
                    <Stack>
                        <SimpleGrid  spacing={4} templateColumns={{base:'repeat(1,1fr)',sm:'repeat(2,1fr)'}}>
                            <SimpleGrid templateColumns={'3fr 4fr'} spacing={4}>
                                <Box >
                                    <Text color={'#68696A'} fontSize={'18px'}>Distance :</Text>
                                </Box>
                                <Box fontWeight={'bold'}>
                                    <Text fontSize={'16px'} fontWeight={600} color='#222D50'>{conveyingDistance.totalDistance} km</Text>
                                </Box>
                            </SimpleGrid>
                            <SimpleGrid templateColumns={'1fr 4fr'} >
                                <Box >
                                    <Text color={'#68696A'} fontSize={'18px'}>Prix :</Text>
                                </Box>
                                <Box>
                                    <Text fontSize={'16px'} fontWeight={600} color='#0F82EA'>{format2Decimals(conveyingDistance.totalPrice * 1.2)} €</Text>
                                </Box>
                            </SimpleGrid>
                        </SimpleGrid>
                    </Stack>
                </SimpleGrid>
            </Flex>
        </Stack>
    )
}

export default PriceCard;