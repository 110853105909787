import React, { useEffect } from 'react'
import { Box, Button, Center, Flex, Image, Input, SimpleGrid, Spinner, Stack, Text } from '@chakra-ui/react';
import carIcon from '../../assets/images/dadycar-conveying-car-icon.webp';
import cardIcon from '../../assets/images/dadycar-conveying-id-card.webp';
import { DadycarAlert, SearchAddressAutocomplete } from '../sectors/_index';
import { Form, Formik, FormikHelpers } from 'formik';
import InputField from './fields/InputField';
import * as yup from 'yup';
import { useState } from 'react';
import { ConveyingType } from '../../models/Conveying';
import { ConveyingViewModel, AddressViewModel, DistanceViewModel } from '../../view-models/_index';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useNavigate } from 'react-router-dom';

const ConveyingSchemaValidation = yup.object({
  from_date: yup.date().required('La date de départ est obligatoire').min(new Date(), 'La date de départ ne peut pas être dans le passé'),
  to_date: yup.date().min(new Date(), 'La date d\'arrivée ne peut pas être dans le passé'),

  company_name: yup.string().min(2, "Le nom de l'entrepris doit contenir au moins 2 caractères").max(255, "Le nom de l'entrepris doit comporter au maximum 255 caractères"),
  owner_first_name: yup.string().required("Le champ nom est obligatoire.").matches(/^[A-Za-z ]*$/, 'Veuillez entrer un nom valide').min(2, 'Le nom doit contenir au moins 2 caractères').max(255, 'Le nom doit comporter au maximum 255 caractères'),
  owner_last_name: yup.string().required("Le champ prénom est obligatoire.").matches(/^[A-Za-z ]*$/, 'Veuillez entrer un prénom valide').min(2, 'Le prénom doit contenir au moins 2 caractères').max(255, 'Le prénom doit comporter au maximum 255 caractères'),
  owner_email: yup.string().required("Le champ email est obligatoire.").email('Veuillez insérer un e-mail valide.').max(255, 'Le nom doit comporter au maximum 255 caractères'),
  owner_phone: yup.string().required("Le champ numéro de téléphone est obligatoire.").matches(/^[0-9]*$/, 'Veuillez entrer un numéro de téléphone valide').length(10, 'Le numéro de téléphone doit comporter 10 chiffres'),

  departure_contact_first_name: yup.string().required("Le champ nom est obligatoire.").matches(/^[A-Za-z ]*$/, 'Veuillez entrer un nom valide').min(2, 'Le nom doit contenir au moins 2 caractères').max(255, 'Le nom doit comporter au maximum 255 caractères'),
  departure_contact_last_name: yup.string().required("Le champ prénom est obligatoire.").matches(/^[A-Za-z ]*$/, 'Veuillez entrer un prénom valide').min(2, 'Le prénom doit contenir au moins 2 caractères').max(255, 'Le prénom doit comporter au maximum 255 caractères'),
  departure_contact_email: yup.string().required("Le champ email est obligatoire.").email('Veuillez insérer un e-mail valide.').max(255, 'Le nom doit comporter au maximum 255 caractères'),
  departure_contact_phone: yup.string().required("Le champ numéro de téléphone est obligatoire.").matches(/^[0-9]*$/, 'Veuillez entrer un numéro de téléphone valide').length(10, 'Le numéro de téléphone doit comporter 10 chiffres'),

  arrival_contact_first_name: yup.string().required("Le champ nom est obligatoire.").matches(/^[A-Za-z ]*$/, 'Veuillez entrer un nom valide').min(2, 'Le nom doit contenir au moins 2 caractères').max(255, 'Le nom doit comporter au maximum 255 caractères'),
  arrival_contact_last_name: yup.string().required("Le champ prénom est obligatoire.").matches(/^[A-Za-z ]*$/, 'Veuillez entrer un prénom valide').min(2, 'Le prénom doit contenir au moins 2 caractères').max(255, 'Le prénom doit comporter au maximum 255 caractères'),
  arrival_contact_email: yup.string().required("Le champ email est obligatoire.").email('Veuillez insérer un e-mail valide.').max(255, 'Le nom doit comporter au maximum 255 caractères'),
  arrival_contact_phone: yup.string().required("Le champ numéro de téléphone est obligatoire.").matches(/^[0-9]*$/, 'Veuillez entrer un numéro de téléphone valide').length(10, 'Le numéro de téléphone doit comporter 10 chiffres'),
})

const ConveyingForm: React.FC<{ onUserLoginOpen: () => void, onStripeOpen: () => void }> = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isStored, setIsStored] = useState(false);
  const conveyingOwnerAddress = useSelector((state: RootState) => state.address.conveyingOwnerAddress)
  const conveyingDepartureAddress = useSelector((state: RootState) => state.address.conveyingDepartureAddress)
  const conveyingReturnAddress = useSelector((state: RootState) => state.address.conveyingReturnAddress)
  const version = useSelector((state: RootState) => state.version.selected);
  const vehicle = useSelector((state: RootState) => state.vehicle.value);
  const conveying = useSelector((state: RootState) => state.conveying.value);
  const clientSecret = useSelector((state: RootState) => state.conveying.clientSecret);
  const user = useSelector((state: RootState) => state.user.current);
  const getConveyingDistanceWithPrice = (new DistanceViewModel()).getConveyingDistanceWithPrice;
  const addressViewModel = new AddressViewModel();
  const selectConveyingOwnerAddress = addressViewModel.selectConveyingOwnerAddress;
  const selectConveyingDepartureAddress = addressViewModel.selectConveyingDepartureAddress;
  const selectConveyingReturnAddress = addressViewModel.selectConveyingReturnAddress;
  const storeConveying = (new ConveyingViewModel()).store;
  const navigate = useNavigate();

  useEffect(() => {
    if (!(version || vehicle)) {
      navigate('/convoyage', { replace: true });
    }
  }, [])

  useEffect(() => {
    if (isConfirmed && conveying && clientSecret) {
      props.onStripeOpen();
    }
  }, [conveying]);

  useEffect(() => {
    if ((version || vehicle) && conveyingDepartureAddress && conveyingReturnAddress)
      getConveyingDistanceWithPrice({ conveyingDepartureAddress, conveyingReturnAddress }, version?.id, vehicle?.type!);
  }, [conveyingDepartureAddress, conveyingReturnAddress]);

  const onSubmitHandler = async (values: ConveyingType) => {
    if (!isConfirmed)
      setIsConfirmed(true);

    if (isStored) {
      props.onStripeOpen();
      return;
    }

    if (!user) {
      props.onUserLoginOpen();
      return;
    }

    if (conveyingOwnerAddress && conveyingDepartureAddress && conveyingReturnAddress) {
      setIsLoading(true);
      values.client_id = user.id;
      values.owner_address = conveyingOwnerAddress.label;
      values.departure_address = conveyingDepartureAddress.label;
      values.arrival_address = conveyingReturnAddress.label;

      if (vehicle) {
        values.vehicle_id = vehicle.id!;
        values.search_type = 'SV';
        values.vehicle_numberplate = vehicle.plateNumber;
      } else {
        values.vehicle_id = version!.id;
        values.search_type = 'V';
        values.vehicle_numberplate = '';
      }

      const response = await storeConveying(values);
      if (response === 'success') {
        setIsStored(true);
        setIsLoading(false);
      } else
        setIsLoading(false);
    }
  }
  return (
    <>
      <Formik
        initialValues={{
          client_id: 0, from_date: '', to_date: '', search_type: '', vehicle_id: 0, vehicle_numberplate: '',
          owner_first_name: '', owner_last_name: '', company_name: '', owner_email: '', owner_phone: '', owner_address: '',
          departure_contact_first_name: '', departure_contact_last_name: '', departure_contact_email: '', departure_contact_phone: '', departure_address: '',
          arrival_contact_first_name: '', arrival_contact_last_name: '', arrival_contact_email: '', arrival_contact_phone: '', arrival_address: '',
        }}
        validationSchema={ConveyingSchemaValidation}
        onSubmit={(values: ConveyingType, { setSubmitting }: FormikHelpers<ConveyingType>) => {
          setTimeout(() => {
            onSubmitHandler(values);
            setSubmitting(false);
          }, 500);
        }}
      >
        <Form>
          <Stack spacing={4}>
            <Box>
              <Flex alignItems={'center'} position={'relative'} py={3}>
                <Box fontWeight={'bold'}>Dates du transport du véhicule</Box>

              </Flex>
              <DadycarAlert type={'alert'}>
                <Text fontSize={'sm'}>Veuillez renseigner la date à partir de laquelle le véhicule est disponible. Il vous sera alors automatiquement renseigné la date limite de livraison.</Text>
                <br />
                <Text fontSize={'sm'}> Notre chauffeur ne pouvant pas à l’avance vous communiquer les dates précises de prise en charge et de livraison, votre véhicule doit être disponible sur la période entière.</Text>

                <br />
                <Text fontSize={'sm'}> Le chauffeur vous contactera, au plus tard, 24h avant son passage.</Text>
              </DadycarAlert>
              <Flex display={{ base: 'block', lg: 'flex' }}>
                <Box p={4} w={'100%'}>
                  <InputField name="from_date" type="date" placeholder="Disponible à partir du" size='lg' />
                </Box>
                <Box p={4} w={'100%'}>
                  <InputField name="to_date" type="date" placeholder="Date limite de livraison" size='lg' />
                </Box>
              </Flex>
            </Box>


            <Box>
              <Flex alignItems={'center'} position={'relative'} py={3}>
                <Box fontWeight={'bold'}>Propriétaire / Responsable</Box>
              </Flex>

              <Box p={4}>
                <InputField name="company_name" type="text" placeholder="Raison social" size='lg' />
              </Box>

              <SimpleGrid templateColumns={{ base: '1fr', lg: '2fr 2fr' }} spacing={6} p={4}>
                <InputField name="owner_first_name" type="text" placeholder="Prénom" size='lg' />
                <InputField name="owner_last_name" type="text" placeholder="Nom" size='lg' />
                <InputField name="owner_email" type="email" placeholder="Email" size='lg' />
                <InputField name="owner_phone" type="text" placeholder="Téléphone" maxLength={10} />
              </SimpleGrid>

              <Box p={4}>
                <SearchAddressAutocomplete
                  placeholder="Address"
                  address={conveyingOwnerAddress}
                  selectAddress={selectConveyingOwnerAddress}
                  backgroundColor={'white'}
                  name="owner_address"
                />
              </Box>

              <Flex display={{ base: 'block', lg: 'flex' }}>
                <Box p={4} w={'100%'}>
                  <Input placeholder='Ville' value={conveyingOwnerAddress?.city} size='lg' borderRadius={'xl'} type={'text'} bg={'#E8EAF1'} readOnly />
                </Box>
                <Box p={4} w={'100%'}>
                  <Input placeholder='Code Postal' value={conveyingOwnerAddress?.postcode} size='lg' borderRadius={'xl'} type={'text'} bg={'#E8EAF1'} readOnly />
                </Box>
              </Flex>
            </Box>

            <Box>
              <Flex alignItems={'center'} position={'relative'} py={3}>
                <Box fontWeight={'bold'}>Prise en charge et livraison</Box>

              </Flex>
              <DadycarAlert type={'alert'}>
                <Text fontSize={'sm'}>
                  Les contacts au départ et à l’arrivée sont en charge de remettre (ou récupérer) les clés et carte grise (copie) du véhicule. Ils devront être muni d’une pièce d’identité.
                </Text>
                <br />
                <Text fontSize={'sm'}>
                  Veillez à ce que les adresses de départ et d’arrivée soient en capacité d’accueillir un porte-véhicules de 20 mètres de long.
                </Text>
              </DadycarAlert>
            </Box>

            <Box>
              <Flex alignItems={'center'} position={'relative'} py={3}>
                <Box fontWeight={'bold'}>Contact au départ</Box>
              </Flex>

              <SimpleGrid templateColumns={{ base: '1fr', lg: '2fr 2fr' }} spacing={6} p={4}>
                <InputField name="departure_contact_first_name" type="text" placeholder="Prénom" size='lg' />
                <InputField name="departure_contact_last_name" type="text" placeholder="Nom" size='lg' />
                <InputField name="departure_contact_email" type="email" placeholder="Email" size='lg' />
                <InputField name="departure_contact_phone" type="text" placeholder="Téléphone" maxLength={10} />
              </SimpleGrid>

              <Box p={2}>
                <SearchAddressAutocomplete
                  placeholder="Address"
                  address={conveyingDepartureAddress}
                  selectAddress={selectConveyingDepartureAddress}
                  backgroundColor={'white'}
                  name="departure_contact_address"
                />
              </Box>

              <Flex display={{ base: 'block', lg: 'flex' }}>
                <Box p={4} w={'100%'}>
                  <Input placeholder='Ville' value={conveyingDepartureAddress?.city} size='lg' borderRadius={'xl'} type={'text'} bg={'#E8EAF1'} readOnly />
                </Box>
                <Box p={4} w={'100%'}>
                  <Input placeholder='Code Postal' value={conveyingDepartureAddress?.postcode} size='lg' borderRadius={'xl'} type={'text'} bg={'#E8EAF1'} readOnly />
                </Box>
              </Flex>
            </Box>


            <Box>
              <Flex alignItems={'center'} position={'relative'} py={3}>
                <Box fontWeight={'bold'}>Contact à l’arrivée</Box>
              </Flex>

              <SimpleGrid templateColumns={{ base: '1fr', lg: '2fr 2fr' }} spacing={6} p={4}>
                <InputField name="arrival_contact_first_name" type="text" placeholder="Prénom" size='lg' />
                <InputField name="arrival_contact_last_name" type="text" placeholder="Nom" size='lg' />
                <InputField name="arrival_contact_email" type="email" placeholder="Email" size='lg' />
                <InputField name="arrival_contact_phone" type="text" placeholder="Téléphone" maxLength={10} />
              </SimpleGrid>

              <Box p={2}>
                <SearchAddressAutocomplete
                  placeholder="Address"
                  address={conveyingReturnAddress}
                  selectAddress={selectConveyingReturnAddress}
                  backgroundColor={'white'}
                  name="arrival_contact_address"
                />
              </Box>

              <Flex display={{ base: 'block', lg: 'flex' }}>
                <Box p={4} w={'100%'}>
                  <Input placeholder='Ville' value={conveyingReturnAddress?.city} size='lg' borderRadius={'xl'} type={'text'} bg={'#E8EAF1'} readOnly />
                </Box>
                <Box p={4} w={'100%'}>
                  <Input placeholder='Code Postal' value={conveyingReturnAddress?.postcode} size='lg' borderRadius={'xl'} type={'text'} bg={'#E8EAF1'} readOnly />
                </Box>
              </Flex>
            </Box>

            <Box>
              <Flex alignItems={'center'} position={'relative'} py={3}>
                <Box fontWeight={'bold'}>Conditions particulières</Box>

              </Flex>
              <DadycarAlert >
                <Text fontWeight={'bold'}>Transport par camion : </Text>
                <br />
                <Text fontSize={'sm'}>
                  Daycar s’engage à vous mettre en relation avec un transporteur camion pour réaliser le transport de votre véhicule entre deux adresses de votre choix.
                </Text>
                <br />
                <Text fontWeight={'bold'}>Compris dans le prix :</Text>
                <br />
                <Text fontSize={'sm'}>
                  Assurance tout risque (voir les détail de l’assurance), péages, carburant.
                  Conditions d’annulation :
                  Si votre véhicule n’est pas encore affecté à un transporteur camion :
                  {' >>'} Plus de 48h (hors dimanche et jours fériés) avant la date limite de livraison : Annulation gratuite
                  {' >>'} Entre 24 et 48h avant la date limite de livraison : Remboursement de 50% du prix de la commande
                  {' >>'} Moins de 24h avant la date limite de livraison : Aucun remboursement
                </Text>
                <br />
                <Text>
                  Si votre véhicule est déjà affecté à un transporteur camion :
                  {' >>'} Plus de 48h (hors dimanche et jours fériés) avant la date de d’enlèvement : Annulation gratuite
                  {' >>'} Entre 24 et 48h avant la date d’enlèvement : Remboursement de 50% du prix de la commande
                  {' >>'} Moins de 24h avant la date d’enlèvement : Aucun remboursement
                </Text>
                <br />
                <Text fontWeight={'bold'}>Conditions de transport :</Text>
                <br />
                <Text fontSize={'sm'}>
                  Vous vous engagez à mettre à disposition un véhicule assuré et dans l’état de fonctionnement mentionné. Veuillez noter qu’un véhicule “”en panne”” doit néanmoins posséder ses 4 roues, une direction et des freins en état de marche. Dans le cas contraire, il s’agit d’un véhicule “”accidenté”” et vous devez revenir à l’étape précédente pour modifier l’état du véhicule. Un état des lieux au départ et à l’arrivée sera effectué. Pour des raisons de sécurité et car le véhicule ne sera pas vérouillé, veuillez ne rien stocker dans le véhicule.
                </Text>
              </DadycarAlert>
            </Box>
            <Box py={10}>
              <Text fontWeight={'bold'}>Documents à apporter lors du départ</Text>
              <SimpleGrid py={5} templateColumns={{ base: '1fr', lg: '2fr 2fr' }} spacing={6}>
                <Stack borderRadius={'2xl'} boxShadow={'lg'} p={5}>
                  <Center>
                    <Image src={carIcon} />
                  </Center>
                  <Center fontWeight={'bold'}>Copie de la carte grise</Center>
                </Stack>
                <Stack borderRadius={'2xl'} boxShadow={'lg'} p={5}>
                  <Center>
                    <Image src={cardIcon} />
                  </Center>
                  <Center fontWeight={'bold'}>Votre pièce d’identité</Center>
                </Stack>
              </SimpleGrid>
              <Center py={10}>
                <Button type='submit' bg={'#5393EC'} color={'white'} borderRadius={'xl'} _hover={{ bg: '#5393EC' }}>
                  {isLoading ?
                    <Center>
                      <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='md' />
                    </Center>
                    :
                    <>
                      Valider ma commande
                    </>
                  }
                </Button>
              </Center>
            </Box>
          </Stack>
        </Form>
      </Formik>
    </>
  )
}

export default ConveyingForm;