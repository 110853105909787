import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Avatar, Box, Card, CardBody, CardHeader, Center, Container, Flex, Heading, Image, List, ListItem, Progress, SimpleGrid, Spinner, Stack, Text } from '@chakra-ui/react';
import checkbox from '../../../../assets/images/dadycar-checkbox-green.webp';
import locationLogo from '../../../../assets/images/dadycar-Icon-material-location-blue.webp';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { VendorViewModel } from '../../../../view-models/_index';
import { RootState } from '../../../../store';
import { useSelector } from 'react-redux';
import { Map } from '../../../../components/sectors/_index';

const DetailsSection: React.FC = () => {
    const { garageId } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const vendorViewModel = new VendorViewModel();
    const getVendorDetails = vendorViewModel.getVendorDetails;
    const getReferncedVendorDetails = vendorViewModel.getReferncedVendorDetails;
    const vendor = useSelector((state: RootState) => state.vendor.details);
    const location = useLocation();
    const currentPath = location.pathname;
    const garageType = currentPath.split('/')[1] === 'garage' ? 'partner' : 'referenced-garages';

    useEffect(() => {
        const initVendorDetails = async () => {
            if (garageId) {
                setIsLoading(true);
                if (garageType === 'partner')
                    await getVendorDetails(Number(garageId));
                else
                    await getReferncedVendorDetails(Number(garageId));
                setIsLoading(false);
            }
        }

        initVendorDetails();
    }, []);
    return (
        <>
            <Box bg={'#F6FAFC'} p={3}>
                <Container maxW={'100%'} py={{ base: 0, lg: 12 }} w={{ lg: '1300px' }} position={'relative'}>
                    <Box p={3} bg={'white'} borderRadius={'2xl'} boxShadow={'md'} >
                        {isLoading ?
                            <>
                                <Center py={'20'}>
                                    <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
                                </Center>
                            </>
                            :
                            <>
                                {vendor ?
                                    <>
                                        {garageType === 'partner' ?
                                            <>
                                                <Flex justifyContent={{ base: 'center', md: 'start' }} position={'relative'}>
                                                    <Center w={{ base: '100px', lg: '150px' }} h={{ base: '50px', lg: '80px' }} p={2} position={'relative'} mx={2} borderRadius={'xl'} bg={'#DFF7FC'} >
                                                        <Image w={'100%'} h={'100%'} src={vendor.image} />
                                                    </Center>
                                                    <Center>
                                                        <Box>
                                                            <Heading textAlign={{ base: 'center', lg: 'start' }} size={{ base: 'xs', lg: 'md' }}>{vendor.name}</Heading>
                                                            <Center justifyContent={'start'} color={'#1385EB'} position={'relative'}>
                                                                <Image src={locationLogo} h={'20px'} px={2} />
                                                                <Text fontSize={{ base: 'xs', lg: 'md' }} color={'#1385EB'}>{vendor.address}</Text>
                                                            </Center>
                                                        </Box>
                                                        <Center p={2} display={{ base: 'none', md: 'unset' }} position={'absolute'} top={0} right={0} w={'100px'} mx={2} borderRadius={'xl'} bg={'#DFF7FC'} h={'60px'}>
                                                            <Box>
                                                                <Text textAlign={'center'} fontWeight={'bold'}>{vendor.reviews + '/5'}</Text>
                                                                <Text textAlign={'center'} fontSize={'xs'} fontWeight={'bold'} color={'#1385EB'}>{"(0 Avis)"}</Text>
                                                            </Box>
                                                        </Center>
                                                    </Center>
                                                </Flex>
                                                <Flex display={{ base: 'block', lg: 'flex' }}>
                                                    <Box py={6} px={3} w={{ lg: '50%' }}>
                                                        <Heading size={'xs'} color={'#1385EB'}>HORAIRES D’OUVERTURE</Heading>
                                                        <List>
                                                            {vendor.week_schedule?.map(schedule => (
                                                                <>
                                                                    {
                                                                        schedule.closed ?
                                                                            <>
                                                                                <ListItem fontSize={{ base: 'xs', lg: 'md' }} color={'grey'}>{schedule.week_day_name_fr} : Fermé</ListItem>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <ListItem fontSize={{ base: 'xs', lg: 'md' }} color={'grey'}>{schedule.week_day_name_fr} : {schedule.opening_time} - {schedule.closing_time}</ListItem>
                                                                            </>
                                                                    }
                                                                </>
                                                            ))}
                                                        </List>
                                                    </Box>
                                                    <Box p={4} h={'100%'} w={{ lg: '50%' }}>
                                                        <Heading size={'xs'} color={'#1385EB'}>LOCALISATION</Heading>
                                                        <Box h={'150px'} boxShadow={'md'} borderRadius={'2xl'} w={{ lg: '100%' }} position={'relative'}>
                                                            <Map center={{ lat: vendor.lat, lng: vendor.lng }} />
                                                        </Box>
                                                    </Box>

                                                </Flex>
                                                <Box p={3}>
                                                    <Heading size={'xs'} color={'#1385EB'}>LES SERVICES PROPOSÉS PAR LE {(vendor.name).toUpperCase()}</Heading>
                                                    <SimpleGrid templateColumns={{ base: '1fr', md: '2fr 2fr', lg: '2fr 2fr 2fr' }}>
                                                        {vendor.services?.map(service => (
                                                            <Stack key={service.id}>
                                                                <Flex alignItems={'center'}>
                                                                    <Image h={'20px'} src={checkbox} />
                                                                    <Text p={2}>{service.name}</Text>
                                                                </Flex>
                                                            </Stack>
                                                        ))}
                                                    </SimpleGrid>
                                                </Box>
                                                {/* <Box p={3}>
                                                        <Heading size={'xs'} color={'#1385EB'}>AVIS CLIENT SUR LE GARAGE AUTO DEPANNAGE 38</Heading>
                                                        <List px={2}>
                                                            <ListItem position={'relative'} color={'grey'}>Accueil
                                                                <Flex px={4} w={'100%'} justify={'center'} justifyContent={'space-between'}>
                                                                    2.5/5<Progress borderRadius={'md'} colorScheme='twitter' value={50} w={'40%'} />
                                                                </Flex>
                                                            </ListItem>
                                                            <ListItem position={'relative'} color={'grey'}>Ponctualité
                                                                <Flex px={4} w={'100%'} justify={'center'} justifyContent={'space-between'}>
                                                                    4.5/5<Progress borderRadius={'md'} colorScheme='twitter' value={80} w={'40%'} />
                                                                </Flex>
                                                            </ListItem>
                                                            <ListItem position={'relative'} color={'grey'}>Conseils
                                                                <Flex px={4} w={'100%'} justify={'center'} justifyContent={'space-between'}>
                                                                    4.5/5<Progress borderRadius={'md'} colorScheme='twitter' value={80} w={'40%'} />
                                                                </Flex>
                                                            </ListItem>
                                                            <ListItem position={'relative'} color={'grey'}>Délai de restitution
                                                                <Flex px={4} w={'100%'} justify={'center'} justifyContent={'space-between'}>
                                                                    4.5/5<Progress borderRadius={'md'} colorScheme='twitter' value={80} w={'40%'} />
                                                                </Flex>
                                                            </ListItem>
                                                        </List>
                                                </Box> */}
                                                {/* <Box p={3}>
                                                        <Heading size={'xs'} color={'#1385EB'}>CLIENT</Heading>

                                                        <Box py={3}>
                                                            <Card maxW='100%'>
                                                                <CardHeader>
                                                                    <Flex >
                                                                        <Flex flex='1' gap='4' alignItems='center' flexWrap='wrap'>
                                                                            <Avatar name='Segun Adebayo' src='https://bit.ly/sage-adebayo' />

                                                                            <Box>
                                                                                <Heading size={{ base: 'xs', md: 'sm' }}>Mohammed Ainoz</Heading>
                                                                                <Text fontSize={{ base: 'xs', md: 'sm' }}>Il y a plus d'un an</Text>
                                                                            </Box>
                                                                        </Flex>
                                                                        <Center p={2} w={'70px'} mx={2} borderRadius={'xl'} bg={'#DFF7FC'} h={'100%'}>
                                                                            <Box>
                                                                                <Text textAlign={'center'} fontWeight={'bold'}>4.5/5</Text>
                                                                            </Box>
                                                                        </Center>
                                                                    </Flex>
                                                                </CardHeader>
                                                                <CardBody>
                                                                    <Accordion allowMultiple >
                                                                        <AccordionItem borderRadius={'xl'} border={'none'}>
                                                                            <h2>
                                                                                <AccordionButton>
                                                                                    <Box as="span" flex='1' textAlign='left'>
                                                                                        Impeccable, révision faite pas de soucis très bon acceuil
                                                                                    </Box>
                                                                                    <AccordionIcon />
                                                                                </AccordionButton>
                                                                            </h2>
                                                                            <AccordionPanel pb={4}>
                                                                                <Box>
                                                                                    <List px={2}>
                                                                                        <ListItem position={'relative'} color={'grey'}>Accueil
                                                                                            <Flex px={4} w={'100%'} justify={'center'} justifyContent={'space-between'}>
                                                                                                2.5/5<Progress borderRadius={'md'} colorScheme='twitter' value={50} w={'40%'} />
                                                                                            </Flex>
                                                                                        </ListItem>
                                                                                        <ListItem position={'relative'} color={'grey'}>Ponctualité
                                                                                            <Flex px={4} w={'100%'} justify={'center'} justifyContent={'space-between'}>
                                                                                                4.5/5<Progress borderRadius={'md'} colorScheme='twitter' value={80} w={'40%'} />
                                                                                            </Flex>
                                                                                        </ListItem>
                                                                                        <ListItem position={'relative'} color={'grey'}>Conseils
                                                                                            <Flex px={4} w={'100%'} justify={'center'} justifyContent={'space-between'}>
                                                                                                4.5/5<Progress borderRadius={'md'} colorScheme='twitter' value={80} w={'40%'} />
                                                                                            </Flex>
                                                                                        </ListItem>
                                                                                        <ListItem position={'relative'} color={'grey'}>Délai de restitution
                                                                                            <Flex px={4} w={'100%'} justify={'center'} justifyContent={'space-between'}>
                                                                                                4.5/5<Progress borderRadius={'md'} colorScheme='twitter' value={80} w={'40%'} />
                                                                                            </Flex>
                                                                                        </ListItem>
                                                                                    </List>
                                                                                </Box>
                                                                            </AccordionPanel>
                                                                        </AccordionItem>
                                                                    </Accordion>
                                                                </CardBody>
                                                            </Card>
                                                        </Box>
                                                    </Box> */}
                                            </>
                                            :
                                            <>
                                                <Flex justifyContent={{ base: 'center', md: 'start' }} position={'relative'} minH={'240px'}>
                                                    <Flex h={{ base: '50px', lg: '100px' }}>
                                                        <Center w={{ base: '100px', lg: '150px' }} h={{ base: '50px', lg: '100px' }} p={2} position={'relative'} mx={2} borderRadius={'xl'} bg={'#DFF7FC'} >
                                                            <Image w={'100%'} h={'100%'} src={vendor.image} />
                                                        </Center>
                                                        <Center>
                                                            <Box>
                                                                <Heading textAlign={{ base: 'center', lg: 'start' }} size={{ base: 'xs', lg: 'md' }}>{vendor.name}</Heading>
                                                                <Center justifyContent={'start'} color={'#1385EB'} position={'relative'}>
                                                                    <Image src={locationLogo} h={'20px'} px={2} />
                                                                    <Text fontSize={{ base: 'xs', lg: 'md' }} color={'#1385EB'}>{vendor.address}</Text>
                                                                </Center>
                                                            </Box>
                                                        </Center>
                                                    </Flex>
                                                    <Center p={2} w={'50%'} position={'absolute'} top={0} right={0} mx={2} borderRadius={'xl'}>
                                                        <Box w={'100%'}>
                                                            <Heading size={'xs'} color={'#1385EB'}>LOCALISATION</Heading>
                                                            <Box h={'200px'} boxShadow={'md'} borderRadius={'2xl'} w={{ lg: '100%' }} position={'relative'}>
                                                                <Map center={{ lat: vendor.lat, lng: vendor.lng }} />
                                                            </Box>
                                                        </Box>
                                                    </Center>
                                                </Flex>
                                            </>
                                        }
                                    </>
                                    :
                                    <>
                                        <Center py={'20'}>
                                            <Text>Garage introuvable!</Text>
                                        </Center>
                                    </>
                                }
                            </>
                        }
                    </Box>
                </Container>
            </Box>
        </>
    )
}

export default DetailsSection