import { extendTheme } from '@chakra-ui/react'
import "@fontsource/plus-jakarta-sans";

const Theme = extendTheme({
  fonts: {
    heading: 'Plus Jakarta Sans,  sans-serif',
    body: 'Plus Jakarta Sans,  sans-serif',
  },
  colors: {
    dadycarBlue: '#5393EC',
    dadycarMainBlue: '#0F82EA',
    dadycarGradienBluer: 'linear-gradient(90deg, #00B1B7 0%, #0075F5 100%)',
  },
})

export default Theme