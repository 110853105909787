import { Box, Button, Center, Container, Heading, Image, SimpleGrid, Stack, Text, VStack } from "@chakra-ui/react";
import convoyage_services from '../../../../../assets/V2/convoyage_services.webp'
import { SectionHeader } from "../../../../../components/sections/_index";

const ServiceSection: React.FC = () => {
    return (
        <Box
            bg={'white'}
            py={5}
        >
            <Container maxW={'80%'} w={{ lg: '1400px' }}>
                <Box py={5}>
                    <SimpleGrid templateColumns={{ base: '1fr', lg: '2fr 2fr' }} spacing={6}>
                        <Stack>
                                <Image alt="Dadycar Covoyage" src={convoyage_services} htmlHeight={'100%'} htmlWidth={"100%"}/>
                        </Stack>
                        <Stack alignSelf={'center'}>
                                <VStack alignItems='start'>
                                    <Text color='#0F82EA' fontSize='20px'>Faites le bon choix</Text>
                                    <Heading fontSize={{base:'22px',sm:'32px',md:'40px'}}  color={'black'}>Les meilleures solutions de transports sont chez Dadycar</Heading>
                                    <Text fontSize={{base:'18px',sm:'20px'}} color={'#0F82EA'} py={2}>
                                    Des Chauffeurs / Convoyeurs Professionnels
                                    </Text>
                                    <Text pb={4} color={'#222D50'} fontSize={{base:'16px',sm:'18px',md:'22px'}} >Des chauffeurs qualifiés sous 72h pour un convoyage ultra rapide!</Text>
                                    <Button color='white' bg='#0F82EA' _hover={{bg:'#3563E9'}} rounded='30px' fontSize={{base:'14px',md:'16px'}} px={'24px'}  h='52px' >Faire transporter mon véhicule</Button>
                                </VStack>
                        </Stack>
                    </SimpleGrid>
                </Box>
            </Container>
        </Box>
    )
}

export default ServiceSection;