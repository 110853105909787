import { Box, Text, VStack } from "@chakra-ui/react"
import RendezVous from "./RendezVous"
import Incoming from "./Incoming"
import RDV from "./RDV"

const Statistiques = () => {
  return (
    <Box flex={2.5} bg={'#0F82EA21'}>
        <Box px={4} py={2} >
            <Box>
                <Text fontSize={'20px'} fontWeight={600} color={'#222D50'}>Statistiques</Text>
                <Text fontWeight={400} fontSize={'14px'} color={'#A3A3A3'}>Tue, 14 Nov, 2022, 11.30 AM </Text>
            </Box>
            <RendezVous />
            <Incoming />
            <RDV />
        </Box>
    </Box>
  )
}

export default Statistiques