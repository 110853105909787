import { Box, Button, Container, Flex, Grid, Text } from "@chakra-ui/react"
import { items } from "./constants"
import Item from "./Item"
import { Link } from "react-router-dom"
import { ServiceViewModel } from "../../../../../../view-models/_index"
import { useSelector } from "react-redux"
import { RootState } from "../../../../../../store"
import { useEffect, useState } from "react"

const Prestations = () => {
    const getServices = (new ServiceViewModel).getServices;
    const services = useSelector((state: RootState) => state.service.list);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const initServices = async () => {
            setIsLoading(true);
            await getServices();
            setIsLoading(false);
        }
        initServices();
    }, [])
    console.log("sssssssssssssssssssssssssssssssssssssssss")
    console.log(services)
  return (
    <Box>
        <Container maxW={'90%'} w={{ lg: '1200px' }}>
            <Box pt={20} pb={5}>
                <Text fontSize={'20px'} fontWeight={600} color={'#0F82EA'}>Nos prestations d’entretien et de réparations</Text>
                <Text fontSize={{base:'25px',sm:'35px',md:'40px'}} fontWeight={700} color={'#0D121F'} w={{lg:'60%'}}>Réparez et entretenez votre véhicule à un prix avantageux grâce à Dadycar</Text>
                <Grid mt={4} gap={4} templateColumns={{base:'repeat(1,1fr)',sm:'repeat(2,1fr)',md:'repeat(3,1fr)',lg:'repeat(4,1fr)',xl:'repeat(5,1fr)',}} >
                    {items.map((item,index)=>(
                        <Item key={item.id} index={index} img={item.img} name={item.name} />
                    ))}
                </Grid>
                <Flex justifyContent='center' mt={8} >
                <Link to={'https://meetings-eu1.hubspot.com/meetings/amine-yahia-cherif?uuid=d67be381-7bc7-461c-ab1c-f55d5ebd83c9'} target="_blank">
                    <Button variant='outline' _hover={{bg:'#0F82EA',color:'white'}} rounded='30px' fontSize={{base:'14px',md:'16px'}} borderWidth={'1px'} borderColor={'#0F82EA'} color='#0F82EA'  w='187px' h='52px'>Voir plus</Button>
                </Link>
                </Flex>
            </Box>
        </Container>
    </Box>
  )
}

export default Prestations