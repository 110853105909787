import { Box, Text } from '@chakra-ui/react'
import { useParams } from 'react-router-dom';

const SearchSection: React.FC = () => {
    const { cityParams } = useParams();
    let cityName = cityParams ? cityParams[0].toUpperCase() + cityParams.slice(1) : '';
    cityName = cityName.replaceAll('-', ' ');

    return (
        <Box py={10} bg={'#F6FAFC'} mt={{ base: 10, lg: 32 }}>
            <Box mx={20} py={10}>
                <Text textAlign={'center'}>Retrouvez les meilleurs garages auto {cityParams && 'à ' + cityName} pour l’entretien auto courant : révision, plaquettes de frein, kit de distribution, décalaminage, embrayage, parallélisme, et la réparation de votre voiture quel que soit la marque et le modèle du véhicule. Trouvez le garage auto le moins cher et comparez rapidement les prix, tarifs et forfaits des garages grâce au devis instantané et la réservation de votre rendez-vous directement en ligne en quelques clics.</Text>
            </Box>
        </Box>
    )
}

export default SearchSection;