import { Box, Flex, FormControl, FormLabel, Grid, Input, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react'
import { Field, FieldProps } from 'formik'
import Select from 'react-select';
import { FormValues, brandOptions, customStyles, modeleOptions, versionOptions } from '../../constants';

type Props = {
    values:FormValues,
    setFieldValue:any,
    handleBlur:any
}

const FirstStep = ({values,setFieldValue,handleBlur}:Props) => {
  return (
    <Box>
        <Text mb={2} fontWeight={600} fontSize={'16px'} color={'#222D50'}>Sélection du véhicule</Text>
        <Tabs defaultIndex={1}  variant='unstyled' colorScheme='blue'>
            <Flex justifyContent={'center'} alignItems={'center'}>
                <TabList transition={'1s'} rounded={'37px'} color={'white'}>
                    <Tab w='200px' h={'48px'} roundedLeft={'37px'}  bg={'#E0E0E0'} color={'#222D50'}  _selected={{ color: 'white', bg: '#0F82EA',zIndex:100,roundedRight:'37px' }}>Immatricule</Tab>
                    <Tab w='200px' h={'48px'}  rounded={'37px'} ml={-10} bg={'#E0E0E0'} color={'#222D50'} _selected={{ color: 'white', bg: '#0F82EA' }}>Marques</Tab>
                </TabList>
            </Flex>
            <TabPanels>
                <TabPanel>
                    <p>input here</p>
                </TabPanel>
                <TabPanel>
                    <Grid templateColumns={`repeat(2,1fr)`} gap={4}>
                        <Box>
                            <Field name={'immatricule'}>
                                {({ field }: FieldProps) => (
                                <FormControl>
                                    <FormLabel fontWeight={600} color={'#1D2433'}>Immatricule</FormLabel>
                                    <Input
                                        py={'8px'}
                                        px={'16px'}
                                        outline={'none'}
                                        borderColor={'#E1E6EF'}
                                        _focus={{borderColor:'#2F6FED'}}
                                        rounded={'8px'}
                                        type='text'
                                        {...field} 
                                        id={'immatricule'}
                                        placeholder='Ex: ep-234-wx'
                                        h={'47px'}
                                    />
                                </FormControl>
                                )}
                            </Field>
                        </Box>
                        <Box>
                            <FormLabel fontWeight={600} color={'#1D2433'}>Modele</FormLabel>
                            <Box h='47px' >
                                <Select
                                    styles={customStyles}
                                    value={values?.modele ? {value:values.modele,label:values.modele} : null}
                                    id={"modele"}
                                    placeholder='Ex: Berlingo'
                                    onChange={option => {
                                        setFieldValue("modele", option?.value)
                                    }}
                                    options={modeleOptions}
                                    onBlur={handleBlur}
                                    noOptionsMessage={() => "No options available"}
                                />
                            </Box>
                        </Box>
                        <Box>
                            <FormLabel fontWeight={600} color={'#1D2433'}>Brand</FormLabel>
                            <Box h='47px' >
                                <Select
                                    styles={customStyles}
                                    value={values?.brand ? {value:values.brand,label:values.brand} : null}
                                    id={"brand"}
                                    placeholder='Ex : Renault'
                                    onChange={option => {
                                        setFieldValue("brand", option?.value)
                                    }}
                                    options={brandOptions}
                                    onBlur={handleBlur}
                                />
                            </Box>
                        </Box>
                        <Box>
                            <FormLabel fontWeight={600} color={'#1D2433'}>Version</FormLabel>
                            <Box h='47px' >
                                <Select
                                    styles={customStyles}
                                    value={values?.version ? {value:values.version,label:values.version} : null}
                                    id={"version"}
                                    placeholder='Ex : 1.6BlueHDI 100'
                                    onChange={option => {
                                        setFieldValue("version", option?.value)
                                    }}
                                    options={versionOptions}
                                    onBlur={handleBlur}
                                />
                            </Box>
                        </Box>
                        <Box>
                            <Field name={'n_serie'}>
                                {({ field }: FieldProps) => (
                                <FormControl>
                                    <FormLabel fontWeight={600} color={'#1D2433'}>N° de Série</FormLabel>
                                    <Input
                                        py={'8px'}
                                        px={'16px'}
                                        outline={'none'}
                                        borderColor={'#E1E6EF'}
                                        _focus={{borderColor:'#2F6FED'}}
                                        rounded={'8px'}
                                        type='text'
                                        {...field} 
                                        id={'n_serie'}
                                        placeholder='Ex : 23658'
                                        h={'47px'}
                                    />
                                </FormControl>
                                )}
                            </Field>
                        </Box>
                        <Box>
                            <Field name={'anne_circulation'}>
                                {({ field }: FieldProps) => (
                                <FormControl>
                                    <FormLabel fontWeight={600} color={'#1D2433'}>Année circulation</FormLabel>
                                    <Input
                                        py={'8px'}
                                        px={'16px'}
                                        outline={'none'}
                                        borderColor={'#E1E6EF'}
                                        _focus={{borderColor:'#2F6FED'}}
                                        rounded={'8px'}
                                        type='text'
                                        {...field} 
                                        id={'anne_circulation'}
                                        placeholder='Ex: 2022'
                                        h={'47px'}
                                    />
                                </FormControl>
                                )}
                            </Field>
                        </Box>
                        <Box>
                            <Field name={'carburant'}>
                                {({ field }: FieldProps) => (
                                <FormControl>
                                    <FormLabel fontWeight={600} color={'#1D2433'}>Carburant</FormLabel>
                                    <Input
                                        py={'8px'}
                                        px={'16px'}
                                        outline={'none'}
                                        borderColor={'#E1E6EF'}
                                        _focus={{borderColor:'#2F6FED'}}
                                        rounded={'8px'}
                                        type='text'
                                        {...field} 
                                        id={'carburant'}
                                        placeholder='Ex : Gazole'
                                        h={'47px'}
                                    />
                                </FormControl>
                                )}
                            </Field>
                        </Box>
                    </Grid>
                </TabPanel>
            </TabPanels>
        </Tabs>
        
    </Box>
  )
}

export default FirstStep