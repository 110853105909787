import { createSlice } from '@reduxjs/toolkit';
import { AddressType } from '../../models/Address';

type SliceState = {
    list: AddressType[] | null,
    departureAddress: AddressType | null,
    returnAddress: AddressType | null,
    conveyingOwnerAddress: AddressType | null,
    conveyingDepartureAddress: AddressType | null,
    conveyingReturnAddress: AddressType | null,
};

const initialState: SliceState = { list: null, departureAddress: null, returnAddress: null, conveyingOwnerAddress: null, conveyingDepartureAddress: null, conveyingReturnAddress: null }

const addressSlice = createSlice({
    name: 'address',
    initialState,
    reducers: {
        updateList(state, action) {
            let array: AddressType[] = [];
            action.payload.map((address: any) => {
                array.push({
                    'value': address['properties']['id'],
                    'label': address['properties']['label'],
                    'city': address['properties']['city'],
                    'postcode': address['properties']['postcode'],
                    'lat': address['geometry']['coordinates'][1],
                    'lng': address['geometry']['coordinates'][0],
                });
            })
            state.list = array;
        },
        updateClientAddress(state, action) {
            state.departureAddress = action.payload;
            state.returnAddress = action.payload;
        },
        updateDepartureAddress(state, action) {
            state.departureAddress = action.payload;
        },
        updateReturnAddress(state, action) {
            state.returnAddress = action.payload;
        },
        updateConveyingOwnerAddress(state, action) {
            state.conveyingOwnerAddress = action.payload;
        },
        updateConveyingDepartureAddress(state, action) {
            state.conveyingDepartureAddress = action.payload;
        },
        updateConveyingReturnAddress(state, action) {
            state.conveyingReturnAddress = action.payload;
        },
        resetClientAddress(state) {
            state.list = null;
            state.departureAddress = null;
            state.returnAddress = null;
        },
    },
});

export const addressActions = addressSlice.actions;
export default addressSlice.reducer;