import { createSlice } from '@reduxjs/toolkit';
import { RegionType } from '../../models/Region';

type SliceState = { list: RegionType[] | null, selected: RegionType | null };

const initialState: SliceState = { list: null, selected: null }

const regionSlice = createSlice({
  name: 'region',
  initialState,
  reducers: {
    updateList(state, action) {
      state.list = action.payload;
    },
    updateSelected(state, action) {
      state.selected = action.payload;
    },
  },
});

export const regionActions = regionSlice.actions;
export default regionSlice.reducer;