import { Icon, Accordion, AccordionButton, AccordionItem, Box, Button, Center, Heading, Image, List, ListItem, SimpleGrid, Stack, Text } from '@chakra-ui/react'
import locationLogo from '../../../../../../assets/images/dadycar-Icon-material-location-blue.webp';
import { VendorType } from '../../../../../../store/slices/vendor-slice';
import { Link } from 'react-router-dom';
import HttpClient from '../../../../../../services/HttpClient';
import { BsFillTelephoneFill } from 'react-icons/bs';
import { TbWorld } from 'react-icons/tb';

const ReferencedVendorCard: React.FC<{ vendor: VendorType }> = ({ vendor }) => {
    const slug = (vendor.name).replace(/\s+/g, '-').toLowerCase();

    return (
        <Box bg={'white'} boxShadow={'md'} borderRadius={'2xl'} px={3} py={4} border={'2px solid white'} _hover={{ border: '2px solid #1385EB' }}>
            <SimpleGrid templateColumns={{ base: '1fr', lg: '60% 40%', md: '1fr' }} position={'relative'}>
                <Stack >
                    <Accordion allowToggle>
                        <AccordionItem>
                            <h2>
                                <Link to={`/garages/${(slug)}/${vendor.id}`} style={{ textDecoration: 'underline' }}>
                                    <AccordionButton position={'relative'}>
                                        <Center position={'relative'} w={{ base: '30%', xl: '30%', lg: '50%', md: '20%' }} mx={2} borderRadius={'xl'} bg={'#DFF7FC'} h={'70px'}>
                                            <Image borderRadius={'xl'} src={vendor.image} />
                                        </Center>
                                        <Box w={{ base: '70%', xl: '70%', lg: '50%', md: '80%' }}>
                                            <Heading size={'xs'} textAlign={'start'}>
                                                {vendor.name}
                                            </Heading>
                                        </Box>
                                    </AccordionButton>
                                </Link>
                            </h2>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionButton position={'relative'}>
                                <Center position={'relative'} w={{ base: '30%', xl: '30%', lg: '50%', md: '20%' }} mx={2} borderRadius={'xl'} bg={'#DFF7FC'} h={'70px'}>
                                    <Image h={'70%'} src={locationLogo} />
                                </Center>
                                <Box w={{ base: '70%', xl: '70%', lg: '50%', md: '80%' }}>
                                    <Heading size={'sm'} textAlign={'start'}>

                                    </Heading>
                                    <Text textAlign={'start'} fontSize={'sm'}>
                                        {vendor.address}
                                    </Text>
                                </Box>
                            </AccordionButton>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionButton position={'relative'}>
                                <Center position={'relative'} w={{ base: '30%', xl: '30%', lg: '50%', md: '20%' }} mx={2} borderRadius={'xl'} bg={'#DFF7FC'} h={'70px'}>
                                    <Icon as={BsFillTelephoneFill} color={'#1985e4'} fontSize={'4xl'} />
                                </Center>
                                <Box w={{ base: '70%', xl: '70%', lg: '50%', md: '80%' }}>
                                    <Heading size={'sm'} textAlign={'start'}>

                                    </Heading>
                                    <Text textAlign={'start'} fontSize={'sm'}>
                                        {vendor.phone_number}
                                    </Text>
                                </Box>
                            </AccordionButton>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionButton position={'relative'}>
                                <Center position={'relative'} w={{ base: '30%', xl: '30%', lg: '50%', md: '20%' }} mx={2} borderRadius={'xl'} bg={'#DFF7FC'} h={'70px'}>
                                    <Icon as={TbWorld} color={'#1985e4'} fontSize={'4xl'} />
                                </Center>
                                <Box w={{ base: '70%', xl: '70%', lg: '50%', md: '80%' }}>
                                    <Heading size={'sm'} textAlign={'start'}>

                                    </Heading>
                                    <Text textAlign={'start'} fontSize={'sm'}>
                                        {vendor.website}
                                    </Text>
                                </Box>
                            </AccordionButton>
                        </AccordionItem>
                    </Accordion>
                </Stack>
                <Stack h={'100%'} position={'relative'}>
                    <Center pt={16} h={'80%'}>
                        <Box>
                            <Text color={'#1385EB'}>Vous êtes le propriétaire de cet établissement ?</Text>
                        </Box>
                    </Center>
                    <Box w={'100%'} pt={2}>
                        <Link to={HttpClient.host + 'garage/register'} target={'_blank'}>
                            <Button fontSize={'small'} w={'100%'} color={'white'} bg={'#1385EB'} _hover={{ bg: '#1385EB' }}>
                                Je suis le propriétaire
                            </Button>
                        </Link>
                    </Box>
                </Stack>
            </SimpleGrid>
        </Box>
    )
}

export default ReferencedVendorCard;