import { useDispatch } from "react-redux";
import { StoreContactType } from "../models/Contact";
import { Contact } from "../models/_index";
import { toastActions } from "../store/slices/toast-slice";

const SuccessStoreToast = {
    title: "Votre message a été bien envoyé.",
    message: "Nous vous contacterons dans les plus brefs délais.",
    status: "success",
}

class ContactViewModel {

    dispatch = useDispatch();

    public store = async (Data: StoreContactType) => {
        const response = await Contact.store(Data);

        if (response.status === 200) {
            this.dispatch(toastActions.display(SuccessStoreToast));
            return { status: 'success' };
        } else if (response.status === 422) {
            this.dispatch(toastActions.display({
                title: "Erreur",
                message: response.data.message,
                status: "error",
            }));
        } else {
            this.dispatch(toastActions.display({
                title: "Erreur",
                message: 'Quelque chose s\'est mal passé.',
                status: "error",
            }));
        }
        return { status: 'error' };
    }
}

export default ContactViewModel;