import { Box } from '@chakra-ui/react'
import { useState } from 'react';
import { DadyCarServiceDescription, AddressForm, BookingSummary} from './components/_index'

const SammurySection: React.FC = () => {
    const [addressFormVisibility, setAddressFormVisibility] = useState(false);

    return (
        <Box bg={'#F6FAFC'}>
            <DadyCarServiceDescription />
            {addressFormVisibility && <AddressForm setAddressFormVisibility={setAddressFormVisibility} addressFormVisibility={addressFormVisibility} />}
            <BookingSummary setAddressFormVisibility={setAddressFormVisibility} addressFormVisibility={addressFormVisibility} />
        </Box>
    )
}

export default SammurySection;