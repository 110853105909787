import { Box, Center, Container, SimpleGrid, Spinner, Stack, Text } from '@chakra-ui/react'
import { VendorCard, ReferencedVendorCard } from './components/_index'
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { VendorsMap } from '../../../../../components/sectors/_index';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { VendorViewModel } from '../../../../../view-models/_index';

const VendorsSection: React.FC = () => {
  const getCityVendors = (new VendorViewModel).getCityVendors;
  const vendors = useSelector((state: RootState) => state.vendor.list);
  const referencedVendors = useSelector((state: RootState) => state.vendor.cityVendors);
  const { cityParams } = useParams();
  const [isLoading, setIsLoading] = useState<Boolean>(false);

  useEffect(() => {
    const initVendors = async () => {
      setIsLoading(true);
      if (cityParams) {
        const response = await getCityVendors(cityParams);
        if (response)
          setIsLoading(false);
      }
    }
    initVendors();
  }, [])
  return (
    <>
      {
        isLoading ?
          <>
            <Center py={'32'} bg={'white'}>
              <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
            </Center>
          </>
          :
          <>
            {
              (vendors && vendors.length > 0) || (referencedVendors && referencedVendors.length > 0) ?
                <>
                  <Container maxW={{ lg: '100%' }} w={{ xl: '2000px' }} bg={'#F6FAFC'}>
                    <SimpleGrid px={{ xl: 30 }} templateColumns={{ base: '1fr', xl: '6fr 6fr', lg: '60% 40%', md: '1fr' }} spacing={5}>
                      <Stack overflowY={'scroll'} w={{ base: '100%', lg: '100%' }} px={6} my={4} h={'1000px'}>
                        {vendors && vendors.length > 0 && vendors.map((vendor) => {
                          return (<VendorCard key={vendor.id} vendor={vendor} />)
                        })}
                        {referencedVendors && referencedVendors.length > 0 && referencedVendors.map((vendor) => {
                          return (<ReferencedVendorCard key={vendor.id} vendor={vendor} />)
                        })}
                      </Stack>
                      <Stack display={{ base: 'none', lg: 'block' }} w={'100%'} px={6} my={4} position={'relative'}>
                        <Box h={'40%'} boxShadow={'md'} borderRadius={'2xl'} w={'90%'} position={'absolute'}>
                          {vendors && <VendorsMap vendors={vendors} center={Object.values(vendors)[0]} />}
                        </Box>
                      </Stack>
                    </SimpleGrid>
                  </Container>
                </>
                :
                <>
                  <Center py={'32'} bg={'white'}>
                    <Text>Aucun garage trouvé</Text>
                  </Center>
                </>
            }
          </>
      }
    </>
  )
}

export default VendorsSection;