import {
  Box,
  Text,
  Image,
  Flex,
  Grid,
  HStack,
  Button,
} from '@chakra-ui/react';
import logo from '../../../assets/images/dadycar-logo.webp';
import { Link } from 'react-router-dom';

import fb from '../../../assets/V2/fb.png'
import linkedin from '../../../assets/V2/linkedin.png'
import twitter from '../../../assets/V2/twitter.png'
import instagram from '../../../assets/V2/instagram.png'



const Footer: React.FC = () => {
  const scrollToSection = (label:string) => {
    const section = document.getElementById(label);
    section!.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Box px={{base:6,sm:10,md:16,lg:20}} p={20} bg='white' >
      <Flex flexDirection={{base:'column',lg:'row'}} gap={8} mt={{base:0,lg:10}} mb={4} >
        <Flex direction='column' alignItems={{base:'start',md:'center',lg:'start'}} flex={1} >
          <Image src={logo} w='188px' h='112px' />
          <Text  fontSize={'18px'} color='#596780' fontWeight={600}  >Le seul logiciel pour la gestion de flottes innovantes, incluant le transport et la maintenance de véhicules.</Text>
        </Flex>
          <Grid flex={2} templateColumns={{base:'repeat(1,1fr)',md:'repeat(4,1fr)'}} gap={6} >
            <Box>
              <Text mb={{base:2,md:6}} fontWeight={600}>Solutions</Text>
              <Flex flexDirection='column' gap={4} >
                <Link to={`https://fleet.dadycar.fr/`} >DadyCar Fleet</Link>
                <Link to={`https://fleet.dadycar.fr/certify`} >DadyCar Certify</Link>
                <Link to={`https://dadycar.fr/garage`}>DadayCar Garage</Link>
                <Link to={'https://dadycar.fr/chauffeur'}>DadayCar Driver</Link>
              </Flex>
            </Box>
            <Box>
              <Text mb={{base:2,md:6}} fontWeight={600}>Services</Text>
              <Flex flexDirection='column' gap={4} >
                <Text onClick={()=>scrollToSection('hero')} cursor={'pointer'} >Gestions De flotte</Text>
                <Link to={`/convoyage`} >Convoyage</Link>
                <Link to={`https://dadycar.fr/prestations`}>Entretint & réparations </Link>
                <Text>CarSharing</Text>
              </Flex>
            </Box>
            <Box>
              <Text mb={{base:2,md:6}} fontWeight={600} >Liens importants</Text>
              <Flex flexDirection='column' gap={4} >
                <Link to={'/chauffeur'}>Devenir chauffeur</Link>
                <Link to={'https://dadycar.fr/garage'}>Etes-vous un garagiste</Link>
                <Link to={'/garages'}>List des garages</Link>
                <Text onClick={()=>scrollToSection('contact')} cursor={'pointer'}>Contactez-nous</Text>
              </Flex>
            </Box>
            <Box>
              <Text mb={{base:2,md:6}} fontWeight={600}>Social</Text>
              <Flex flexDirection='column' gap={4} >
                <Link to={`https://fr.linkedin.com/company/dadycar`} target='_blank'>
                  <Text>LinkedIn</Text>
                </Link>
                <Link to={`https://twitter.com/DadycarFr`} target='_blank' >
                  <Text>Twitter</Text>
                </Link>
                <Link to={`https://www.instagram.com/dadycar23`} target='_blank' >
                  <Text>Instagram</Text>
                </Link>
                <Link to={`https://www.youtube.com/channel/UCHGmakVXbTvQ-JhLCm5q_6Q`} target='_blank' >
                  <Text>Youtube</Text>
                </Link>
              </Flex>
            </Box>
          </Grid>
      </Flex>
      <Flex justifyContent={'space-between'} mt={8} pt={4} borderTop={'1px'} borderTopColor={'#CEBEFE'} >
        <HStack display={{base:'none',md:'flex'}} >
            <Text fontSize={'18px'} color={'#1A202C'} >Privacy Policy</Text>
            <Box w='1px' h='27px' bg='#1A202C'></Box>
            <Text fontSize={'18px'} color={'#1A202C'}>Terms & Conditions</Text>
            <Box w='1px' h='27px' bg='#1A202C'></Box>
            <Text fontSize={'18px'} color={'#1A202C'}>Cookie Policy</Text>
          </HStack>
        <Text textAlign={{base:'center',md:'start'}} color='#596780' >&copy; DadyCar 2024</Text>
      </Flex>
    </Box>
  );
}

export default Footer;