import { useEffect } from 'react';
import { BookingSection, SearchSection, VendorsSection } from './components/_index'

const VendorsFilterPage: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <BookingSection />
      <SearchSection />
      <VendorsSection />
    </>
  )
}

export default VendorsFilterPage;