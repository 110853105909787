import {
    Box,
    Flex,
    Step,
    StepDescription,
    StepIcon,
    StepIndicator,
    StepNumber,
    StepSeparator,
    StepStatus,
    StepTitle,
    Stepper,
    useSteps,
  } from '@chakra-ui/react'


    
type Props = {
    steps:{
        title:string
    }[],
    activeStep:number,
    setActiveStep:any
}

const StepBox = ({steps,activeStep,setActiveStep}:Props) => {
    console.log(activeStep)
    
  return (
    <Stepper index={activeStep} style={{display:'flex',justifyContent:'center'}} >
        {steps.map((step, index) => (
            <Step key={index} onClick={() => setActiveStep(index)}>
                <StepIndicator>
                    <StepStatus
                    complete={<StepIcon />}
                    incomplete={<StepNumber />}
                    active={<StepNumber />}
                    />
                </StepIndicator>

                <Box flexShrink='0'>
                    <StepTitle>{step.title}</StepTitle>
                </Box>

                <StepSeparator />
            </Step>
        ))}
    </Stepper>
  )
}

export default StepBox