import React, { useEffect } from 'react'
import { LayoutSection, LegalNoticeSection } from './components/_index'

const LegalNoticePage: React.FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <LayoutSection>
                <LegalNoticeSection />
            </LayoutSection>
        </>
    )
}

export default LegalNoticePage