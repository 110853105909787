import { createSlice } from '@reduxjs/toolkit';

type ModelType = { id: number, name: string };
type SliceState = { list: ModelType[] | null, selected: ModelType | null };

const initialState: SliceState = { list: null, selected: null }

const modelSlice = createSlice({
  name: 'model',
  initialState,
  reducers: {
    updateList(state, action) {
      state.list = action.payload;
    },
    updateSelected(state, action) {
      state.selected = action.payload;
    },
    removeSelected(state) {
      state.selected = null;
    },
  },
});

export {type ModelType};
export const modelActions = modelSlice.actions;
export default modelSlice.reducer;