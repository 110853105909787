import { useEffect } from 'react';
import { ContactSection, LayoutContainer } from './components/_index'

const ContactPage: React.FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <LayoutContainer>
                <ContactSection />
            </LayoutContainer>
        </>
    )
}

export default ContactPage