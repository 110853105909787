import { Box, Button, Center, SimpleGrid, Spinner, Stack, Textarea } from '@chakra-ui/react'
import { Form, Formik, FormikHelpers } from 'formik';
import InputField from './fields/InputField';
import * as yup from 'yup';
import { useState, useRef } from 'react';
import { ContactViewModel } from '../../view-models/_index';
import { StoreContactType } from '../../models/Contact';
import { useNavigate } from 'react-router-dom';

const ContactSchemaValidation = yup.object({
    first_name: yup.string().required("Veuillez insérer votre nom.").matches(/^[A-Za-z ]*$/, 'Veuillez entrer un nom valide').min(2, 'Le nom doit contenir au moins 2 caractères').max(255, 'Le nom doit comporter au maximum 255 caractères'),
    last_name: yup.string().required("Veuillez insérer votre prénom.").matches(/^[A-Za-z ]*$/, 'Veuillez entrer un prénom valide').min(2, 'Le prénom doit contenir au moins 2 caractères').max(255, 'Le prénom doit comporter au maximum 255 caractères'),
    email: yup.string().required("Veuillez insérer votre email.").email('Veuillez insérer un e-mail valide.').max(255, 'Le nom doit comporter au maximum 255 caractères'),
    phone: yup.string().required("Veuillez insérer votre numéro de téléphone.").matches(/^[0-9]*$/, 'Veuillez entrer un numéro de téléphone valide').length(10, 'Le numéro de téléphone doit comporter 10 chiffres'),
})

const ContactForm: React.FC = () => {
    const [isLoading, setIsLoading] = useState(false);
    const messageRef = useRef<HTMLTextAreaElement>(null);
    const storeContact = (new ContactViewModel()).store;
    const navigate = useNavigate();

    const onSubmitHandler = async (values: StoreContactType) => {
        setIsLoading(true);
        values.message = messageRef.current?.value!;
        const response = await storeContact(values);
        if (response.status === 'success') {
            setIsLoading(false);
            navigate('/contact/coordonnees-recues', { replace: true });
        } else
            setIsLoading(false);
    }

    return (
        <>
            <Box p={10}>
                <Formik
                    initialValues={{ first_name: '', last_name: '', email: '', phone: '', message: '' }}
                    validationSchema={ContactSchemaValidation}
                    onSubmit={(values: StoreContactType, { setSubmitting }: FormikHelpers<StoreContactType>) => {
                        setTimeout(() => {
                            onSubmitHandler(values);
                            setSubmitting(false);
                        }, 500);
                    }}
                >
                    <Form>
                        <Stack spacing={4}>
                            <SimpleGrid templateColumns={{ base: '1fr', lg: '2fr 2fr' }} spacing={6}>
                                <InputField name="first_name" type="text" placeholder="Prénom" bg={'#E8EAF1'} size={'lg'} />
                                <InputField name="last_name" type="text" placeholder="Nom" bg={'#E8EAF1'} size={'lg'} />
                                <InputField name="email" type="email" placeholder="Email" bg={'#E8EAF1'} size={'lg'} />
                                <InputField name="phone" type="text" placeholder="Téléphone" maxLength={10} bg={'#E8EAF1'} size={'lg'} />
                            </SimpleGrid>
                            <Textarea name='message' ref={messageRef} rows={5} borderRadius={'2xl'} bg={'#E8EAF1'} placeholder={'Laisser un commentaire'} required></Textarea>

                            <Center>
                                <Button type='submit' bg={'#07D0C1'} color={'white'} borderRadius={'xl'} _hover={{ bg: '#07D0C1' }}>
                                    {isLoading ?
                                        <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='md' />

                                        :
                                        <>
                                            Envoyer
                                        </>
                                    }
                                </Button>
                            </Center>

                        </Stack>
                    </Form>
                </Formik>
            </Box>
        </>
    )
}

export default ContactForm