import { Box, Container, Flex, Image, Text } from '@chakra-ui/react'
import transparence from '../../../../../../assets/V2/prestation/transparence.webp'
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer'

const MotionBox = motion(Box);

const Transparence = () => {
  const [isVisible, setIsVisible] = useState(false);
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) {
      setIsVisible(true);
    }
  }, [inView]);
  return (
    <MotionBox
    ref={ref}
    initial={{ x: -200, opacity: 0 }}
    animate={{ x: inView ? 0 : -200, opacity: inView ? 1 : 0 }}
    transition={{ duration: 0.5 }}
    >
      <Container maxW={'90%'} w={{ lg: '1200px' }}>
        <Box py={8}>
            <Flex flexDirection={{base:'column-reverse',lg:'row'}} gap={{base:4,lg:10}} justifyContent='space-between' alignItems={{xl:'center'}}  >
                <Flex flexDirection={'column'} gap={4} flex={1} >
                    <Text fontSize={{base:'30px',sm:'35px',md:'40px'}} fontWeight={700} color={'#0D121F'} >Transparence et suivi en ligne:</Text>
                    <Text fontSize={{base:'18px',sm:'20px',md:'22px'}} color={'#222D50'}><Text as='span' color={'#0F82EA'} fontWeight={600}>Suivez l'état d'avancement des travaux : </Text>Accédez à un portail en ligne dédié pour suivre en temps réel l'état d'avancement des réparations et des entretiens de vos véhicules.</Text>
                    <Text fontSize={{base:'18px',sm:'20px',md:'22px'}} color={'#222D50'}><Text as='span' color={'#0F82EA'} fontWeight={600}>Historique complet : </Text>Consultez l'historique des interventions passées, y compris les pièces détachées utilisées et les coûts associés, pour une gestion claire et transparente de votre flotte.</Text>
                </Flex>
                <Box flex={1} >
                    <Image src={transparence} h='100%' objectFit={'fill'} />
                </Box>
            </Flex>
        </Box>
      </Container>
    </MotionBox>
  )
}

export default Transparence