import HttpClient from "../services/HttpClient";

type DriverLoginType = {
    email: string;
    password: string;
}

type DriverRegisterType = {
    name: string;
    email: string;
    driving_license: string;
    experience_years: number;
    phone_number: string;
    type: string;
    password: string;
}

class Driver {

    public static login = async (driverLoginData: DriverLoginType) => {
        return HttpClient.post('/driver/login', driverLoginData);
    }

    public static register = async (driverLoginData: DriverRegisterType) => {
        return HttpClient.post('/driver/register', driverLoginData);
    }
}

export { type DriverLoginType };
export { type DriverRegisterType };
export default Driver;