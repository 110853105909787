import { Box, Container, Flex, Image, Text } from '@chakra-ui/react'
import garage from '../../../../../../assets/V2/prestation/garage.webp'
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer'

import { useEffect, useState } from 'react';
const MotionBox = motion(Box);

const Garage = () => {
  const [isVisible, setIsVisible] = useState(false);
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) {
      setIsVisible(true);
    }
  }, [inView]);
  
  return (
    <MotionBox
        ref={ref}
        initial={{ x: 200, opacity: 0 }}
        animate={{ x: inView ? 0 : 200, opacity: inView ? 1 : 0 }}
        transition={{ duration: 0.5 }}
    >
      <Container maxW={'90%'} w={{ lg: '1200px' }}>
        <Box py={8}>
            <Flex flexDirection={{base:'column',lg:'row'}} gap={{base:4,lg:10}} justifyContent='space-between' alignItems={{xl:'center'}}  >
                <Box flex={1} >
                    <Image src={garage} h='100%' objectFit={'fill'} />
                </Box>
                <Flex flexDirection={'column'} gap={4} flex={1} >
                    <Text fontSize={{base:'30px',sm:'35px',md:'40px'}} fontWeight={700} color={'#0D121F'} >Réseau national de garages partenaires:</Text>
                    <Text fontSize={{base:'18px',sm:'20px',md:'22px'}} color={'#222D50'}><Text as='span' color={'#0F82EA'} fontWeight={600}>Où que vous soyez, nous sommes là :</Text>Notre réseau de garages partenaires s'étend sur tout le territoire, garantissant une prise en charge rapide et professionnelle de vos véhicules, peu importe leur localisation.</Text>
                    <Text fontSize={{base:'18px',sm:'20px',md:'22px'}} color={'#222D50'}><Text as='span' color={'#0F82EA'} fontWeight={600}>Expertise locale : </Text>Bénéficiez de l'expertise et du savoir-faire de techniciens locaux qui connaissent parfaitement les routes et les conditions de conduite spécifiques à votre région.</Text>
                </Flex>
            </Flex>
        </Box>
      </Container>
    </MotionBox>
  )
}

export default Garage