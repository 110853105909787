import { createSlice } from '@reduxjs/toolkit';
import { ServiceType, SubServiceType } from '../../models/Service';

type SliceState = { list: ServiceType[] | null, selected: ServiceType | null, subService: SubServiceType | null, selectedSubService : SubServiceType | null };

const initialState: SliceState = { list: null, selected: null, subService: null, selectedSubService: null }

const serviceSlice = createSlice({
  name: 'service',
  initialState,
  reducers: {
    updateList(state, action) {
      state.list = action.payload;
    },
    updateSelected(state, action) {
      state.selected = action.payload;
    },
    removeSelected(state) {
      state.selected = null;
    },
    updateSubService(state, action) {
      state.subService = action.payload;
    },
    updateSelectedSubService(state, action) {
      state.selectedSubService = action.payload;
    },
    removeSelectedSubService(state) {
      state.selectedSubService = null;
    },
  },
});

export const serviceActions = serviceSlice.actions;
export default serviceSlice.reducer;