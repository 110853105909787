import { useEffect } from "react";
import { HeaderSection, MessageSection } from "./components/_index";

const ContactSuccessPage: React.FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <HeaderSection />
            <MessageSection />
        </>
    )
}

export default ContactSuccessPage;