import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Center, Flex, Heading, Image, List, ListItem, Progress, SimpleGrid, Stack, Text, UnorderedList } from '@chakra-ui/react'
import locationLogo from '../../../../../../assets/images/dadycar-Icon-material-location-blue.webp'
import dashboardLogo from '../../../../../../assets/images/dadycar-Icon-material-dashboard-blue.webp'
import { VendorType } from '../../../../../../store/slices/vendor-slice'
import { Link } from 'react-router-dom'

const VendorCard: React.FC<{ vendor: VendorType }> = ({ vendor }) => {
    const slug = (vendor.name).replace(/\s+/g, '-').toLowerCase();

    return (
        <Box bg={'white'} boxShadow={'md'} borderRadius={'2xl'} px={3} py={4} border={'2px solid white'} _hover={{ border: '2px solid #1385EB' }}>
            <SimpleGrid templateColumns={{ base: '1fr', lg: '60% 40%', md: '1fr' }} position={'relative'}>
                <Stack >
                    <Accordion allowToggle>
                        <AccordionItem>
                            <h2>
                                <Link to={`/garage/${(slug)}/${vendor.id}`} style={{ textDecoration: 'underline' }}>
                                    <AccordionButton position={'relative'}>
                                        <Center position={'relative'} w={{ base: '30%', xl: '30%', lg: '50%', md: '20%' }} mx={2} borderRadius={'xl'} bg={'#DFF7FC'} h={'70px'}>
                                            <Image h={'70%'} src={vendor.image} />
                                        </Center>
                                        <Box w={{ base: '70%', xl: '70%', lg: '50%', md: '80%' }}>
                                            <Heading size={'xs'} textAlign={'start'}>
                                                {vendor.name}
                                            </Heading>
                                            <Text textAlign={'start'} fontSize={'x-small'}>
                                                Ouvert de..
                                            </Text>
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </Link>
                            </h2>
                            <AccordionPanel pb={4}>
                                <Box overflowY={'scroll'}>
                                    <Heading size={'sm'}>HORAIRES D’OUVERTURE</Heading>
                                    <List>
                                        {vendor.week_schedule?.map(schedule => (
                                            <>
                                                {
                                                    schedule.closed ?
                                                        <>
                                                            <ListItem fontSize={{ base: 'xs', lg: 'md' }} color={'grey'}>{schedule.week_day_name_fr} : Fermé</ListItem>
                                                        </>
                                                        :
                                                        <>
                                                            <ListItem fontSize={{ base: 'xs', lg: 'md' }} color={'grey'}>{schedule.week_day_name_fr} : {schedule.opening_time} - {schedule.closing_time}</ListItem>
                                                        </>
                                                }
                                            </>
                                        ))}
                                    </List>
                                </Box>
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <h2>
                                <AccordionButton>
                                    <Center w={{ base: '30%', xl: '30%', lg: '50%', md: '20%' }} mx={2} borderRadius={'xl'} bg={'#DFF7FC'} h={'70px'}>
                                        <Text color={'#1385EB'}>{vendor.reviews + '/5'}</Text>
                                    </Center>
                                    <Box w={{ base: '70%', xl: '70%', lg: '50%', md: '80%' }}>
                                        <Heading size={'sm'} textAlign={'start'}>
                                            Très bien
                                        </Heading>
                                        <Text textAlign={'start'}>
                                            0 avis
                                        </Text>
                                    </Box>
                                </AccordionButton>
                            </h2>
                        </AccordionItem>

                        <AccordionItem>
                            <h2>
                                <AccordionButton position={'relative'}>
                                    <Center position={'relative'} w={{ base: '30%', xl: '30%', lg: '50%', md: '20%' }} mx={2} borderRadius={'xl'} bg={'#DFF7FC'} h={'70px'}>
                                        <Image h={'70%'} src={locationLogo} />
                                    </Center>
                                    <Box w={{ base: '70%', xl: '70%', lg: '50%', md: '80%' }}>
                                        <Heading size={'sm'} textAlign={'start'}>

                                        </Heading>
                                        <Text textAlign={'start'}>
                                            {vendor.address}
                                        </Text>
                                    </Box>
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                                <Box overflowY={'scroll'} position={'relative'} display={{ base: 'unset', lg: 'none' }}>
                                    {/* <Iframe /> */}
                                </Box>
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <h2>
                                <AccordionButton>
                                    <Center position={'relative'} w={{ base: '30%', xl: '30%', lg: '50%', md: '20%' }} mx={2} borderRadius={'xl'} bg={'#DFF7FC'} h={'70px'}>
                                        <Image h={'70%'} src={dashboardLogo} />
                                    </Center>
                                    <Box w={{ base: '70%', xl: '70%', lg: '50%', md: '80%' }}>
                                        <Heading size={'xs'} textAlign={'start'}>
                                            Voir tous les services proposés
                                        </Heading>
                                        <Text>

                                        </Text>
                                    </Box>

                                    <AccordionIcon />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                                <Box overflowY={'scroll'}>

                                    <UnorderedList>
                                        {vendor.services && vendor.services.map((service) => {
                                            return (
                                                <ListItem key={service.id}>{service.name}</ListItem>
                                            )
                                        })}
                                    </UnorderedList>
                                </Box>
                            </AccordionPanel>
                        </AccordionItem>


                    </Accordion>
                </Stack>
                <Stack h={'100%'} position={'relative'}>
                    <Heading as={'h6'} size={'sm'}>Votre devis en 4 clics</Heading>
                    <Center py={2} h={'80%'}>
                        <List>
                            <ListItem color={'#1385EB'}>Devis immédiat en ligne</ListItem>
                            <ListItem color={'#1385EB'}>Prise de RDV instantané</ListItem>
                            <ListItem color={'#1385EB'}>Avis clients certifiés</ListItem>
                            <ListItem color={'#1385EB'}>Garantie 5 ans pièces & main d’oeuvre</ListItem>
                        </List>
                    </Center>
                    <Box w={'100%'} py={2}>
                        <Button fontSize={'x-small'} w={'100%'} color={'white'} bg={'#1385EB'} _hover={{ bg: '#1385EB' }} onClick={() => { window.scroll(0, 0) }}>
                            Voir les Tarifs pourMa voiture
                        </Button>
                    </Box>
                </Stack>
            </SimpleGrid>
        </Box>
    )
}

export default VendorCard;