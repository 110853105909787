import { useEffect } from 'react';
import { Head } from '../../../../components/blocks/_index';
import { ServiceSection, BookingSection, AboutSection } from './components/_index'
import FAQ from '../../pro-fleet/components/FAQ';
import Contact from '../../pro-fleet/components/Contact';

const title = "Convoyage";
const description = "Réservez votre service de convoyage avec Dadycar et profitez d'un transport de véhicules de qualité. Nos chauffeurs professionnels se chargent de prendre en charge et livrer votre véhicule en toute sécurité et à l'heure. Réservez en ligne en quelques clics et simplifiez votre transport de véhicules.";
const keywords = "convoyage de véhicules, transport de véhicules, chauffeur professionnel, réservation en ligne";

const ConveyingPage: React.FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Head title={title} description={description} keywords={keywords} />
            <BookingSection >
                <ServiceSection />
                <AboutSection />
                <FAQ />
                <Contact />
            </BookingSection>
        </>
    )
}

export default ConveyingPage;