import { Alert, AlertIcon, Box, Button, Center, Image, Spinner, Stack, Text } from '@chakra-ui/react';
import { BsArrowRight } from 'react-icons/bs';
import logo from '../../assets/images/dadycar-logo.webp';
import googleIcon from '../../assets/images/dadycar-google-2.webp';
import { Form, Formik, FormikHelpers } from 'formik';
import InputField from './fields/InputField';
import * as yup from 'yup';
import { UserRegisterType } from '../../models/User';
import { UserViewModel } from '../../view-models/_index';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useState } from 'react';

interface Values {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  password: string;
  password_confirmation: string;
}

const RegisterSchemaValidation = yup.object({
  first_name: yup.string().required("Veuillez insérer votre nom.").matches(/^[A-Za-z ]*$/, 'Veuillez entrer un nom valide').min(2, 'Le nom doit contenir au moins 2 caractères').max(255, 'Le nom doit comporter au maximum 255 caractères'),
  last_name: yup.string().required("Veuillez insérer votre prénom.").matches(/^[A-Za-z ]*$/, 'Veuillez entrer un prénom valide').min(2, 'Le prénom doit contenir au moins 2 caractères').max(255, 'Le prénom doit comporter au maximum 255 caractères'),
  email: yup.string().required("Veuillez insérer votre email.").email('Veuillez insérer un e-mail valide.').max(255, 'Le nom doit comporter au maximum 255 caractères'),
  phone: yup.string().required("Veuillez insérer votre numéro de téléphone.").matches(/^[0-9]*$/, 'Veuillez entrer un numéro de téléphone valide').length(10, 'Le numéro de téléphone doit comporter 10 chiffres'),
  password: yup.string().required('Veuillez insérer votre mot de passe.').min(8, 'Le mot de passe doit comporter au moins 8 caractères').max(255, 'Le mot de passe doit comporter au maximum 255 caractères'),
  password_confirmation: yup.string().required('Veuillez confirmer votre mot de passe.').oneOf([yup.ref('password')], 'Les mots de passe ne correspondent pas')
})

const UserRegisterForm: React.FC<{ onUserRegisterClose: () => void }> = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const registerUser = (new UserViewModel()).register;
  const SubmitHamdler = async (values: UserRegisterType) => {
    setIsLoading(true);
    const response = await registerUser(values);
    if (response.status === 'success') {
      props.onUserRegisterClose();
    }
    setIsLoading(false);
  }
  const registerErrorMessage = useSelector((state: RootState) => state.user.registerErrorMessage);

  return (
    <>
      <Box p={10}>
        <Stack align={'center'}>
          <Image src={logo} alt="dadycar-logo" htmlHeight={'100%'} htmlWidth={'100%'} w={'100px'} h={'auto'} />
        </Stack>
        <Stack>
          <Text textAlign={'start'}>Vous pouvez nous joindre à tout moment via :</Text>
          <Text color={'#5393EC'} textAlign={'start'}>contact@dadycar.fr</Text>
        </Stack>
        {/* <Stack>
          <Button borderRadius={'lg'} bg={'white'} color={'black'} border={'1px solid grey'} _hover={{ bg: 'white' }}>
            <Image src={googleIcon} mx={2} />
            continuer avec google
          </Button>
        </Stack>
        <Stack>
          <Text textAlign={'center'}>Ou</Text>
        </Stack> */}
        <Formik
          initialValues={{ first_name: '', last_name:'', email: '', phone: '', password: '', password_confirmation: '' }}
          validationSchema={RegisterSchemaValidation}
          onSubmit={(values: Values, { setSubmitting }: FormikHelpers<Values>) => {
            setTimeout(() => {
              SubmitHamdler(values);
              setSubmitting(false);
            }, 500);
          }}
        >
          <Form>
            <Stack spacing={4} mt={'4'}>
              {registerErrorMessage && <Alert status='error'><AlertIcon /> {registerErrorMessage}</Alert>}
              <InputField label="Nom" name="first_name" type="text" placeholder="Votre nom" />
              <InputField label="Prénom" name="last_name" type="text" placeholder="Votre prénom" />
              <InputField label="Adresse mail" name="email" type="email" placeholder="...@example.com" />
              <InputField label="Numéro de téléphone" name="phone" type="text" placeholder="0x xx xx xx xx" maxLength={10} />
              <InputField label="Mot de passe" name="password" type="password" placeholder="************" />
              <InputField label="Confirmer le mot de passe" name="password_confirmation" type="password" placeholder="************" />

              <Button type="submit" borderRadius={'lg'} bg={'#5393EC'} color={'white'} _hover={{ bg: 'blue.500' }} disabled={isLoading}>
                {isLoading ?
                  <Center>
                    <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='md' />
                  </Center>
                  :
                  <>
                    S’enregistrer
                    < BsArrowRight />
                  </>
                }
              </Button>
            </Stack>
          </Form>
        </Formik>
      </Box>
    </>
  )
}

export default UserRegisterForm;