import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Button,
    useDisclosure,
    Box,
    Text,
    Stack,
    Checkbox,
    HStack,
    Input,
    Textarea,
    FormLabel,
    VStack,
    FormControl
  } from '@chakra-ui/react'
  import * as yup from 'yup';
  import { Form, Formik, Field, FieldProps } from 'formik';
  import { useToast } from '@chakra-ui/react'

  const RefusDevisValidation = yup.object({
    date_rdv: yup.array().of(yup.string()).required('Required'),
    raison: yup.string().optional(),
    change_date : yup.string().optional()
})

interface FormValues {
  date_rdv: string[];
  raison:string;
  change_date:string
}
const RefusDevisModal = () => {
    const toast = useToast()
    const initialValues: FormValues = { date_rdv: [],raison:"",change_date:"" };
    const { isOpen, onOpen, onClose } = useDisclosure()

    const onSubmitHandler = async (values: FormValues,resetForm:any) => {
      if(values?.date_rdv?.length === 0 ){
        toast({
          title: 'valeurs manquantes',
          description: "Assurez-vous de vérifier la raison de refus!",
          status: 'error',
          duration: 5000,
          isClosable: true,
          position:'top'
        })
        return
      }
      if(values.date_rdv?.includes('Date de rendez-vous pas disponible') && !values?.change_date){
        toast({
          description: "Assurez-vous de selectionner la nouvelle date de rendez-vous!",
          status: 'error',
          duration: 5000,
          isClosable: true,
          position:'top'
        })
        return
      }
      if(values.date_rdv?.includes('Autre') && !values?.raison){
        toast({
          description: "Assurez-vous de remplir la raison!",
          status: 'error',
          duration: 5000,
          isClosable: true,
          position:'top'
        })
        return
      }
      let theValues = values
      if(!values.date_rdv?.includes('Date de rendez-vous pas disponible')){
        theValues = {...theValues,change_date:""}
      }
      if(!values.date_rdv?.includes('Autre')){
        theValues = {...theValues,raison:""}
      }

      console.log(theValues)
  }

  return (
    <Box w='100%'>
        <Button w='100%' py={0} variant={'ghost'} fontSize={'18px'} color={'#182F45'} fontWeight={500} onClick={onOpen}>Annuler</Button>

        <Modal size={'3xl'} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader fontSize={'18px'} fontWeight={600} color={'#222D50'} textAlign={'center'}>Refuser rendez-vous</ModalHeader>
            <ModalBody >
              <Text fontSize={'22px'} fontWeight={700} color={'#222D50'} mb={4}>Raison du refus</Text>
              <Formik
                initialValues={initialValues}
                validationSchema={RefusDevisValidation}
                onSubmit={(values,{resetForm})=>{onSubmitHandler(values,resetForm)}}
              >
                {({ handleSubmit, values }) => (
                  <Form onSubmit={handleSubmit}>
                    <VStack alignItems={'start'} gap={3} w='100%'  >
                      <Stack spacing={3} direction='column' w='100%'>
                        <Field type="checkbox" name="date_rdv" value="Date de rendez-vous pas disponible">
                          {({ field } : FieldProps) => (
                            <Checkbox {...field} colorScheme='blue'>
                              Date de rendez-vous pas disponible
                            </Checkbox>
                          )}
                        </Field>
                        <Field type="checkbox" name="date_rdv" value="Autre">
                          {({ field} : FieldProps) => (
                            <Checkbox {...field} colorScheme='blue'>
                              Autre
                            </Checkbox>
                          )}
                        </Field>
                      </Stack>
                      {values.date_rdv?.includes('Date de rendez-vous pas disponible') ? (
                          <Box mb={2} w='100%'>
                            <Field name="change_date">
                              {({ field }: FieldProps) => (
                                <FormControl>
                                  <FormLabel htmlFor="raison" fontWeight={500} color={'#222D50'} >Change date de rendez-vous</FormLabel>
                                  <Input w={'50%'} type='date' {...field} id="change_date" />
                                  
                                </FormControl>
                              )}
                            </Field>
                          </Box>
                      ) : null}
                      {values.date_rdv?.includes('Autre') ? (
                          <Box mb={2} w='100%'>
                            <Field name="raison">
                              {({ field }: FieldProps) => (
                                <FormControl>
                                  <FormLabel htmlFor="raison" fontWeight={500} color={'#222D50'} >Entrer la raison</FormLabel>
                                  <Textarea rows={6} {...field} id="raison" />
                                  
                                </FormControl>
                              )}
                            </Field>
                          </Box>
                      ) : null}
                      <HStack gap={4} w='100%' mt={2} my={4}>
                        <Button h={'67px'} flex={1} variant='ghost' borderWidth={'1px'} rounded={'94px'} borderColor={'#0F82EA'} color={'#0F82EA'} onClick={onClose}>
                          Retour
                        </Button>
                        <Button h='67px' type='submit' flex={1} rounded={'94px'} colorScheme='blue' bg='#0F82EA' color={'white'} _hover={{ bg: '#0F82BA' }}>
                          Envoyer le devis
                        </Button>
                      </HStack>
                    </VStack>
                  </Form>
                )}
              </Formik>
            </ModalBody>

        </ModalContent>
        </Modal>
    </Box>
  )
}

export default RefusDevisModal