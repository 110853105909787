import { FormControl, FormErrorMessage } from '@chakra-ui/form-control';
import { Form, Formik, FormikHelpers } from 'formik';
import { Input, InputGroup, InputLeftElement } from '@chakra-ui/input';
import { Box } from '@chakra-ui/react';
import * as yup from 'yup';
import { CiSearch } from "react-icons/ci";

const formSchema = yup.object({
  text: yup.string().required("Veuillez insérer votre search.").matches(/^[A-Za-z ]*$/, 'Veuillez entrer un search valide').min(2, 'Le search doit contenir au moins 2 caractères').max(255, 'Le prénom doit comporter au maximum 255 caractères'),
  })
  
  const onSubmitHandler = async (values: {text:string},resetForm:any) => {
    
}

const SearchInput = () => {

  return (
    <Box flex={1} >
        <Formik
            initialValues={{ text:''}}
            validationSchema={formSchema}
            onSubmit={(values,{resetForm})=>{onSubmitHandler(values,resetForm)}}
        >
            <Form style={{width:'100%'}} >
                <InputGroup>
                  <InputLeftElement pointerEvents='none' mt={1}>
                    <CiSearch color='#2A2D3799'  size={25}  />
                  </InputLeftElement>
                  <Input
                  backgroundColor={'#F6F8FA'} height={'47px'} rounded={'8px'}
                    type='text'
                    name='text'
                    placeholder='Search immatricule ou nom ..etc'
                  />
                </InputGroup>
            </Form>
        </Formik>
    </Box>
  )
}

export default SearchInput