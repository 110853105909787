import { Box, Flex, HStack, Text } from '@chakra-ui/react'
import { FaArrowDown} from "react-icons/fa";

const Incoming = () => {
  return (
    <Box bg='#FDFDFD' rounded={'6px'} my={2}>
        <Box p={4} >
            <Flex justifyContent={'space-between'} alignItems={'center'} borderBottom={'1px'} borderBottomColor={'#F1F1F4'} pb={2} >
                <Text fontSize={'18px'} fontWeight={500} color={'#656575'}>Income</Text>
                <Text bg={'#F4F5F7'} rounded={'2px'} py={'4px'} px={'8px'} fontSize={'12px'} fontWeight={400} color={'#656575'}>Mois</Text>
            </Flex>
            <Flex mt={2} justifyContent={'space-between'} alignItems={'center'}>
                <Text fontSize={'28px'} fontWeight={700} color={'#1A1919'}>$ 9460.00</Text>
                <HStack fontSize={'16px'} fontWeight={500} color='#FF2727'>
                    <FaArrowDown />
                    <Text>1.5%</Text>
                </HStack>
            </Flex>
            <Flex justifyContent={'space-between'} alignItems={'center'} borderBottom={'1px'} borderBottomColor={'#F1F1F4'} pb={2} >
                <Text fontSize={'14px'} fontWeight={500} color={'#525256'}>Last week Income</Text>
                <Text fontSize={'14px'} fontWeight={500} color={'#525256'}>$25658.00</Text>
            </Flex>
        </Box>
    </Box>
  )
}

export default Incoming