import { Box, Button, Center, Container, Flex, Heading, Image, Stack, Text } from "@chakra-ui/react";
import header_left from '../../../../../assets/V2/header_left.png';
import { Link } from "react-router-dom";
import hero_image from '../../../../../assets/V2/prestation/hero.webp'
import { motion} from "framer-motion";
import { useEffect, useState } from "react";
import { useInView } from 'react-intersection-observer'


const MotionBox = motion(Box);

const BookingSection: React.FC<{ children: any }> = (props) => {
    const { ref, inView } = useInView();
    const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (inView) {
      setIsVisible(true);
    }
  }, [inView]);
  
    return (
        <Box >
            <Box pt={32} bg='#0D121F' pos='relative'>
                <Box> 
                    <Container maxW={'90%'} w={{ lg: '1200px' }}>
                        <Box
                            position='absolute'
                            top={0}
                            right={0}
                            w='300px'
                            h='300px'
                            backgroundImage={header_left}
                            backgroundSize={'cover'}
                            backgroundRepeat={'no-repeat'}
                            backgroundPosition={{ base: 'top', lg: 'center' }}>   
                        </Box>
                        <MotionBox
                          ref={ref} initial={{ scale: 0.8, opacity: 0 }}
                          animate={{ scale: inView ? 1 : 0.8, opacity: inView ? 1 : 0 }}
                          transition={{ duration: 0.5 }}
                        >
                            <Flex flexDirection={{base:'column',lg:'row'}} justifyContent={'space-between'} gap={8} >
                                <Flex flex={1} flexDirection='column' gap={4} >
                                    <Text fontSize={{base:'30px',sm:'40px',lg:'30px',xl:'48px'}} fontWeight={700} color={'#EDF8FD'} >Simplifiez la gestion de votre flotte : Entretien et Réparation à portée de clic </Text>
                                    <Stack gap={4} direction={['column','row']}>
                                        <Link to={'https://meetings-eu1.hubspot.com/meetings/amine-yahia-cherif/dadycar'} target="_blank" >
                                            <Button bg='#3563E9' _hover={{bg:'#0F82EA'}} rounded='30px' fontSize={{base:'14px',md:'16px'}} w='187px' h='52px' color='white' >Prendre RDV</Button>
                                        </Link>
                                        <Link to={'https://meetings-eu1.hubspot.com/meetings/amine-yahia-cherif?uuid=d67be381-7bc7-461c-ab1c-f55d5ebd83c9'} target="_blank">
                                            <Button variant='outline' _hover={{bg:'#3563E9'}} rounded='30px' fontSize={{base:'14px',md:'16px'}} color='white'  w='187px' h='52px'>Organiser une démo</Button>
                                        </Link>
                                    </Stack>
                                </Flex>
                                <Box flex={1}>
                                    <Image src={hero_image} zIndex={100}  />
                                </Box>        
                            </Flex>
                        </MotionBox>
                    </Container>
                </Box> 
                <Box bg={'white'}>
                    {props.children}       
                </Box>            
            </Box>

        </Box>
    )
}

export default BookingSection;