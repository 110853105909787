import { Image } from '@chakra-ui/image'
import { Box, Center, Container, Link, SimpleGrid, Stack, Text, Flex } from '@chakra-ui/layout'
import { MdLocationOn } from "react-icons/md";
import { FaCalendarAlt } from "react-icons/fa";
import { FaRegCheckSquare } from "react-icons/fa";

import { SectionHeader } from '../../../../../components/sections/_index'
import dotBg from '../../../../../assets/images/dadycar-dots-bg-2.webp';
import { Button } from '@chakra-ui/react'

const AboutSection: React.FC = () => {
    const data = [{
        image: MdLocationOn,
        title: 'Départ et arrivée en toute simplicité',
        text: "Précisez l'adresse de départ et d'arrivée pour votre convoyage."
    }, {
        image: FaCalendarAlt,
        title: 'Réservez en toute sérénité',
        text: "Choisissez la date de livraison de votre véhicule en toute tranquillité."
    }, {
        image: FaRegCheckSquare ,
        title: "Livraison assurée",
        text: "Un conseiller Dadycar vous contactera pour confirmer la livraison de votre véhicule en toute sécurité."
    },]
    return (
        <Box
            bg='#0D121F'
            py={10}
        >
            <Box py={12} color='white'>
                <Text fontSize='20px' fontWeight={600} textAlign={'center'} color={'white'}>Informez-vous</Text>
                <Text fontSize={{base:'26px',sm:'36px'}} fontWeight={700} textAlign={'center'} mt={4}>Comment ça marche ?</Text>    
            </Box>
            <Container
                maxW={'90%'}
                py={{ base: 0, lg: 12 }}
            >
                <Box>
                    <SimpleGrid templateColumns={{ base: '2fr', lg: '2fr 2fr 2fr' }} spacing={12}>
                        {data.map((item, idx) => (
                            <Stack key={idx}>
                                <Center>
                                    <Flex w='90px' h='90px' bg={'#0F82EA'} rounded={'8px'} justifyContent='center' alignItems='center' >
                                        <item.image fontSize={30} color='white'/>
                                    </Flex>
                                </Center>
                                <Box mt={1}>
                                    <Text fontWeight={700} fontSize='20px' textAlign={'center'} color={'white'}>{item.title}</Text>
                                </Box>
                                <Center py={2}>
                                    <Text fontWeight={400} fontSize={'18px'} color='white' opacity={'80%'} textAlign={'center'}>{item.text}</Text>
                                </Center>
                            </Stack>
                        ))}
                    </SimpleGrid>
                </Box>

            </Container>
        </Box>

    )
}
export default AboutSection;