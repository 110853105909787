import { Box, Center, Container, SimpleGrid, Spinner, Stack, Text } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { Map } from '../../../../../components/sectors/_index';
import { RootState } from '../../../../../store';
import { VendorCard } from './components/_index';

const VendorsSection: React.FC = () => {
  const vendors = useSelector((state: RootState) => state.vendor.list);
  const isLoading = useSelector((state: RootState) => state.vendor.isLoading);
  const departureAddress = useSelector((state: RootState) => state.address.departureAddress);
  return (
    <>
      <Box bg={'#F6FAFC'}>
        <Container maxW={{ lg: '100%' }} w={{ xl: '2000px' }} >
          {vendors && vendors.length > 0 &&
            <Stack mx={{ base: 6, xl: 14 }} mb={4} bg={'white'} borderRadius={'2xl'} boxShadow={'md'} border={'2px solid transparent'}>
              <Center padding={5}>
                <Box>
                  <Text textAlign={'center'}>
                    Le devis pour la prestation de votre véhicule nécessite un retour de la part du réparateur. Le devis adapté à vos besoins vous sera communiqué en moins de 12 heures après la validation de votre rendez-vous.                  </Text>
                </Box>
              </Center>
            </Stack>
          }
          <SimpleGrid px={{ xl: 30 }} templateColumns={{ base: '1fr', xl: '6fr 6fr', lg: '60% 40%', md: '1fr' }} spacing={5}>
            <Stack overflowY={'scroll'} w={{ base: '100%', lg: '100%' }} px={6} my={4} h={{ base: '1000px', lg: '2000px' }}>
              <>
                {isLoading ?
                  <Center>
                    <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
                  </Center>
                  :
                  <>
                    {vendors && vendors.length > 0 ? vendors.map((vendor) => {
                      return (<VendorCard key={vendor.id} vendor={vendor} />)
                    })
                      :
                      <Center>
                        <Text>Aucun résultat trouvé pour votre recherche</Text>
                      </Center>
                    }
                  </>
                }
              </>
            </Stack>
            <Stack display={{ base: 'none', lg: 'block' }} w={'100%'} px={6} my={4} position={'relative'}>
              <Box h={'40%'} boxShadow={'md'} borderRadius={'2xl'} w={'90%'} position={'absolute'}>
                {vendors && vendors.length > 0 && <Map vendors={vendors} center={departureAddress!} />}
              </Box>
            </Stack>
          </SimpleGrid>
        </Container>
      </Box>
    </>
  )
}

export default VendorsSection;