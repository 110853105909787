import { useDispatch } from 'react-redux';
import Vehicle from '../models/Vehicle';
import { VehicleType, vehicleActions } from '../store/slices/vehicle-slice';

class VehicleViewModel {

    dispatch = useDispatch();

    public searchWithMatriculation = async (plateNumber: string): Promise<{ status: string, vehicleNmae: string }> => {
        const response = await Vehicle.searchWithMatriculation(plateNumber);

        if (response.status === 200) {
            this.dispatch(vehicleActions.updateValue({
                id: response.data.data.id,
                plateNumber: plateNumber,
                mark: response.data.data.mark,
                model: response.data.data.model,
                version: response.data.data.version,
                type: response.data.data.vehicle_type,
                isSearched: true
            }));

            return {
                'status': 'success',
                'vehicleNmae': response.data.data.mark + ' /' + response.data.data.model,
            };
        } else
            return {
                'status': 'error',
                'vehicleNmae': '',
            };
    }

    public storeVehicle = (vehicle: VehicleType) => {
        this.dispatch(vehicleActions.updateValue(vehicle));
    }

    public removeVehicle = () => {
        this.dispatch(vehicleActions.removeVehicle());
    }
}

export default VehicleViewModel;