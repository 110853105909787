import { useDispatch } from "react-redux";
import { RegionType } from "../models/Region";
import { Region } from "../models/_index";
import { regionActions } from "../store/slices/region-slice";

class RegionViewModel {

    dispatch = useDispatch();

    public getRegions = async (): Promise<{ status: string }> => {
        const response = await Region.getRegions();
        if (response.status === 200) {
            this.dispatch(regionActions.updateList(response.data));
            return { status: 'success' };
        }
        return { status: 'error' };
    }

    public selectRegion = async (region: RegionType) => {
        this.dispatch(regionActions.updateSelected(region));
    }
}

export default RegionViewModel;