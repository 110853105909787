import React, { useEffect } from 'react'
import { Box, Button, Center, Flex, Image, Input, SimpleGrid, Spinner, Stack, Text } from '@chakra-ui/react';
import carIcon from '../../../assets/images/dadycar-conveying-car-icon.webp';
import cardIcon from '../../../assets/images/dadycar-conveying-id-card.webp';
import { DadycarAlert, SearchAddressAutocomplete } from '../../sectors/V2/_index';
import { Form, Formik, FormikHelpers } from 'formik';
import InputField from '../fields/InputField';
import * as yup from 'yup';
import { useState } from 'react';
import { ConveyingType } from '../../../models/Conveying';
import { ConveyingViewModel, AddressViewModel, DistanceViewModel } from '../../../view-models/_index';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { useNavigate } from 'react-router-dom';

const ConveyingSchemaValidation = yup.object({
  from_date: yup.date().required('La date de départ est obligatoire').min(new Date(), 'La date de départ ne peut pas être dans le passé'),
  to_date: yup.date().min(new Date(), 'La date d\'arrivée ne peut pas être dans le passé'),

  company_name: yup.string().min(2, "Le nom de l'entrepris doit contenir au moins 2 caractères").max(255, "Le nom de l'entrepris doit comporter au maximum 255 caractères"),
  owner_first_name: yup.string().required("Le champ nom est obligatoire.").matches(/^[A-Za-z ]*$/, 'Veuillez entrer un nom valide').min(2, 'Le nom doit contenir au moins 2 caractères').max(255, 'Le nom doit comporter au maximum 255 caractères'),
  owner_last_name: yup.string().required("Le champ prénom est obligatoire.").matches(/^[A-Za-z ]*$/, 'Veuillez entrer un prénom valide').min(2, 'Le prénom doit contenir au moins 2 caractères').max(255, 'Le prénom doit comporter au maximum 255 caractères'),
  owner_email: yup.string().required("Le champ email est obligatoire.").email('Veuillez insérer un e-mail valide.').max(255, 'Le nom doit comporter au maximum 255 caractères'),
  owner_phone: yup.string().required("Le champ numéro de téléphone est obligatoire.").matches(/^[0-9]*$/, 'Veuillez entrer un numéro de téléphone valide').length(10, 'Le numéro de téléphone doit comporter 10 chiffres'),

  departure_contact_first_name: yup.string().required("Le champ nom est obligatoire.").matches(/^[A-Za-z ]*$/, 'Veuillez entrer un nom valide').min(2, 'Le nom doit contenir au moins 2 caractères').max(255, 'Le nom doit comporter au maximum 255 caractères'),
  departure_contact_last_name: yup.string().required("Le champ prénom est obligatoire.").matches(/^[A-Za-z ]*$/, 'Veuillez entrer un prénom valide').min(2, 'Le prénom doit contenir au moins 2 caractères').max(255, 'Le prénom doit comporter au maximum 255 caractères'),
  departure_contact_email: yup.string().required("Le champ email est obligatoire.").email('Veuillez insérer un e-mail valide.').max(255, 'Le nom doit comporter au maximum 255 caractères'),
  departure_contact_phone: yup.string().required("Le champ numéro de téléphone est obligatoire.").matches(/^[0-9]*$/, 'Veuillez entrer un numéro de téléphone valide').length(10, 'Le numéro de téléphone doit comporter 10 chiffres'),

  arrival_contact_first_name: yup.string().required("Le champ nom est obligatoire.").matches(/^[A-Za-z ]*$/, 'Veuillez entrer un nom valide').min(2, 'Le nom doit contenir au moins 2 caractères').max(255, 'Le nom doit comporter au maximum 255 caractères'),
  arrival_contact_last_name: yup.string().required("Le champ prénom est obligatoire.").matches(/^[A-Za-z ]*$/, 'Veuillez entrer un prénom valide').min(2, 'Le prénom doit contenir au moins 2 caractères').max(255, 'Le prénom doit comporter au maximum 255 caractères'),
  arrival_contact_email: yup.string().required("Le champ email est obligatoire.").email('Veuillez insérer un e-mail valide.').max(255, 'Le nom doit comporter au maximum 255 caractères'),
  arrival_contact_phone: yup.string().required("Le champ numéro de téléphone est obligatoire.").matches(/^[0-9]*$/, 'Veuillez entrer un numéro de téléphone valide').length(10, 'Le numéro de téléphone doit comporter 10 chiffres'),
})

const ConveyingForm: React.FC<{ onUserLoginOpen: () => void, onStripeOpen: () => void,activeStep:number,setActiveStep:any }> = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isStored, setIsStored] = useState(false);
  const conveyingOwnerAddress = useSelector((state: RootState) => state.address.conveyingOwnerAddress)
  const conveyingDepartureAddress = useSelector((state: RootState) => state.address.conveyingDepartureAddress)
  const conveyingReturnAddress = useSelector((state: RootState) => state.address.conveyingReturnAddress)
  const version = useSelector((state: RootState) => state.version.selected);
  const vehicle = useSelector((state: RootState) => state.vehicle.value);
  const conveying = useSelector((state: RootState) => state.conveying.value);
  const clientSecret = useSelector((state: RootState) => state.conveying.clientSecret);
  const user = useSelector((state: RootState) => state.user.current);
  const getConveyingDistanceWithPrice = (new DistanceViewModel()).getConveyingDistanceWithPrice;
  const addressViewModel = new AddressViewModel();
  const selectConveyingOwnerAddress = addressViewModel.selectConveyingOwnerAddress;
  const selectConveyingDepartureAddress = addressViewModel.selectConveyingDepartureAddress;
  const selectConveyingReturnAddress = addressViewModel.selectConveyingReturnAddress;
  const storeConveying = (new ConveyingViewModel()).store;
  const navigate = useNavigate();

  useEffect(() => {
    if (!(version || vehicle)) {
      navigate('/convoyage', { replace: true });
    }
  }, [])

  useEffect(() => {
    if (isConfirmed && conveying && clientSecret) {
      props.onStripeOpen();
    }
  }, [conveying]);

  useEffect(() => {
    if ((version || vehicle) && conveyingDepartureAddress && conveyingReturnAddress)
      getConveyingDistanceWithPrice({ conveyingDepartureAddress, conveyingReturnAddress }, version?.id, vehicle?.type!);
  }, [conveyingDepartureAddress, conveyingReturnAddress]);

  const onSubmitHandler = async (values: ConveyingType) => {
    if (!isConfirmed)
      setIsConfirmed(true);

    if (isStored) {
      props.onStripeOpen();
      return;
    }

    if (!user) {
      props.onUserLoginOpen();
      return;
    }

    if (conveyingOwnerAddress && conveyingDepartureAddress && conveyingReturnAddress) {
      setIsLoading(true);
      values.client_id = user.id;
      values.owner_address = conveyingOwnerAddress.label;
      values.departure_address = conveyingDepartureAddress.label;
      values.arrival_address = conveyingReturnAddress.label;

      if (vehicle) {
        values.vehicle_id = vehicle.id!;
        values.search_type = 'SV';
        values.vehicle_numberplate = vehicle.plateNumber;
      } else {
        values.vehicle_id = version!.id;
        values.search_type = 'V';
        values.vehicle_numberplate = '';
      }

      const response = await storeConveying(values);
      if (response === 'success') {
        setIsStored(true);
        setIsLoading(false);
      } else
        setIsLoading(false);
    }
  }
  return (
    <>
      <Formik
        initialValues={{
          client_id: 0, from_date: '', to_date: '', search_type: '', vehicle_id: 0, vehicle_numberplate: '',
          owner_first_name: '', owner_last_name: '', company_name: '', owner_email: '', owner_phone: '', owner_address: '',
          departure_contact_first_name: '', departure_contact_last_name: '', departure_contact_email: '', departure_contact_phone: '', departure_address: '',
          arrival_contact_first_name: '', arrival_contact_last_name: '', arrival_contact_email: '', arrival_contact_phone: '', arrival_address: '',
        }}
        validationSchema={ConveyingSchemaValidation}
        onSubmit={(values: ConveyingType, { setSubmitting }: FormikHelpers<ConveyingType>) => {
          setTimeout(() => {
            onSubmitHandler(values);
            setSubmitting(false);
          }, 500);
        }}
      >
        <Form>
          <Stack spacing={4}>
              <DadycarAlert type={'alert'}>
                <Text color='#0F61AC' fontSize={'sm'}>{props.activeStep === 0 ? `Veuillez renseigner la date à partir de laquelle le véhicule est disponible. Il vous sera alors automatiquement renseigné la date limite de livraison.
                        Notre chauffeur ne pouvant pas à l’avance vous communiquer les dates précises de prise en charge et de livraison, votre véhicule doit être disponible sur la période entière.
                        Le chauffeur vous contactera, au plus tard, 24h avant son passage.` 
                      : 
                      `Les contacts au départ et à l’arrivée sont en charge de remettre (ou récupérer) les clés et carte grise (copie) du véhicule. Ils devront être muni d’une pièce d’identité.
                      Veillez à ce que les adresses de départ et d’arrivée soient en capacité d’accueillir un porte-véhicules de 20 mètres de long.` }</Text>
              </DadycarAlert>
              {props.activeStep === 0 ? (
                <Box>
                  <Box>
                    <Flex alignItems={'center'} position={'relative'}>
                      <Box py={2}>
                        <Text fontWeight={500} fontSize='22px' color='#0F82EA'>Dates du transport</Text>
                      </Box>
                    </Flex>
                    <Flex display={{ base: 'block', lg: 'flex' }} gap={4}>
                      <Box  w={'100%'}>
                        <Flex alignItems={'center'} position={'relative'} mb={1}>
                          <Box fontWeight={500}>Disponible a partir</Box>
                        </Flex>
                        <InputField name="from_date" type="date" placeholder="-- /-- / --" size='lg' />
                      </Box>
                      <Box  w={'100%'}>
                        <Flex alignItems={'center'} position={'relative'} mb={1}>
                          <Box fontWeight={500}>Limite de livraison</Box>
                        </Flex>
                        <InputField name="to_date" type="date" placeholder="-- /-- / --" size='lg' />
                      </Box>
                    </Flex>
                  </Box>
                  <Box>
                    <Flex alignItems={'center'} position={'relative'}>
                      <Box py={2}>
                        <Text fontWeight={500} fontSize='22px' color='#0F82EA'>Propriétaire / Responsable</Text>
                      </Box>
                    </Flex>
                    <Box >
                      <Flex alignItems={'center'} position={'relative'} mb={1}>
                        <Box fontWeight={500}>Raison social</Box>
                      </Flex>
                      <InputField name="company_name" type="text" placeholder="Raison social" size='lg' />
                    </Box>
                    <SimpleGrid templateColumns={{ base: '1fr', lg: '2fr 2fr' }} spacing={6} >
                      <Box mt={4}>
                        <Flex alignItems={'center'} position={'relative'} mb={1}>
                          <Box fontWeight={500}>Prénom</Box>
                        </Flex>
                        <InputField name="owner_first_name" type="text" placeholder="Prénom" size='lg' />
                      </Box>
                      <Box mt={4}>
                        <Flex alignItems={'center'} position={'relative'} mb={1}>
                          <Box fontWeight={500}>Nom</Box>
                        </Flex>
                        <InputField name="owner_last_name" type="text" placeholder="Nom" size='lg' />
                      </Box>
                      <Box >
                        <Flex alignItems={'center'} position={'relative'} mb={1}>
                          <Box fontWeight={500}>Email</Box>
                        </Flex>
                        <InputField name="owner_email" type="email" placeholder="email@exmple.com" size='lg' />
                      </Box>
                      <Box >
                        <Flex alignItems={'center'} position={'relative'} mb={1}>
                          <Box fontWeight={500}>Téléphone</Box>
                        </Flex>
                        <InputField name="owner_phone" type="text" placeholder="+33xxxxxxxx" maxLength={10} />
                      </Box>
                    </SimpleGrid>
                    <Box mt={4}>
                      <Flex alignItems={'center'} position={'relative'} mb={1}>
                        <Box fontWeight={500}>Address</Box>
                      </Flex>
                      <SearchAddressAutocomplete
                        placeholder="Ex :site 1"
                        address={conveyingOwnerAddress}
                        selectAddress={selectConveyingOwnerAddress}
                        backgroundColor={'white'}
                        name="owner_address"
                      />
                    </Box>
                    <Flex mt={4} display={{ base: 'block', lg: 'flex' }} gap={4}>
                      <Box  w={'100%'}>
                        <Flex alignItems={'center'} position={'relative'} mb={1}>
                          <Box fontWeight={500}>Ville</Box>
                        </Flex>
                        <Input placeholder='Ville' value={conveyingOwnerAddress?.city} size='lg' borderRadius={'xl'} type={'text'} readOnly />
                      </Box>
                      <Box w={'100%'}>
                        <Flex alignItems={'center'} position={'relative'} mb={1}>
                          <Box fontWeight={500}>Code postal</Box>
                        </Flex>
                        <Input placeholder='Code Postal' value={conveyingOwnerAddress?.postcode} size='lg' borderRadius={'xl'} type={'text'} readOnly />
                      </Box>
                    </Flex>
                  </Box>
                </Box>
              ) : props.activeStep === 1 ? (
                <Box>
                    <Flex alignItems={'center'} position={'relative'}>
                      <Box py={2}>
                        <Text fontWeight={500} fontSize='22px' color='#0F82EA'>Contact au départ</Text>
                      </Box>
                    </Flex>
                  <SimpleGrid templateColumns={{ base: '1fr', lg: '2fr 2fr' }} spacing={6}>
                    <Box>
                      <Flex alignItems={'center'} position={'relative'} mb={1}>
                        <Box fontWeight={500}>Prénom</Box>
                      </Flex>
                      <InputField name="departure_contact_first_name" type="text" placeholder="Prénom" size='lg' />
                    </Box>
                    <Box>
                      <Flex alignItems={'center'} position={'relative'} mb={1}>
                        <Box fontWeight={500}>Nom</Box>
                      </Flex>
                      <InputField name="departure_contact_last_name" type="text" placeholder="Nom" size='lg' />
                    </Box>
                    <Box>
                      <Flex alignItems={'center'} position={'relative'} mb={1}>
                        <Box fontWeight={500}>Email</Box>
                      </Flex>
                      <InputField name="departure_contact_email" type="email" placeholder="email@exmple.com" size='lg' />
                    </Box>
                    <Box>
                      <Flex alignItems={'center'} position={'relative'} mb={1}>
                        <Box fontWeight={500}>Téléphone</Box>
                      </Flex>
                      <InputField name="departure_contact_phone" type="text" placeholder="+33xxxxxxxx" maxLength={10} />
                    </Box>
                  </SimpleGrid>
                  <Box mt={4}>
                    <Flex alignItems={'center'} position={'relative'} mb={1}>
                      <Box fontWeight={500}>Address</Box>
                    </Flex>
                    <SearchAddressAutocomplete
                        placeholder="Ex :site 1"
                        address={conveyingDepartureAddress}
                        selectAddress={selectConveyingDepartureAddress}
                        backgroundColor={'white'}
                        name="departure_contact_address"
                      />  
                  </Box>
                  <Flex display={{ base: 'block', lg: 'flex' }} gap={4} mt={4}>
                    <Box w={'100%'}>
                      <Flex alignItems={'center'} position={'relative'} mb={1}>
                        <Box fontWeight={500}>Ville</Box>
                      </Flex>
                      <Input placeholder='Ex :site 1' value={conveyingDepartureAddress?.city} size='lg' borderRadius={'xl'} type={'text'} readOnly />
                    </Box>
                    <Box w={'100%'}>
                      <Flex alignItems={'center'} position={'relative'} mb={1}>
                        <Box fontWeight={500}>Code Postal</Box>
                      </Flex>
                      <Input placeholder='Ex :site 1' value={conveyingDepartureAddress?.postcode} size='lg' borderRadius={'xl'} type={'text'} readOnly />
                    </Box>
                  </Flex>
                </Box>
              ): props.activeStep === 2 ? (
                <Box>
                  <Flex alignItems={'center'} position={'relative'}>
                    <Box py={2}>
                      <Text fontWeight={500} fontSize='22px' color='#0F82EA'>Contact à l’arrivée</Text>
                    </Box>
                  </Flex>
                  <SimpleGrid templateColumns={{ base: '1fr', lg: '2fr 2fr' }} spacing={6}>
                    <Box>
                      <Flex alignItems={'center'} position={'relative'} mb={1}>
                        <Box fontWeight={500}>Prénom</Box>
                      </Flex>
                      <InputField name="arrival_contact_first_name" type="text" placeholder="Prénom" size='lg' />
                    </Box>
                    <Box>
                      <Flex alignItems={'center'} position={'relative'} mb={1}>
                        <Box fontWeight={500}>Nom</Box>
                      </Flex>
                      <InputField name="arrival_contact_last_name" type="text" placeholder="Nom" size='lg' />
                    </Box>
                    <Box>
                      <Flex alignItems={'center'} position={'relative'} mb={1}>
                        <Box fontWeight={500}>Email</Box>
                      </Flex>
                      <InputField name="arrival_contact_email" type="email" placeholder="email@exmple.com" size='lg' />
                    </Box>
                    <Box>
                      <Flex alignItems={'center'} position={'relative'} mb={1}>
                        <Box fontWeight={500}>Téléphone</Box>
                      </Flex>
                      <InputField name="arrival_contact_phone" type="text" placeholder="+33xxxxxxxx" maxLength={10} />
                    </Box>
                  </SimpleGrid>
                  <Box mt={4}>
                    <Flex alignItems={'center'} position={'relative'} mb={1}>
                      <Box fontWeight={500}>Address</Box>
                    </Flex>
                    <SearchAddressAutocomplete
                      placeholder="Address"
                      address={conveyingReturnAddress}
                      selectAddress={selectConveyingReturnAddress}
                      backgroundColor={'white'}
                      name="arrival_contact_address"
                    />
                  </Box>
                  <Flex display={{ base: 'block', lg: 'flex' }} mt={4} gap={4}>
                    <Box w={'100%'}>
                      <Flex alignItems={'center'} position={'relative'} mb={1}>
                        <Box fontWeight={500}>Ville</Box>
                      </Flex>
                      <Input placeholder='Ville' value={conveyingReturnAddress?.city} size='lg' borderRadius={'xl'} type={'text'} readOnly />
                    </Box>
                    <Box w={'100%'}>
                      <Flex alignItems={'center'} position={'relative'} mb={1}>
                        <Box fontWeight={500}>Code Postal</Box>
                      </Flex>
                      <Input placeholder='Code Postal' value={conveyingReturnAddress?.postcode} size='lg' borderRadius={'xl'} type={'text'} readOnly />
                    </Box>
                  </Flex>
                </Box>
              ) : null}
              <Flex justifyContent='flex-end' gap={4} mt={4} >
                {props.activeStep !== 0 ? (
                  <Button w='230px' h='52px' borderWidth='2px' bg='white' rounded='30px' onClick={()=>props.setActiveStep(props.activeStep  - 1)}>Retour</Button>
                ) : null}
                {props.activeStep === 2 ? (
                  (
                    <Button type='submit' w='230px' h='52px' bg='#0F82EA' color='white' _hover={{bg:'#3563E9'}} rounded='30px'>Valider ma commande</Button>
                  )
                ) : (
                  <Button w='230px' h='52px' bg='#0F82EA' color='white' _hover={{bg:'#3563E9'}} rounded='30px' onClick={()=>props.setActiveStep(props.activeStep + 1)}>Suivant</Button>
                )}
              </Flex>
          </Stack>
        </Form>
      </Formik>
    </>
  )
}

export default ConveyingForm;