import { useEffect } from 'react';
import { Head } from '../../../../components/blocks/_index';
import Hero from './components/hero/Hero';
import Avantage from './components/avantage/Avantage';
import { Box } from '@chakra-ui/react';
import AutoPartage from './components/partage/AutoPartage';
import Revolution from './components/revolution/Revolution';
import Optimizer from './components/optimizer/Optimizer';
import Application from './components/application/Application';
import FAQ from '../../pro-fleet/components/FAQ';
import Contact from '../../pro-fleet/components/Contact';

const title = "Convoyage";
const description = "Réservez votre service de convoyage avec Dadycar et profitez d'un transport de véhicules de qualité. Nos chauffeurs professionnels se chargent de prendre en charge et livrer votre véhicule en toute sécurité et à l'heure. Réservez en ligne en quelques clics et simplifiez votre transport de véhicules.";
const keywords = "convoyage de véhicules, transport de véhicules, chauffeur professionnel, réservation en ligne";


const CarSharingPage = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

  return (
    <>
        <Head title={title} description={description} keywords={keywords} />
        <Box overflow={'hidden'}>
          <Hero />    
          <Avantage />
          <AutoPartage />
          <Revolution />
          <Optimizer />
          <Application />
          <FAQ />
          <Contact />
        </Box>
    </>
  )
}

export default CarSharingPage