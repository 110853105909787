import { Box, Button, Flex, FormControl, HStack, Input, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import { Field, FieldProps } from 'formik';
import React from 'react'
import { FaChevronDown, FaTrash } from "react-icons/fa";

const Alternateur = () => {
  return (
    <Box my={4}>
        <Flex mb={2} justifyContent={'space-between'} alignItems={'center'}>
            <Text fontSize={'22px'} fontWeight={700} color={'#222D50'} >Alternateur</Text>
            <FaChevronDown size={30} color='#0F82EA' cursor={'pointer'}/>
        </Flex>
        <TableContainer w={'100%'} maxH={'20rem'} overflowY={'auto'}>
            <Table>
                <Thead py={2} bg='#A8BDFF4D' opacity={30} rounded={'6px'}>
                    <Tr>
                    <Th fontWeight={'450'} color={'#4E5D78'} >Désignation</Th>
                    <Th></Th>
                    <Th fontWeight={'450'} color={'#4E5D78'}>Prix unit. € HT</Th>
                    <Th fontWeight={'450'} color={'#4E5D78'}>Qté ou MO</Th>
                    <Th fontWeight={'450'} color={'#4E5D78'}>Remise</Th>
                    <Th fontWeight={'450'} color={'#4E5D78'}>Prix total € HT</Th>
                    <Th fontWeight={'450'} color={'#4E5D78'}>Prix total € TTC</Th>
                    <Th></Th>
                    </Tr>
                </Thead>
                <Tbody>
                    <Tr rounded={'6px'}>
                        <Td>Alternateur 0009067104</Td>
                        <Td fontWeight={700} color={'#0F82EA'} >BOSCH <br />1986A01232</Td>
                        <Td>
                            <Field   name="date_rdv">
                            {({ field }: FieldProps) => (
                                <FormControl>
                                <Input type='number' bg='white' borderWidth={'2px'} borderColor={'#A8BDFF4D'} {...field} id="date_rdv" />
                                </FormControl>
                            )}
                            </Field>
                        </Td>
                        <Td>
                            <Field   name="date_rdv">
                            {({ field }: FieldProps) => (
                                <FormControl>
                                <Input type='number' bg='white' borderWidth={'2px'} borderColor={'#A8BDFF4D'}  {...field} id="date_rdv" />
                                </FormControl>
                            )}
                            </Field>
                        </Td>
                        <Td>
                            <Field   name="date_rdv">
                            {({ field }: FieldProps) => (
                                <FormControl>
                                <Input type='number' bg='white' borderWidth={'2px'} borderColor={'#A8BDFF4D'}  {...field} id="date_rdv" />
                                </FormControl>
                            )}
                            </Field>
                        </Td>
                        <Td fontWeight={700} color={'#0F82EA'}>502 €</Td>
                        <Td fontWeight={700} color={'#0F82EA'}>720 €</Td>
                        <Td>
                            <Flex rounded={'6px'} cursor={'pointer'} _hover={{bg:'#F78C9C6F'}} justifyContent={'center'} alignItems={'center'} bg='#F78C9C66' w={'32px'} h='32px' >
                                    <FaTrash color='#EB5757' />
                            </Flex>
                        </Td>
                    </Tr>
                    <Tr bg='#A8BDFF4D' opacity={30} rounded={'6px'}>
                        <Td>Alternateur 0009067104</Td>
                        <Td fontWeight={700} color={'#0F82EA'}>BOSCH <br />1986A01232</Td>
                        <Td>
                            <Field   name="date_rdv">
                            {({ field }: FieldProps) => (
                                <FormControl>
                                <Input type='number' bg='white' borderWidth={'2px'} borderColor={'#A8BDFF4D'}  {...field} id="date_rdv" />
                                </FormControl>
                            )}
                            </Field>
                        </Td>
                        <Td>
                            <Field   name="date_rdv">
                            {({ field }: FieldProps) => (
                                <FormControl>
                                <Input type='number' bg='white' borderWidth={'2px'} borderColor={'#A8BDFF4D'}  {...field} id="date_rdv" />
                                </FormControl>
                            )}
                            </Field>
                        </Td>
                        <Td>
                            <Field   name="date_rdv">
                            {({ field }: FieldProps) => (
                                <FormControl>
                                <Input type='number' bg='white' borderWidth={'2px'} borderColor={'#A8BDFF4D'}  {...field} id="date_rdv" />
                                </FormControl>
                            )}
                            </Field>
                        </Td>
                        <Td fontWeight={700} color={'#0F82EA'}>502 €</Td>
                        <Td fontWeight={700} color={'#0F82EA'}>720 €</Td>
                        <Td>
                            <Flex rounded={'6px'} cursor={'pointer'} _hover={{bg:'#F78C9C6F'}} justifyContent={'center'} alignItems={'center'} bg='#F78C9C66' w={'32px'} h='32px' >
                            <FaTrash color='#EB5757' />
                            </Flex>
                        </Td>
                    </Tr>
                        <Tr rounded={'6px'}>
                        <Td>Alternateur 0009067104</Td>
                        <Td fontWeight={700} color={'#0F82EA'}>BOSCH <br />1986A01232</Td>
                        <Td>
                            <Field   name="date_rdv">
                            {({ field }: FieldProps) => (
                                <FormControl>
                                <Input type='number' bg='white' borderWidth={'2px'} borderColor={'#A8BDFF4D'}  {...field} id="date_rdv" />
                                </FormControl>
                            )}
                            </Field>
                        </Td>
                        <Td>
                            <Field   name="date_rdv">
                            {({ field }: FieldProps) => (
                                <FormControl>
                                <Input type='number' bg='white' borderWidth={'2px'} borderColor={'#A8BDFF4D'}  {...field} id="date_rdv" />
                                </FormControl>
                            )}
                            </Field>
                        </Td>
                        <Td>
                            <Field   name="date_rdv">
                            {({ field }: FieldProps) => (
                                <FormControl>
                                <Input type='number' bg='white' borderWidth={'2px'} borderColor={'#A8BDFF4D'}  {...field} id="date_rdv" />
                                </FormControl>
                            )}
                            </Field>
                        </Td>
                        <Td fontWeight={700} color={'#0F82EA'}>502 €</Td>
                        <Td fontWeight={700} color={'#0F82EA'}>720 €</Td>
                        <Td>
                            <Flex rounded={'6px'} cursor={'pointer'} _hover={{bg:'#F78C9C6F'}} justifyContent={'center'} alignItems={'center'} bg='#F78C9C66' w={'32px'} h='32px' >
                            <FaTrash color='#EB5757' />
                            </Flex>
                        </Td>
                    </Tr>
                </Tbody>
            </Table>
        </TableContainer>
        <Flex w='100%' my={4} alignItems={'center'} justifyContent={'space-between'}>
            <Text fontSize={'18px'} fontWeight={700} color={'#222D50'} >Total</Text>
            <HStack gap={8}>
            <Text fontSize={'18px'} fontWeight={700} color={'#0F82EA'}>00.00 €</Text>
            <HStack gap={4}>
                <Text fontSize={'18px'} fontWeight={700} color={'#0F82EA'}>1024 €</Text>
                <Text fontSize={'18px'} fontWeight={700} color={'#0F82EA'}>1464 €</Text>
            </HStack>
            </HStack>
        </Flex>
    </Box>
  )
}

export default Alternateur