import { useDispatch } from "react-redux";
import { DepartmentType } from "../models/Department";
import { Department } from "../models/_index";
import { departmentActions } from "../store/slices/department-slice";

class DepartmentViewModel {

    dispatch = useDispatch();

    public getDepartments = async (regionCode: number) => {
        const response = await Department.getDepartments(regionCode);
        if (response.status === 200)
            this.dispatch(departmentActions.updateList(response.data));
    }

    public searchDepartments = async (regionSlug: string): Promise<{ status: string }> => {
        const response = await Department.searchDepartments(regionSlug);
        if (response.status === 200) {
            this.dispatch(departmentActions.updateList(response.data));
            return { status: 'success' };
        }
        return { status: 'error' };
    }

    public selectDepartment = async (department: DepartmentType) => {
        this.dispatch(departmentActions.updateSelected(department));
    }
}

export default DepartmentViewModel;