import { Button, Drawer, DrawerBody, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Link, SimpleGrid, Stack, Text, useDisclosure } from '@chakra-ui/react'


const CookiesModal: React.FC = () => {
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true })
  const alreadyAnswered = localStorage.getItem('cookiesAccepted');
  const onAcceptHandler = () => {
    localStorage.setItem('cookiesAccepted', 'true');
    onClose();
  }

  const onRefuseHandler = () => {
    localStorage.setItem('cookiesAccepted', 'false');
    onClose();
  }

  return (
    <>
      {!alreadyAnswered &&
        <Drawer placement={"bottom"} closeOnOverlayClick={false} onClose={onClose} isOpen={isOpen}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerHeader borderBottomWidth='1px'>🍪 La sécurité de vos données est pour nous une priorité absolue</DrawerHeader>
            <DrawerBody>
              <Text>
                Dadycar utilise des cookies nécessaires au bon fonctionnement du site. Nous utilisons également des cookies pour mesurer le trafic et pour vous montrer du contenu publicitaire personnalisé, cela nous permet d’améliorer votre navigation. Pour en savoir plus sur les cookies, les données utilisées et leur traitement, vous pouvez consulter notre politique en matière de cookies. Vous pourrez modifier vos préférences à tout moment en cliquant sur notre <Link color={'#5393EC'} textDecoration={'underline'} href={'/politique-de-confidentialite'}>Politique de confidentialité</Link> .
              </Text>
            </DrawerBody>
            <DrawerFooter justifyContent={{ base: 'center', lg: 'end' }}>
              <SimpleGrid templateColumns={{ base: '1fr', md: '2fr 2fr' }} spacing={6}>
                <Stack>
                  <Button
                    borderRadius={'lg'}
                    bg={'#5393EC'}
                    color={'white'}
                    _hover={{
                      bg: 'blue.500',
                    }}
                    onClick={onAcceptHandler}>Accepter tous les cookies</Button>
                </Stack>
                <Stack>
                  <Button
                    border={'2px solid #5393EC'}
                    color={'#5393EC'}
                    bg={'white'}
                    _hover={{
                      bg: '#5393EC',
                      color: 'white'
                    }}
                    onClick={onRefuseHandler}
                  >
                    Refuser
                  </Button>
                </Stack>
              </SimpleGrid>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      }
    </>
  )
}

export default CookiesModal