import { RouterProvider } from 'react-router-dom'
import { Fonts, Theme } from './config/_index';
import { ChakraProvider } from '@chakra-ui/react';
import { Toast } from './components/toasts/_index';
import { CookiesModal } from './components/modals/_index';
import RouterConfig from './routes/V2/_RouterConfig';
// import RouterConfig from './routes/V2/_RouterConfig';

function App() {
  return (
    <>
      <ChakraProvider theme={Theme}>
        <Toast />
        <Fonts />
        <CookiesModal />
        <RouterProvider router={RouterConfig} />
      </ChakraProvider>
    </>
  );
}
export default App;