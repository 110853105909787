import { FormControl, FormErrorMessage } from '@chakra-ui/form-control';
import { Form, Formik, Field, FieldProps } from 'formik';
import { Input, InputGroup, InputLeftElement } from '@chakra-ui/input';
import { Box, HStack,FormLabel, Flex, Button } from '@chakra-ui/react';
import * as yup from 'yup';
import { CiSearch } from "react-icons/ci";
import Select from 'react-select';

const formSchema = yup.object({
    search: yup.string().optional(),
    brand: yup.string().optional(),
    site: yup.string().optional(),
    responsable: yup.string().optional(),
  })
  
  

interface FormValues {
    search:string;
    brand:string;
    site:string;
    responsable:string
  }

const Filtring = () => {
    const initialValues: FormValues = { search:"",brand:"",site:"",responsable:"" };

    const brandOptions = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
    ]

    const siteOptions = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
    ]

    const responsableOptions = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
    ]

      const onSubmitHandler = async (values: FormValues ,resetForm:any) => {
        console.log(values)
      }

      const customStyles = {
        control: (provided : any) => ({
          ...provided,
          height: '47px',
          minHeight: '47px',
          border: 'none',
          boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
        }),
        valueContainer: (provided : any) => ({
          ...provided,
          height: '47px',
          display: 'flex',
          alignItems: 'center',
          padding: '0 8px',
        }),
        input: (provided : any) => ({
          ...provided,
          margin: 0,
          padding: 0,
        }),
        indicatorsContainer: (provided : any) => ({
          ...provided,
          height: '47px',
        }),
        placeholder: (provided : any) => ({
          ...provided,
          margin: 0,
          padding: 0,
        }),
        indicatorSeparator: () => ({
            display: 'none',
          }),
      };
    return (
        <Box my={4} >
            <Formik
                initialValues={initialValues}
                validationSchema={formSchema}
                onSubmit={(values,{resetForm})=>{onSubmitHandler(values,resetForm)}}
            >
                {({ handleSubmit,setFieldValue,handleBlur }) => (
                    <Form onSubmit={handleSubmit}>
                        <Flex alignItems={'center'} gap={3} w='100%'  >
                            <Box>
                                <Field name="search">
                                    {({ field }: FieldProps) => (
                                    <FormControl>
                                        <InputGroup>
                                            <InputLeftElement pointerEvents='none' mt={1}>
                                                <CiSearch color='#2A2D3799'  size={25}  />
                                            </InputLeftElement>
                                            <Input
                                                style={{boxShadow:'0 1px 2px rgba(0, 0, 0, 0.1)'}}
                                                color={'#394456'}
                                                fontWeight={500}
                                                backgroundColor={'white'} 
                                                height={'47px'} 
                                                rounded={'8px'}
                                                type='text'
                                                {...field} 
                                                id="search"
                                                placeholder='Search'
                                            />
                                        </InputGroup>                                        
                                    </FormControl>
                                    )}
                                </Field>
                            </Box>
                            <Box h='47px' >
                                <Select
                                    styles={customStyles}
                                    id={"brand"}
                                    placeholder='By Brand'
                                    onChange={option => {
                                        setFieldValue("brand", option?.value)
                                    }}
                                    options={brandOptions}
                                    onBlur={handleBlur}
                                />
                            </Box>
                            <Box h='47px' >
                                <Select
                                    styles={customStyles}
                                    id={"site"}
                                    placeholder='By Site'
                                    onChange={option => {
                                        setFieldValue("site", option?.value)
                                    }}
                                    options={siteOptions}
                                    onBlur={handleBlur}
                                />
                            </Box>
                            <Box h='47px' >
                                <Select
                                    styles={customStyles}
                                    id={"responsable"}
                                    placeholder='By Responsable'
                                    onChange={option => {
                                        setFieldValue("responsable", option?.value)
                                    }}
                                    options={responsableOptions}
                                    onBlur={handleBlur}
                                />
                            </Box>
                            <Button type='submit' >Search</Button>
                        </Flex>
                    </Form>
                )}
            </Formik>
        </Box>
    )
}

export default Filtring