import { useDispatch } from 'react-redux';
import { SelectedDateType, vendorActions, VendorType } from '../store/slices/vendor-slice';
import { Vendor } from '../models/_index';
import { AddressType } from '../models/Address';
import { SubServiceType } from '../models/Service';
import { VendorLoginType } from '../models/Vendor';
import { toastActions } from '../store/slices/toast-slice';

const SuccessLoginToast = {
    title: "Connexion réussie",
    message: "Vous êtes maintenant connecté.",
    status: "success",
}

const ErrorLoginToast = {
    title: "Erreur de connexion",
    message: "E-mail ou mot de passe incorrect.",
    status: "error",
}

const ErrorDisabledAccountToast = {
    title: "Erreur de connexion",
    message: "Votre compte n'est pas activé.",
    status: "error",
}

const ServcerError = {
    title: "Erreur",
    message: "Une erreur s'est produite. Veuillez réessayer.",
    status: "error",
}

class VendorViewModel {

    dispatch = useDispatch();

    public login = async (driverLoginData: VendorLoginType): Promise<{ status: string; eu_support?: string }> => {
        const response = await Vendor.login(driverLoginData);

        if (response.status === 200) {
            this.dispatch(toastActions.display(SuccessLoginToast));
            return { status: 'success', 'eu_support': response.data?.eu_support };
        } else if (response.status === 422) {
            if (response.data.message === "Your account is not activated.")
                this.dispatch(toastActions.display(ErrorDisabledAccountToast));
            else
                this.dispatch(toastActions.display(ErrorLoginToast));
        } else {
            this.dispatch(toastActions.display(ServcerError));
        }
        return { status: 'error' };
    }

    public getVendors = async () => {
        const response = await Vendor.getVendors();
        if (response.status === 200)
            this.dispatch(vendorActions.updateList(response.data.data));
    }

    public getVendorDetails = async (vendorId: number) => {
        const response = await Vendor.getVendor(vendorId);

        if (response.status === 200)
            this.dispatch(vendorActions.updateVendorDetails(response.data));
    }

    public getReferncedVendorDetails = async (vendorId: number) => {
        const response = await Vendor.getReferncedVendor(vendorId);

        if (response.status === 200)
            this.dispatch(vendorActions.updateVendorDetails(response.data));
    }

    public getVendorsWithFilter = async (selectedVehilce: { id?: number, plateNumber?: string }, selectedAddress: AddressType, selectedSubService: SubServiceType) => {
        this.dispatch(vendorActions.updateIsLoading(true));
        const data = {
            versionId: selectedVehilce!.id,
            plateNumber: selectedVehilce!.plateNumber,
            lat: selectedAddress!.lat,
            lng: selectedAddress!.lng,
            radius: 100,
            subServiceId: selectedSubService!.id,
        };

        const response = await Vendor.getVendorsWithFilter(data);
        if (response.status === 200)
            this.dispatch(vendorActions.updateList(response.data.data));

        this.dispatch(vendorActions.updateIsLoading(false));
    }

    public getCityVendors = async (citySlug: string): Promise<{ status: string }> => {
        const response = await Vendor.getCityVendors(citySlug);

        if (response.status === 200) {
            this.dispatch(vendorActions.updateList(response.data.garages));
            this.dispatch(vendorActions.updateCityVendors(response.data.referencedGarages));
            return { status: 'success' };
        } else {
            this.dispatch(vendorActions.updateCityVendors(null));
            return { status: 'error' };
        }
    }

    public selectVendor = async (vendor: VendorType) => {
        this.dispatch(vendorActions.updateSelected(vendor));
    }

    public selectDate = (selectedDate: SelectedDateType) => {
        this.dispatch(vendorActions.selectDate(selectedDate));
    }
}

export default VendorViewModel;