import { Box, Container, Text } from "@chakra-ui/layout";
import { SectionHeader } from "../../../../components/sections/_index";

const AboutVendorsSection: React.FC = () => {
    return (
        <>
            <Box bg={'white'} py={5} px={{ base: 10 }}>
                <Container
                    maxW={'80%'}
                    w={{ lg: '1000px' }}
                    py={10}
                    px={{ lg: 12 }}
                >
                    <SectionHeader title={"Comparez les Garages et Centres auto : meilleurs avis et prix pas chers !"} />

                    <Text>
                        Vous recherchez un garage auto près de chez vous pour le changement de vos disques et plaquettes de frein ? Vous ne savez pas où se trouve le meilleur garage autour de vous ni lequel est le moins cher pour opérer cette prestation ?

                        Ne vous embêtez pas : Dadycar a référencé pour vous une multitude de garages sur son comparateur pour vous permettre de comparer et de trouver le meilleur garage au meilleur prix avec des remises.

                        Grâce à Dadycar, vous n’avez plus besoin de vous déplacer pour prendre un rendez-vous. Il vous suffit en quelques clics de choisir un garage, selon la date, le créneau horaire et le devis de la prestation auto qui vous convient.
                        Dadycar vous fait gagner du temps et de l’argent sur toutes vos réparations auto grâce à notre comparateur. Il ne vous reste plus qu’à réserver votre intervention dans le meilleur garage à proximité de vous pour effectuer une révision, un changement d’amortisseurs ou de kit de distribution.
                    </Text>
                </Container>
            </Box>
        </>
    )
}

export default AboutVendorsSection;
