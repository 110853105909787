import { createSlice } from '@reduxjs/toolkit';
import { DepartmentType } from '../../models/Department';

type SliceState = { list: DepartmentType[] | null, selected: DepartmentType | null };

const initialState: SliceState = { list: null, selected: null }

const departmentSlice = createSlice({
  name: 'department',
  initialState,
  reducers: {
    updateList(state, action) {
      state.list = action.payload;
    },
    updateSelected(state, action) {
      state.selected = action.payload;
    },
  },
});

export const departmentActions = departmentSlice.actions;
export default departmentSlice.reducer;