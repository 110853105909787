import { Box, Flex } from "@chakra-ui/react"
import Statistiques from "./components/statistiques/Statistiques"
import DashData from "./components/dashData/DashData"

const GaragePage = () => {
  return (
    <Box bg='#fafafa'>
        <Flex px={4} py={2} gap={4}>
          <Statistiques />
          <DashData />
        </Flex>
    </Box>
  )
}

export default GaragePage