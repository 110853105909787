import { Box, Button, Image, Stack } from '@chakra-ui/react';
import { BsArrowRight } from 'react-icons/bs';
import logo from '../../assets/images/dadycar-logo.webp';
import { Form, Formik, FormikHelpers } from 'formik';
import InputField from './fields/InputField';
import * as yup from 'yup';
import { VehicleType } from '../../store/slices/vehicle-slice';
import { VehicleViewModel, UserViewModel } from '../../view-models/_index';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

const validationSchema = yup.object({
    plateNumber: yup.string().required("Veuillez insérer le matricule du véhicule.").length(7, 'Veuillez insérer un matricule valide.'),
})

const CompleteVehicleDataForm: React.FC<{ onCompleteVehicleDataClose: () => void, onUserLoginOpen: () => void }> = (props) => {
    const storeVehicle = (new VehicleViewModel()).storeVehicle;
    const getCurrentUser = (new UserViewModel()).getCurrentUser;
    const user = useSelector((state: RootState) => state.user.current);

    useEffect(() => {
        getCurrentUser()
    }, []);

    const onSubmitHandler = (values: VehicleType) => {
        storeVehicle(values);
        props.onCompleteVehicleDataClose();
        if (!user)
            props.onUserLoginOpen();
    }

    return (
        <>
            <Box p={10}>
                <Stack align={'center'}>
                    <Image src={logo} width={'100px'} />
                </Stack>
                <Stack spacing={4}>
                    <Formik
                        initialValues={{ plateNumber: '' }}
                        validationSchema={validationSchema}
                        onSubmit={(values: VehicleType, { setSubmitting }: FormikHelpers<VehicleType>) => {
                            setTimeout(() => {
                                onSubmitHandler(values);
                                setSubmitting(false);
                            }, 500);
                        }}
                    >
                        <Form>
                            <Stack spacing={4}>
                                <InputField label="Matricule du véhicule" name="plateNumber" type="text" placeholder="AB-123-CD" minLength={7} maxLength={7} isUppercase={true} />
                                <Button type="submit" borderRadius={'lg'} bg={'#5393EC'} color={'white'} _hover={{ bg: 'blue.500' }}>
                                    Compléter
                                    <BsArrowRight />
                                </Button>
                            </Stack>
                        </Form>
                    </Formik>
                </Stack>
            </Box>
        </>
    );
};

export default CompleteVehicleDataForm;