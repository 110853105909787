import { Box, Flex, Image, Text } from '@chakra-ui/react'
import logo from '../../../../../../assets/V2/logo.png'
import SideLinks from './SideLinks'

const Sidebar = () => {
  return (
    <Box w={'278px'} pos={'fixed'} top={0} left={0} h={'100vh'} bg={'white'} className='sideShadow' >
        <Box p={4} >
            <Flex justifyContent={'center'} >
              <Image src={logo} />
            </Flex>
            <SideLinks />
        </Box>
    </Box>
  )
}

export default Sidebar