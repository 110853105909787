import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Box,
  Text,
  Stack,
  Checkbox,
  HStack,
  Input,
  Textarea,
  FormLabel,
  VStack,
  FormControl,
  Flex,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td
} from '@chakra-ui/react'

import * as yup from 'yup';
import { Form, Formik, Field, FieldProps } from 'formik';
import { useToast } from '@chakra-ui/react'
import { FaTrash } from 'react-icons/fa';

const RefusDevisValidation = yup.object({
  date_rdv: yup.string().required('Required'),

})

interface FormValues {
  date_rdv: string;

}

const NewDevisModal = () => {
    const toast = useToast()
    const initialValues: FormValues = { date_rdv:""};
    const { isOpen, onOpen, onClose } = useDisclosure()

    const onSubmitHandler = async (values: FormValues,resetForm:any) => {
      

      console.log(values)
  }

  return (
    <Box w='100%' >
        <Button w='100%' py={0} variant={'ghost'} fontSize={'18px'} color={'#182F45'} fontWeight={500} onClick={onOpen}>Accepter</Button>

        <Modal size={'5xl'} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
        <ModalHeader fontSize={'18px'} fontWeight={600} color={'#222D50'} textAlign={'center'}>Cree un devis</ModalHeader>
            <ModalBody>
              <Formik
                  initialValues={initialValues}
                  validationSchema={RefusDevisValidation}
                  onSubmit={(values,{resetForm})=>{onSubmitHandler(values,resetForm)}}
                >
                  {({ handleSubmit, values }) => (
                    <Form onSubmit={handleSubmit}>
                      <VStack alignItems={'start'} gap={3} w='100%'  >
                        <Flex alignItems={'center'} w='fit-content'  >
                          <FormLabel flex={1} fontWeight={600} color={'#222D50'} >Date de Rendez-vous</FormLabel>
                          <Box flex={1}>
                            <Field   name="date_rdv">
                              {({ field }: FieldProps) => (
                                <FormControl>
                                  <Input type='date' {...field} id="date_rdv" />
                                </FormControl>
                              )}
                            </Field>
                          </Box>
                        </Flex>
                        <Text fontSize={'22px'} fontWeight={700} color='#222D50' >Les prestations</Text>
                        <TableContainer w={'100%'} maxH={'20rem'} overflowY={'auto'}>
                          <Table>
                            <Thead py={2} bg='#A8BDFF4D' opacity={30} rounded={'6px'}>
                              <Tr>
                                <Th fontWeight={'450'} color={'#4E5D78'} >Désignation</Th>
                                <Th></Th>
                                <Th fontWeight={'450'} color={'#4E5D78'}>Prix unit. € HT</Th>
                                <Th fontWeight={'450'} color={'#4E5D78'}>Qté ou MO</Th>
                                <Th fontWeight={'450'} color={'#4E5D78'}>Remise</Th>
                                <Th fontWeight={'450'} color={'#4E5D78'}>Prix total € HT</Th>
                                <Th fontWeight={'450'} color={'#4E5D78'}>Prix total € TTC</Th>
                                <Th></Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              <Tr rounded={'6px'}>
                                <Td>Alternateur 0009067104</Td>
                                <Td fontWeight={700} color={'#0F82EA'} >BOSCH 1986A01232</Td>
                                <Td>
                                  <Field   name="date_rdv">
                                    {({ field }: FieldProps) => (
                                      <FormControl>
                                        <Input type='number' bg='white' borderWidth={'2px'} borderColor={'#A8BDFF4D'} {...field} id="date_rdv" />
                                      </FormControl>
                                    )}
                                  </Field>
                                </Td>
                                <Td>
                                  <Field   name="date_rdv">
                                    {({ field }: FieldProps) => (
                                      <FormControl>
                                        <Input type='number' bg='white' borderWidth={'2px'} borderColor={'#A8BDFF4D'}  {...field} id="date_rdv" />
                                      </FormControl>
                                    )}
                                  </Field>
                                </Td>
                                <Td>
                                  <Field   name="date_rdv">
                                    {({ field }: FieldProps) => (
                                      <FormControl>
                                        <Input type='number' bg='white' borderWidth={'2px'} borderColor={'#A8BDFF4D'}  {...field} id="date_rdv" />
                                      </FormControl>
                                    )}
                                  </Field>
                                </Td>
                                <Td fontWeight={700} color={'#0F82EA'}>502 €</Td>
                                <Td fontWeight={700} color={'#0F82EA'}>720 €</Td>
                                <Td>
                                  <Flex rounded={'6px'} cursor={'pointer'} _hover={{bg:'#F78C9C6F'}} justifyContent={'center'} alignItems={'center'} bg='#F78C9C66' w={'32px'} h='32px' >
                                    <FaTrash color='#EB5757' />
                                  </Flex>
                                </Td>
                              </Tr>
                              <Tr bg='#A8BDFF4D' opacity={30} rounded={'6px'}>
                                <Td>Alternateur 0009067104</Td>
                                <Td fontWeight={700} color={'#0F82EA'}>BOSCH 1986A01232</Td>
                                <Td>
                                  <Field   name="date_rdv">
                                    {({ field }: FieldProps) => (
                                      <FormControl>
                                        <Input type='number' bg='white' borderWidth={'2px'} borderColor={'#A8BDFF4D'}  {...field} id="date_rdv" />
                                      </FormControl>
                                    )}
                                  </Field>
                                </Td>
                                <Td>
                                  <Field   name="date_rdv">
                                    {({ field }: FieldProps) => (
                                      <FormControl>
                                        <Input type='number' bg='white' borderWidth={'2px'} borderColor={'#A8BDFF4D'}  {...field} id="date_rdv" />
                                      </FormControl>
                                    )}
                                  </Field>
                                </Td>
                                <Td>
                                  <Field   name="date_rdv">
                                    {({ field }: FieldProps) => (
                                      <FormControl>
                                        <Input type='number' bg='white' borderWidth={'2px'} borderColor={'#A8BDFF4D'}  {...field} id="date_rdv" />
                                      </FormControl>
                                    )}
                                  </Field>
                                </Td>
                                <Td fontWeight={700} color={'#0F82EA'}>502 €</Td>
                                <Td fontWeight={700} color={'#0F82EA'}>720 €</Td>
                                <Td>
                                  <Flex rounded={'6px'} cursor={'pointer'} _hover={{bg:'#F78C9C6F'}} justifyContent={'center'} alignItems={'center'} bg='#F78C9C66' w={'32px'} h='32px' >
                                    <FaTrash color='#EB5757' />
                                  </Flex>
                                </Td>
                              </Tr>
                              <Tr rounded={'6px'}>
                                <Td>Alternateur 0009067104</Td>
                                <Td fontWeight={700} color={'#0F82EA'}>BOSCH 1986A01232</Td>
                                <Td>
                                  <Field   name="date_rdv">
                                    {({ field }: FieldProps) => (
                                      <FormControl>
                                        <Input type='number' bg='white' borderWidth={'2px'} borderColor={'#A8BDFF4D'}  {...field} id="date_rdv" />
                                      </FormControl>
                                    )}
                                  </Field>
                                </Td>
                                <Td>
                                  <Field   name="date_rdv">
                                    {({ field }: FieldProps) => (
                                      <FormControl>
                                        <Input type='number' bg='white' borderWidth={'2px'} borderColor={'#A8BDFF4D'}  {...field} id="date_rdv" />
                                      </FormControl>
                                    )}
                                  </Field>
                                </Td>
                                <Td>
                                  <Field   name="date_rdv">
                                    {({ field }: FieldProps) => (
                                      <FormControl>
                                        <Input type='number' bg='white' borderWidth={'2px'} borderColor={'#A8BDFF4D'}  {...field} id="date_rdv" />
                                      </FormControl>
                                    )}
                                  </Field>
                                </Td>
                                <Td fontWeight={700} color={'#0F82EA'}>502 €</Td>
                                <Td fontWeight={700} color={'#0F82EA'}>720 €</Td>
                                <Td>
                                  <Flex rounded={'6px'} cursor={'pointer'} _hover={{bg:'#F78C9C6F'}} justifyContent={'center'} alignItems={'center'} bg='#F78C9C66' w={'32px'} h='32px' >
                                    <FaTrash color='#EB5757' />
                                  </Flex>
                                </Td>
                              </Tr>
                            </Tbody>
                          </Table>
                        </TableContainer>
                        <Flex w='100%' my={4} alignItems={'center'} justifyContent={'space-between'}>
                            <Text fontSize={'18px'} fontWeight={700} color={'#222D50'} >Total</Text>
                          <HStack gap={8}>
                            <Text fontSize={'18px'} fontWeight={700} color={'#0F82EA'}>00.00 €</Text>
                            <HStack gap={4}>
                              <Text fontSize={'18px'} fontWeight={700} color={'#0F82EA'}>1024 €</Text>
                              <Text fontSize={'18px'} fontWeight={700} color={'#0F82EA'}>1464 €</Text>
                            </HStack>
                          </HStack>
                        </Flex>
                        <HStack gap={4} w='100%' mt={2} mb={4}>
                          <Button h={'67px'} flex={1} variant='ghost' borderWidth={'1px'} rounded={'94px'} borderColor={'#0F82EA'} color={'#0F82EA'} onClick={onClose}>
                            Retour
                          </Button>
                          <Button h='67px' type='submit' flex={1} rounded={'94px'} colorScheme='blue' bg='#0F82EA' color={'white'} _hover={{ bg: '#0F82BA' }}>
                            Envoyer le devis
                          </Button>
                        </HStack>
                      </VStack>
                    </Form>
                  )}
                </Formik>
            </ModalBody>
        </ModalContent>
        </Modal>
    </Box>
  )
}

export default NewDevisModal