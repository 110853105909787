import { useEffect } from 'react';
import { BookingSection, DepartmentsSection } from './components/_index'

const DepartmentsPage: React.FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <BookingSection />
            <DepartmentsSection />
        </>
    )
}

export default DepartmentsPage