import { Box, Button, Center, Flex, Heading, Image, SimpleGrid, Stack, Text } from '@chakra-ui/react'
import locationLogo from '../../../../../../../assets/images/dadycar-Icon-material-location-blue.webp'
import { FaPhoneAlt } from 'react-icons/fa'
import { DatePicker } from './components/_index'
import React, { useState } from 'react'
import { VendorType } from '../../../../../../../store/slices/vendor-slice'
import { RootState } from '../../../../../../../store'
import { useSelector } from 'react-redux'
import { VendorViewModel, DistanceViewModel } from '../../../../../../../view-models/_index'
import { Link, useNavigate } from 'react-router-dom'

const VendorCard: React.FC<{ vendor: VendorType }> = ({ vendor }) => {
    const selectVendor = (new VendorViewModel()).selectVendor;
    const getDistanceWithPrice = (new DistanceViewModel()).getDistanceWithPrice;
    const selectedDate = useSelector((state: RootState) => state.vendor.selectedDate);
    const departureAddress = useSelector((state: RootState) => state.address.departureAddress);
    const returnAddress = useSelector((state: RootState) => state.address.returnAddress);
    const version = useSelector((state: RootState) => state.version.selected);
    const vehicle = useSelector((state: RootState) => state.vehicle.value);
    const [isDateSelected, setIsDateSelected] = useState<boolean>(true);
    const navigate = useNavigate();

    const onClickHandler = () => {
        if (selectedDate?.vendorId === vendor.id && departureAddress && returnAddress && (vehicle || version)) {
            selectVendor(vendor);
            getDistanceWithPrice(vendor, { departureAddress, returnAddress }, version?.id, 'P');
            navigate('/garage/reservation', { replace: true });
        } else {
            setIsDateSelected(false);
        }
    }

    const slug = (vendor.name).replace(/\s+/g, '-').toLowerCase();
    return (
        <Box bg={'white'} boxShadow={'md'} borderRadius={'2xl'} px={3} py={4} border={'2px solid white'} _hover={{ border: '2px solid #1385EB' }}>
            <SimpleGrid templateColumns={{ base: '1fr', lg: '50% 50%', md: '1fr' }} position={'relative'}>
                <Stack >
                    <Flex position={'relative'}>
                        <Center p={2} position={'relative'} w={{ base: '30%', xl: '40%', lg: '50%', md: '20%' }} mx={2} borderRadius={'xl'} bg={'#DFF7FC'} h={'70px'}>
                            <Image h={'100%'} src={vendor.image} />
                        </Center>
                        <Box w={{ base: '70%', xl: '60%', lg: '50%', md: '80%' }}>
                            <Link to={`/garage/${(slug)}/${vendor.id}`} style={{ textDecoration:'underline' }}>
                                <Text fontWeight={'bold'} fontSize={{ base: '2xs', xl: 'sm' }} textAlign={'start'}>
                                    {vendor.name}
                                </Text>
                            </Link>
                            <Text textAlign={'start'} fontSize={'x-small'}>
                                {vendor.description}
                            </Text>
                        </Box>
                    </Flex>
                    <Flex>
                        <Center w={{ base: '30%', xl: '40%', lg: '50%', md: '20%' }} mx={2} borderRadius={'xl'} bg={'#DFF7FC'} h={'50px'}>
                            <Text color={'#1385EB'}>{vendor.reviews}</Text>
                        </Center>
                        <Box w={{ base: '70%', xl: '60%', lg: '50%', md: '80%' }} alignSelf='center'>
                            <Text> {vendor.reviews} avis </Text>
                        </Box>
                    </Flex>
                    <Flex position={'relative'}>
                        <Center py={3} px={1} position={'relative'} w={{ base: '30%', xl: '40%', lg: '50%', md: '20%' }} mx={2} borderRadius={'xl'} bg={'#DFF7FC'} h={'50px'}>
                            <Image h={'100%'} src={locationLogo} />
                            <Box px={2}>
                                <Text fontWeight={'bold'} fontSize={'xs'}>À {vendor.distance ? parseInt(vendor.distance, 10) : null} km</Text>
                            </Box>
                        </Center>
                        <Box w={{ base: '70%', xl: '60%', lg: '50%', md: '80%' }}>
                            <Text textAlign={'start'} fontSize={'xs'}>
                                {vendor.address}
                            </Text>
                        </Box>
                    </Flex>
                    <Center py={3} w={'100%'}>
                        {/* <Center position={'relative'} w={{ base: '30%', xl: '40%', lg: '50%', md: '20%' }} mx={2} borderRadius={'xl'} bg={'#DFF7FC'} h={'40px'}>
                            <Text fontWeight={'bold'} >{vendor.price} €</Text>
                        </Center> */}
                        <Box w={{ base: '70%', xl: '100%', lg: '50%', md: '80%' }} px={3}>
                            <Button
                                color={'white'}
                                fontSize={'sm'}
                                borderRadius={'xl'}
                                bg={'#5393EC'}
                                w={"100%"}
                                _hover={{ bg: "#5393EC" }}
                                onClick={() => { onClickHandler() }}
                            >
                                Prendre Rendez-Vous
                            </Button>
                        </Box>
                    </Center>
                    {!isDateSelected && <Text color={'orange'}>Vous devez sélectionner une date</Text>}
                </Stack>
                <Stack h={'100%'} position={'relative'} border={'1px solid #5393EC'} borderRadius={"20px"}>
                    <Center borderRadius={'20px 20px 0 0'} bg={'#5393EC'} h={'50px'}>
                        <Heading as={'h6'} color={'white'} fontSize={'sm'} size={'xs'}>Choisissez votre heure de dépôt</Heading>
                    </Center>

                    <Center py={2} maxH={'80%'} h={{ base: '150px', lg: '80%' }} position={'relative'} overflowY={'scroll'} overflowX={'hidden'}>
                        <DatePicker schedule={vendor.schedule} vendorId={vendor.id} />
                    </Center>
                    <Box w={'100%'} py={2}>
                        <Text textAlign={'center'} fontSize={'sm'} color={'#707070'}>Une question pour ce garage ?</Text>
                        <a href="tel:01 84 88 49 09" style={{
                            color: '#1385EB',
                            justifyContent: 'center',
                            display: 'flex',
                            padding: "0 10%"
                        }}>
                            <FaPhoneAlt />
                            <Text px={2}>01 84 88 49 09</Text>
                        </a>
                    </Box>
                </Stack>
            </SimpleGrid>
        </Box>
    )
}

export default VendorCard;