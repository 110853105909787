import { Box, Flex, Text, useBreakpointValue } from "@chakra-ui/react"
import { useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";

type Props = {
    question:string,
    answer:string
}


const FAQComp = ({question,answer}:Props) => {
    const isMobile = useBreakpointValue({ base: true, md:false });

    const [isShow,setIsShow] = useState(false)
    
    const handleShow = () => {
        setIsShow((prev)=>!prev)
    }

    const handleShowMobile = () => {
        if(isMobile){
            setIsShow((prev)=>!prev)
        }else{
            return null
        }
    }

  return (
    <Box bg='white' boxShadow='md' px={8} py={4} borderWidth={2} rounded='18px' borderColor={isShow ? '#0F82EA' : 'white'} onClick={handleShowMobile} >
        <Flex justifyContent='space-between' alignItems='center' gap={4} >
            <Text  fontSize={{base:'18px',md:'22px'}} fontWeight='bold' >{question}</Text>
            <Flex display={{base:'none',md:'flex'}} w='51px' h='51px' justifyContent='center' alignItems='center' bg={isShow ? '#0F82EA' : 'white'} rounded='100%' onClick={handleShow}>
                {isShow ? (
                    <FaAngleUp color={isShow ? 'white' : '#222D50'} fontSize={30} cursor='pointer' />
                ) : (
                    <FaAngleDown color={isShow ? 'white' : '#222D50'} fontSize={30} cursor='pointer' />
                )}
            </Flex>
        </Flex>
        {isShow ? (
            <Text fontSize={{base:'14px',md:'18px'}} maxW='716px' color='#6F6C90' >{answer}</Text>
        ) : null}
    </Box>
  )
}

export default FAQComp