import { useEffect } from "react";
import Head from "../../components/blocks/Head";
import { AboutSection, AdventageSection, LoginSection, StepsSection, SliderSection, RegisterSection } from "./components/_index"

const title = "Devenir un Chauffeur";
const description = "Rejoignez l'équipe de chauffeurs professionnels de Dadycar et bénéficiez d'un revenu flexible et d'un emploi stable. Inscrivez-vous dès maintenant pour devenir chauffeur et profitez des avantages de notre plateforme de maintenance automobile en ligne.";
const keywords = "devenir chauffeur, emploi flexible, emploi stable, plateforme de maintenance automobile, inscription, avantages, Dadycar";

const DriverPage: React.FC<{ action: string }> = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Head title={title} description={description} keywords={keywords} />
      {props.action === "login" ? <LoginSection /> : <RegisterSection />}
      <AdventageSection />
      <AboutSection />
      <StepsSection />
      <SliderSection />
    </>
  )
}

export default DriverPage;
