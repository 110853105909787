import { useDispatch } from 'react-redux';
import Conveying, { ConveyingType } from '../models/Conveying';
import { conveyingActions } from '../store/slices/conveying-slice';
import { toastActions } from '../store/slices/toast-slice';

const StoreConveyingToast = {
    title: "Convoyage créé",
    message: "Votre convoyage a été créé avec succès.",
    status: "success",
}

const StoreConveyingErrorToast = {
    title: "Votre convoyage n'a pas pu être créé",
    message: "Quelque chose s'est mal passé, Veuillez réessayer ou contacter le support.",
    status: "error",
}
class ConveyingViewModel {

    dispatch = useDispatch();

    public getUserConveyings = async () => {
        const response = await Conveying.getClientConveyings();

        if (response.status === 200)
            this.dispatch(conveyingActions.updateList(response.data.data.conveyings));
    }

    public store = async (data: ConveyingType) => {
        const response = await Conveying.store(data);

        if (response.status === 200) {
            this.dispatch(conveyingActions.updateValue(response.data.data.conveying));
            this.dispatch(conveyingActions.updateClientSecret(response.data.data.clientSecret));
            this.dispatch(toastActions.display(StoreConveyingToast));
            return 'success';
        } else {
            this.dispatch(toastActions.display(StoreConveyingErrorToast));
            return 'error';
        }
    }
}

export default ConveyingViewModel;