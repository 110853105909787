import { Alert, AlertIcon, Box, Button, Center, Image, Link, Spinner, Stack, Text } from '@chakra-ui/react';
import { BsArrowRight } from 'react-icons/bs';
import logo from '../../assets/images/dadycar-logo.webp';
import googleIcon from '../../assets/images/dadycar-google-2.webp';
import { Form, Formik, FormikHelpers } from 'formik';
import InputField from './fields/InputField';
import * as yup from 'yup';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { UserLoginType } from '../../models/User';
import { UserViewModel } from '../../view-models/_index';
import { useState } from 'react';

interface Values {
  email: string;
  password: string;
}

const loginSchemaValidation = yup.object({
  email: yup.string().required("Veuillez insérer votre email.").email('Veuillez insérer un e-mail valide.'),
  password: yup.string().required('Veuillez insérer votre mot de passe.')
})

const UserLoginForm: React.FC<{ onUserLoginClose: () => void, onUserRegisterOpen: () => void }> = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const loginUser = (new UserViewModel()).login;
  const onSubmitHandler = async (values: UserLoginType) => {
    setIsLoading(true);
    const response = await loginUser(values);
    if (response.status === 'success') {
      props.onUserLoginClose();
    }
    setIsLoading(false);
  }
  const loginErrorMessage = useSelector((state: RootState) => state.user.loginErrorMessage);

  return (
    <>
      <Box p={10}>
        <Stack align={'center'}>
          <Image src={logo} alt="dadycar-logo" htmlHeight={'100%'} htmlWidth={'100%'} w={'100px'} h={'auto'} />
        </Stack>
        <Stack spacing={4}>
          <Formik
            initialValues={{ email: '', password: '' }}
            validationSchema={loginSchemaValidation}
            onSubmit={(values: Values, { setSubmitting }: FormikHelpers<Values>) => {
              setTimeout(() => {
                onSubmitHandler(values);
                setSubmitting(false);
              }, 500);
            }}
          >
            <Form>
              <Stack spacing={4}>
                {loginErrorMessage && <Alert status='error'><AlertIcon /> {loginErrorMessage}</Alert>}
                <InputField label="Adresse mail" name="email" type="email" placeholder="...@example.com" />
                <InputField label="Mot de passe" name="password" placeholder="Mot de passe" type="password" />
                <Button type="submit" borderRadius={'lg'} bg={'#5393EC'} color={'white'} _hover={{ bg: 'blue.500' }} disabled={isLoading}>
                  {isLoading ?
                    <Center>
                      <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='md' />
                    </Center>
                    :
                    <>
                      Se connecter
                      < BsArrowRight />
                    </>
                  }
                </Button>

              </Stack>
            </Form>
          </Formik>

          <Stack spacing={6}>
            <Stack direction={{ base: 'column', sm: 'row' }} align={'start'} justify={'space-between'}>
              <Link color={'blue.400'}>Mot de passe oublié ?</Link>
            </Stack>
            <Stack>
              <Text textAlign={'center'}>Vous n’avez pas de compte ?</Text>
            </Stack>
            <Stack>
              <Button w={'100%'} borderRadius={'lg'} bg={'#07D0C1'} color={'white'} _hover={{ bg: '#07D0C1' }}
                onClick={() => {
                  props.onUserLoginClose();
                  props.onUserRegisterOpen();
                }}
              >
                S’enregistrer
              </Button>
            </Stack>
            {/* <Stack>
              <Text textAlign={'center'}>ou</Text>
            </Stack>
            <Stack>
              <Button borderRadius={'lg'} bg={'white'} color={'black'} border={'1px solid grey'} _hover={{ bg: 'white' }}>
                <Image src={googleIcon} mx={2} />
                continuer avec google
              </Button>
            </Stack> */}
          </Stack>
        </Stack>
      </Box>
    </>
  )
}

export default UserLoginForm;