import { Box, Button, Center, FormControl, FormLabel, Image, Select, Spinner, Stack, Text } from '@chakra-ui/react';
import { BsArrowRight } from 'react-icons/bs';
import logo from '../../assets/images/dadycar-logo.webp';
import { Form, Formik, FormikHelpers } from 'formik';
import InputField from './fields/InputField';
import * as yup from 'yup';
import { useRef, useState } from 'react';
import { DriverRegisterType } from '../../models/Driver';
import DriverViewModel from '../../view-models/DriverViewModel';
import HttpClient from '../../services/HttpClient';

const RegisterSchemaValidation = yup.object({
  name: yup.string().required("Veuillez insérer votre nom.").matches(/^[A-Za-z ]*$/, 'Veuillez entrer un nom valide').min(3, 'Le nom doit contenir au moins 3 caractères').max(255, 'Le nom doit comporter au maximum 255 caractères'),
  email: yup.string().required("Veuillez insérer votre email.").email('Veuillez insérer un e-mail valide.').max(255, 'Le nom doit comporter au maximum 255 caractères'),
  phone_number: yup.string().required("Veuillez insérer votre numéro de téléphone.").matches(/^[0-9]*$/, 'Veuillez entrer un numéro de téléphone valide').length(10, 'Le numéro de téléphone doit comporter 10 chiffres'),
  driving_license: yup.string().required("Veuillez insérer votre numéro de permis de conduire."),
  experience_years: yup.number().required("Veuillez insérer votre nombre d'années d'expérience.").min(0, 'Le nombre d\'années d\'expérience doit être supérieur ou égal à 0'),
  password: yup.string().required('Veuillez insérer votre mot de passe.').min(8, 'Le mot de passe doit comporter au moins 8 caractères').max(255, 'Le mot de passe doit comporter au maximum 255 caractères'),
})

const DriverRegisterForm: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const registerDriver = (new DriverViewModel()).register;
  const TypeRef = useRef<HTMLSelectElement>(null);
  const onSubmitHandler = async (values: DriverRegisterType) => {
    values.type = TypeRef.current?.value as string;
    setIsLoading(true);
    const response = await registerDriver(values);
    if (response.status === 'success') {
      window.location.href = HttpClient.host + 'driver';
    }
    setIsLoading(false);
  }

  return (
    <>
      <Box>
        <Stack align={'center'}>
          <Image src={logo} width={'100px'} />
        </Stack>
        <Stack spacing={4}>
          <Formik
            initialValues={{ name: '', email: '', phone_number: '', driving_license: '', experience_years: 0, type: 'A', password: '', }}
            validationSchema={RegisterSchemaValidation}
            onSubmit={(values: DriverRegisterType, { setSubmitting }: FormikHelpers<DriverRegisterType>) => {
              setTimeout(() => {
                onSubmitHandler(values);
                setSubmitting(false);
              }, 500);
            }}
          >
            <Form>
              <Stack spacing={4}>
                <InputField label="Nom" name="name" type="text" placeholder="Votre nom et prénom" />
                <InputField label="Adresse mail" name="email" type="email" placeholder="...@example.com" />
                <InputField label="Numéro de téléphone" name="phone_number" type="text" placeholder="0x xx xx xx xx" maxLength={10} />
                <FormControl id="select">
                  <FormLabel>Êtes-vous auto-entrepreneur ou gérant d’une société ?</FormLabel>
                  <Select name='type' ref={TypeRef} borderRadius={'2xl'} required>
                    <option value="A">Auto-entrepreneur</option>
                    <option value="M">Gérant d’une société</option>
                  </Select>
                </FormControl>

                <InputField label="Permis de conduire" name="driving_license" type="text" placeholder="Votre numéro de permis de conduire" />
                <InputField label="Nombre d'années d'expérience" name="experience_years" type="number" placeholder="" />
                <InputField label="Mot de passe" name="password" type="password" placeholder="************" />
                <Button type="submit" borderRadius={'lg'} bg={'#5393EC'} color={'white'} _hover={{ bg: 'blue.500' }} disabled={isLoading}>
                  {isLoading ?
                    <Center>
                      <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='md' />
                    </Center>
                    :
                    <>
                      S'inscrire
                      < BsArrowRight />
                    </>
                  }
                </Button>

              </Stack>
            </Form>
          </Formik>
        </Stack>
      </Box>
    </>
  )
}

export default DriverRegisterForm;