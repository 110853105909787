import { Box, Center, Image } from '@chakra-ui/react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SectionHeader from '../../../../components/sections/SectionHeader';
import Brand1 from '../../../../assets/images/dadycar-top-garage-logo.webp';
import Brand2 from '../../../../assets/images/dadycar-ad-logo.webp';
import Brand3 from '../../../../assets/images/dadycar-Fiat-Logo.webp';
import Brand4 from '../../../../assets/images/dadycar-bosch-logo.webp';
import Brand5 from '../../../../assets/images/dadycar-renault-Logo.webp';
import Brand6 from '../../../../assets/images/dadycar-midas.webp';
import Brand7 from '../../../../assets/images/dadycar-auto-primo-logo.webp';

const PartenerSection: React.FC = () => {
    const partener = [
        Brand1,
        Brand2,
        Brand3,
        Brand4,
        Brand5,
        Brand6,
        Brand7,
    ]
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <Box bg={'white'} maxW={'100vw'}>
            <SectionHeader title={"Ils nous font confiance"} />
            <Box py={10} bg={'#F6FAFC'} px={8}>
                <Slider {...settings}>
                    {partener.map((item, idx) => (
                        <div key={idx}>
                            <Center>
                                <Image src={item} maxH={'70px'}/>
                            </Center>
                        </div>
                    ))}

                </Slider>
            </Box>

        </Box>
    );
}

export default PartenerSection;