import { Card, CardBody, Heading, Stack } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { DepartmentType } from '../../../../models/Department';

const DepartmentCard: React.FC<{ department: DepartmentType }> = ({ department }) => {
    return (
        <>
            <Stack key={department.id}>
                <Link to={`/garages/departement/${department.slug}`}>
                    <Card align='center' borderRadius={'20px'} border={'2px solid transparent'} h={'100%'} _hover={{ border: '2px solid #1385EB' }} bg={{ base: 'transparent', hover: 'white' }} >
                        <CardBody p={4}>
                            <Heading textAlign={'center'} my={3} size={'xs'}>{department.name}</Heading>
                            {/* <Heading textAlign={'center'} size={'xs'} color={'#1385EB'}>{20} Garage</Heading> */}
                        </CardBody>
                    </Card>
                </Link>
            </Stack>
        </>
    )
}

export default DepartmentCard;