import { Box, Button, Flex, Heading, Image, Input, Link, Stack, StackDivider, Text, VStack } from "@chakra-ui/react";
import { useState, useEffect, useRef } from 'react';
import { useSelector } from "react-redux";
import carIcon from '../../assets/images/dadycar-Icon-ionic-ios-car.webp';
import { RootState } from "../../store/index";
import { MarkViewModel, ModelViewModel, VehicleViewModel, VersionViewModel } from "../../view-models/_index";

const SelectVehicle: React.FC = () => {
    const vehicleViewModel = new VehicleViewModel();
    const vehicle = useSelector((state: RootState) => state.vehicle.value);

    const markViewModel = new MarkViewModel();
    const marks = useSelector((state: RootState) => state.mark.list);
    const selectedMark = useSelector((state: RootState) => state.mark.selected);

    const modelViewModel = new ModelViewModel();
    const models = useSelector((state: RootState) => state.model.list);
    const selectedModel = useSelector((state: RootState) => state.model.selected);

    const versionViewModel = new VersionViewModel();
    const versions = useSelector((state: RootState) => state.version.list);
    const selectedVersion = useSelector((state: RootState) => state.version.selected);

    const [searchType, setSearchType] = useState('matriculation');
    const [searchValue, setSearchValue] = useState('');
    const [inputFocus, setInputFocus] = useState(false);
    const [option, setOption] = useState('marques');
    const [options, setOptions] = useState(marks);
    const [reasultSearch, setReasultSearch] = useState(marks);

    const wrapperRef = useRef(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const searchRef = useRef<HTMLInputElement>(null);

    const [matriculationValue, setMatriculationValue] = useState('');
    const [matriculationError, setMatriculationError] = useState('');

    useEffect(() => {
        if (vehicle) {
            inputRef.current!.value = vehicle.mark + ' /' + vehicle.model;
            setMatriculationValue(vehicle.plateNumber);
        } else if (selectedMark && selectedModel && selectedVersion){
            inputRef.current!.value = selectedMark.name + ' /' + selectedModel.name + ' /' + selectedVersion.name;
        }

        markViewModel.getMarks();
    }, []);

    useEffect(() => {
        setOption('marks');
        setOptions(marks);
        setReasultSearch(marks);
        setSearchValue('')
    }, [marks]);

    useEffect(() => {
        setOption('models');
        setOptions(models);
        setReasultSearch(models);
        setSearchValue('')
    }, [models]);

    useEffect(() => {
        setOption('versions');
        setOptions(versions);
        setReasultSearch(versions);
        setSearchValue('')
    }, [versions]);

    useOutsideAlerter(wrapperRef, setInputFocus);

    const onfocusHandler = () => {
        setInputFocus(true);
    }

    const onchangeInputHandler = (event: React.FormEvent<HTMLInputElement>) => {
        let value = event.currentTarget.value;
        setSearchValue(value);
        value = value.toLowerCase();
        if (value.length > 0) {
            const res = options!.filter(obj => Object.values(obj).some(val => val?.toString().toLowerCase().includes(value)));
            setReasultSearch(res);
        } else {
            setReasultSearch(options);
        }
    }

    const onClickHandler = (id: number, name: string) => {
        switch (option) {
            case 'marks':
                modelViewModel.getMarkModels(id);
                markViewModel.selectMark({ id: id, name: name })
                inputRef.current!.value = name + ' /';
                searchRef.current!.value = '';
                resetMatriculationSearch();
                break;
            case 'models':
                versionViewModel.getModelVersions(id);
                modelViewModel.selectModel({ id: id, name: name })
                inputRef.current!.value = inputRef.current!.value + name + ' /';
                searchRef.current!.value = '';
                resetMatriculationSearch();
                break;
            case 'versions':
                versionViewModel.selectVersion({ id: id, name: name })
                inputRef.current!.value = inputRef.current!.value + name + ' /';
                searchRef.current!.value = '';
                setOption('summary');
                resetMatriculationSearch();
                break;
        }
    }

    const resetSearch = () => {
        resetMarkSearch();
        resetMatriculationSearch();
        inputRef.current!.value = '';
    }

    const resetMarkSearch = () => {
        setOption('marks');
        setOptions(marks);
        setReasultSearch(marks);
        setSearchValue('');
        markViewModel.removeSelectedMark();
        modelViewModel.removeSelectedModel();
        versionViewModel.removeSelectedVersion();
    }

    const resetMatriculationSearch = () => {
        setMatriculationValue('');
        vehicleViewModel.removeVehicle();
    }

    const handleMatriculationInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.toUpperCase();
        const pattern = /^[A-Z]{2}\d{3}[A-Z]{2}$/;

        setMatriculationValue(value);
        setMatriculationError('')
        inputRef.current!.value = '';

        if (value.length !== 7) return;

        if (pattern.test(value)) {
            if (value === vehicle?.plateNumber) {
                inputRef.current!.value = value;
                return;
            };
            const response = await vehicleViewModel.searchWithMatriculation(value);
            if (response.status === 'success') {
                inputRef.current!.value = response.vehicleNmae;
                resetMarkSearch();
                setInputFocus(false);
            } else {
                setMatriculationError("Désolé, nous avons pas trouvé votre plaque d'immatriculation . Veuillez réessayer ou utiliser la fonctionnalité de recherche par marques.");
            }
        } else {
            setMatriculationError("Le format de votre plaque d'immatriculation est invalide");
        }
    };

    return (
        <>
            <Box ref={wrapperRef}>
                <Stack bg={'#E8EAF1'} direction={'row'} m={'2'} align={'center'} borderRadius={'2xl'} pl={4} py={1}>
                    <Image h={'20px'} src={carIcon} />
                    <Input
                        bg={'transparent'}
                        border={'none'}
                        outline={'none'}
                        placeholder={'Vehicule'}
                        onFocus={onfocusHandler}
                        ref={inputRef}
                        readOnly
                    />
                    {
                        (selectedMark || vehicle) ?
                            <Button aria-label='Reset' _hover={{ bg: 'null' }} variant='link' onClick={resetSearch}>x</Button>
                            : null
                    }
                </Stack>
                {
                    inputFocus ?
                        <VStack
                            width={"72"}
                            rounded={12}
                            boxShadow={'2xl'}
                            border='1px'
                            borderColor='gray.200'
                            position={'absolute'}
                            zIndex={'999'}
                            bg='white'
                            divider={<StackDivider borderColor='gray.200' />}
                            align='stretch'
                            pb={2}
                        >
                            {option === 'summary' ?
                                <VStack align='stretch' px={4}>
                                    <Heading as='h3' size='sm' py={4} color={'#1e90ff'}>
                                        Le résumé
                                    </Heading>
                                    <Text>Marque: {selectedMark?.name}</Text>
                                    <Text>Model: {selectedModel?.name}</Text>
                                    <Text>Version: {selectedVersion?.name}</Text>
                                </VStack>
                                :
                                <Box px={4}>
                                    <Flex justifyContent={'space-between'}>
                                        <Heading as='h3' size='sm' py={4} color={'#1e90ff'}>
                                            <Link onClick={() => setSearchType('matriculation')} textDecoration="underline">Immatriculation</Link>
                                        </Heading>
                                        <Heading as='h3' size='sm' py={4} color={'#1e90ff'}>
                                            <Link onClick={() => setSearchType('marks')} textDecoration="underline">{renderSummaryTitle(option)}</Link>
                                        </Heading>
                                    </Flex>
                                    {searchType === 'matriculation' ?
                                        <>
                                            <Input
                                                id="3"
                                                type="text"
                                                value={matriculationValue}
                                                onChange={handleMatriculationInputChange}
                                                placeholder={'AB-123-CD'}
                                                autoFocus={true}
                                                minLength={7}
                                                maxLength={7}
                                            />
                                            <Text color={'red'} my='4'>{matriculationError}</Text>
                                        </>
                                        :
                                        <>
                                            <Input
                                                ref={searchRef}
                                                type="text"
                                                placeholder={'Chercher...'}
                                                bg={'#E8EAF1'}
                                                onChange={onchangeInputHandler}
                                                value={searchValue}
                                            />
                                            <VStack
                                                align='stretch'
                                                divider={<StackDivider borderColor='gray.200' />}
                                                maxH={'300px'}
                                                overflow={'auto'}
                                                mt={'4'}
                                            >
                                                {reasultSearch && reasultSearch.map((each) => {
                                                    return (
                                                        <Text key={each.id} _hover={{ bg: '#1e90ff', color: 'white' }} h='30px' pt={1} justifyContent={'center'} onClick={() => onClickHandler(each.id, each.name)}>
                                                            {each.name}
                                                        </Text>
                                                    )
                                                })}
                                            </VStack>
                                        </>
                                    }
                                </Box>

                            }
                        </VStack>
                        : null
                }
            </Box>
        </>
    );
}

const renderSummaryTitle = (option: string) => {
    switch (option) {
        case 'models':
            return 'Models';
        case 'versions':
            return 'Versions';
        case 'summary':
            return 'Le résumé';
        default:
            return 'Marques';
    }
}

const useOutsideAlerter = (ref: any, setInputFocus: any) => {
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (ref.current && !ref.current.contains(event.target))
                setInputFocus(false);
        }

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

export default SelectVehicle;