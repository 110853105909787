import { Box, Center, Container, Heading, SimpleGrid, Stack, Text } from '@chakra-ui/react'

const SuggestionsSection: React.FC = () => {
    const filter = [{
        filterType: "LE PLUS RAPIDE",
        vendorPrice: "65,23",
        filterInfo: "mar. 29 novembre"
    },
    {
        filterType: "LE PLUS PROCHE",
        vendorPrice: "77,23",
        filterInfo: "à 3 km de vous"
    },
    {
        filterType: "LE MOINS CHER",
        vendorPrice: "50,00",
        filterInfo: "-52% en moyenne"
    }]
    return (
        <Box bg={'#F6FAFC'}>
            <Container maxW={{ lg: '100%' }} w={{ xl: '1100px' }} py={5}>
                <SimpleGrid templateColumns={{ base: '1fr', lg: '2fr 2fr 2fr' }} spacing={12} >
                    {/* {filter.map((item, idx) => (
                        <Stack key={idx} bg={'white'} borderRadius={'2xl'} boxShadow={'md'} padding={5} border={'2px solid transparent'} _hover={{ border: '2px solid #1385EB' }}>
                            <Center>
                                <Box>
                                    <Heading size={'sm'} textAlign={'center'}>{item.filterType}</Heading>
                                    <Heading size={'sm'} textAlign={'center'}>{item.vendorPrice}€</Heading>
                                    <Text textAlign={'center'}>{item.filterInfo}</Text>
                                </Box>
                            </Center>
                        </Stack>
                    ))} */}
                </SimpleGrid>
            </Container>
        </Box>
    )
}

export default SuggestionsSection;