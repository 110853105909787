import { Box, HStack, Image, Tab, Text } from '@chakra-ui/react'
import React, { useState } from 'react'

type Props = {
    image:any,
    title:string,
    isActive:number,
    index:number
}

const TabComp = ({image,title,isActive,index}:Props) => {
    

  return (
    <Tab  color='#68696A' _selected={{ color: '#0F82EA'}} fontWeight='bold' >
        <Box w='40px' h='40px' p={2} rounded='12px' bg='#0F82EA' opacity={isActive === index ? '100%' : '50%'}  >
            <Image src={image} w='100%' h='100%' />
        </Box>
        <Text ml={2} >{title}</Text>
    </Tab>
  )
}

export default TabComp