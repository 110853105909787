import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Flex, Icon, Input, InputGroup, InputRightElement, List, ListIcon, ListItem, Stack, StackDivider, VStack } from "@chakra-ui/react";
import { useState, useEffect, useRef } from 'react';
import { useSelector } from "react-redux";
import { RootState } from "../../store/index";
import { ServiceViewModel } from "../../view-models/_index";
import { AiOutlineDown } from 'react-icons/ai';
import { SubServiceType } from "../../models/Service";
import { HiWrenchScrewdriver } from 'react-icons/hi2';

const SelectVehicle: React.FC = () => {
    const serviceViewModel = new ServiceViewModel();
    const services = useSelector((state: RootState) => state.service.list);
    const selectedSubService = useSelector((state: RootState) => state.service.selectedSubService);
    const selectSubService = serviceViewModel.selectSubService;
    const removeSelectedSubService = serviceViewModel.removeSelectedSubService;

    const [inputFocus, setInputFocus] = useState(false);
    const wrapperRef = useRef(null);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (selectedSubService)
            inputRef.current!.value = selectedSubService.name;

        serviceViewModel.getServices();
    }, []);


    useOutsideAlerter(wrapperRef, setInputFocus);

    const onfocusHandler = () => {
        setInputFocus(true);
    }

    const onSelectHandler = (subService: SubServiceType) => {
        selectSubService(subService);
        setInputFocus(false);
        inputRef.current!.value = subService.name;
    }

    const resetSelect = () => {
        removeSelectedSubService();
        inputRef.current!.value = '';
    }

    return (
        <>
            <Box ref={wrapperRef}>
                <Stack bg={'#E8EAF1'} direction={'row'} m={'2'} align={'center'} borderRadius={'2xl'} px={4} py={1}>
                    <InputGroup onFocus={onfocusHandler}>
                        <Input
                            bg={'transparent'}
                            border={'none'}
                            outline={'none'}
                            placeholder={'Votre Prestation'}
                            ref={inputRef}
                            readOnly
                        />
                        {!selectedSubService &&
                            <InputRightElement pointerEvents='none'>
                                <Icon as={AiOutlineDown} />
                            </InputRightElement>
                        }
                    </InputGroup>
                    {selectedSubService && <Button aria-label='Reset' _hover={{ bg: 'null' }} variant='link' onClick={resetSelect}>x</Button>}
                </Stack>
                {inputFocus &&
                    <VStack
                        width={"80"}
                        rounded={12}
                        boxShadow={'2xl'}
                        border='1px'
                        borderColor='gray.200'
                        position={'absolute'}
                        zIndex={'999'}
                        bg='white'
                        divider={<StackDivider borderColor='gray.200' />}
                        align='stretch'
                        pb={2}
                    >
                        <Box px={4}>
                            <VStack
                                align='stretch'
                                maxH={'400px'}
                                overflow={'auto'}
                                mt={'4'}
                            >
                                <Accordion allowMultiple>
                                    {services && services.map((service) => {
                                        return (<>
                                            <AccordionItem key={service.id}>
                                                <h2>
                                                    <AccordionButton>
                                                        <Box as="span" flex='1' textAlign='left' fontSize={'lg'} fontWeight={'600'}>
                                                            {service.name}
                                                        </Box>
                                                        <AccordionIcon />
                                                    </AccordionButton>
                                                </h2>
                                                <AccordionPanel pb={4}>
                                                    <List>
                                                        {service.subServices && service.subServices.map((subService) => {
                                                            return (
                                                                <ListItem pr={'2'} key={subService.id} _hover={{ bg: '#deebff' }} py={2}>
                                                                    <Flex>
                                                                        <ListIcon as={HiWrenchScrewdriver} justifyItems={'center'} />
                                                                        <Button w={'100%'} h={'100%'} fontWeight={'400'} bg={'transparent'} _hover={{ bg: 'transparent' }} justifyContent={'left'} textAlign={'left'} px={1} whiteSpace={'normal'} onClick={() => onSelectHandler(subService)}>
                                                                            {subService.name}
                                                                        </Button>
                                                                    </Flex>
                                                                </ListItem>
                                                            )
                                                        })}
                                                    </List>
                                                </AccordionPanel>
                                            </AccordionItem>
                                        </>
                                        )
                                    })}
                                </Accordion>
                            </VStack>
                        </Box>
                    </VStack>
                }
            </Box>
        </>
    );
}

const useOutsideAlerter = (ref: any, setInputFocus: any) => {
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (ref.current && !ref.current.contains(event.target))
                setInputFocus(false);
        }

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

export default SelectVehicle;