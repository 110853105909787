import React, { useEffect } from 'react'
import { LayoutSection, ContentSection } from './components/_index'

const SuccessPage: React.FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <LayoutSection>
                <ContentSection />
            </LayoutSection>
        </>
    )
}

export default SuccessPage