import { Box, Container, Text } from '@chakra-ui/react'
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer'
import { useEffect, useState } from 'react';

const MotionBox = motion(Box);

const Avantage = () => {
  const [isVisible, setIsVisible] = useState(false);
  const { ref, inView } = useInView({threshold:0.4});

  useEffect(() => {
    if (inView) {
      setIsVisible(true);
    }
  }, [inView]);
  return (
    <MotionBox
      ref={ref}
      initial={{ x: 200, opacity: 0 }}
      animate={{ x: inView ? 0 : 200, opacity: inView ? 1 : 0 }}
      transition={{ duration: 0.5 }}
    >
        <Container maxW={'90%'} w={{ lg: '1200px' }}>
          <Box pt={20} pb={5}>
            <Text fontSize={'20px'} fontWeight={600} color={'#0F82EA'}>Avantages clés</Text>
            <Text fontSize={{base:'25px',sm:'35px',md:'40px'}} fontWeight={700} color={'#0D121F'} w={{lg:'60%'}}>Optimisez votre flotte et réduisez vos coûts avec nos services d'entretien et de réparation</Text>
          </Box>
        </Container>
    </MotionBox>
  )
}

export default Avantage