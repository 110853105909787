import { Box, Button, Flex, Text } from "@chakra-ui/react"
import Vehicule from "./Vehicule"
import Prestations from "./Prestations"
import Alternateur from "./Alternateur"
import TotalDevis from "./TotalDevis"

const ThirdStep = () => {
  return (
    <Box>
        <Flex justifyContent={'space-between'} alignItems={'center'}>
            <Text fontSize={'22px'} fontWeight={700} color='#222D50'>Devis</Text>
            <Button _hover={{ bg: '#0F82BA' }} bg={'#0F82EA'} w='150px' h='40px' fontWeight={300} rounded={'46px'} color={'white'} px={'16px'} >Imprimer Devis</Button>
        </Flex>
        <Vehicule />
        <Prestations />
        <Alternateur />
        <TotalDevis />
    </Box>
  )
}

export default ThirdStep