import { Heading, Image, Box, Text } from "@chakra-ui/react"
import notFound from '../../assets/images/dadycar-not_found.webp'
import { Link } from 'react-router-dom'
import Head from "../../components/blocks/Head"
import { useEffect } from "react";

const title = "Dadycar";

const NotFound: React.FC = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Head title={title} />
            <Box boxSize='xl' mx="auto" my={'8'} >
                <Image src={notFound} />
            </Box>
            <Heading as="h1" size="2xl" mt="-48" textAlign={"center"} fontFamily={"poppins"} fontWeight={"medium"}>Page introuvable</Heading>
            <Text textAlign={"center"} fontSize={"xl"} color="#0F82EA" mt="4" pb={'16'} textTransform={"capitalize"} fontWeight={"medium"}>
                <Link to="/">Retourner</Link>
            </Text>
        </>
    )
}

export default NotFound;