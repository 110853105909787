import { Box, Container, Flex, Image, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from "@chakra-ui/react"
import convoyage from '../../../../assets/V2/convoyage.webp'
import { motion, useAnimation } from "framer-motion";
import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'

const MotionBox = motion(Box);


const Convoyage = () => {
    const [isVisible, setIsVisible] = useState(false);
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) {
      setIsVisible(true);
    }
  }, [inView]);

  return (
    <Box bg='#0D1829' pb={20} pt={10}  color='white'>
        <MotionBox
            ref={ref}
            initial={{ x: 200, opacity: 0 }}
            animate={{ x: inView ? 0 : 200, opacity: inView ? 1 : 0 }}
            transition={{ duration: 0.5 }}
        >
            <Container maxW={'90%'} w={{ lg: '1200px' }}>
                <Text>DadyCar Convoyage</Text>
                <Text fontSize={{base:'20px',md:'40px'}} fontWeight={700}>Réduisez le temps consacré aux  gestion des <br /> transferts de véhicules grâce à Dadycar.</Text>
                <Flex flexDirection={{base:'column',lg:'row'}} mt={8} justifyContent='space-between' gap={6}>
                    <Box flex={1} >
                    <Tabs variant='soft-rounded'>
                        <TabList bg='#EDEDED' w='fit-content' p={0} rounded={30}>
                            <Flex justifyContent={{base:'center',lg:'start'}} >
                                <Tab px='24px' py='13px'  color='gray.500' _selected={{ color: 'white',bg:'#0F84EA'}} fontSize={{base:10,sm:12,xl:16}}>Avec DadyCar</Tab>
                                <Tab px='24px' py='13px'  color='gray.500' _selected={{ color: 'white',bg:'#0F82EA'}} fontSize={{base:10,sm:12,xl:16}}>Sans DadyCar</Tab>
                            </Flex>
                        </TabList>
                        <TabPanels>
                            <TabPanel>
                                <Flex flexDirection='column' gap={1} >
                                    <Text color='#EDF8FDCC'><span style={{color:'white'}} >Logistique sans effort : </span>Réservez, gérez et suivez vos convois depuis une seule plateforme. Fini les jonglages entre appels et emails multiples.</Text>
                                    <Text color='#EDF8FDCC'><span style={{color:'white'}} >Suivi en direct : </span>Visualisez le déplacement de vos véhicules en temps réel sur une carte, avec des estimations d'arrivée.</Text>
                                    <Text color='#EDF8FDCC'><span style={{color:'white'}} >Prix compétitifs et transparents : </span>Notre réseau de conducteurs qualifiés et notre tarification claire vous garantissent un transport économique.</Text>
                                    <Text color='#EDF8FDCC'><span style={{color:'white'}} >Assistance disponible 24/7 : </span>Notre équipe est à votre écoute 24 heures sur 24, 7 jours sur 7 pour répondre à vos questions et traiter vos préoccupations.</Text>
                                    <Text color='#EDF8FDCC'><span style={{color:'white'}} >Minimisez les risques : </span>Garantissez des livraisons fiables et ponctuelles grâce à des conducteurs expérimentés et des solutions de transport sécurisées.</Text>
                                    <Text color='#EDF8FDCC'><span style={{color:'white'}} >Gagnez du temps : </span>Libérez-vous des tâches logistiques pour vous concentrer sur vos activités principales.</Text>
                                    <Text color='#EDF8FDCC'><span style={{color:'white'}} >Adaptabilité : </span>Gérez un ou plusieurs convois simultanément, quelle que soit la taille de votre flotte.</Text>

                                </Flex>
                            </TabPanel>
                            <TabPanel>
                                <Flex flexDirection='column' gap={1} >
                                    <Text color='#EDF8FDCC'><span style={{color:'white'}} >Coordination manuelle : </span>Passez des heures à coordonner les conducteurs, les transporteurs et les intervenants, ce qui entraîne des retards et de la confusion.</Text>
                                    <Text color='#EDF8FDCC'><span style={{color:'white'}} >Visibilité limitée : </span>Pas de suivi en temps réel, vous laissant à l'aveugle face aux problèmes potentiels et incapable de réagir de manière proactive.</Text>
                                    <Text color='#EDF8FDCC'><span style={{color:'white'}} >Coûts cachés : </span>Des frais inattendus et des hausses de prix potentielles peuvent grever votre budget.</Text>
                                    <Text color='#EDF8FDCC'><span style={{color:'white'}} >Risque de retards : </span>Des conducteurs peu fiables et une logistique défaillante peuvent impacter les délais de livraison et la satisfaction client.</Text>
                                </Flex>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                    </Box>
                    <Box  flex={1} width='100%' h='450px' >
                        <Image src={convoyage} w='100%' h='100%' objectFit='fill' />
                    </Box>
                </Flex>
            </Container>
        </MotionBox>
    </Box>
  )
}

export default Convoyage