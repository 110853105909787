import { Box, Button, Center, Container, Text } from '@chakra-ui/react'
import SectionHeader from '../../../../components/sections/SectionHeader'
import { useNavigate } from "react-router-dom";

const MessageSection: React.FC = () => {
    const navigate = useNavigate();
    const handleReturnBack = () => {
        navigate(-1);
    }

    return (
        <>
            <Box mt={12} bg={'#F6FAFC'} py={20}>
                <SectionHeader title={`Merci !`} color={'#5393EC'} />
                <Container maxW={'90%'} w={'1000px'} p={5}>
                    <Text fontSize={'xl'} textAlign={'center'}>
                        Nous vous contactera sous peu pour discuter de la façon dont nous pouvons vous aider.
                    </Text>
                    <br />
                    <Center>
                        <Button onClick={handleReturnBack} transition={'ease-in'} w={{ base: '100%', lg: 'initial' }} color={'white'} borderRadius={'xl'} width={200} _hover={{ bg: '#5393EC' }} bg={{ base: 'linear-gradient(90deg, #00B1B7 0%, #0075F5 100%)', lg: '#5393EC' }}>
                            Retour
                        </Button>
                    </Center>
                </Container >
            </Box >
        </>
    )
}

export default MessageSection;