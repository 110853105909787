import HttpClient from '../services/HttpClient'

type ServiceType = {
    id: number;
    name: string;
    slug?: string;
    description?: string;
    subServices?: SubServiceType[]
}

type SubServiceType = {
    id: number;
    image: string;
    name: string;
    slug: string;
    title: string;
    price: number;
    description?: string;
    type?: string;
    options?: OptionType[];
}

type OptionType = {
    id: number;
    name: string;
}
class Service {

    public static getService = async (serviceId: number) => {
        return HttpClient.get('/services/' + serviceId);
    }

    public static getServices = async () => {
        return await HttpClient.get('/services');
    }
    
    public static searchSubService = async (serviceSlug: string, subServiceSlug: string) => {
        return HttpClient.get('/sub-services/slug/' + serviceSlug + '/' + subServiceSlug);
    }
}
export type { ServiceType, SubServiceType, OptionType };
export default Service;