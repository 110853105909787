import { Box, Container, Heading, Text } from '@chakra-ui/react'
import { ContactForm } from '../../../../components/forms/_index'
import { SectionHeader } from '../../../../components/sections/_index'

const ContactSection: React.FC = () => {
  return (
    <Box bg={'white'} p={10}>
      <SectionHeader title='Contactez-nous' />
      <Container maxW={'80%'} w={'1200px'}>
        <Heading size={'sm'} textAlign={'center'}>Nous sommes là pour vous aider</Heading>
        <Text textAlign={'center'} color={'gray.500'}>Nous sommes disponibles du lundi au vendredi, de 8h30 à 18h</Text>
        <Box>
          <ContactForm />
        </Box>
      </Container>
    </Box>
  )
}

export default ContactSection