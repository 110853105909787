import { Box } from "@chakra-ui/react"
import NewRendezvous from "./NewRendezvous"
import EarningSummury from "./EarningSummury"

const DashData = () => {
  return (
    <Box flex={5} overflowX={'auto'} >
        <NewRendezvous />
        {/* <EarningSummury /> */}
    </Box>
  )
}

export default DashData