import { Box, Button, Container, Grid, GridItem, Stack } from "@chakra-ui/react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { SearchAddressAutocomplete } from "../../../../../../components/sectors/_index";
import { RootState } from "../../../../../../store";
import { AddressViewModel, DistanceViewModel } from "../../../../../../view-models/_index";

interface ChildProps {
    setAddressFormVisibility: (value: boolean) => void;
    addressFormVisibility: boolean;
}

const AddressForm: React.FC<ChildProps> = (props) => {
    const getDistanceWithPrice = (new DistanceViewModel()).getDistanceWithPrice;
    const addressViewModel = new AddressViewModel();
    const selectDepartureAddress = addressViewModel.selectDepartureAddress;
    const selectReturnAddress = addressViewModel.selectReturnAddress;
    const departureAddress = useSelector((state: RootState) => state.address.departureAddress);
    const returnAddress = useSelector((state: RootState) => state.address.returnAddress);
    const version = useSelector((state: RootState) => state.version.selected);
    const vendor = useSelector((state: RootState) => state.vendor.selected);
    const vehicle = useSelector((state: RootState) => state.vehicle.value);

    useEffect(() => {
        if (vendor && departureAddress && returnAddress && (version || vehicle))
            getDistanceWithPrice(vendor, { departureAddress, returnAddress }, version?.id, 'P');
    }, [departureAddress, returnAddress]);

    const onclickHandler = () => {
        props.setAddressFormVisibility(false)
    }

    return (
        <Container bg={'white'} maxW={'80%'} w={{ lg: '950px' }} borderRadius={'3xl'} boxShadow={'0px 0px 15px rgba(0, 0, 0, 0.203922)'} p={'5'} my={'4'}>
            <Box display={{ lg: 'flex', base: 'block' }} justifyContent={'center'}>

                <Grid w={'90%'} templateColumns={{ lg: 'repeat(2, 1fr)' }} gap={2}>
                    <GridItem>
                        <SearchAddressAutocomplete
                            placeholder="Addresses de collect de voiture"
                            address={departureAddress}
                            selectAddress={selectDepartureAddress}
                        />
                    </GridItem>
                    <GridItem>
                        <SearchAddressAutocomplete
                            placeholder="Adress de réquisition"
                            address={returnAddress}
                            selectAddress={selectReturnAddress}
                        />
                    </GridItem>
                </Grid>

                <Stack m={'2'} mt={{ base: '4', lg: '2' }} align={'center'}>
                    <Button
                        bg={'#252525'}
                        color={'white'}
                        borderRadius={'2xl'}
                        w={'100%'}
                        fontSize={'sm'}
                        h={'100%'} px={4} py={3}
                        _hover={{ bg: '#5a5959' }}
                        onClick={onclickHandler}
                    >
                        Sauvegarder
                    </Button>
                </Stack>
            </Box>
        </Container>
    )
}

export default AddressForm;