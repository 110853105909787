import { Box } from '@chakra-ui/react'
import Header from './components/Header';
import Filtring from './components/Filtring';
import DevisData from './components/DevisData';

const MesDevis = () => {
  return (
    <Box bg='#fafafa'>
        <Box px={4} py={2} gap={4}>
          <Header />
          <Filtring />
          <DevisData />
        </Box>
    </Box>
  )
}

export default MesDevis