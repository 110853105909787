import { Image } from '@chakra-ui/image'
import { Box, Flex, Center, Container, SimpleGrid, Stack, Text } from '@chakra-ui/layout'
import mapIcon from '../../../../assets/images/dadycar-France map.webp'
import listIcon from '../../../../assets/images/dadycar-checklist.webp'
import servicesIcon from '../../../../assets/images/dadycar-car-service.webp'
import SectionHeader from '../../../../components/sections/SectionHeader'
import { ExpertContactModal } from "../../../../components/modals/_index";
import { Button, Tab, TabIndicator, TabList, TabPanel, TabPanels, Tabs, useDisclosure } from '@chakra-ui/react'
import car from '../../../../assets/V2/car.png'
import car_settings from '../../../../assets/V2/car_settings.png'
import conveying from '../../../../assets/V2/conveying.png'
import key from '../../../../assets/V2/key.png'
import cartuser from '../../../../assets/V2/cartuser.png'
import services1 from '../../../../assets/V2/services1.webp'
import services2 from '../../../../assets/V2/services2.webp'
import services3 from '../../../../assets/V2/services3.webp'
import services4 from '../../../../assets/V2/services4.webp'
import services5 from '../../../../assets/V2/services5.webp'

import TabComp from './TabComp'
import { motion, useAnimation } from "framer-motion";
import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { Link } from 'react-router-dom'

const MotionBox = motion(Box);

const ServicesSection: React.FC = () => {
    const { isOpen: isExpertContactOpen, onOpen: onExpertContactOpen, onClose: onExpertContactClose } = useDisclosure();
    const data = [{
        image: car,
        title: 'Gestion de flotte',
    }, {
        image:cartuser,
        title: 'Suivi en temps réel et reporting',
    }, {
        image: conveying,
        title: "Convoyage",
    },{
        image: car_settings,
        title: "Entretien et réparation",
    },{
        image: key,
        title: "Car Sharing",
    },
    ]
    const [isSelected,setIsSelected] = useState(0)

    const handleActive = (index : number) => {
        setIsSelected(index)
    }
    const { ref, inView } = useInView({threshold:0.3});
    const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (inView) {
      setIsVisible(true);
    }
  }, [inView]);

    return (
        <Box pb={20} pt={10} bg='white'>
            <MotionBox
                ref={ref} initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 50 }}
                transition={{ duration: 0.5 }}
            >
                <Container maxW={'90%'} w={{ lg: '1200px' }} >
                    <Flex flexDirection='column' gap={4} alignItems='center' justifyContent='center'>
                        <Text style={{textTransform:'uppercase'}} fontSize='20px' color='#0F82EA' textAlign={{base:'center',lg:'start'}} >Ce qui rend DadyCar parfait</Text>
                        <Text fontWeight='bold' fontSize={{base:'20px',md:'40px'}} textAlign={{base:'center',lg:'start'}} >Nos services et fonctionnalités </Text>
                        <Text color='#596780' textAlign={'center'} > Gestion de flottes innovantes, transport et maintenance de véhicules.</Text>
                    </Flex>
                    <Tabs variant="line" mt={10} onChange={handleActive}>
                        <TabList style={{ whiteSpace: "nowrap" }} overflowX='auto' 
                                sx={{ 
                                    '::-webkit-scrollbar': {
                                        display: 'none'
                                    },
                                    scrollbarWidth: 'none',
                                    }}>
                                {data?.map((d,index)=>(
                                    <TabComp key={index} image={d?.image} title={d.title} isActive={isSelected} index={index} />
                                ))}
                        </TabList>

                        <TabPanels mt={4}>
                            <TabPanel px={0}>
                                <Flex flexDirection={{base:'column',lg:'row'}} justifyContent='space-between' alignItems='start' gap={4} >
                                    <Flex flexDirection='column' gap={4} flex={1} >
                                        <Text fontWeight='bold' fontSize={{base:'22px',md:'28px'}} >Surveillance de flotte sans effort</Text>
                                        <Text color='#68696A' fontSize={{base:'16px',md:'22px'}} > Notre plateforme vous permet de maximiser l'efficacité opérationnelle et d'optimiser les coûts.
                                            Contrôlez et gérez facilement vos véhicules depuis une seule plateforme. Gestion des véhicules, gestion des utilisateurs et gestion des multi-sites.
                                            Accédez à des analyses et de reporting poussées pour monitorer au mieux la gestion de votre flotte.
                                        </Text>
                                        <Link to={'https://meetings-eu1.hubspot.com/meetings/amine-yahia-cherif/dadycar'} target="_blank" >
                                            <Button rounded='30px' bg='#0F82EA' _hover={{bg:'#3563E9'}} color='white' w='187px' h='52px' >Prendre RDV</Button>
                                        </Link>
                                    </Flex>
                                    <Box flex={1} height='413px' w='100%' >
                                        <Image src={services1} height='100%' w='100%' />
                                    </Box>
                                </Flex>
                            </TabPanel>
                            <TabPanel px={0}>
                                <Flex flexDirection={{base:'column',lg:'row'}} justifyContent='space-between' alignItems='start' gap={4} >
                                    <Flex flexDirection='column' gap={4} flex={1} >
                                        <Text fontWeight='bold' fontSize={{base:'22px',md:'28px'}} >Localisation de flotte optimisée</Text>
                                        <Text color='#68696A' fontSize={{base:'16px',md:'22px'}} >Suivez vos véhicules en temps réel pour une meilleure planification et sécurisation de votre flotte et de vos utilisateurs.</Text>
                                        <Link to={'https://meetings-eu1.hubspot.com/meetings/amine-yahia-cherif/dadycar'} target="_blank" >
                                            <Button rounded='30px' bg='#0F82EA' _hover={{bg:'#3563E9'}} color='white' w='187px' h='52px' >Prendre RDV</Button>
                                        </Link>
                                    </Flex>
                                    <Box flex={1} height='413px' w='100%' >
                                        <Image src={services2} height='100%' w='100%' />
                                    </Box>

                                </Flex>
                            </TabPanel>
                            <TabPanel px={0}>
                                <Flex flexDirection={{base:'column',lg:'row'}} justifyContent='space-between' alignItems='start' gap={4} >
                                    <Flex flexDirection='column' gap={4} flex={1} >
                                        <Text fontWeight='bold' fontSize={{base:'22px',md:'28px'}} >Solutions de transport de véhicules fluides</Text>
                                        <Text color='#68696A' fontSize={{base:'16px',md:'22px'}} >Gérez le transport de vos véhicules grâce à notre réseau de chauffeurs et partenaires.</Text>
                                        <Link to={'https://meetings-eu1.hubspot.com/meetings/amine-yahia-cherif/dadycar'} target="_blank" >
                                            <Button rounded='30px' bg='#0F82EA' _hover={{bg:'#3563E9'}} color='white' w='187px' h='52px' >Prendre RDV</Button>
                                        </Link>
                                    </Flex>
                                    <Box flex={1} height='413px' w='100%' >
                                        <Image src={services3} height='100%' w='100%' />
                                    </Box>
                                </Flex>
                            </TabPanel>
                            <TabPanel px={0}>
                                <Flex flexDirection={{base:'column',lg:'row'}} justifyContent='space-between' alignItems='start' gap={4} >
                                    <Flex flexDirection='column' gap={4} flex={1} >
                                        <Text fontWeight='bold' fontSize={{base:'22px',md:'28px'}} >Gestion proactive de l'entretien des voitures</Text>
                                        <Text color='#68696A' fontSize={{base:'16px',md:'22px'}} >Gérez l’entretien et la réparation de vos véhicules facilement avec vos partenaires ou nos réseaux de garagistes, en incluant les véhicules électriques.Connectez vos véhicules pour prédire la maintenance des véhicules.</Text>
                                        <Link to={'https://meetings-eu1.hubspot.com/meetings/amine-yahia-cherif/dadycar'} target="_blank" >
                                            <Button rounded='30px' bg='#0F82EA' _hover={{bg:'#3563E9'}} color='white' w='187px' h='52px' >Prendre RDV</Button>
                                        </Link>
                                    </Flex>
                                    <Box flex={1} height='413px' w='100%' >
                                        <Image src={services4} height='100%' w='100%' />
                                    </Box>
                                </Flex>
                            </TabPanel>
                            <TabPanel px={0}>
                                <Flex flexDirection={{base:'column',lg:'row'}} justifyContent='space-between' alignItems='start' gap={4} >
                                    <Flex flexDirection='column' gap={4} flex={1} >
                                        <Text fontWeight='bold' fontSize={{base:'22px',md:'28px'}} >Solutions de covoiturage simplifiées</Text>
                                        <Text color='#68696A' fontSize={{base:'16px',md:'22px'}} >Connectivité et développement du car-Sharing entre employés : Favorisez une mobilité durable avec le partage de véhicules grâce à nos boitiers connectés.</Text>
                                        <Link to={'https://meetings-eu1.hubspot.com/meetings/amine-yahia-cherif/dadycar'} target="_blank" >
                                            <Button rounded='30px' bg='#0F82EA' _hover={{bg:'#3563E9'}} color='white' w='187px' h='52px' >Prendre RDV</Button>
                                        </Link>
                                    </Flex>
                                    <Box flex={1} height='413px' w='100%' >
                                        <Image src={services5} height='100%' w='100%' />
                                    </Box>
                                </Flex>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </Container>
            </MotionBox>
        </Box>

    )
}

export default ServicesSection;