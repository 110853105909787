import { Box, Button, Container, Flex, Image, Stack, Text } from '@chakra-ui/react'
import rev from '../../../../../../assets/V2/carsharing/revolution.webp'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer'
import { useEffect, useState } from 'react';

const MotionBox = motion(Box);

const Revolution = () => {
    const [isVisible, setIsVisible] = useState(false);
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) {
      setIsVisible(true);
    }
  }, [inView]);
  return (
    <MotionBox
        ref={ref}
        initial={{ x: 200, opacity: 0 }}
        animate={{ x: inView ? 0 : 200, opacity: inView ? 1 : 0 }}
        transition={{ duration: 0.5 }}
    >
        <Container maxW={'90%'} w={{ lg: '1200px' }}>
            <Box py={8}>
                <Flex flexDirection={{base:'column',lg:'row'}} gap={{base:4,lg:10}} justifyContent='space-between'  >
                    <Box flex={1} >
                        <Image src={rev} h='100%' objectFit={'fill'} />
                    </Box>
                    <Flex flexDirection={'column'} gap={2} flex={1} >
                        <Text fontSize={{base:'25px',sm:'35px',md:'40px'}} fontWeight={700} color={'#0D121F'} >Réduction des coûts d'entretien</Text>
                        <Text fontSize={{base:'18px',sm:'20px',md:'22px'}} color={'#222D50'}>Gardez une longueur d'avance grâce à notre solution digitale innovante, conçue pour rationaliser les opérations et améliorer l'efficacité. <Text as='span' color='#0F82EA' fontWeight={700}><Link to={`https://fleet.dadycar.fr/`} target='_blank'>DadyCar fleet</Link></Text> fournit un suivi des véhicules en temps réel, des outils de gestion de flotte complets et des rapports d'utilisation détaillés, vous donnant un contrôle total sur votre activité d'autopartage.</Text>
                        <Stack gap={4} direction={['column','row']} mt={4}>
                            <Link to={'https://meetings-eu1.hubspot.com/meetings/amine-yahia-cherif/dadycar'} target="_blank" >
                                <Button bg='#3563E9' _hover={{bg:'#0F82EA'}} rounded='30px' fontSize={{base:'14px',md:'16px'}} w='187px' h='52px' color='white' >Prendre RDV</Button>
                            </Link>
                            <Link to={'https://meetings-eu1.hubspot.com/meetings/amine-yahia-cherif?uuid=d67be381-7bc7-461c-ab1c-f55d5ebd83c9'} target="_blank">
                                <Button variant='outline' _hover={{bg:'#3563E9',color:'white',borderColor:'#3563E9'}} rounded='30px' fontSize={{base:'14px',md:'16px'}} color='#222D50' borderWidth={'1px'} borderColor={'#222D50'}  w='187px' h='52px'>Organiser une démo</Button>
                            </Link>
                        </Stack>
                    </Flex>
                </Flex>
            </Box>
        </Container>
    </MotionBox>
  )
}

export default Revolution