import { Box, Modal, ModalContent, ModalOverlay } from '@chakra-ui/react';
import { UserLoginForm } from '../forms/_index';

const UserLoginModal: React.FC<{ isUserLoginOpen: boolean, onUserLoginClose: () => void, onUserRegisterOpen: () => void }> = (props) => {

    return (
        <>
            <Modal isOpen={props.isUserLoginOpen} onClose={props.onUserLoginClose} size={'md'}>
                <ModalOverlay />
                <ModalContent borderRadius={'2xl'}>
                    <Box>
                        <UserLoginForm onUserLoginClose={props.onUserLoginClose} onUserRegisterOpen={props.onUserRegisterOpen} />
                    </Box>
                </ModalContent>
            </Modal>
        </>
    )
}

export default UserLoginModal