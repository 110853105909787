import { Box, IconButton, Menu, MenuButton, MenuItem, MenuList, Portal } from '@chakra-ui/react';
import { BsThreeDotsVertical } from "react-icons/bs";
import NewDevisModal from './NewDevisModal';
import RefusDevisModal from './RefusDevisModal';

const RowAction = () => {
  return (
    <Menu>
        <MenuButton
            as={IconButton}
            aria-label='Options'
            icon={<BsThreeDotsVertical />}
            variant='ghost'
            bg='#0F82EA'
            rounded={'6px'}
            color={'white'}
            _hover={{bg:'blue'}}
        />
        <Portal>
            <MenuList>
                <MenuItem >
                    <NewDevisModal />
                </MenuItem>
                <MenuItem>
                    <RefusDevisModal />
                </MenuItem>
            </MenuList>
        </Portal>
        </Menu>
  )
}

export default RowAction