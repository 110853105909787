import { Button, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { AiOutlinePlusCircle } from 'react-icons/ai'

const Prestations = () => {
  return (
    <Flex my={4} w='100%' justifyContent={'space-between'}>
        <Text fontSize={'22px'} fontWeight={700} color={'#222D50'} >Les prestations</Text>
        <Button _hover={{bg:'#0F82EA',color:'white'}} fontSize={'15px'} variant={'ghost'} borderWidth={'1px'} borderColor={'#0F82EA'} color={'#0F82EA'} rounded={'46px'}   leftIcon={<AiOutlinePlusCircle size={20}/>}>
              Cree un devis
          </Button>
    </Flex>
  )
}

export default Prestations