import { Button } from '@chakra-ui/button'
import { FormControl, FormLabel } from '@chakra-ui/form-control'
import { Image } from '@chakra-ui/image'
import { Input } from '@chakra-ui/input'
import { Box, Flex, SimpleGrid, Stack, Text } from '@chakra-ui/layout'
import workerBg from '../../../../assets/images/dadycar-worker-bg.webp'
import logo from '../../../../assets/images/dadycar-logo.webp'
import googleIcon from '../../../../assets/images/dadycar-google-2.webp'
import { Checkbox, useColorModeValue } from '@chakra-ui/react'

const RegisterSection: React.FC = () => {
    return (
        <Box
            backgroundImage={workerBg}
            backgroundSize={{ lg: 'cover' }}
            backgroundRepeat={'no-repeat'}
            backgroundPosition={{ base: 'top', lg: 'center' }}>
            <SimpleGrid templateColumns={{ base: '2fr', lg: '2fr 2fr' }}>
                <Stack display={{ base: 'none', lg: 'unset' }}
                >
                    {/* <Image src={workerBg} /> */}
                </Stack>
                <Stack py={{ base: 20, lg: 20, md: 20 }}>
                    <Box w={'100%'} h={'700px'} position={'relative'}>
                        <Flex
                            align={'center'}
                            justify={'center'}
                        >
                            <Stack w={{ base: '90%', lg: '60%' }} maxH={'90%'}>
                                <Box borderRadius={'3xl'}
                                    bg={useColorModeValue('white', 'gray.700')}
                                    boxShadow={'md'}
                                    p={8}
                                    w={'100%'}>
                                    <Stack align={'center'}>
                                        <Image src={logo} width={'100px'} />
                                    </Stack>
                                    <Stack>
                                        <Text textAlign={'center'}>Vous pouvez nous joindre à tout moment via :</Text>
                                        <Text color={'#5393EC'} textAlign={'center'}>contact@dadycar.fr</Text>
                                    </Stack>
                                    <Stack>
                                        <Button
                                            borderRadius={'lg'}
                                            bg={'white'}
                                            color={'black'}
                                            border={'1px solid grey'}
                                            _hover={{
                                                bg: 'white',
                                            }}><Image src={googleIcon} mx={2} />
                                            continuer avec google
                                        </Button>
                                    </Stack>
                                    <Stack>
                                        <Text textAlign={'center'}>Ou</Text>
                                    </Stack>
                                    <Stack spacing={4}>
                                        <FormControl id="email">
                                            <FormLabel>Prénom</FormLabel>
                                            <Input borderRadius={'2xl'} type="name" />
                                        </FormControl>
                                        <FormControl id="email">
                                            <FormLabel>Adresse mail</FormLabel>
                                            <Input borderRadius={'2xl'} type="email" />
                                        </FormControl>
                                        <FormControl id="email">
                                            <FormLabel>Numéro de téléphone </FormLabel>
                                            <Input borderRadius={'2xl'} type="number" />
                                        </FormControl>
                                        <FormControl id="password">
                                            <FormLabel>Mot de passe</FormLabel>
                                            <Input borderRadius={'2xl'} type="password" />
                                        </FormControl>
                                        <FormControl id="password">
                                            <FormLabel>Confirmer le mot de passe</FormLabel>
                                            <Input borderRadius={'2xl'} type="password" />
                                        </FormControl>
                                        <Stack>
                                            <Checkbox>Accepter nos conditions générales</Checkbox>
                                        </Stack>
                                        <Stack spacing={6}>
                                            <Button
                                                borderRadius={'lg'}
                                                bg={'#5393EC'}
                                                color={'white'}
                                                _hover={{
                                                    bg: 'blue.500',
                                                }}>
                                                Se connecter
                                            </Button>
                                        </Stack>
                                    </Stack>
                                </Box>
                            </Stack>
                        </Flex>
                    </Box>
                </Stack>
            </SimpleGrid>
        </Box>
    )
}
export default RegisterSection;