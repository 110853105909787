import { Box, Image, Text } from "@chakra-ui/react"
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

type Props = {
    img:string,
    name:string,
    index:number
}
const MotionBox = motion(Box);

const Item = ({img,name,index}:Props) => {
  const [isVisible, setIsVisible] = useState(false);
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) {
      setIsVisible(true);
    }
  }, [inView]);
  return (
    <MotionBox ref={ref}
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 20 }}
    transition={{ duration: 0.5, delay: index * 0.1 }}>
        <Box  borderWidth={'1px'} borderColor={'#F4F9FC'} >
            <Image src={img} />
        </Box>
        <Text mt={2} fontSize={'18px'} fontWeight={400}>{name}</Text>
    </MotionBox>
  )
}

export default Item