import HttpClient from "../services/HttpClient";

type CityType = {
    id: number,
    insee_code: number,
    zip_code: number,
    name: string,
    slug: string,
    department_name: string,
    region_name: string,
    department_code: number,
}

class City {

    public static getCities = async (departmentCode: number) => {
        return HttpClient.get('/administrative/departments/' + departmentCode + '/cities');
    }

    public static searchCities = async (departmentSlug: string) => {
        return HttpClient.get('/administrative/departments/slug/' + departmentSlug + '/cities');
    }
}

export { type CityType };
export default City;