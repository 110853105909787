import { Box, Button, Center, Container, Flex, Heading, Image, List, ListItem, SimpleGrid, Spinner, Stack, Text, useDisclosure } from '@chakra-ui/react'
import { FiEdit } from 'react-icons/fi'
import cardGraphic from '../../../../../../assets/images/dadycar-checkout-graphic.webp'
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store';
import CompleteVehicleDataModal from '../../../../../../components/modals/CompleteVehicleDataModal';
import { useEffect, useState } from 'react';
import { AppointmentViewModel, UserViewModel } from '../../../../../../view-models/_index';
import { UserLoginModal, UserRegisterModal } from '../../../../../../components/modals/_index';
import { useNavigate } from 'react-router-dom';

interface ChildProps {
    setAddressFormVisibility: (value: boolean) => void;
    addressFormVisibility: boolean;
}

const BookingSummary: React.FC<ChildProps> = (props) => {
    const vendor = useSelector((state: RootState) => state.vendor.selected);
    const date = useSelector((state: RootState) => state.vendor.selectedDate);
    const departureAddress = useSelector((state: RootState) => state.address.departureAddress);
    const returnAddress = useSelector((state: RootState) => state.address.returnAddress);
    const selectedSubService = useSelector((state: RootState) => state.service.selectedSubService);
    const mark = useSelector((state: RootState) => state.mark.selected);
    const model = useSelector((state: RootState) => state.model.selected);
    const version = useSelector((state: RootState) => state.version.selected);
    const distancePrice = useSelector((state: RootState) => state.distancePrice.value);
    const vehicle = useSelector((state: RootState) => state.vehicle.value);
    const appointment = useSelector((state: RootState) => state.appointment.value);
    const user = useSelector((state: RootState) => state.user.current);
    const getCurrentUser = (new UserViewModel()).getCurrentUser;
    const storeAppointment = (new AppointmentViewModel()).storeAppointment;
    const { isOpen: isCompleteVehicleDataOpen, onOpen: onCompleteVehicleDataOpen, onClose: onCompleteVehicleDataClose } = useDisclosure();
    const { isOpen: isUserLoginOpen, onOpen: onUserLoginOpen, onClose: onUserLoginClose } = useDisclosure();
    const { isOpen: isUserRegisterOpen, onOpen: onUserRegisterOpen, onClose: onUserRegisterClose } = useDisclosure();
    const [isConfirmed, setIsConfirmed] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const selectedVehicle = vehicle ? vehicle.mark + ' ' + vehicle.model : mark?.name + ' ' + model?.name + ' ' + version?.name;

    useEffect(() => {
        getCurrentUser()
    }, []);

    useEffect(() => {
        if (vendor && selectedSubService && date && departureAddress && returnAddress && version)
            if (isConfirmed && vehicle && user) {
                setIsLoading(true);
                const data = {
                    provider_id: vendor.id,
                    sub_service_id: selectedSubService.id,
                    appointment_date: date.date,
                    appointment_time: date.hour,
                    going_address: departureAddress.label,
                    coming_address: returnAddress.label,
                    search_type: vehicle.isSearched ? 'SV' : 'V',
                    vehicle: {
                        vehicle_id: version.id,
                        vehicle_numberplate: vehicle.plateNumber
                    }
                };

                const store = async () => {
                    const response = await storeAppointment(data);
                    if (response === 'success') {
                        setIsLoading(false);
                        navigate("/garage/reservation/" + appointment?.id + "/complete");
                    }
                };
                store();
            }
    }, [vehicle]);

    const onSubmitHandler = async () => {
        setIsLoading(true);
        setIsConfirmed(true);

        if (vendor && selectedSubService && date && departureAddress && returnAddress && (vehicle || version)) {
            if (vehicle && user) {
                const data = {
                    provider_id: vendor.id,
                    sub_service_id: selectedSubService.id,
                    appointment_date: date.date,
                    appointment_time: date.hour,
                    going_address: departureAddress.label,
                    coming_address: returnAddress.label,
                    search_type: vehicle.isSearched ? 'SV' : 'V',
                    vehicle: {
                        vehicle_id: version?.id,
                        vehicle_numberplate: vehicle.plateNumber
                    }
                };
                const response = await storeAppointment(data);
                if (response === 'success') {
                    setIsLoading(false);
                    navigate("/garage/reservation/" + appointment?.id + "/complete");
                } else {
                    setIsLoading(false);
                }
            } else if (!vehicle) {
                onCompleteVehicleDataOpen();
                setIsLoading(false);
            } else {
                onUserLoginOpen();
                setIsLoading(false);
            }
        } else {
            setIsLoading(false);
        }
    }

    return <>
        <CompleteVehicleDataModal isCompleteVehicleDataOpen={isCompleteVehicleDataOpen} onCompleteVehicleDataClose={onCompleteVehicleDataClose} onUserLoginOpen={onUserLoginOpen} />
        <UserLoginModal isUserLoginOpen={isUserLoginOpen} onUserRegisterOpen={onUserRegisterOpen} onUserLoginClose={onUserLoginClose} />
        <UserRegisterModal isUserRegisterOpen={isUserRegisterOpen} onUserRegisterClose={onUserRegisterClose} />

        <Container maxW={'90%'} w={{ lg: '1000px' }} py={10}>
            <SimpleGrid templateColumns={{ base: '1fr', lg: '4fr 3fr' }} spacing={6}>
                <Stack bg={'white'} borderRadius={'30px'} boxShadow={'md'}>
                    <Flex position={'relative'} justifyContent={{ base: 'center', lg: 'unset' }}>
                        <Box h={'100%'} display={{ base: 'none', lg: 'block' }}>
                            <Image src={cardGraphic} h={'310px'} />
                        </Box>
                        <Box px={5} py={4}>
                            <Heading color={'#008FE6'} size={'md'}>La récapitulation</Heading>
                            <Stack my='5'>
                                <List spacing={2}>
                                    <ListItem>Vehicle: {selectedVehicle}</ListItem>
                                    <ListItem>Service : {selectedSubService?.name}</ListItem>
                                    <ListItem>Garage : {vendor?.name}</ListItem>
                                    <ListItem>Distance totale : {distancePrice.totalDistance} Km</ListItem>
                                </List>
                            </Stack>
                            {isLoading ? <Spinner /> :
                                <>
                                    <Button bg={'#5393EC'} color={'white'} onClick={onSubmitHandler}>Confirmer</Button>
                                </>
                            }
                        </Box>
                    </Flex>
                </Stack>

                <Stack bg={'white'} borderRadius={'30px'} boxShadow={'md'} padding={5} gap='1'>
                    <Stack>
                        <Heading textAlign={'center'} size={'md'}>{date?.date + ' ' + date?.hour}</Heading>
                    </Stack>
                    <List spacing={2}>
                        <ListItem textAlign={'center'}>Plus d'informations sur la prise en charge et la restitution de votre véhicule vous seront communiquées prochainement</ListItem>
                        <ListItem textAlign={'center'}> Adresse de collect de véhicule : <br /> {departureAddress?.label} </ListItem>
                        <ListItem textAlign={'center'}> Adresse de restitution de véhicule : <br /> {returnAddress?.label} </ListItem>
                        {!props.addressFormVisibility &&
                            <ListItem >
                                <Center color={'#008FE6'} textAlign={'center'}>
                                    <Button leftIcon={<FiEdit />} onClick={() => { props.setAddressFormVisibility(true) }}>Modifier mon rendez-vous</Button>
                                </Center>
                            </ListItem>
                        }
                    </List>
                </Stack>
            </SimpleGrid>
        </Container>
    </>
}

export default BookingSummary;