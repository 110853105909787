import { Box, Text } from "@chakra-ui/react"
import Filtring from "./components/Filtring"
import HistoriqueData from "./components/HistoriqueData"

const HistoriquePage = () => {
  return (
    <Box bg='#fafafa'>
        <Box px={4} py={2}>
            <Text fontSize={'24px'} fontWeight={600} color={'#222D50'} >Demender Rdv</Text>
            <Text maxW={'838px'} fontWeight={400} color={'#4E5D78'} >Consultez ci-dessous les rendez-vous pris en compte pour avril. Si une erreur s’y est glissée, cliquez sur « Réclamation » pour nous la signaler.</Text>
            <Filtring />
            <HistoriqueData />
        </Box>
    </Box>
  )
}

export default HistoriquePage