import { Box, Container, Flex, Image, Text } from '@chakra-ui/react'
import gain from '../../../../../../assets/V2/prestation/temp.webp'
import { motion} from 'framer-motion';
import { useInView } from 'react-intersection-observer'

import { useEffect, useState } from 'react';
const MotionBox = motion(Box);

const GainTemp = () => {
  const [isVisible, setIsVisible] = useState(false);
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) {
      setIsVisible(true);
    }
  }, [inView]);
  
  return (
    <MotionBox
        ref={ref}
        initial={{ x: -200, opacity: 0 }}
        animate={{ x: inView ? 0 : -200, opacity: inView ? 1 : 0 }}
        transition={{ duration: 0.5 }}
    >
      <Container maxW={'90%'} w={{ lg: '1200px' }}>
        <Box py={8}>
            <Flex flexDirection={{base:'column-reverse',lg:'row'}} gap={{base:4,lg:10}} justifyContent='space-between' alignItems={{xl:'center'}}  >
                <Flex flexDirection={'column'} gap={4} flex={1} >
                    <Text fontSize={{base:'30px',sm:'35px',md:'40px'}} fontWeight={700} color={'#0D121F'} >Gain de temps et d'efficacité</Text>
                    <Text fontSize={{base:'18px',sm:'20px',md:'22px'}} color={'#222D50'}><Text as='span' color={'#0F82EA'} fontWeight={600}>Optimisez votre temps : </Text>Prenez rendez-vous pour l'entretien ou la réparation en ligne en quelques clics et profitez d'un service rapide et efficace.</Text>
                    <Text fontSize={{base:'18px',sm:'20px',md:'22px'}} color={'#222D50'}><Text as='span' color={'#0F82EA'} fontWeight={600}>Restez productifs : </Text>Minimisez les temps d'arrêt de vos véhicules grâce à un réseau de garages partenaires et à un suivi en ligne transparent.</Text>
                </Flex>
                <Box flex={1} >
                    <Image src={gain} h='100%' objectFit={'fill'} />
                </Box>
            </Flex>
        </Box>
      </Container>
    </MotionBox>
  )
}

export default GainTemp