import { Badge, Card, CardHeader, Center, Heading, Spinner, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { ConveyingViewModel } from "../../../view-models/_index";

const GetConveyingStatus = (status: string) => {
    switch (status) {
        case 'P':
            return <Badge w={'24'} justifyContent={'center'} variant='solid' colorScheme={'yellow'} whiteSpace={'nowrap'} px={'2'} py={'1'} rounded={'6'}><Center>En attente</Center></Badge>
        case 'A':
            return <Badge w={'24'} variant='solid' colorScheme={'blue'} whiteSpace={'normal'} px={'2'} py={'1'} rounded={'6'}><Center>Accepté</Center></Badge>
        case 'F':
            return <Badge w={'24'} variant='solid' colorScheme={'green'} whiteSpace={'normal'} px={'2'} py={'1'} rounded={'6'}><Center>Terminé</Center></Badge>
        case 'CC':
            return <Badge w={'24'} variant='solid' colorScheme={'red'} whiteSpace={'normal'} px={'2'} py={'1'} rounded={'6'}><Center>Annulé</Center></Badge>
        case 'CU':
            return <Badge w={'24'} variant='solid' colorScheme={'red'} whiteSpace={'normal'} px={'2'} py={'1'} rounded={'6'}><Center>Annulé</Center></Badge>
        default:
            return <Badge w={'32'} fontSize={'10'} variant='solid' colorScheme={'red'} whiteSpace={'normal'} px={'2'} py={'1'} rounded={'6'}><Center>L'attente du payment</Center></Badge>
    }
};

const UserConveyingsPage: React.FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const getUserConveyings = (new ConveyingViewModel()).getUserConveyings;
    const conveyings = useSelector((state: RootState) => state.conveying.list);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        const initConveyings = async () => {
            setIsLoading(true);
            await getUserConveyings();
            setIsLoading(false);
        }

        initConveyings();
    }, []);

    return <>
        <Card>
            <CardHeader>
                <Heading fontSize={'20'} px={'4'} as={'h1'} color={'gray.600'}>Votre liste de convoyages</Heading>
            </CardHeader>
        </Card>
        <Card mt={'4'}>
            {isLoading ?
                <>
                    <Center py={12}>
                        <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
                    </Center>
                </>
                :
                <>
                    <TableContainer fontSize={'14'} py={'4'}>
                        <Table variant='simple' whiteSpace={'normal'}>
                            <Thead>
                                <Tr textAlign={'center'} borderBottom={'2px'} borderColor={'RGBA(0, 0, 0, 0.16)'}>
                                    <Th textAlign={'center'}>Status</Th>
                                    <Th textAlign={'center'}>Date <Text whiteSpace={'nowrap'}> de départ</Text></Th>
                                    <Th textAlign={'center'}>Date <Text whiteSpace={'nowrap'}> d'arrivée</Text></Th>
                                    <Th textAlign={'center'}>Prix <Text fontSize={'xx-small'} whiteSpace={'nowrap'}>(Service + convoyage)</Text></Th>
                                    <Th textAlign={'center'}>Vehicle</Th>
                                    <Th textAlign={'center'}>Adresse de départ</Th>
                                    <Th textAlign={'center'}>Adresse d'arrivée</Th>
                                </Tr>
                            </Thead>
                            <Tbody>

                                {conveyings && conveyings.map((conveying, key) => {
                                    return (
                                        <Tr key={key}>
                                            <Td textAlign={'center'}>{GetConveyingStatus(conveying.status!.title)}</Td>
                                            <Td textAlign={'center'} minW={'150px'}>{conveying.from_date}</Td>
                                            <Td textAlign={'center'} minW={'150px'}>{conveying.to_date ? conveying.to_date : 'Pas encore assigné'}</Td>
                                            <Td textAlign={'center'}>{conveying.price}</Td>
                                            <Td textAlign={'center'}>{conveying.vehicle_mark + ' ' + conveying.vehicle_model}</Td>
                                            <Td textAlign={'center'} minW={'200px'}>{conveying.departure_address}</Td>
                                            <Td textAlign={'center'} minW={'200px'}>{conveying.arrival_address}</Td>
                                        </Tr>
                                    )
                                })}
                            </Tbody>
                        </Table>
                    </TableContainer>
                </>
            }
        </Card>
    </>
}

export default UserConveyingsPage;