import HttpClient from '../services/HttpClient'

type StoreExpertContactType = {
    first_name: string;
    last_name: string;
    company_name: string;
    company_profile: string;
    email: string;
    phone: string;
    message: string;
}

class ExpertContact {
    public static store = async (data: StoreExpertContactType) => {
        return HttpClient.post('/fleet-contact/store', data);
    }
}

export { type StoreExpertContactType };

export default ExpertContact;