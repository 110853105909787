import { Box, Flex, HStack } from "@chakra-ui/react"
import SearchInput from "./SearchInput"
import NotificationComp from "./NotificationComp"
import UserMenu from "./UserMenu"

const Navbar = () => {
  return (
    <Box as='nav' bg={'#FFFFFF'} boxShadow={'sm'} >
        <Flex px={4} py={2} justifyContent={'space-between'} alignItems={'center'} >
            <SearchInput />
            <HStack ml={20} >
                <NotificationComp />
                <Box w={'2px'} h='30px' bg={'#E4E9EF'} />
                <UserMenu />
            </HStack>
        </Flex>
    </Box>
  )
}

export default Navbar