import { Box, Center, Container, Heading, SimpleGrid, Spinner, Text } from '@chakra-ui/react'
import SectionHeader from '../../../../components/sections/SectionHeader'
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { DepartmentViewModel } from '../../../../view-models/_index';
import { useEffect, useState } from 'react';
import DepartmentCard from './DepartmentCard';
import { useParams } from 'react-router-dom';

const DepartmentsSection: React.FC = () => {
    const searchDepartments = (new DepartmentViewModel).searchDepartments;
    const departments = useSelector((state: RootState) => state.department.list);
    const [isLoading, setIsLoading] = useState<Boolean>(false);
    const { regionParams } = useParams();

    useEffect(() => {
        const initDepartments = async () => {
            if (regionParams) {
                setIsLoading(true);
                const response = await searchDepartments(regionParams);
                if (response)
                    setIsLoading(false);
            }
        }
        initDepartments();
    }, []);
    return (
        <>
            {isLoading ?
                <>
                    <Center py={'32'} bg={'white'}>
                        <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
                    </Center>
                </>
                :
                <>
                    {departments ?
                        <>
                            <Box mt={12} bg={'#F6FAFC'} py={12}>
                                <SectionHeader title={`Nos garages dans la région ${Object.values(departments)[0]?.name}`} sectionName='Départements' />
                                <Container
                                    maxW={'80%'}
                                    w={{ lg: '1000px' }}
                                    py={10}
                                    px={{ lg: 12 }}
                                >
                                    <Text >
                                        Découvrez la liste des garages et centres auto en région Ile-de-France pouvant réaliser l'entretien courant de votre voiture (révision, vidange, disques et plaquettes, batterie...) ainsi que tout autre type de réparations (décalaminage, courroie de distribution...) au meilleur prix. Comparez les tarifs pratiqués par nos garages partenaires pour prévoir les coûts de votre prestation et prenez un rendez-vous dans le garage qui correspond à tous vos critères : horaires, avis clients, prix de l'intervention.
                                    </Text>
                                    <Box py={6}>
                                        <Center py={6}>
                                            <Heading textAlign={'center'} size={'md'} color={'#1385EB'}>Tous les départements de la région</Heading>
                                        </Center>
                                        <SimpleGrid templateColumns={{ lg: '2fr 2fr 2fr 2fr' }} spacing={6}>
                                            {departments.map((department) => (
                                                <DepartmentCard key={department.id} department={department} />
                                            ))}
                                        </SimpleGrid>
                                    </Box>
                                </Container>
                            </Box>
                        </>
                        :
                        <>
                            <Center py={'32'} bg={'white'}>
                                <Text>Aucun département trouvé</Text>
                            </Center>
                        </>
                    }
                </>
            }
        </>
    )
}

export default DepartmentsSection;