import { createSlice } from '@reduxjs/toolkit';

type AppointmentType = {
    id: number,
    departureDate: string,
    arrivalDate: string | null,
    service: string,
    garage: string,
    vendor: string,
    vehicle: string,
    departureAddress: string,
    arrivalAddress: string,
    priceToPay: number,
    priceHT: number,
    priceTTC: number,
    totalDiscount: number,
    finalPrice: number,
    leftToPay: number,
    status: string,
    servicePrice: number,
    conveyingPrice: number,
    distance: string,
};
type SliceState = {
    list: {
        total: number,
        count: number,
        perPage: number,
        currentPage: number,
        totalPages: number,
        appointments: AppointmentType[]
    } | null, value: AppointmentType | null, clientSecret: string | null, finalPrice: number | null
};

const initialState: SliceState = { list: null, value: null, clientSecret: null, finalPrice: null }

const appointmentSlice = createSlice({
    name: 'appointment',
    initialState,
    reducers: {
        updateList(state, action) {
            state.list = action.payload;
        },
        updateValue(state, action) {
            state.value = action.payload;
        },
        updateClientSecret(state, action) {
            state.clientSecret = action.payload;
        },
        updateFinalPrice(state, action) {
            state.finalPrice = action.payload;
        }
    },
});

export { type AppointmentType };
export const appointmentActions = appointmentSlice.actions;
export default appointmentSlice.reducer;