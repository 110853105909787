import { Box, Center, Heading, Image, Link, Text } from "@chakra-ui/react";
import ourServicesBg from '../../../../../assets/images/dadycar-conveying-bg.webp';
import ourServicesBg1 from '../../../../../assets/images/dadycar-conveying-bg-md.webp';
import ourServicesbg2 from '../../../../../assets/images/dadycar-conveying-bg-sm.webp';
import { ConveyingBookingSearch } from "../../../../../components/sections/V2/_index";
import header_left from '../../../../../assets/V2/header_left.png';

const BookingSection: React.FC<{ children: any }> = (props) => {
    return (
        <Box >
            <Box pt={32} pb={40} bg='#0D121F' pos='relative'>
                <Box
                    position='absolute'
                    top={0}
                    right={0}
                    w='300px'
                    h='300px'
                    backgroundImage={header_left}
                    backgroundSize={'cover'}
                    backgroundRepeat={'no-repeat'}
                    backgroundPosition={{ base: 'top', lg: 'center' }}>   
                </Box>
                <Box py={{ lg: 7, sm: 10 }}>
                    <Heading fontSize='30px' color={'white'} textAlign={'center'}>DadyCar Convoyage</Heading>
                    <Center>
                        <Box>
                            <Text mt={4} fontSize='16px' color={'white'} textAlign={'center'}>
                            Combien de véhicules devez-vous déplacer ?
                            </Text>
                        </Box>
                    </Center>
                </Box>

                    <ConveyingBookingSearch />
            </Box>
            <Box>
                {props.children}
            </Box>
        </Box>

    )
}

export default BookingSection;