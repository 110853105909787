import { Box, Flex, Text } from '@chakra-ui/react'
import React from 'react'

const VehiculeResume = () => {
  return (
    <Box flex={1.5} bg='#CFE7FC66' rounded={'8px'} p={'16px'}>
        <Flex justifyContent={'space-between'} alignItems={'center'} >
            <Text fontWeight={600} fontSize={'18px'} color={'#222D50'} >Véhicule</Text>
            <Text fontWeight={700} fontSize={'18px'} color={'#0F82EA'} >MERCEDES A250e (V177) 1.3i 217 8G-DCT</Text>
        </Flex>
        <Flex justifyContent={'space-between'} alignItems={'center'} mt={6}>
            <Text flex={2} fontWeight={600} fontSize={'16px'} color={'#222D50'} >Immatriculation : <Text as='span' color={'#0F82EA'}>Af25639</Text></Text>
            <Flex flex={1} justifyContent={'flex-start'} >
                <Text fontWeight={600} fontSize={'16px'} color={'#222D50'} >Immatriculation : <Text as='span' color={'#0F82EA'}>Af25639</Text></Text>
            </Flex>
        </Flex>
        <Flex justifyContent={'space-between'} alignItems={'center'} mt={2}>
            <Text flex={2} fontWeight={600} fontSize={'16px'} color={'#222D50'} >Date de mise en circulation : <Text as='span' color={'#0F82EA'}>2022</Text></Text>
            <Flex flex={1} justifyContent={'flex-start'} >
                <Text fontWeight={600} fontSize={'16px'} color={'#222D50'} >Date de mise en circulation : <Text as='span' color={'#0F82EA'}>2022</Text></Text>
            </Flex>
        </Flex>
        <Flex justifyContent={'space-between'} alignItems={'center'} mt={2}>
            <Text flex={2} fontWeight={600} fontSize={'16px'} color={'#222D50'} >N° de Série : <Text as='span' color={'#0F82EA'}>Af25639</Text></Text>
            <Flex flex={1} justifyContent={'flex-start'} >
                <Text fontWeight={600} fontSize={'16px'} color={'#222D50'} >Boîte de vitesse : <Text as='span' color={'#0F82EA'}>Auto</Text></Text>
            </Flex>
        </Flex>
        <Flex justifyContent={'space-between'} alignItems={'center'} mt={2}>
            <Text flex={2} fontWeight={600} fontSize={'16px'} color={'#222D50'} >Energie : <Text as='span' color={'#0F82EA'}>Hybride essence et électrique rechargeable</Text></Text>
            <Flex flex={1} justifyContent={'flex-start'} >
                <Text fontWeight={600} fontSize={'16px'} color={'#222D50'} >Kilométrage : <Text as='span' color={'#0F82EA'}>kilométrage : </Text></Text>
            </Flex>
        </Flex>
        <Flex justifyContent={'space-between'} alignItems={'center'} mt={2}>
            <Text flex={2} fontWeight={600} fontSize={'16px'} color={'#222D50'} >Immatriculation : <Text as='span' color={'#0F82EA'}>Af25639</Text></Text>
            <Flex flex={1} justifyContent={'flex-start'} >
                <Text fontWeight={600} fontSize={'16px'} color={'#222D50'} >Immatriculation : <Text as='span' color={'#0F82EA'}>Af25639</Text></Text>
            </Flex>
        </Flex>
    </Box>
  )
}

export default VehiculeResume