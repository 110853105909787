import { Box, Button, Center, Flex, Heading, Image, List, ListItem, Modal, ModalContent, ModalOverlay, Spinner, Stack, Text } from '@chakra-ui/react';
import { AcceptQuoteModalDataType } from '../../models/Appointment';
import cardGraphic from '../../assets/images/dadycar-checkout-graphic.webp';

const AcceptQuoteModal: React.FC<{ isOpen: boolean, onClose: () => void, data: AcceptQuoteModalDataType, isLoading: boolean, onAcceptHandler: () => void }> = ({ isOpen, onClose, data, isLoading, onAcceptHandler }) => {
    return <>
        <Modal isOpen={isOpen} onClose={onClose} size={'xl'}>
            <ModalOverlay />
            <ModalContent borderRadius={'48px'}>
                <Stack bg={'white'} borderRadius={'48px'} boxShadow={'md'}>
                    <Flex position={'relative'} justifyContent={{ base: 'center', lg: 'unset' }}>
                        <Box h={'100%'} display={{ base: 'none', lg: 'block' }}>
                            <Image src={cardGraphic} h={'340px'} />
                        </Box>
                        <Center>
                            <Box px={5} pt={4} alignItems={'center'} justifyItems={'center'}>
                                <Heading color={'#008FE6'} size={'md'}>VOTRE DEVIS</Heading>
                                <Stack my='5'>
                                    <List spacing={2}>
                                        <ListItem>Votre vehicle : {data.vehicle}</ListItem>
                                        <ListItem>Distance totale : {data.distance} Km</ListItem>
                                        {/* <ListItem>Prix du convoyage: {data.conveyingPrice} € (Une remise de 50% sera automatiquement appliquée sur le prix du convoyage)</ListItem> */}
                                        <ListItem>Prix du voyage : {data.conveyingPrice} €</ListItem>
                                        <ListItem>Service : {data.service} </ListItem>
                                        <ListItem>Prix du service : {data.servicePrice} €</ListItem>
                                    </List>
                                    <Flex gap={2}>
                                        <Stack alignSelf={'center'}>
                                            <Text fontWeight={'bold'} color={'#5393EC'}> Total prix a payer : {data.totalPrice} €</Text>
                                        </Stack>
                                    </Flex>
                                </Stack>
                                {isLoading ?
                                    <Spinner />
                                    :
                                    <>
                                        <Button bg={'#5393EC'} color={'white'} onClick={onAcceptHandler}>Accepter le devis</Button>
                                    </>
                                }
                            </Box>
                        </Center>
                    </Flex>
                </Stack>
            </ModalContent>
        </Modal>
    </>
}

export default AcceptQuoteModal;