import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { Box, Text } from '@chakra-ui/react';

const localizer = momentLocalizer(moment);

const MyCalendar = () => {
  const events = [
    {
      title: 'Car-Sharing',
      describe:"asd",
      start: new Date(2024, 4, 27, 10, 0), // March 1, 2024, 10:00 AM
      end: new Date(2024, 4, 27, 12, 0), // March 1, 2024, 12:00 PM
      allDay: false,
    },
    {
      title: 'Car-Sharing',
      describe:"asd",
      start: new Date(2024, 4, 16, 10, 0), // March 1, 2024, 10:00 AM
      end: new Date(2024, 4, 16, 12, 0), // March 1, 2024, 12:00 PM
      allDay: false,
    },
    {
      title: 'Car-Sharing',
      describe:"asd",
      start: new Date(2024, 4, 20, 10, 0), // March 1, 2024, 10:00 AM
      end: new Date(2024, 4, 20, 12, 0), // March 1, 2024, 12:00 PM
      allDay: false,
    },
    {
      title: 'WindShield Change',
      start: new Date(2024, 2, 10),
      end: new Date(2024, 2, 10),
      allDay: true,
    },
    // Add more events here
  ];

  return (
    <Box bg={'white'} mx={4} my={2} p={4} boxShadow={'lg'}>
      <Text mb={4} fontSize={'28px'} color={'#252525'} fontWeight={500}><Text as={'span'} fontWeight={900}>{moment().format('MMMM')}</Text> {moment().format('YYYY')}</Text>
      <Calendar
        views={['month']}
        toolbar={false}
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
      />
    </Box>
  );
};

export default MyCalendar;