import { distanceActions } from './../store/slices/distance-slice';
import { useDispatch } from 'react-redux';
import { VendorType } from '../store/slices/vendor-slice';
import { Distance } from '../models/_index';
import { AddressType } from '../models/Address';
class DistanceViewModel {

    dispatch = useDispatch();

    public getDistanceWithPrice = async (vendor: VendorType, addresses: { departureAddress: AddressType, returnAddress: AddressType }, carId?: number, vehicleType?: string) => {
        let requestBody;
        if (carId)
            requestBody = {
                car_id: carId,
                departure_point: { lat: addresses.departureAddress.lat, lng: addresses.departureAddress.lng },
                arrival_point: { lat: vendor.lat, lng: vendor.lng },
                coming_point: { lat: addresses.returnAddress.lat, lng: addresses.returnAddress.lng },
            };
        else if (vehicleType)
            requestBody = {
                vehicle_type: vehicleType,
                departure_point: { lat: addresses.departureAddress.lat, lng: addresses.departureAddress.lng },
                arrival_point: { lat: vendor.lat, lng: vendor.lng },
                coming_point: { lat: addresses.returnAddress.lat, lng: addresses.returnAddress.lng },
            };
        else
            return;

        const response = await Distance.getDistanceWithPrice(requestBody);
        if (response.status === 200)
            this.dispatch(distanceActions.updateValue(response.data));
    }

    public getConveyingDistanceWithPrice = async (addresses: { conveyingDepartureAddress: AddressType, conveyingReturnAddress: AddressType }, carId?: number, vehicleType?: string) => {
        let requestBody;
        if (carId)
            requestBody = {
                car_id: carId,
                departure_point: { lat: addresses.conveyingDepartureAddress.lat, lng: addresses.conveyingDepartureAddress.lng },
                arrival_point: { lat: addresses.conveyingReturnAddress.lat, lng: addresses.conveyingReturnAddress.lng },
            };
        else if (vehicleType)
            requestBody = {
                vehicle_type: vehicleType,
                departure_point: { lat: addresses.conveyingDepartureAddress.lat, lng: addresses.conveyingDepartureAddress.lng },
                arrival_point: { lat: addresses.conveyingReturnAddress.lat, lng: addresses.conveyingReturnAddress.lng },
            };
        else
            return;

        const response = await Distance.getConveyingDistanceWithPrice(requestBody);
        if (response.status === 200)
            this.dispatch(distanceActions.updateConveyingDistance(response.data));
    }
}

export default DistanceViewModel;