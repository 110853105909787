import { Outlet } from "react-router-dom";
import Navbar from "./components/layout/navbar/Navbar";
import { Box, Flex } from "@chakra-ui/react";
import Sidebar from "./components/layout/sidebar/Sidebar";

const GarageLayout: React.FC = () => {
    return <Box>
            <Sidebar />
            <Box ml={'278px'}>
                <Navbar />
                <Outlet />
            </Box>
    </Box>
}

export default GarageLayout;