import { useEffect } from 'react';
import { FormSection, HeaderSection } from './components/_index'

const ConveyingBookingPage: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <HeaderSection />
      <FormSection />
    </>
  )
}

export default ConveyingBookingPage;