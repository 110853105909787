import { Helmet } from 'react-helmet';

const Head: React.FC<{ title: string, description?: string, keywords?: string, robots?: string }> = (props) => {
    return <>
        <Helmet>
            <title>{props.title}</title>
            {props.description && <meta name="description" content={props.description}></meta>}
            {props.keywords && <meta name="keywords" content={props.keywords}></meta>}
            {props.robots && <meta name="robots" content={props.robots}></meta>}
        </Helmet>
    </>
};

export default Head;