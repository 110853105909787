import { Box, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import { BsThreeDotsVertical } from "react-icons/bs";
import RowAction from './RowAction';

const NewRendezvous = () => {
  return (
    <Box h={'auto'} bg={'white'} boxShadow={'sm'} rounded={'8px'} >
        <Box px={4} py={2}  >
            <Text fontWeight={600} color={'#222D50'} mb={4}>Nouveaux Demander Rendez-vous</Text>
            <TableContainer  >
                <Table variant='simple'  >
                    <Thead>
                    <Tr>
                        <Th>Véhicule</Th>
                        <Th></Th>
                        <Th>Client</Th>
                        <Th>prestation </Th>
                        <Th>Date du RDV</Th>
                        <Th>Status</Th>
                        <Th></Th>
                    </Tr>
                    </Thead>
                    <Tbody>
                        <Tr>
                            <Td color={'#1A1919'} fontSize={'16px'}>FG077AB</Td>
                            <Td color={'#656575'} fontSize={'14px'}>BERLINGO 1.6 <br />Blue HDi 100</Td>
                            <Td color={'#656575'} fontSize={'14px'}>July Fride</Td>
                            <Td color={'#656575'} fontSize={'14px'}>Vidange</Td>
                            <Td color={'#656575'} fontSize={'14px'}>24-04-2024</Td>
                            <Td>
                                <Text color={'white'} w='fit-content' px={'6px'} py={'8px'} bg='#E1B000' rounded={'6px'}>En attente</Text>
                            </Td>
                            <Td>
                                <RowAction />
                            </Td>       
                        </Tr>
                        <Tr>
                            <Td color={'#1A1919'} fontSize={'16px'}>FG077AB</Td>
                            <Td color={'#656575'} fontSize={'14px'}>BERLINGO 1.6 <br />Blue HDi 100</Td>
                            <Td color={'#656575'} fontSize={'14px'}>July Fride</Td>
                            <Td color={'#656575'} fontSize={'14px'}>Vidange</Td>
                            <Td color={'#656575'} fontSize={'14px'}>24-04-2024</Td>
                            <Td>
                                <Text color={'white'} w='fit-content' px={'6px'} py={'8px'} bg='#E1B000' rounded={'6px'}>En attente</Text>
                            </Td>
                            <Td>
                                <RowAction />
                            </Td>       
                        </Tr>
                        <Tr>
                            <Td color={'#1A1919'} fontSize={'16px'}>FG077AB</Td>
                            <Td color={'#656575'} fontSize={'14px'}>BERLINGO 1.6 <br />Blue HDi 100</Td>
                            <Td color={'#656575'} fontSize={'14px'}>July Fride</Td>
                            <Td color={'#656575'} fontSize={'14px'}>Vidange</Td>
                            <Td color={'#656575'} fontSize={'14px'}>24-04-2024</Td>
                            <Td>
                                <Text color={'white'} w='fit-content' px={'6px'} py={'8px'} bg='#E1B000' rounded={'6px'}>En attente</Text>
                            </Td>
                            <Td>
                                <RowAction />
                            </Td>       
                        </Tr>
                    </Tbody>
                </Table>
            </TableContainer>
        </Box>

    </Box>
  )
}

export default NewRendezvous