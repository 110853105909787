import { Box, Container, Flex, Image, Text } from "@chakra-ui/react"
import sponsor1 from '../../../../assets/V2/sponsor1.webp'
import sponsor2 from '../../../../assets/V2/sponsor2.webp'
import sponsor3 from '../../../../assets/V2/sponsor3.webp'
import sponsor4 from '../../../../assets/V2/sponsor4.webp'
import { motion } from "framer-motion";
import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
const theSponsors = [
  {
    img:sponsor1
  },
  {
    img:sponsor2
  },
  {
    img:sponsor3
  },
  {
    img:sponsor4
  }
]

const MotionBox = motion(Box);

const Sponsors = () => {
  const { ref, inView } = useInView({threshold:0.3});
    const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (inView) {
      setIsVisible(true);
    }
  }, [inView]);
  return (
    <Box bg='white' py={8}>
        <MotionBox
          ref={ref} initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : -50 }}
          transition={{ duration: 0.5 }}
        >
          <Container maxW={'90%'} w={{ lg: '1200px' }} >
            <Flex  flexDirection='column' justifyContent='center' alignItems='center'>
                <Text mb={4} color='#0F82EA' >ils nous font confiance</Text>
                <Flex alignItems='center' justifyContent='center' flexWrap='wrap' gap={8} >
                    {theSponsors?.map((s,index)=>(
                      <Image key={index} src={s?.img} />
                    ))}
                </Flex>
            </Flex>
          </Container>
        </MotionBox>
    </Box>
  )
}

export default Sponsors