import { createSlice } from '@reduxjs/toolkit';
import { CityType } from '../../models/City';

type SliceState = { list: CityType[] | null, selected: CityType | null };

const initialState: SliceState = { list: null, selected: null }

const citySlice = createSlice({
  name: 'city',
  initialState,
  reducers: {
    updateList(state, action) {
      state.list = action.payload;
    },
    updateSelected(state, action) {
      state.selected = action.payload;
    },
  },
});

export const cityActions = citySlice.actions;
export default citySlice.reducer;