import HttpClient from '../services/HttpClient'

type StoreContactType = {
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    message: string;
}

class Contact {
    public static store = async (data: StoreContactType) => {
        return HttpClient.post('/contact/store', data);
    }
}

export { type StoreContactType };
export default Contact;