import HttpClient from "../services/HttpClient";

type RegionType = {
    id: number,
    code: number,
    name: string,
    slug: string,
    image: string,
}

class Region {

    public static getRegions = async () => {
        return HttpClient.get('/administrative/regions');
    }
}

export { type RegionType };
export default Region;