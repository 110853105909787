import { useEffect } from 'react';
import Head from '../../../components/blocks/Head';
import { BookingSection, AboutVendorsSection, RegionsSection } from './components/_index'

const title = "Listes des Garages";
const description = "Comparez les garages et centres auto les plus proches de chez vous sur Dadycar pour l'entretien et la maintenance de votre véhicule. Trouvez le garage auto le moins cher et le mieux noté en quelques clics et économisez sur toutes vos réparations automobiles.";
const keywords = "garage auto, centres auto, comparateur, entretien automobile, maintenance, réparations auto, prix, avis, économies, révision, changement d’amortisseurs, kit de distribution";
const robots = "index, follow";

const RegionsPage: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Head title={title} description={description} keywords={keywords} robots={robots} />
      <BookingSection >
        <AboutVendorsSection />
        <RegionsSection />
      </BookingSection>

    </>
  )
}

export default RegionsPage;
