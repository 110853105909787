import { Box, Container, Grid, Image, Text, VStack } from "@chakra-ui/react";
import { motion, useAnimation } from "framer-motion";
import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import about1 from '../../../../../../assets/V2/carsharing/about1-sharing.webp'
import about2 from '../../../../../../assets/V2/carsharing/about2-sharing.webp'
import about3 from '../../../../../../assets/V2/carsharing/about3-sharing.webp'
import AvantageComp from "./AvantageComp";
const MotionBox = motion(Box);

const avantages = [
    {
        img:about1,
        title:"Optimisez vos dépenses en transport",
        desc:"Dadycar Autopartage rationalise vos dépenses en transport en favorisant l'utilisation partagée des véhicules au sein de votre entreprise"
    },
    {
        img:about2,
        title:"Favorisez la mobilité et le bien-être de vos collaborateurs",
        desc:"Accédez à un réseau de véhicules facilement disponibles pour les déplacements professionnels, simplifiant leur quotidien et boostant leur productivité."
    },
    {
        img:about3,
        title:"Tirez le meilleur parti de votre flotte automobile",
        desc:"Notre plateforme intelligente permet une utilisation partagée efficace des voitures, maximisant ainsi leur valeur et réduisant les coûts d'entretien."
    },

]

const Avantage = () => {
    const { ref, inView } = useInView({threshold:0.1});
  const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        if (inView) {
        setIsVisible(true);
        }
    }, [inView]);


  return (
    <Box  pb={20} pt={10} bg='white'>
         <Container maxW={'90%'} w={{ lg: '1200px' }}>
            <MotionBox ref={ref} 
                initial={{ scale: 0.8, opacity: 0 }}
                animate={{ scale: inView ? 1 : 0.8, opacity: inView ? 1 : 0 }}
                transition={{ duration: 0.5 }}>
                    <VStack align={'start'}>
                        <Text fontSize={{base:'18px',sm:'20px'}} color={'#0F82EA'} fontWeight={600}>Les avantages de l’autopartage en entreprise</Text>
                        <Text fontSize={{base:'22px',sm:'24px',md:'26px',lg:'28px'}} color={'#0D121F'} fontWeight={400}>Au-delà d’un service innovant pour vos collaborateurs, l’autopartage en entreprise c’est aussi l’occasion de profiter d’un panel d’avantages qui vous accompagneront dans votre démarche vers une mobilité plus durable.</Text>
                    </VStack>
            </MotionBox>
            
            <Grid mt={6} gap={4} templateColumns={{base:'repeat(1,1fr)',md:'repeat(2,1fr)',lg:'repeat(3,1fr)'}} >
                {avantages?.map((a,index)=>(
                    <AvantageComp img={a.img} title={a.title} desc={a.desc} index={index} />
                ))}
            </Grid>
         </Container>
    </Box>
  )
}

export default Avantage