import { Box, Heading, ListItem, OrderedList, Text, UnorderedList } from '@chakra-ui/react'
import SectionHeader from '../../../components/sections/SectionHeader'

const PrivacyPolicySection = () => {
    return (
        <>
            <Box mt={12} bg={'#F6FAFC'} py={12}>
                <SectionHeader title={`Politique de confidentialité`} />
                <Box p={5}>
                    <Heading color={'#1385EB'}>Politique de confidentialité de Dadycar</Heading>
                    <br />
                    <OrderedList>
                        <ListItem py={2} color={'#1385EB'} fontSize={'lg'} fontWeight={'bold'}>
                            INTRODUCTION
                            <Box px={5} color={'#1d1d1d'} fontSize={'md'} fontWeight={'normal'}>
                                <Text>Bienvenue chez Dadycar !</Text>
                                <br />
                                <Text>
                                    Si vous utilisez le service Dadycar par le biais de notre application (que nous appellerons notre "App" en abrégé) ou de notre site Web http://www.Dadycar.fr (que nous appellerons notre "Site Web" en abrégé), ceux-ci vous sont proposés par Dadycar dont le siège social est situé 36 Rue Raspail, 92300 Levallois-Perret , et dont le numéro d'entreprise est Nanterre  B 920955366("nous", "notre" ou "nos").
                                </Text>
                                <br />
                                <Text>
                                    La présente politique de confidentialité (que nous appellerons " Politique de confidentialité "), ainsi que nos conditions d'utilisation (que nous appellerons " Conditions " en abrégé) définissent des informations importantes sur la manière dont nous recueillons et utilisons vos informations lorsque vous naviguez ou utilisez le service Dadycar (ou " Service ") par le biais du site Web ou de l'application (ou " Service "). Dans la présente Politique de confidentialité, lorsque nous faisons référence à " vous " et " votre ", il s'agit de vous, la personne qui utilise ou visite notre Site Internet et notre App ou qui effectue des achats chez nous.
                                </Text>
                                <br />
                                <Text>
                                    Si vous êtes âgé de moins de 18 ans, veuillez ne pas utiliser le Service. En utilisant le Service, vous nous confirmez que vous êtes âgé de 18 ans ou plus.
                                </Text>
                                <br />
                                <Text>
                                    En visitant ou en utilisant l'App ou le Site Web, en effectuant des achats avec nous ou en interagissant autrement avec nous, vous consentez à ce que nous traitions vos données personnelles conformément à la présente Politique de confidentialité.
                                </Text>
                            </Box>
                        </ListItem>
                        <ListItem py={2} color={'#1385EB'} fontSize={'lg'} fontWeight={'bold'}>
                            INFORMATIONS QUE NOUS POUVONS RECUEILLIR À VOTRE SUJET
                            <Box px={5} color={'#1d1d1d'} fontSize={'md'} fontWeight={'normal'}>
                                <Text>
                                    Lorsque nous recueillons des informations auprès de vous ou à votre sujet, nous devons identifier la base légale (la raison légale) de cette collecte. Il peut s'agir de l'une des six bases légales suivantes : Consentement, contrat, obligation légale, protection des intérêts vitaux, mission publique ou intérêt légitime. Nous indiquons dans chacune des sections suivantes quelle base légale s'applique.
                                </Text>
                                <br />
                                <Text>
                                    Informations que vous nous fournissez :
                                </Text>
                                <br />
                                <Text>
                                    Nous collecterons toute information que vous nous fournissez lorsque vous : (i) téléchargez l'application à partir d'une place de marché électronique, (ii) accédez à l'application ou au site web ou les utilisez, (iii) créez un compte chez nous via l'application ou le site web ou vous connectez via un site de réseau social, (iv) passez une commande chez nous via l'application ou le site web, (v) participez à l'une de nos promotions ou enquêtes, ou (vi) vous inscrivez à notre liste de diffusion. Ces informations peuvent inclure votre nom, vos adresses, votre adresse électronique, votre numéro de téléphone, votre nom d'utilisateur, votre mot de passe, votre image (par le biais d'une photographie que vous téléchargez sur votre compte de profil), votre profil de réseau social, votre localisation, l'immatriculation de votre voiture, les détails de votre voiture, les informations du contrôle technique, l'historique de l'entretien de la voiture, les informations fiscales, les informations de paiement et d'autres informations relatives à l'achat que vous effectuez ou au compte pour lequel vous vous inscrivez.
                                </Text>
                                <br />
                                <Text>
                                    Nous recueillons ces informations avec votre consentement, ce qui constitue la base légale identifiée. Lorsque vous consentez à ce que nous recueillions et utilisions vos informations, vous avez la possibilité de retirer votre consentement à tout moment. Cela peut avoir un impact sur notre capacité à vous fournir certains services ou informations. Si vous souhaitez retirer votre consentement, vous pouvez le faire en nous contactant à l'adresse contact@Dadycar.fr ou au numéro 01 76 44 05 11.
                                </Text>
                                <br />
                                <Text>
                                    Informations que nous recueillons à votre sujet :
                                </Text>
                                <br />
                                <Text>
                                    Nous collecterons toute information contenue dans toute correspondance entre vous et nous. Par exemple, si vous nous contactez via la fonctionnalité de chat de l'application ou du site Web, si vous nous signalez un problème par e-mail, téléphone ou courrier, nous pouvons conserver un enregistrement de cette correspondance. Ces informations sont collectées et enregistrées car nous avons un intérêt légitime à conserver les détails qui nous aideront à répondre à votre demande de contact ou à votre plainte.
                                </Text>
                                <br />
                                <Text>
                                    Nous pouvons également recueillir certaines informations sur vos visites et votre utilisation de notre site web et de l'application. Il peut s'agir de votre adresse IP, de votre emplacement géographique, des informations relatives à votre appareil, du type de navigateur, de la source de référence, de la durée de la visite (y compris la date et l'heure), du système d'exploitation, du nombre de pages consultées, des Services que vous avez consultés ou recherchés, des devis que vous avez demandés, des achats que vous avez effectués et d'informations similaires. Ces informations peuvent être collectées par un fournisseur tiers de services d'analyse de sites Web en notre nom et/ou peuvent être collectées à l'aide de cookies. Pour plus d'informations sur les cookies, veuillez vous référer au paragraphe 6 ci-dessous.
                                </Text>
                                <br />
                                <Text>
                                    Nous avons un intérêt légitime à collecter ces informations car cela nous permet de vous présenter des informations actualisées et de nous assurer que notre site web répond aux besoins de nos clients.
                                </Text>
                                <br />
                                <Text>
                                    Informations que nous recevons de tiers :
                                </Text>
                                <br />
                                <Text>
                                    WNous pouvons recueillir des informations vous concernant auprès de tiers avec lesquels nous travaillons en étroite collaboration dans le but de vous fournir les services que vous nous demandez. Par exemple, les garages qui vous fourniront les services en notre nom, Auto Data Guru Limited qui nous aide à fournir des informations automobiles dans le cadre des services que nous vous fournissons.
                                </Text>
                                <br />
                                <Text>
                                    En recueillant des informations auprès des tiers avec lesquels nous travaillons, nous pouvons remplir des obligations contractuelles tant avec les tiers qu'avec vous. Cela relève de la base légale du contrat et de l'exécution d'un contrat.
                                </Text>
                                <br />
                                <Text>
                                    Nous pouvons également engager des tiers pour exploiter des sites web en notre nom ; ces entités peuvent collecter des informations directement auprès de vous concernant vos visites sur le Site en notre nom. Nous passons des contrats avec ces parties et, dans le cadre de l'exécution de ces contrats, nous leur demandons de recueillir certaines informations et de les partager avec nous dans le cadre de ce contrat.
                                </Text>
                                <br />
                                <Text>
                                    Nous pouvons également recevoir des informations vous concernant de la part de tiers lorsque vous avez consenti à ce que ces tiers partagent vos informations avec nous à des fins spécifiques. Par exemple, nous pouvons recevoir des informations vous concernant de la part de tiers si vous avez indiqué à ce tiers que vous souhaitiez recevoir de notre part de plus amples informations sur nos services. Là encore, lorsque vous donnez votre accord pour un tel contact ou partage, votre consentement constitue la base légale du traitement de ces informations. Comme précédemment, vous avez le droit de retirer votre consentement et pouvez le faire en nous contactant à l'adresse contact@Dadycar.fr ou au numéro 01 76 44 05 11.
                                </Text>
                            </Box>
                        </ListItem>
                        <ListItem py={2} color={'#1385EB'} fontSize={'lg'} fontWeight={'bold'}>
                            COMMENT NOUS UTILISONS LES INFORMATIONS VOUS CONCERNANT
                            <Box px={5} color={'#1d1d1d'} fontSize={'md'} fontWeight={'normal'}>
                                <Text>
                                    Vous acceptez que nous puissions utiliser vos informations aux fins suivantes :
                                </Text>
                                <UnorderedList px={5}>
                                    <ListItem py={1}>
                                        Pour vous fournir l'accès à notre Site Internet et à l'App, pour vous fournir les Services que vous avez achetés toute autre information que vous avez pu nous demander ;
                                    </ListItem>
                                    <ListItem py={1}>
                                        Pour vous contacter au sujet de la collecte, de la livraison ou d'autres informations concernant les Services que vous avez commandés ou achetés ;
                                    </ListItem>
                                    <ListItem py={1}>
                                        Pour rester en contact avec vous au sujet d'autres produits ou services que nous pensons pouvoir vous intéresser, à condition que vous ayez déjà été client et que vous ne refusiez pas de recevoir ces communications ;
                                    </ListItem>
                                    <ListItem py={1}>
                                        Pour vous fournir des informations sur nos produits ou services qui, selon nous, sont susceptibles de vous intéresser, si vous avez choisi (donné votre consentement) de recevoir ces communications ;
                                    </ListItem>
                                    <ListItem py={1}>
                                        Lorsque vous nous y autorisez, partager vos informations avec d'autres tiers sélectionnés, afin de leur permettre de vous contacter pour vous communiquer des informations sur des biens ou des services susceptibles de vous intéresser ;
                                    </ListItem>
                                    <ListItem py={1}>
                                        Pour fournir un service et une assistance à la clientèle, traiter les demandes de renseignements ou vous informer des changements relatifs aux services que vous avez commandés ou achetés ;
                                    </ListItem>
                                    <ListItem py={1}>
                                        Améliorer notre site Web et l'application et veiller à ce que le contenu soit présenté de la manière la plus efficace et optimale ;
                                    </ListItem>
                                    <ListItem py={1}>
                                        Pour effectuer des recherches agrégées et anonymisées sur l'engagement général des consommateurs envers nos Services ;
                                    </ListItem>
                                    <ListItem py={1}>
                                        Pour nous permettre de faire valoir nos droits légaux, ou pour protéger les droits, la propriété ou la sécurité de nos employés ; et
                                    </ListItem>
                                    <ListItem py={1}>
                                        À des fins de sécurité.
                                    </ListItem>
                                </UnorderedList>
                            </Box>
                        </ListItem>
                        <ListItem py={2} color={'#1385EB'} fontSize={'lg'} fontWeight={'bold'}>
                            AVEC QUI NOUS POUVONS PARTAGER VOS INFORMATIONS
                            <Box px={5} color={'#1d1d1d'} fontSize={'md'} fontWeight={'normal'}>
                                <Text>Nous partagerons vos informations personnelles pour les raisons suivantes :</Text>
                                <UnorderedList px={5}>
                                    <ListItem py={1}>
                                        Tout autre tiers sélectionné avec lequel nous travaillons lorsque cela est nécessaire pour vous fournir les services que vous avez achetés ou tout autre service que vous nous demandez, et nous partageons vos informations dans le cadre des obligations contractuelles avec les tiers sélectionnés qui vous fournissent des services ;
                                    </ListItem>
                                    <ListItem py={1}>
                                        Nous travaillons en étroite collaboration avec Stripe, qui nous aide à traiter les achats que vous effectuez chez nous. Nous devrons partager vos informations avec eux pour leur permettre d'effectuer des paiements. Ce partage de vos informations nous permet de remplir notre obligation légale de veiller à ce que les paiements soient effectués conformément aux réglementations financières applicables, y compris les réglementations fiscales ;
                                    </ListItem>
                                    <ListItem py={1}>
                                        Nous pouvons également engager d'autres sous-traitants ou prestataires de services tiers pour nous aider à héberger notre Site et notre App ou à gérer nos services par exemple ; Lorsque vous utilisez notre service, vous serez interrogé sur l'utilisation des données que ces sous-traitants tiers peuvent collecter par le biais des paramètres en ligne et vous pouvez choisir les paramètres par le biais de votre navigateur ;
                                    </ListItem>
                                    <ListItem py={1}>
                                        Tout autre tiers sélectionné avec lequel vous consentez à ce que nous partagions vos informations à des fins de marketing ;
                                    </ListItem>
                                    <ListItem py={1}>
                                        Tout vendeur ou acheteur potentiel de notre entreprise ou de nos actifs, dans le cas où nous déciderions de vendre ou d'acheter une entreprise ou des actifs, car cela fera partie de tout contrat convenu avec tout vendeur ou acheteur. Nous nous assurerons qu'un tel contrat inclut des garanties et des assurances pour la protection de vos informations personnelles dans le cadre de l'exécution dudit contrat. Pour toute vente de l'entreprise ou des actifs, vous pouvez exercer vos droits et obtenir des éclaircissements concernant la protection et le traitement de vos informations par l'acquéreur (acheteur) en le contactant directement. Nous vous fournirons les coordonnées de contact lorsque cela est applicable ;
                                    </ListItem>
                                    <ListItem py={1}>
                                        Tout autre tiers lorsque cela est nécessaire pour nous permettre de faire valoir nos droits légaux ou pour protéger les droits, la propriété ou la sécurité de nos employés ou lorsque cette divulgation peut être autorisée ou exigée par la loi.
                                    </ListItem>
                                </UnorderedList>
                            </Box>
                        </ListItem>
                        <ListItem py={2} color={'#1385EB'} fontSize={'lg'} fontWeight={'bold'}>
                            COOKIES
                            <Box px={5} color={'#1d1d1d'} fontSize={'md'} fontWeight={'normal'}>
                                <UnorderedList>
                                    <ListItem py={1}>
                                        Nous utilisons des cookies pour nous assurer que vous tirez le meilleur parti de notre Site Internet et de notre App. Les cookies sont de petites quantités d'informations sous forme de fichiers texte que nous stockons sur l'appareil que vous utilisez pour accéder au Site Internet et à l'App. Les cookies nous permettent de surveiller votre utilisation du logiciel et de simplifier votre utilisation du Site Internet et de l'App. Par exemple, un cookie temporaire est également utilisé pour garder une trace de votre "session". Sans ce cookie temporaire (qui n'est pas stocké après que vous ayez quitté votre navigateur), vous devriez vous connecter chaque fois que vous accédez à une nouvelle page.
                                    </ListItem>
                                    <ListItem py={1}>
                                        Si vous ne souhaitez pas que des cookies soient installés sur votre appareil, vous pouvez modifier les paramètres de votre navigateur ou de votre appareil pour rejeter les cookies. Pour plus d'informations sur la manière de rejeter les cookies en utilisant les paramètres de votre navigateur Internet, veuillez consulter la section "Aide" de votre navigateur Internet (ou visitez le site http://www.aboutcookies.org). Veuillez noter que, si vous configurez votre navigateur Internet pour qu'il rejette les cookies, il se peut que vous ne puissiez pas accéder à toutes les fonctions du site Web et que votre expérience soit moins satisfaisante sans utiliser de cookies.
                                    </ListItem>
                                    <ListItem py={1}>
                                        Les noms des cookies utilisés par le Site Internet et l'App et les objectifs pour lesquels ces cookies sont utilisés sont indiqués dans cette liste.
                                    </ListItem>
                                    <ListItem py={1}>
                                        Notre Site Internet peut également contenir du contenu et des liens vers d'autres sites exploités par des tiers qui peuvent également utiliser des cookies. Nous ne contrôlons pas ces sites ou cookies tiers et la présente Politique de confidentialité ne s'y applique pas. Veuillez consulter les conditions générales et la politique de confidentialité du site tiers concerné pour savoir comment ce site collecte et utilise vos informations et pour établir si et dans quel but il utilise des cookies.
                                    </ListItem>
                                    <ListItem py={1}>
                                        Pour plus d'informations, consultez notre Politique en matière de cookies.
                                    </ListItem>
                                </UnorderedList>
                            </Box>
                        </ListItem>
                        <ListItem py={2} color={'#1385EB'} fontSize={'lg'} fontWeight={'bold'}>
                            COMMENT NOUS PRENONS SOIN DE VOS INFORMATIONS ET COMBIEN DE TEMPS NOUS LES CONSERVONS
                            <Box px={5} color={'#1d1d1d'} fontSize={'md'} fontWeight={'normal'}>
                                <UnorderedList px={5}>
                                    <ListItem py={1}>
                                        La transmission d'informations sur Internet peut être peu sûre et, bien que nous employions des mesures pour protéger vos informations contre tout accès non autorisé, vous reconnaissez que nous ne pouvons pas toujours garantir la sécurité des informations envoyées sur Internet.
                                    </ListItem>
                                    <ListItem py={1}>
                                        Si vous nous fournissez des informations sur votre carte de crédit, celles-ci sont cryptées à l'aide de la technologie SSL (Secure Socket Layer) et stockées avec un cryptage AES-256. Les données de votre carte sont cryptées conformément à la norme de sécurité des données de l'industrie des cartes de paiement (PCI-DSS). Les exigences PCI-DSS permettent de garantir la sécurité du traitement des informations relatives aux cartes de crédit par notre fournisseur de services de paiement, Stripe, et ses fournisseurs de services. La norme PCI-DSS est gérée par le Conseil des normes de sécurité PCI, qui est un effort conjoint de marques comme Visa, MasterCard, American Express et Discover.
                                    </ListItem>
                                    <ListItem py={1}>
                                        Les données relatives à vos transactions d'achat ne sont conservées que le temps nécessaire à la réalisation de votre transaction d'achat. Une fois celle-ci terminée, les informations relatives à votre transaction d'achat sont supprimées.
                                    </ListItem>
                                    <ListItem py={1}>
                                        Nous conserverons vos informations aussi longtemps que nécessaire pour vous fournir les services que vous nous avez demandés ou aussi longtemps que la loi le permet. Nous disposons d'un calendrier de conservation qui décrit les périodes de conservation individuelles pour les différentes données que nous collectons. Par exemple, les informations financières seront conservées pendant 6 ans après l'exercice financier en cours afin de respecter les réglementations financières et fiscales (cela signifie que nous avons l'obligation légale de conserver ces informations). Contactez-nous à l'adresse contact@Dadycar.fr pour obtenir une copie du calendrier de conservation.
                                    </ListItem>
                                </UnorderedList>
                            </Box>
                        </ListItem>
                        <ListItem py={2} color={'#1385EB'} fontSize={'lg'} fontWeight={'bold'}>
                            OÙ NOUS STOCKONS VOS INFORMATIONS
                            <Box px={5} color={'#1d1d1d'} fontSize={'md'} fontWeight={'normal'}>
                                <Text>Nos serveurs sont des IONOS by 1&1  et toutes les informations que nous recueillons à votre sujet sont stockées sur ces serveurs.</Text>
                                <br />
                                <Text>
                                    Stockage et conservation de vos informations
                                </Text>
                                <br />
                                <Text>
                                    Il est important pour nous de conserver vos informations en toute sécurité. Nous disposons de politiques, de procédures et d'une sécurité technique à cet effet, et notre personnel et nos associés comprennent tous la protection et la confidentialité des données.
                                </Text>
                                <br />
                                <Text>
                                    Dans la mesure du possible, nous stockons vos informations en France. Toutefois, certains prestataires de services avec lesquels nous avons passé des contrats peuvent utiliser des installations de stockage ailleurs, notamment aux États-Unis et dans des pays situés en dehors de l'UE/EEE.
                                </Text>
                                <br />
                                <Text>
                                    Nous nous assurerons que des contrats et des licences sont en place pour tous les prestataires de services utilisés et que nous avons entrepris des vérifications des clauses contractuelles. Il s'agit notamment de s'assurer que tout autre pays a mis en place des niveaux de protection adéquats pour les données personnelles, que le pays dispose d'un accord britannique connu sous le nom de "décision d'adéquation", ou que toute disposition est équivalente à la protection offerte par la législation britannique.
                                </Text>
                            </Box>
                        </ListItem>
                        <ListItem py={2} color={'#1385EB'} fontSize={'lg'} fontWeight={'bold'}>
                            SITES WEB ET SERVICES TIERS
                            <Box px={5} color={'#1d1d1d'} fontSize={'md'} fontWeight={'normal'}>
                                <UnorderedList px={5}>
                                    <ListItem py={1}>
                                        L'application et le site peuvent contenir du contenu provenant de divers sites web et des liens vers ceux-ci. La présente politique de confidentialité ne s'applique pas aux informations personnelles recueillies par ces sites web. Veuillez consulter les conditions générales et la politique de confidentialité de ces sites web pour savoir comment ils collectent et utilisent vos informations personnelles et pour établir à quelles fins ils peuvent utiliser vos informations personnelles.
                                    </ListItem>
                                    <ListItem py={1}>
                                        En général, les fournisseurs tiers auxquels nous faisons appel ne collectent, n'utilisent et ne divulguent vos informations que dans la mesure nécessaire pour leur permettre d'exécuter les services qu'ils nous fournissent.
                                    </ListItem>
                                    <ListItem py={1}>
                                        Toutefois, certains fournisseurs de services tiers, tels que les passerelles de paiement et autres processeurs de transactions de paiement, ont leurs propres politiques de confidentialité en ce qui concerne les informations que nous sommes tenus de leur fournir pour vos transactions d'achat. Pour ces fournisseurs, nous vous recommandons de lire leurs politiques de confidentialité afin de comprendre la manière dont vos informations personnelles seront traitées par ces fournisseurs.
                                    </ListItem>
                                    <ListItem py={1}>
                                        En particulier, n'oubliez pas que certains fournisseurs peuvent être situés ou avoir des installations situées dans une juridiction différente de la vôtre ou de la nôtre. Ainsi, si vous choisissez de procéder à une transaction qui implique les services d'un prestataire de services tiers, vos informations peuvent être soumises aux lois de la ou des juridictions dans lesquelles ce prestataire de services ou ses installations sont situés.
                                    </ListItem>
                                    <ListItem py={1}>
                                        Dès que vous quittez notre application ou notre site ou que vous êtes redirigé vers un site Web ou une application tiers, vous n'êtes plus régi par la présente convention.
                                    </ListItem>
                                </UnorderedList>
                            </Box>
                        </ListItem>
                        <ListItem py={2} color={'#1385EB'} fontSize={'lg'} fontWeight={'bold'}>
                            VOS DROITS SUR LES INFORMATIONS QUE NOUS DÉTENONS À VOTRE SUJET
                            <Box px={5} color={'#1d1d1d'} fontSize={'md'} fontWeight={'normal'}>
                                <Text>
                                    Vous disposez de certains droits concernant les informations que nous détenons à votre sujet. Ce sont :
                                </Text>
                                <UnorderedList px={5}>
                                    <ListItem py={1}>
                                        Le droit d'être informé du traitement de vos informations personnelles (c'est pourquoi nous fournissons cette politique de confidentialité) ;
                                    </ListItem>
                                    <ListItem py={1}>
                                        Le droit de demander l'accès aux informations que nous détenons à votre sujet ;
                                    </ListItem>
                                    <ListItem py={1}>
                                        Le droit de nous demander de rectifier toute information incorrecte que nous détenons à votre sujet ;
                                    </ListItem>
                                    <ListItem py={1}>
                                        Le droit de nous demander d'effacer vos données personnelles (également appelé droit à l'oubli) ;
                                    </ListItem>
                                    <ListItem py={1}>
                                        Le droit de restreindre le traitement dans certaines circonstances ;
                                    </ListItem>
                                    <ListItem py={1}>
                                        Le droit à la portabilité des données lorsque vous avez fourni des informations avec votre consentement et que nous les traitons automatiquement ;
                                    </ListItem>
                                    <ListItem py={1}>
                                        Le droit de s'opposer au traitement et, en particulier, le droit de s'opposer à l'utilisation de vos informations personnelles à des fins de marketing ;
                                    </ListItem>
                                    <ListItem py={1}>
                                        Le droit de ne pas être soumis à une prise de décision et à un profilage automatisés ;
                                    </ListItem>
                                    <ListItem py={1}>
                                        Nous l'avons déjà mentionné, mais lorsque vous fournissez vos informations avec votre consentement, vous pouvez le retirer à tout moment en nous contactant à contact@Dadycar.fr
                                    </ListItem>
                                </UnorderedList>
                                <br />
                                <Text>
                                    Vous pouvez exercer les droits susmentionnés en nous contactant aux coordonnées indiquées dans la présente déclaration de confidentialité ou, dans le cas d'une opposition à nos activités de marketing, en cochant certaines cases des formulaires que nous utilisons pour collecter vos données afin de nous indiquer que vous ne souhaitez pas être impliqué dans le marketing.
                                </Text>
                                <br />
                                <Text>
                                    Lorsque vous exercez vos droits, y compris l'accès à vos informations, nous ne facturons généralement pas de frais. Nous n'envisagerons de facturer des frais que si vous demandez une deuxième copie de données que nous vous avons déjà fournies, ou si votre demande est répétée et qu'un délai raisonnable ne s'est pas écoulé entre deux demandes, ou si nous considérons que votre demande est infondée (par exemple, elle n'a pas de raison réelle) ou qu'elle est excessive. Si une redevance doit être versée, nous sommes autorisés à facturer une redevance raisonnable et nous vous en informerons avant de poursuivre votre demande. Nous pouvons également refuser votre demande si elle est infondée, répétée ou excessive, mais nous vous expliquerons nos raisons dans ce cas.
                                </Text>
                                <br />
                                <Text>
                                    Vous pouvez obtenir plus d'informations sur ces droits et d'autres informations sur le site Web de l'Information Commissioner's Office à l'adresse www.ico.org.uk ou en nous contactant aux coordonnées indiquées dans la présente déclaration de confidentialité.
                                </Text>
                            </Box>
                        </ListItem>
                        <ListItem py={2} color={'#1385EB'} fontSize={'lg'} fontWeight={'bold'}>
                            VOS DROITS SUR LES INFORMATIONS QUE NOUS DÉTENONS À VOTRE SUJET
                            <Box px={5} color={'#1d1d1d'} fontSize={'md'} fontWeight={'normal'}>
                                <Text>Nous pouvons apporter des modifications à la présente Politique de confidentialité de temps à autre. Nous publierons toute modification sur notre site Web et sur l'application ou nous vous informerons de toute modification importante par e-mail. Toute modification entrera en vigueur la prochaine fois que vous vous engagerez avec nous après que les modifications ont été notifiées.</Text>
                            </Box>
                        </ListItem>
                        <ListItem py={2} color={'#1385EB'} fontSize={'lg'} fontWeight={'bold'}>
                            CESSION ET DROITS DES TIERS
                            <Box px={5} color={'#1d1d1d'} fontSize={'md'} fontWeight={'normal'}>
                                <Text>
                                Sous réserve du paragraphe ci-dessous, personne d'autre qu'une partie à la présente Politique de Confidentialité n'aura le droit d'appliquer l'un quelconque de ses termes.
                                </Text>
                                <br/>
                                <Text>
                                Nonobstant tout autre terme de cette Politique de Confidentialité, si à tout moment nous souhaitons nommer une autre personne (la Nouvelle Entité du Licencié) à notre place pour les besoins de cette Politique de Confidentialité :
                                </Text>
                                <br/>
                                <UnorderedList px={5}>
                                    <ListItem py={1}>
                                    Nous vous adresserons une notification écrite vous informant de ce fait et de la date (la Date de la Nouvelle Entité) à laquelle la nomination de la Nouvelle Entité Concédante doit prendre effet ;
                                    </ListItem>
                                    <ListItem py={1}>
                                    Nous ferons en sorte que la Nouvelle Entité Concédante vous fournisse, au plus tard à la Date de la Nouvelle Entité, une confirmation écrite que la Nouvelle Entité Concédante assumera nos obligations et responsabilités en vertu de la présente Politique de Confidentialité à compter de la Date de la Nouvelle Entité ;
                                    </ListItem>
                                    <ListItem py={1}>
                                    La Nouvelle Entité Concédante deviendra partie à la présente Politique de Confidentialité à notre place avec effet à la Date de la Nouvelle Entité et aura droit, à partir de cette date, à tous nos droits, recours et avantages en vertu de la présente Politique de Confidentialité et sera tenue d'exécuter ou de s'acquitter de toutes nos obligations et responsabilités en cours en vertu de la présente Politique de Confidentialité et toutes les références à nous dans la présente Politique de Confidentialité seront lues et interprétées comme si elles faisaient plutôt référence à la Nouvelle Entité Concédante comme si, dans chaque cas, la Nouvelle Entité Concédante avait toujours été partie à la présente Politique de Confidentialité en tant que partie contractante initiale ; et
                                    </ListItem>
                                    <ListItem py={1}>
                                    A compter de la Date de la Nouvelle Entité, nous serons inconditionnellement et irrévocablement libérés de toutes les obligations et responsabilités en vertu de la présente Politique de Confidentialité survenant à partir de la Date de la Nouvelle Entité.
                                    </ListItem>
                                </UnorderedList>
                            </Box>
                        </ListItem>
                    </OrderedList>
                </Box >
            </Box >
        </>
    )
}

export default PrivacyPolicySection