import React, { useState, useCallback } from 'react'
import { GoogleMap, InfoWindow, Marker, useJsApiLoader } from '@react-google-maps/api';
import { VendorType } from '../../store/slices/vendor-slice';
import { Box, Heading } from '@chakra-ui/react';

const containerStyle = {
    width: '100%',
    height: '100%'
};

// const key = process.env.REACT_APP_GOOGLE_MAP_KEY!;
const key = 'AIzaSyAb-BWFEW91txKjDdja7vKhMWQyXseMtGw';

const VendorsMap: React.FC<{ vendors?: VendorType[], center: { lat: number, lng: number } }> = (props) => {
    const center = {
        lat: +props.center.lat,
        lng: +props.center.lng
    };

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: key
    })

    const [map, setMap] = useState<google.maps.Map | null>(null)

    const onLoad = useCallback(function callback(map: google.maps.Map) {
        setMap(map)
    }, [])

    const onUnmount = useCallback(function callback(map: google.maps.Map) {
        setMap(null)
    }, [])

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={10}
            onLoad={onLoad}
            onUnmount={onUnmount}
        >
            <>
                {props.vendors?.map((vendor, index) => (
                    <>
                        {(vendor.lat && vendor.lng) &&
                            <>
                                <Marker key={index} position={{ lat: vendor.lat, lng: vendor.lng }} />
                            </>
                        }
                    </>
                ))}
            </>
        </GoogleMap>
    ) : <></>
}


export default React.memo(VendorsMap);