import { createSlice } from '@reduxjs/toolkit';

type DistanceType = {
  forwardDistance: number,
  backDistance: number,
  forwardPrice: number,
  backPrice: number,
  totalDistance: number,
  totalPrice: number,
};

type SliceState = { value: DistanceType, conveyingDistance: DistanceType };

const initialState: SliceState = {
  value: {
    forwardDistance: 0,
    backDistance: 0,
    forwardPrice: 0,
    backPrice: 0,
    totalDistance: 0,
    totalPrice: 0,
  },
  conveyingDistance: {
    forwardDistance: 0,
    backDistance: 0,
    forwardPrice: 0,
    backPrice: 0,
    totalDistance: 0,
    totalPrice: 0,
  }
}

const distanceSlice = createSlice({
  name: 'distance',
  initialState,
  reducers: {
    updateValue(state, action) {
      state.value = action.payload;
    },
    updateConveyingDistance(state, action) {
      state.conveyingDistance = action.payload;
    },
  },
});

export { type DistanceType };
export const distanceActions = distanceSlice.actions;
export default distanceSlice.reducer;