import { Box, Container, Flex, Image, Text } from '@chakra-ui/react'
import reduction from '../../../../../../assets/V2/prestation/reduction.webp'
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer'
import { useEffect, useState } from 'react';

const MotionBox = motion(Box);

const Reduction = () => {
  const [isVisible, setIsVisible] = useState(false);
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) {
      setIsVisible(true);
    }
  }, [inView]);
  return (
    <MotionBox
        ref={ref}
        initial={{ x: 200, opacity: 0 }}
        animate={{ x: inView ? 0 : 200, opacity: inView ? 1 : 0 }}
        transition={{ duration: 0.5 }}
    >
      <Container maxW={'90%'} w={{ lg: '1200px' }}>
        <Box py={8}>
            <Flex flexDirection={{base:'column',lg:'row'}} gap={{base:4,lg:10}} justifyContent='space-between' alignItems={{xl:'center'}}  >
                <Box flex={1} >
                    <Image src={reduction} h='100%' objectFit={'fill'} />
                </Box>
                <Flex flexDirection={'column'} gap={4} flex={1} >
                    <Text fontSize={{base:'30px',sm:'35px',md:'40px'}} fontWeight={700} color={'#0D121F'} >Réduction des coûts d'entretien</Text>
                    <Text fontSize={{base:'18px',sm:'20px',md:'22px'}} color={'#222D50'}><Text as='span' color={'#0F82EA'} fontWeight={600}>Maîtrisez vos dépenses : </Text>Profitez de prix compétitifs et de forfaits d'entretien préventifs pour réduire vos coûts d'entretien à long terme et optimiser votre budget flotte.</Text>
                    <Text fontSize={{base:'18px',sm:'20px',md:'22px'}} color={'#222D50'}><Text as='span' color={'#0F82EA'} fontWeight={600}>Prévenez les pannes : </Text>Des entretiens réguliers et des réparations anticipées permettent d'éviter les pannes majeures et les dépenses imprévues, contribuant ainsi à une gestion de flotte rentable.</Text>
                </Flex>
            </Flex>
        </Box>
      </Container>
    </MotionBox>
  )
}

export default Reduction