import { Button } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/index";
import { useState, useEffect } from "react";
import VendorViewModel from "../../../../view-models/VendorViewModel";
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const SubmitButton: React.FC<{ text: string }> = (props) => {
    const [isDisabled, setIsDisabled] = useState(false);
    const selectedVersion = useSelector((state: RootState) => state.version.selected);
    const departureAddress = useSelector((state: RootState) => state.address.departureAddress);
    const selectedSubService = useSelector((state: RootState) => state.service.selectedSubService);
    const vehicle = useSelector((state: RootState) => state.vehicle.value);
    const vendorViewModel = new VendorViewModel();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if ((selectedVersion || vehicle) && departureAddress && selectedSubService)
            setIsDisabled(false);
        else
            setIsDisabled(true);
    }, [selectedVersion, departureAddress, selectedSubService, vehicle]);

    const HandleSubmit = () => {
        if ((selectedVersion || vehicle) && departureAddress && selectedSubService) {
            const selectedVehicle = vehicle ? vehicle : selectedVersion;
            vendorViewModel.getVendorsWithFilter(selectedVehicle!, departureAddress, selectedSubService);
        }

        if (location.pathname !== '/garages/recherche') {
            navigate('/garages/recherche', { replace: true });
        }
    }

    return (
        <>
            <Button
                bg={'#252525'}
                color={'white'}
                borderRadius={'2xl'}
                w={'100%'}
                fontSize={'sm'}
                h={'100%'} px={4} py={3}
                _hover={{ bg: '#5a5959' }}
                onClick={HandleSubmit}
                isDisabled={isDisabled}
            >
                {props.text}
            </Button>
        </>
    );
}

export default SubmitButton;