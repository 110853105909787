import { createSlice } from '@reduxjs/toolkit';

type VehicleType = {
  id?: number;
  plateNumber: string,
  mark?: string,
  model?: string,
  version?: string,
  type?: string,
  isSearched?: boolean
};
type SliceState = { value: VehicleType | null };

const initialState: SliceState = { value: null }

const vehicleSlice = createSlice({
  name: 'vehicle',
  initialState,
  reducers: {
    updateValue(state, action) {
      state.value = action.payload;
    },
    removeVehicle(state) {
      state.value = null;
    }
  },
});


export { type VehicleType };
export const vehicleActions = vehicleSlice.actions;
export default vehicleSlice.reducer;