import { createSlice } from '@reduxjs/toolkit';
import { ConveyingType } from '../../models/Conveying';

type SliceState = { list: ConveyingType[] | null, value: ConveyingType | null, clientSecret: string | null };

const initialState: SliceState = { list: null, value: null, clientSecret: null };

const conveyingSlice = createSlice({
    name: 'conveying',
    initialState,
    reducers: {
        updateList(state, action) {
            state.list = action.payload;
        },
        updateValue(state, action) {
            state.value = action.payload;
        },
        updateClientSecret(state, action) {
            state.clientSecret = action.payload;
        }
    },
});

export const conveyingActions = conveyingSlice.actions;
export default conveyingSlice.reducer;