import { Box, Button, Center, Image, Link, Spinner, Stack, Text } from '@chakra-ui/react';
import { BsArrowRight } from 'react-icons/bs';
import logo from '../../assets/images/dadycar-logo.webp';
import { Form, Formik, FormikHelpers } from 'formik';
import InputField from './fields/InputField';
import * as yup from 'yup';
import { useState } from 'react';
import { VendorLoginType } from '../../models/Vendor';
import HttpClient from '../../services/HttpClient';
import VendorViewModel from '../../view-models/VendorViewModel';

const loginSchemaValidation = yup.object({
  email: yup.string().required("Veuillez insérer votre email.").email('Veuillez insérer un e-mail valide.'),
  password: yup.string().required('Veuillez insérer votre mot de passe.')
})

const VendorLoginForm: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const loginVendor = (new VendorViewModel()).login;

  const onSubmitHandler = async (values: VendorLoginType) => {
    setIsLoading(true);
    const response = await loginVendor(values);

    if (response.status === 'success' && response.eu_support)
      window.location.href = HttpClient.host + 'garage-eu/appointment/list';
    else if (response.status === 'success')
      window.location.href = HttpClient.host + 'garage';

    setIsLoading(false);
  }

  const redirectionToResetPassword = () => {
    window.location.href = HttpClient.host + 'garage/password/reset';
  }

  const redirectionToRegister = () => {
    window.location.href = HttpClient.host + 'garage/register';
  }

  return (
    <>
      <Box>
        <Stack align={'center'}>
          <Image src={logo} width={'100px'} />
        </Stack>
        <Stack spacing={4}>
          <Formik
            initialValues={{ email: '', password: '' }}
            validationSchema={loginSchemaValidation}
            onSubmit={(values: VendorLoginType, { setSubmitting }: FormikHelpers<VendorLoginType>) => {
              setTimeout(() => {
                onSubmitHandler(values);
                setSubmitting(false);
              }, 500);
            }}
          >
            <Form>
              <Stack spacing={4}>
                <InputField label="Adresse mail" name="email" type="email" placeholder="...@example.com" />
                <InputField label="Mot de passe" name="password" placeholder="Mot de passe" type="password" />
                <Button type="submit" borderRadius={'lg'} bg={'#5393EC'} color={'white'} _hover={{ bg: 'blue.500' }} disabled={isLoading}>
                  {isLoading ?
                    <Center>
                      <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='md' />
                    </Center>
                    :
                    <>
                      Se connecter
                      < BsArrowRight />
                    </>
                  }
                </Button>

              </Stack>
            </Form>
          </Formik>

          <Stack
            direction={{ base: 'column', sm: 'row' }}
            align={'start'}
            justify={'space-between'}>
            <Link color={'blue.400'} onClick={redirectionToResetPassword}>Mot de passe oublié ?</Link>
          </Stack>
          <Stack>
            <Text textAlign={'center'}>Vous n’avez pas de compte ?</Text>
          </Stack>
          <Stack>
            <Link onClick={redirectionToRegister}>
              <Button w={'100%'} borderRadius={'lg'} bg={'#07D0C1'} color={'white'} _hover={{ bg: '#07D0C1' }}>
                S’enregistrer
              </Button>
            </Link>
          </Stack>
        </Stack>
      </Box>
    </>
  )
}

export default VendorLoginForm;