import { Box, Image, Stack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { RootState } from "../../../store/index";
import Select, { InputActionMeta } from 'react-select';
import positionIcon from '../../../assets/images/dadycar-Icon-material-location-on.webp';
import AddressViewModel from '../../../view-models/AddressViewModel';
import { useEffect, useState } from 'react';
import { AddressType } from '../../../models/Address';

interface ChildProps {
    placeholder: string,
    address: AddressType | null,
    backgroundColor?: string,
    name?: string,
    selectAddress: (address: AddressType) => void
};

const SearchAddressAutocomplete: React.FC<ChildProps> = (props) => {
    const [value, setValue] = useState<AddressType | null>()

    const addressViewModel = new AddressViewModel();
    const getAddress = addressViewModel.getAddress;

    let addresses = useSelector((state: RootState) => state.address.list);
    addresses = addresses ? addresses : [];

    useEffect(() => {
        if (props.address) {
            setValue(props.address)
        }
    }, [props.address]);

    const onChangeHandler = (selected: any, actionMeta: any) => {
        if (actionMeta.action === 'select-option') {
            setValue(selected)
            props.selectAddress(selected);
        }
        else if (actionMeta.action === 'clear')
            setValue(null)

    };

    const onInputChangeHandler = (inputValue: string, { action }: InputActionMeta) => {
        if (action === 'input-change' && inputValue.length > 2)
            getAddress(inputValue)
    };

    return (
        <>
            <Stack direction={'row'} align={'center'} border={'1px'} borderColor={'#E8EAF1'} bg={props.backgroundColor ? props.backgroundColor : '#E8EAF1'}  borderRadius={'2xl'} pl={4} pr={1} py={1}>
                <Image h={'20px'} src={positionIcon} />
                <Box w={'100%'}>
                    <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isClearable={true}
                        isSearchable={true}
                        name={props.name ? props.name :"address"}
                        placeholder={props.placeholder}
                        options={addresses}
                        onChange={onChangeHandler}
                        onInputChange={onInputChangeHandler}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderColor: state.isFocused ? '#1A202' : '#E8EAF1',
                                border: '1px',
                                backgroundColor: props.backgroundColor ? props.backgroundColor : '#E8EAF1',
                            }),
                            dropdownIndicator: (provided) => ({
                                ...provided,
                                color: '#0F82EA', // Change color of dropdown indicator
                              }),
                        }}
                        value={value}
                    />
                </Box>
            </Stack>
        </>
    );
};

export default SearchAddressAutocomplete;
