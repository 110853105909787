import { Box, Center, Heading, Image, Link, Text } from "@chakra-ui/react";
import vendorsBg from '../../../../assets/images/dadycar-vendors-bg.webp';
import vendorsBg1 from '../../../../assets/images/dadycar-vendors-bg-md.webp';
import vendorsbg2 from '../../../../assets/images/dadycar-vendors-bg-sm.webp';
import { Search } from "../../../../components/sections/_index";

const BookingSection: React.FC<{ children: any }> = (props) => {
    return (
        <Box>
            <Box position={'absolute'} zIndex={-1} >
                <picture>
                    <source media="(min-width:48em)" srcSet={`${vendorsBg}`} />
                    <source media="(min-width:31em)" srcSet={`${vendorsBg1}`} />
                    <Image src={vendorsbg2} width="100vw"
                        backgroundRepeat={'no-repeat'}
                        backgroundSize={'contain'}
                        objectFit={'cover'} />
                </picture>
            </Box>
            <Box pt={32}>
                <Box py={{ lg: 7, sm: 10 }}>
                    <Heading color={'white'} textAlign={'center'}>Liste Des Garages</Heading>
                    <Center>
                        <Box>
                            <Text color={'white'} textAlign={'center'}>Nous offrons les meilleurs services aux tarifs compétitifs Voir tous les services</Text>
                            <Text textAlign={'center'} color={'white'}><Link color={'#0F82EA'}>Home</Link>/ Liste Des Garages</Text>
                        </Box>
                    </Center>
                </Box>
                
                <Box my={7} zIndex={2} position={{ base: 'relative', lg: 'sticky' }} top={0}>
                     {/* <Search text='Trouver le meilleur garage' /> */}
                </Box>
                <Box>
                    {props.children}
                </Box>
            </Box>
        </Box>
    )
}

export default BookingSection;