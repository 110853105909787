import { Container, SimpleGrid, Box, Spinner, Center } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { RegionViewModel } from "../../../../view-models/_index";
import RegionCard from "./RegionCard";

const RegionsSection: React.FC = () => {
    const getRegions = (new RegionViewModel).getRegions;
    const regions = useSelector((state: RootState) => state.region.list);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        const initRegions = async () => {
            setIsLoading(true);
            const response = await getRegions();
            if (response) {
                setIsLoading(false);
            }
        }
        initRegions();
    }, [])

    return (
        <>
            {
                isLoading ?
                    <>
                        <Center py={20} bg={'white'}>
                            <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
                        </Center>
                    </>
                    :
                    <>
                        {
                            regions &&
                            <>
                                <Box bg={'#F6FAFC'} py={8}>
                                    <Container
                                        maxW={'80%'}
                                        w={{ lg: '1000px' }}
                                        py={10}
                                        px={{ lg: 32 }}
                                        bg={{ lg: 'white' }}
                                        borderRadius={'2xl'}
                                        boxShadow={{ lg: 'md' }}>
                                        <Box py={6}>
                                            <SimpleGrid templateColumns={{ lg: '2fr 2fr 2fr' }} spacing={8}>
                                                {regions.map((region, index) => {
                                                    return <>
                                                        <RegionCard key={index} region={region} />
                                                    </>
                                                })}
                                            </SimpleGrid>
                                        </Box>
                                    </Container>
                                </Box>
                            </>
                        }
                    </>
            }
        </>
    )
}

export default RegionsSection;