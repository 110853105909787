import { Box, Container, Flex, Grid, Heading, Image, Text, VStack } from '@chakra-ui/react'
import card1 from '../../../../assets/V2/card1.webp'
import card2 from '../../../../assets/V2/card2.webp'
import card3 from '../../../../assets/V2/card3.webp'
import about_car from '../../../../assets/V2/about_car.png'
import about_car2 from '../../../../assets/V2/about_car2.png'
import about_maintenance from '../../../../assets/V2/about_maintenance.png'
import about_transport from '../../../../assets/V2/about_transport.png'
import about_suivi from '../../../../assets/V2/about_suivi.png'
import about_flotte from '../../../../assets/V2/about_flotte.png'
import feature_bg from '../../../../assets/V2/feature-bg.webp'
import { motion, useAnimation } from "framer-motion";
import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'

const data = [
    {
        logo:about_car,
        title:'Optimisation de Flotte',
        desc:'Réduisez les coûts et optimisez l’exploitation de votre flotte, tout en gagnant du temps.'
    },
    {
        logo:about_car2,
        title:'Services Étendus',
        desc:'Proposez plus de services à vos utilisateurs et générez plus de satisfactions utilisateurs.'
    },
    {
        logo:about_maintenance,
        title:'Maintenance Intelligente',
        desc:'Bénéficiez d’intervention immédiate pour l’entretien, la réparation de vos véhicules ainsi que la maintenance prédictive pour les véhicules connectés.'
    },
    {
        logo:about_transport,
        title:'Transport Simplifié',
        desc:'Déléguez le transport  de vos véhicules grâce à notre réseau de chauffeurs et partenaires.'
    },
    {
        logo:about_suivi,
        title:'Suivi en Temps Réel',
        desc:'Assurez la protection de vos véhicules et la sécurité de vos conducteurs avec notre suivi en temps réel.'
    },
    {
        logo:about_flotte,
        title:'Flotte Éco-Responsable',
        desc:'Passez au GREEN avec une flotte plus éco-responsable en connectant vos véhicules pour favoriser le car sharing entre employés.'
    }
]
const MotionBox = motion(Box);

const About = () => {
    const { ref, inView } = useInView({threshold:0.3});
  const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        if (inView) {
        setIsVisible(true);
        }
    }, [inView]);
  
  return (
    <Box pb={20} pt={10}  bg='white' position='relative' >
        <MotionBox
            ref={ref} initial={{ opacity: 0, scale: 1.1}}
            animate={{ opacity: inView ? 1 : 0, scale: inView ? 1 : 1.1 }}
            transition={{ duration: 0.5 }}
        >
            <Container maxW={'90%'} w={{ lg: '1200px' }}>
                <Text zIndex={100} mb={4} fontWeight='semibold' color='#0F82EA' >POURQUOI CHOISIR DADYCAR</Text>
                
                <Grid zIndex={100} mb={8} templateColumns={{base:'repeat(1, 1fr)',md:'repeat(3, 1fr)'}} gap={6} >
                    <Box>
                        <Heading as='h2'>
                            Intuitif, accessible et une multitude de services intégré
                        </Heading>
                    </Box>
                    <Box>
                        <Text color='#596780' fontSize='20px' >Pourquoi DadyCar est la solution de gestion de flotte que vous attendiez</Text>
                    </Box>
                </Grid>
                
                <Box backgroundImage={{base:'none',md:feature_bg}}
                    backgroundSize={'contain'}
                    backgroundRepeat={'no-repeat'}
                    backgroundPosition={'center'}      
                    >
                    <Grid zIndex={100} templateColumns={{base:'repeat(1,1fr)',md:'repeat(2,1fr)',lg:'repeat(3,1fr)'}} gap={6} >
                        {data?.map((item,index)=>(
                            <Box zIndex={100} key={index} bg='white' boxShadow='md' minH='300px' rounded='20px' >
                                <Box p={4} >
                                    <VStack alignItems='start' >
                                        <Image src={item.logo} w='86px' h='86px' />
                                        <VStack alignItems='start' >
                                            <Text fontSize='22px' fontWeight='bold' >{item.title}</Text>
                                            <Text fontSize='18px' color='#7A7D9C' >{item.desc}</Text>
                                        </VStack>
                                    </VStack>
                                </Box>
                            </Box>
                        ))}
                    </Grid>
                </Box>
            </Container>
        </MotionBox>
    </Box>
  )
}

export default About