import { useDispatch } from 'react-redux';
import { addressActions } from '../store/slices/address-slice';
import { Address } from '../models/_index';
import { AddressType } from '../models/Address';

class AddressViewModel {

    dispatch = useDispatch();

    public getAddress = async (address: string) => {
        const response = await Address.getAddress(address);
        if (response.status === 200 && response.data['features'].length > 0)
            this.dispatch(addressActions.updateList(response.data['features']));
    }

    public selectClientAddress = (address: AddressType) => {
        this.dispatch(addressActions.updateClientAddress(address));
    }

    public selectDepartureAddress = (address: AddressType) => {
        this.dispatch(addressActions.updateDepartureAddress(address));
    }

    public selectReturnAddress = (address: AddressType) => {
        this.dispatch(addressActions.updateReturnAddress(address));
    }

    public selectConveyingOwnerAddress = (address: AddressType) => {
        this.dispatch(addressActions.updateConveyingOwnerAddress(address));
    }

    public selectConveyingDepartureAddress = (address: AddressType) => {
        this.dispatch(addressActions.updateConveyingDepartureAddress(address));
    }

    public selectConveyingReturnAddress = (address: AddressType) => {
        this.dispatch(addressActions.updateConveyingReturnAddress(address));
    }

    public resetClientAddress = () => {
        this.dispatch(addressActions.resetClientAddress());
    }
}

export default AddressViewModel;