import { Box, Container, Flex, Grid, Image, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from "@chakra-ui/react"
import dady_services from '../../../../assets/V2/dady_services.png'
import { motion, useAnimation } from "framer-motion";
import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'

const MotionBox = motion(Box);
const DadyServices = () => {
    const [isVisible, setIsVisible] = useState(false);
    const { ref, inView } = useInView();
  
    useEffect(() => {
      if (inView) {
        setIsVisible(true);
      }
    }, [inView]);


  return (
    <Box bg='white'  pb={20} pt={10}>
        <MotionBox
            ref={ref}
            initial={{ x: -200, opacity: 0 }}
            animate={{ x: inView ? 0 : -200, opacity: inView ? 1 : 0 }}
            transition={{ duration: 0.5 }}
        >
            <Container maxW={'90%'} w={{ lg: '1200px' }}>
                <Grid templateColumns={{base:'repeat(1,1fr)',lg:'repeat(2,1fr)'}} >
                    <Box display={{base:'none',lg:'block'}} ></Box>
                    <Box>
                        <Text color='#0F82EA' >DadyCar Services</Text>
                        <Text fontSize={{base:"20px",sm:"30px",md:'40px'}} fontWeight={700}>Trouvez les meilleurs garages et suivez vos réparations grâce au service Dadycar</Text>
                    </Box>
                </Grid>
                <Flex flexDirection={{base:'column-reverse',lg:'row'}} mt={8} justifyContent='space-between'>
                    <Box flex={1} width='100%' h='450px' >
                        <Image src={dady_services} w='100%' h='100%' />
                    </Box>
                    <Box flex={1} >
                        <Tabs variant='soft-rounded'>
                            <TabList bg='#EDEDED' w='fit-content' p={0} rounded={30}>
                                <Tab px='24px' py='13px' color='gray.500' _selected={{ color: 'white',bg:'#0F84EA'}} fontSize={{base:10,sm:12,xl:16}}>Avec DadyCar</Tab>
                                <Tab px='24px' py='13px' color='gray.500' _selected={{ color: 'white',bg:'#0F82EA'}} fontSize={{base:10,sm:12,xl:16}}>Sans DadyCar</Tab>
                            </TabList>
                            <TabPanels>
                                <TabPanel>
                                    <Flex flexDirection='column' gap={1} >
                                        <Text color='black'><span style={{color:'#0F84EA'}} >Trouvez le garage idéal : </span> Recherchez par localisation, type de service, avis utilisateurs et prix pour trouver celui qui correspond parfaitement à vos besoins.</Text>
                                        <Text color='black'><span style={{color:'#0F84EA'}} >Tarification transparente : </span>Obtenez des devis clairs avant de réserver, sans frais cachés ni factures surprises.</Text>
                                        <Text color='black'><span style={{color:'#0F84EA'}} >Réservation simplifiée : </span>Prenez rendez-vous en ligne ou via l'application, pour gagner du temps et éviter les complications.</Text>
                                        <Text color='black'><span style={{color:'#0F84EA'}} >Suivi en direct : </span>Restez informé de l'avancement des réparations grâce à des mises à jour en temps réel et des estimations de fin de travaux. </Text>
                                        <Text color='black'><span style={{color:'#0F84EA'}} >Garantie de qualité : </span>Ayez confiance, vous faites appel à un réseau de garages sélectionnés et fiables.</Text>
                                        <Text color='black'><span style={{color:'#0F84EA'}} >Services supplémentaires : </span>Accédez au changement de pneus, aux diagnostics et à d'autres services essentiels pour votre voiture.</Text>
                                    </Flex>
                                </TabPanel>
                                <TabPanel>
                                    <Flex flexDirection='column' gap={1} >
                                        <Text color='black'><span style={{color:'#0F84EA'}} >Recherche interminable : </span> Passez des heures à chercher et comparer des garages, sans certitude sur leur qualité ou leurs tarifs.</Text>
                                        <Text color='black'><span style={{color:'#0F84EA'}} >Coûts cachés : </span>Des frais inattendus et des hausses de prix potentielles peuvent grever votre budget.</Text>
                                        <Text color='black'><span style={{color:'#0F84EA'}} >Manque de communication : </span> Le manque de transparence et de mises à jour peut entraîner frustration et sentiment d'être laissé à l'écart.</Text>
                                        <Text color='black'><span style={{color:'#0F84EA'}} >nefficacité potentielle : </span>Jongler entre les rendez-vous et gérer les réparations peut être chronophage et source de perturbation.</Text>
                                    </Flex>
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </Box>
                </Flex>
            </Container>
        </MotionBox>
    </Box>
  )
}

export default DadyServices