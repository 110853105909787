import { Box, Center, Heading, Image, Link, Text } from "@chakra-ui/react";
import ourServicesBg from '../../../../assets/images/dadycar-conveying-bg.webp';
import ourServicesBg1 from '../../../../assets/images/dadycar-conveying-bg-md.webp';
import ourServicesbg2 from '../../../../assets/images/dadycar-conveying-bg-sm.webp';
import { Search } from "../../../../components/sections/_index";

const LayoutContainer: React.FC = () => {
    return (
        <Box>
            <Box position={'absolute'} zIndex={-1} overflow={'initial'}>
                <picture>
                    <source media="(min-width:48em)" srcSet={`${ourServicesBg}`} />
                    <source media="(min-width:32em)" srcSet={`${ourServicesBg1}`} />
                    <Image src={ourServicesbg2} width="100vw"
                        backgroundRepeat={'no-repeat'}
                        backgroundSize={'contain'}
                        objectFit={'cover'} />
                </picture>
            </Box>
            <Box pt={32}>
                <Box py={{ lg: 7, sm: 10 }}>
                    <Heading color={'white'} textAlign={'center'}>Contact</Heading>
                    <Center>
                        <Box>
                            <Text color={'white'} textAlign={'center'}>
                                Vous avez des questions
                            </Text>
                            <Text textAlign={'center'} color={'white'}><Link color={'#0F82EA'}>Contact</Link>/ success</Text>
                        </Box>
                    </Center>
                </Box>
            </Box>
            <Box my={7} zIndex={2} position={{ base: 'relative', lg: 'sticky' }}>
                <Search text='Commander un dadycar' />
            </Box>
        </Box>
    )
}

export default LayoutContainer;