import { Box, Button, Container, FormLabel, Stack, Textarea, VStack } from '@chakra-ui/react'
import * as yup from 'yup';
import { Form, Formik, FormikHelpers } from 'formik';
import InputField from '../../../../components/forms/fields/InputField';
import { useEffect, useRef, useState } from 'react';
import { ContactViewModel } from '../../../../view-models/_index';
import { StoreContactType } from '../../../../models/Contact';
import { useNavigate } from 'react-router-dom';
import { toastActions } from '../../../../store/slices/toast-slice';
import { useAppDispatch } from '../../../../store';


const ContactSchemaValidation = yup.object({
    first_name: yup.string().required("Veuillez insérer votre nom.").matches(/^[A-Za-z ]*$/, 'Veuillez entrer un nom valide').min(2, 'Le nom doit contenir au moins 2 caractères').max(255, 'Le nom doit comporter au maximum 255 caractères'),
    last_name: yup.string().required("Veuillez insérer votre prénom.").matches(/^[A-Za-z ]*$/, 'Veuillez entrer un prénom valide').min(2, 'Le prénom doit contenir au moins 2 caractères').max(255, 'Le prénom doit comporter au maximum 255 caractères'),
    email: yup.string().required("Veuillez insérer votre email.").email('Veuillez insérer un e-mail valide.').max(255, 'Le nom doit comporter au maximum 255 caractères'),
    phone: yup.string().required("Veuillez insérer votre numéro de téléphone.").matches(/^[0-9]*$/, 'Veuillez entrer un numéro de téléphone valide').length(10, 'Le numéro de téléphone doit comporter 10 chiffres'),
    message:yup.string().optional()
    // message: yup.string().required("Veuillez insérer votre message.").matches(/^[A-Za-z ]*$/, 'Veuillez entrer un message valide').min(2, 'Le message doit contenir au moins 2 caractères').max(255, 'Le message doit comporter au maximum 255 caractères'),
})

const ContactForm = () => {
    const dispatch = useAppDispatch()
    const [isLoading, setIsLoading] = useState(false);
    const messageRef = useRef<HTMLTextAreaElement>(null);
    const storeContact = (new ContactViewModel()).store;

    const onSubmitHandler = async (values: StoreContactType,resetForm:any) => {
        setIsLoading(true);
        values.message = messageRef.current?.value!;
        if(messageRef.current?.value === ''){
            dispatch(toastActions.display({
                title: "Erreur",
                message: 'Le message doit contenir au moins 2 caractères',
                status: "error",
            }))
            setIsLoading(false)
            return null
        }
        const response = await storeContact(values);
        if (response.status === 'success') {
            setIsLoading(false);
            resetForm()
            messageRef.current!.value = ''
            console.log("done")
        } else{
            setIsLoading(false);
            console.log("error")
        }
    }

    useEffect(() => {
        // Add HubSpot tracking code dynamically
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.async = true;
        script.defer = true;
        script.src = `//${process.env.REACT_APP_HUBSPOT_TRACKING_CODE}`;
        document.head.appendChild(script);
        return () => {
          document.head.removeChild(script);
        };
      }, []);
  return (
    <Box flex={1}  w='100%' >
        <Formik
            initialValues={{ first_name: '', last_name: '', email: '', phone: '', message: '' }}
            validationSchema={ContactSchemaValidation}
            onSubmit={(values,{resetForm})=>{onSubmitHandler(values,resetForm)}}
        >
            <Form style={{width:'100%'}} >
                <VStack gap={3} alignItems='start' w='100%' >
                    <Stack direction={{base:'column',md:'row'}} w='100%'  >
                        <InputField  name="first_name" type="text" placeholder="Nom" bg={'#E8EAF1'} size={'lg'}/>
                        <InputField name="last_name" type="text" placeholder="Prénom" bg={'#E8EAF1'} size={'lg'}/>
                    </Stack>
                    <InputField  name="email" type="email" placeholder="Email" bg={'#E8EAF1'} size={'lg'}/>
                    <InputField  name="phone" type="phone" placeholder="Téléphone" bg={'#E8EAF1'} size={'lg'}/>
                    <FormLabel>Message</FormLabel>
                    <Textarea color={'black'} rows={5} name='message' ref={messageRef} placeholder='Entrer votre message ici' bg={'#E8EAF1'}/>
                    <Button isDisabled={isLoading} type='submit'  bg='#3563E9' _hover={{bg:'#0F82EA'}} rounded='30px' w='187px' h='52px' color='white' >Envoyer</Button>
                </VStack>
            </Form>
        </Formik>
    </Box>
  )
}

export default ContactForm