import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, ListItem, Text, UnorderedList } from '@chakra-ui/react'
import React from 'react'
import SectionHeader from '../../../components/sections/SectionHeader'

const QuestionsSection = () => {
  const questionAndAnswer = [
    {
      question: "Qu'est-ce que Dadycar et quel est son objectif principal ?",
      answer: "Dadycar est une entreprise innovante de maintenance automobile en ligne qui simplifie l'entretien des véhicules pour les conducteurs et les gestionnaires de flotte. Son objectif principal est de fournir des services de haute qualité sans que les utilisateurs aient à se rendre physiquement dans un garage.",
    },
    {
      question: "Comment fonctionne la plateforme Dadycar ?",
      answer: "La plateforme Dadycar permet aux utilisateurs de réserver facilement des services de maintenance automobile en ligne avec des professionnels de confiance sélectionnés pour leur expertise et leurs connaissances. Les chauffeurs professionnels viennent chercher et livrer le véhicule, ce qui rend l'entretien facile et pratique.",
    },
    {
      question: "Comment accéder aux services de maintenance automobile en ligne de Dadycar ?",
      answer: "Pour accéder aux services de maintenance automobile en ligne de Dadycar, il suffit de se rendre sur leur plateforme en ligne et de suivre les étapes de réservation.",
    },
    {
      question: "Quels sont les avantages de la prise en charge et de la livraison du véhicule par des chauffeurs professionnels sélectionnés par Dadycar ?",
      answer: "Les avantages de la prise en charge et de la livraison du véhicule par des chauffeurs professionnels sont la simplicité et la praticité de l'entretien, ainsi que la garantie de bénéficier de services de haute qualité.",
    },
    {
      question: "Comment Dadycar sélectionne-t-elle les professionnels de confiance pour ses services de maintenance automobile en ligne ?",
      answer: "Les professionnels de confiance sont sélectionnés par Dadycar pour leur expertise et leurs connaissances dans le domaine de la maintenance automobile.",
    },
    {
      question: "Comment sont fixés les tarifs pour les prestations de maintenance automobile en ligne proposées par Dadycar ?",
      answer: " Les tarifs pour les services de maintenance automobile en ligne de Dadycar sont transparents et fixés en fonction des prestations demandées.",
    },
    {
      question: "Comment effectuer une réservation pour la collecte et la restitution de mon véhicule avec Dadycar ?",
      answer: "Pour effectuer une réservation pour la collecte et la restitution de votre véhicule, il suffit de se rendre sur la plateforme Dadycar et de suivre les étapes de réservation.",
    },
    {
      question: "Quel est le délai de prise en charge de mon véhicule par Dadycar ?",
      answer: "Le délai de prise en charge de votre véhicule par Dadycar dépend de la disponibilité des professionnels de confiance.",
    },
    {
      question: "Quelles prestations de maintenance automobile sont proposées par Dadycar ?",
      answer: "Dadycar propose une large gamme de prestations de maintenance automobile, y compris la révision, la vidange et le diagnostic.",
    },
    {
      question: "Comment suivre l'état d'avancement de la maintenance de mon véhicule avec Dadycar ?",
      answer: "Les utilisateurs peuvent suivre l'état d'avancement de la maintenance de leur véhicule en se connectant à leur compte sur la plateforme Dadycar.",
    },
    {
      question: "Quel est le délai de restitution de mon véhicule une fois la maintenance effectuée par Dadycar ?",
      answer: <Text>
        Le délai de restitution du véhicule une fois la maintenance effectuée dépend des prestations demandées et de la disponibilité des professionnels de confiance.
        <br />
        <br/>
        Quelle est la politique de garantie proposée par Dadycar pour ses prestations de maintenance automobile en ligne ?
        Dadycar propose une politique de garantie pour ses services de maintenance automobile en ligne, garantissant la satisfaction du client.
      </Text>,
    },
    {
      question: "Quels moyens de paiement sont acceptés par Dadycar pour ses services de maintenance automobile en ligne ?",
      answer: "Les moyens de paiement acceptés par Dadycar pour ses prestations de maintenance automobile en ligne incluent les cartes de crédit et les paiements en ligne.",
    },
    {
      question: "Comment contacter le service client de Dadycar en cas de problème ou de question ?",
      answer: "Les utilisateurs peuvent contacter le service client de Dadycar en cas de problème ou de question en utilisant le formulaire de contact sur la plateforme en ligne.",
    },
    {
      question: "Comment annuler ou modifier ma réservation de maintenance automobile en ligne chez Dadycar ?",
      answer: "Pour annuler ou modifier une réservation de maintenance automobile en ligne chez Dadycar, il suffit de contacter le service client en utilisant le formulaire de contact sur la plateforme en ligne.",
    },
  ]
  return (
    <>
      <Box mt={12} bg={'#F6FAFC'} py={12}>
        <SectionHeader title={`Qui Sommes nous`} />
        <Box p={3}>
          <Text>
            Notre entreprise Dadycar est une plateforme innovante de maintenance automobile en ligne qui propose une solution simple et pratique pour l'entretien des véhicules. Nous croyons que notre service peut apporter une valeur ajoutée aux clients grâce à plusieurs avantages :
          </Text>

          <UnorderedList p={4}>
            <ListItem>
              Un accès facile à des services de haute qualité sans avoir à se déplacer physiquement dans un garage.
            </ListItem>
            <ListItem>
              Des chauffeurs professionnels qui se chargent de récupérer et livrer le véhicule pour une expérience d'entretien facile et pratique.
            </ListItem>
            <ListItem>
              Un service de Convoyage pour les personnes qui souhaitent faire déplacer leur véhicule d'un lieu à un autre.
            </ListItem>
            <ListItem>
              Une sélection de professionnels de confiance, choisis pour leur expertise et leur savoir-faire.
            </ListItem>
            <ListItem>
              Une tarification transparente et une politique d'engagement envers la satisfaction du client.
            </ListItem>
          </UnorderedList>

          <Text>
            Chez Dadycar, nous sommes fiers de faire partie des entreprises qui cherchent à rendre la vie plus facile pour nos clients. N'hésitez pas à nous contacter pour toute demande supplémentaire.
          </Text>
        </Box>


        <Box p={5}>
          <Accordion >
            {questionAndAnswer.map((item, idx) => (
              <AccordionItem borderRadius={'xl'} border={'2px solid transparent'} _hover={{color:'#0F82EA',border:'2px solid #0F82EA'}} key={idx} my={3}>
                <h2>
                  <AccordionButton p={5}  >
                    <Box as="span" flex='1' fontWeight={'bold'} textAlign='left'>
                      {item.question}
                    </Box>
                    <AccordionIcon color={'blue.500'} />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  {item.answer}
                </AccordionPanel>
              </AccordionItem>
            ))}


          </Accordion>
        </Box>
      </Box>
    </>

  )
}

export default QuestionsSection