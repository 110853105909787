import { useDispatch } from "react-redux";
import { CityType } from "../models/City";
import { City } from "../models/_index";
import { cityActions } from "../store/slices/city-slice";

class CityViewModel {

    dispatch = useDispatch();

    public getCities = async (departmentCode: number) => {
        const response = await City.getCities(departmentCode);
        if (response.status === 200)
            this.dispatch(cityActions.updateList(response.data));
    }

    public searchCities = async (departmentSlug: string): Promise<{ status: string }> => {
        const response = await City.searchCities(departmentSlug);
        if (response.status === 200) {
            this.dispatch(cityActions.updateList(response.data));
            return { status: 'success' };
        }
        return { status: 'error' };
    }

    public selectCity = async (city: CityType) => {
        this.dispatch(cityActions.updateSelected(city));
    }
}

export default CityViewModel;