import { Box, Button, Flex, Text } from "@chakra-ui/react"
import VehiculeResume from "./VehiculeResume"
import DevisResume from "./DevisResume"
import ClientForm from "./ClientForm"
import Agenda from "./Agenda"

const FourthStep = () => {
  return (
    <Box>
        <Flex mb={4} justifyContent={'space-between'} alignItems={'center'}>
            <Text fontSize={'22px'} fontWeight={700} color='#222D50'>Devis</Text>
            <Button _hover={{ bg: '#0F82BA' }} bg={'#0F82EA'} fontWeight={300} w='150px' h='40px' rounded={'46px'} color={'white'} px={'16px'} >Imprimer Devis</Button>
        </Flex>
        <Flex gap={4} borderBottom={'1px'} borderBottomColor={'#CFE7FC'} pb={4}>
            <VehiculeResume />
            <DevisResume />
        </Flex>
        <Box my={4}>
            <Text fontSize={'14px'} fontWeight={400} color={'#4E5D78'} mb={4} >Envoyer le devis pour un client qui existant ou no</Text>
            <Flex gap={4}>
                <ClientForm />
                <Agenda />
            </Flex>
        </Box>
    </Box>
  )
}

export default FourthStep