import { createSlice } from '@reduxjs/toolkit';

type VersionType = { id: number, name: string };
type SliceState = { list: VersionType[] | null, selected: VersionType | null };

const initialState: SliceState = { list: null, selected: null }

const versionSlice = createSlice({
  name: 'version',
  initialState,
  reducers: {
    updateList(state, action) {
      state.list = action.payload;
    },
    updateSelected(state, action) {
      state.selected = action.payload;
    },
    removeSelected(state) {
      state.selected = null;
    },
  },
});


export {type VersionType};
export const versionActions = versionSlice.actions;
export default versionSlice.reducer;