import { Box, Flex, HStack, Text } from '@chakra-ui/react';
import { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

const Agenda = () => {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [activeStartDate, setActiveStartDate] = useState<any>(new Date());

    const onChange = (theDate: any) => {
        setSelectedDate(theDate);
    };

    const goToPreviousMonth = () => {
        const newDate = new Date(activeStartDate.getFullYear(), activeStartDate.getMonth() - 1, 1);
        setActiveStartDate(newDate);
    };

    const goToNextMonth = () => {
        const newDate = new Date(activeStartDate.getFullYear(), activeStartDate.getMonth() + 1, 1);
        setActiveStartDate(newDate);
    };

    const isWeekend = ({ date }: any) => {
        const day = date.getDay();
        return day === 0 || day === 6; // 0 for Sunday, 6 for Saturday
    };

    return (
        <Box flex={1}>
            <Flex mb={2} justifyContent={'space-between'}>
                <Text fontWeight={700} color='#222D50'>Sélectionner une date</Text>
                <HStack>
                    <FaChevronLeft size={30} color='#0F82EA' cursor={'pointer'} onClick={goToPreviousMonth} />
                    <FaChevronRight size={30} color='#0F82EA' cursor={'pointer'} onClick={goToNextMonth} />
                </HStack>
            </Flex>
            <Calendar
                navigationAriaLabel={undefined}
                prev2Label={null}
                prevLabel={null}
                nextLabel={null}
                next2Label={null}
                view="month" // Set the default view to month
                activeStartDate={activeStartDate} // Control the active start date
                onActiveStartDateChange={({ activeStartDate }) => setActiveStartDate(activeStartDate)} // Update state on active start date change
                className="my-custom-calendar"
                onChange={onChange}
                value={selectedDate}
                tileClassName={({ date, view }) =>
                    view === 'month' && isWeekend({ date }) ? 'weekend' : null
                }
            />
        </Box>
    );
};

export default Agenda;
