import { Heading, Image, Text, Stack, Card, CardHeader, CardBody, CardFooter } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { RegionType } from "../../../../models/Region";

const RegionCard: React.FC<{ region: RegionType }> = ({ region }) => {

    return <>
        <Stack>
            <Link to={`/garages/region/${region.slug}`}>
                <Card align='center' borderRadius={'20px'} h={'100%'} border={'2px solid transparent'} _hover={{ border: '2px solid #1385EB' }} bg={{ base: 'transparent', hover: 'white' }} >
                    <CardHeader p={0}>
                        <Image src={region.image} borderRadius={'20px 20px 0 0'} />
                    </CardHeader>
                    <CardBody py={1}>
                        <Heading size={'xs'} textAlign={'center'}>{region.name}</Heading>
                    </CardBody>
                    <CardFooter py={2}>
                        <Text color={'#1385EB'}>{`Trouvez le garage le moins cher à ${region.name} pour l’entretien de vote auto.`}</Text>
                    </CardFooter>
                </Card>
            </Link>
        </Stack>
    </>
}

export default RegionCard;