import { Box, Container, Flex, Text } from '@chakra-ui/react'
import FAQComp from './FAQComp'
import { motion, useAnimation } from "framer-motion";
import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'

const MotionBox = motion(Box);

const FAQ = () => {
    const faqs = [
        {
            question:"En quoi Dadycar Fleet peut-il m'aider à gérer ma flotte de véhicules ?",
            answer:"Dadycar Fleet vous offre une plateforme centralisée pour superviser et gérer facilement votre flotte. Vous pouvez suivre vos véhicules en temps réel sur une carte, visualiser les estimations d'arrivée et optimiser la gestion de vos convois"
        },
        {
            question:"Est-il possible d'utiliser Dadycar Fleet avec une petite flotte de véhicules ?",
            answer:"Oui, Dadycar Fleet est parfaitement adapté à la gestion des flottes de toute taille. Que vous ayez quelques véhicules ou une flotte plus importante, Dadycar Fleet vous offre une solution évolutive pour optimiser vos opérations de transport et de logistique."
        },
        {
            question:"Dadycar propose-t-il des services d'entretien et de réparation pour mon véhicule ?",
            answer:"Dadycar ne propose pas directement de services d'entretien et de réparation. Cependant, la plateforme peut vous aider à trouver des garages fiables et qualifiés à proximité de chez vous."
        },
        {
            question:"Comment Dadycar peut-il m'aider à organiser le convoyage de mon véhicule ?",
            answer:"Dadycar collabore avec un réseau de conducteurs professionnels triés sur le volet pour leur fiabilité et leur ponctualité."
        }
    ]
    const { ref, inView } = useInView({threshold:0.3});
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (inView) {
      setIsVisible(true);
    }
  }, [inView]);


  return (
    <MotionBox ref={ref} 
    initial={{ scale: 0.8, opacity: 0 }}
    animate={{ scale: inView ? 1 : 0.8, opacity: inView ? 1 : 0 }}
    transition={{ duration: 0.5 }} pb={20} pt={10} bg='white'>
        <Container maxW={'90%'} w={{ lg: '1200px' }}>
            <Flex flexDirection='column' gap={3} alignItems='center' justifyContent='center'>
                <Text style={{textTransform:'uppercase'}} fontSize='22px' color='#0D121F' fontWeight={500} textAlign='center' >FAQ</Text>
                <Text fontWeight={500} fontSize={'22px'} color={'#0D121F'} textAlign='center' >On a les réponses à vos questions !</Text>
            </Flex>
            <Flex flexDirection='column' gap={4} w={{base:'95%',md:'90%',lg:'80%'}} mx='auto' mt={8} >
                {faqs.map((f,index)=>(
                    <FAQComp key={index} question={f.question} answer={f.answer} />
                ))}
            </Flex>
        </Container>
    </MotionBox>
  )
}

export default FAQ