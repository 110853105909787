import { createSlice } from '@reduxjs/toolkit';

type UserType = {
    id: number,
    first_name: string,
    last_name: string,
    email: string,
    phone: string,
    ttl: number,
};

type SliceState = { current: UserType | null, registerErrorMessage: string | null, loginErrorMessage: string | null };

const initialState: SliceState = { current: null, registerErrorMessage: null, loginErrorMessage: null };

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        updateCurrent(state, action) {
            state.current = action.payload;
        },
        updateRegisterError(state, action) {
            state.registerErrorMessage = action.payload;
        },
        updateLoginError(state, action) {
            state.loginErrorMessage = action.payload;
        }
    },
});

export { type UserType };
export const userActions = userSlice.actions;
export default userSlice.reducer;