import React, { useEffect } from 'react'
import { BookingSection, DetailsSection } from './components/_index'

const VendorDetailsPage: React.FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <BookingSection />
            <DetailsSection />
        </>
    )
}

export default VendorDetailsPage