import HttpClient from '../services/HttpClient'

type VendorLoginType = {
    email: string;
    password: string;
}

type FilterDataType = {
    versionId?: number;
    plateNumber?: string;
    plate_number?: string;
    lat: number;
    lng: number;
    radius: number;
    subServiceId: number;
}

class Vendor {

    public static login = async (vendorLoginData: VendorLoginType) => {
        return await HttpClient.post('/providers/login', vendorLoginData);
    }

    public static getVendor = async (vendorId: number) => {
        return HttpClient.get('/providers/details/' + vendorId);
    }

    public static getReferncedVendor = async (vendorId: number) => {
        return HttpClient.get('/referenced-garages/show/' + vendorId);
    }

    public static getVendors = async () => {
        return await HttpClient.get('/providers');
    }

    public static getCityVendors = async (citySlug: string) => {
        return await HttpClient.get('/referenced-garages/slug/' + citySlug);
    }

    public static getVendorsWithFilter = async (data: FilterDataType) => {
        if (data.plateNumber)
            return await HttpClient.get('/providers/filter?plate_number=' + data.plateNumber + '&lat=' + data.lat + '&lng=' + data.lng + '&radius=' + data.radius + '&sub_service=' + data.subServiceId);
        else
            return await HttpClient.get('/providers/filter?vehicle=' + data.versionId + '&lat=' + data.lat + '&lng=' + data.lng + '&radius=' + data.radius + '&sub_service=' + data.subServiceId);
    }
}

export type { VendorLoginType };
export default Vendor;