import { Center, Spinner, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from '../../../store';
import CityViewModel from '../../../view-models/CityViewModel';
import { BookingSection, CitiesSection } from './components/_index';

const CitiesPage: React.FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const searchCities = (new CityViewModel).searchCities;
    const cities = useSelector((state: RootState) => state.city.list);
    const [isLoading, setIsLoading] = useState<Boolean>(false);
    const { departmentParams } = useParams();

    useEffect(() => {
        const initCites = async () => {
            if (departmentParams) {
                setIsLoading(true);
                const response = await searchCities(departmentParams);
                if (response)
                    setIsLoading(false);
            }
        }
        initCites();
    }, []);

    return (
        <>{
            isLoading ?
                <>
                    <Center py={'32'} bg={'white'}>
                        <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
                    </Center>
                </>
                :
                <>
                    {
                        cities ?
                            <>
                                <BookingSection city={Object.values(cities)[0]} />
                                <CitiesSection cities={cities} />
                            </>
                            :
                            <>
                                <Center py={'32'} bg={'white'}>
                                    <Text>Aucune ville trouvée</Text>
                                </Center>
                            </>
                    }
                </>
        }
        </>
    )
}

export default CitiesPage;