import { Box, Container, SimpleGrid, Stack, useDisclosure,useSteps } from '@chakra-ui/react';
import { ConveyingForm } from '../../../../../../components/forms/V2/_index';
import { AlertPrice, FormSteps, PriceCard } from './components/_index';
import { StripeModal, UserLoginModal, UserRegisterModal } from '../../../../../../components/modals/_index';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store';


const FormSection: React.FC = () => {
    const { isOpen: isUserLoginOpen, onOpen: onUserLoginOpen, onClose: onUserLoginClose } = useDisclosure();
    const { isOpen: isUserRegisterOpen, onOpen: onUserRegisterOpen, onClose: onUserRegisterClose } = useDisclosure();
    const { isOpen: isStripeOpen, onOpen: onStripeOpen, onClose: onStripeClose } = useDisclosure();
    const conveying = useSelector((state: RootState) => state.conveying.value);
    const clientSecret = useSelector((state: RootState) => state.conveying.clientSecret);

    const steps = [
        { title: 'First', description: 'Dates du transport' },
        { title: 'Second', description: 'Contact au départ' },
        { title: 'Third', description: 'Contact à l’arrivée' },
      ]

    const { activeStep, setActiveStep } = useSteps({
        index: 0,
        count: steps.length,
      })
    return (
        <>
            <Box bg={'white'}>
                <Container maxW={{ lg: '100%' }} w={{ xl: '2000px' }}>
                    <SimpleGrid px={{ xl: 30 }} templateColumns={{ base: '1fr', xl: '6fr 6fr', lg: '60% 40%', md: '1fr' }} spacing={5}>
                        <Stack order={{ base: 2, lg: 1 }} overflowY={'scroll'} w={{ base: '100%', lg: '100%' }} px={6} my={4} >
                            <FormSteps steps={steps} activeStep={activeStep} />
                            <ConveyingForm onUserLoginOpen={onUserLoginOpen} onStripeOpen={onStripeOpen} activeStep={activeStep} setActiveStep={setActiveStep} />
                        </Stack>
                        <Stack order={{ base: 1, lg: 2 }} w={'100%'} px={6} my={4}>
                            <Box w={'100%'} zIndex={2} position={{ base: 'relative', lg: 'sticky' }} top={0}>
                                <PriceCard />
                                {activeStep === 2 ? (
                                    <Box my={4}>
                                        <AlertPrice />
                                    </Box>
                                ) : null}
                            </Box>
                        </Stack>
                    </SimpleGrid>
                </Container>
            </Box>
            <UserLoginModal isUserLoginOpen={isUserLoginOpen} onUserRegisterOpen={onUserRegisterOpen} onUserLoginClose={onUserLoginClose} />
            <UserRegisterModal isUserRegisterOpen={isUserRegisterOpen} onUserRegisterClose={onUserRegisterClose} />
            <StripeModal isStripeOpen={isStripeOpen} onStripeClose={onStripeClose} clientSecret={clientSecret!} successRoute={process.env.REACT_APP_URL + "convoyage/reservation/" + conveying?.id + "/complete"} type={'conveying'} />
        </>
    )
}

export default FormSection;