import { useDispatch } from 'react-redux';
import { toastActions } from '../store/slices/toast-slice';
import { Driver } from '../models/_index';
import { DriverLoginType, DriverRegisterType } from '../models/Driver';

const SuccessLoginToast = {
    title: "Connexion réussie",
    message: "Vous êtes maintenant connecté.",
    status: "success",
}

const ErrorLoginToast = {
    title: "Erreur de connexion",
    message: "E-mail ou mot de passe incorrect.",
    status: "error",
}

const SuccessRegisterToast = {
    title: "Inscription réussie",
    message: "Vous êtes maintenant inscrit.",
    status: "success",
}

const ServcerError = {
    title: "Erreur",
    message: "Une erreur s'est produite. Veuillez réessayer.",
    status: "error",
}


class DriverViewModel {

    dispatch = useDispatch();

    public login = async (driverLoginData: DriverLoginType): Promise<{ status: string; }> => {
        const response = await Driver.login(driverLoginData);

        if (response.status === 200) {
            this.dispatch(toastActions.display(SuccessLoginToast));
            return { status: 'success' };
        } else if (response.status === 422) 
           this.dispatch(toastActions.display(ErrorLoginToast));
         else 
            this.dispatch(toastActions.display(ServcerError));
        
        return { status: 'error' };
    }

    public register = async (driverRegisterData: DriverRegisterType): Promise<{ status: string; }> => {
        const response = await Driver.register(driverRegisterData);

        if (response.status === 200) {
            this.dispatch(toastActions.display(SuccessRegisterToast));
            return { status: 'success' };
        } else if (response.status === 422)
            this.dispatch(toastActions.display({
                title: "Erreur d'inscription",
                message: response.data.message,
                status: "error",
            }));
        else
            this.dispatch(toastActions.display(ServcerError));

        return { status: 'error' };
    }
}

export default DriverViewModel;