import { Box, Heading, Text } from '@chakra-ui/react'

const SectionHeader: React.FC<{ sectionName?: string, title?: string, color?: string }> = (props) => {
    return (
        <Box py={12}>
            {props.sectionName ?
                <Text textAlign={'center'} color={'#1385EB'}>{props.sectionName}</Text>
                :
                null
            }
            <Heading textAlign={'center'} color={props.color ? props.color : ''}>{props.title ? props.title : 'Your Title'}</Heading>
        </Box>
    )
}

export default SectionHeader;