import { useEffect } from "react";
import { HeaderSection, SummarySection } from "./components/_index";

const ConveyingSuccessPage: React.FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <HeaderSection />
            <SummarySection />
        </>
    )
}

export default ConveyingSuccessPage;