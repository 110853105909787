import { Image } from '@chakra-ui/image'
import {
    Center,
    SimpleGrid,
    Flex,
    Box,
    Stack,
    Heading,
    useColorModeValue,
    Container,
    Text,
} from '@chakra-ui/react';
import loginBg from '../../../assets/images/dadycar-login-section-bg.webp'
import loginBg1 from '../../../assets/images/dadycar-login-section-bg-md.webp'
import loginBg2 from '../../../assets/images/dadycar-login-section-bg-sm.webp'
import { DriverLoginForm } from '../../../components/forms/_index';

const LoginSection: React.FC = () => {
    return (
        <Box>
            <Box position={'absolute'} zIndex={-1} >
                <picture>
                    <source media="(min-width:48em)" srcSet={`${loginBg}`} />
                    <source media="(min-width:34em)" srcSet={`${loginBg1}`} />
                    <Image
                        alt='Dadycar Chauffeur'
                        src={loginBg2}
                        htmlWidth="100vw"
                        htmlHeight="100%"
                        w={'100vw'}
                        h={'auto'}
                        backgroundRepeat={'no-repeat'}
                        backgroundSize={'contain'}
                        objectFit={'cover'} />
                </picture>
            </Box>
            <Container
                maxW={'100%'}
                py={{ base: 0, lg: 12 }}
                w={{ lg: '1440px' }}>
                <SimpleGrid templateColumns={{ base: '1fr', lg: '2fr 2fr', md: '2fr 2fr' }}>
                    <Stack>
                        <Box pt={{ base: 24, lg: 32 }}>
                            <Box py={{ lg: 12, sm: 10 }}>
                                <Center>
                                    <Box px={5}>
                                        <Heading size={{ base: 'lg', lg: 'xl' }} color={'white'} textAlign={{ base: 'center', lg: 'start', md: 'start' }}>Devenez Chauffeur Dadycar</Heading>
                                        <br />
                                        <Text fontSize={{ base: 'md', lg: '2xl' }} color={'white'} textAlign={{ base: 'center', lg: 'start', md: 'start' }}>Convoyage de véhicules de particuliers et d’entreprises</Text>
                                        <br />
                                        <Text fontSize={{ base: 'md', lg: '2xl' }} color={'white'} textAlign={{ base: 'center', lg: 'start', md: 'start' }}>Recevez des revenus complémentaires et profitez d'une grande flexibilité horaire.</Text>
                                        <br />
                                        <Text fontSize={{ base: 'md', lg: '2xl' }} color={'white'} textAlign={{ base: 'center', lg: 'start', md: 'start' }}>Travaillez pour des clients réputés.</Text>
                                    </Box>
                                </Center>
                            </Box>
                        </Box>
                    </Stack>
                    <Stack pt={{ base: 5, lg: 24, md: 20 }}>
                        <Box w={'100%'} >
                            <Flex align={'center'} justify={'center'} >
                                <Stack w={{ base: '90%', lg: '60%' }} >
                                    <Box borderRadius={'3xl'} bg={useColorModeValue('white', 'gray.700')} boxShadow={'md'} p={8} w={'100%'}>
                                        <DriverLoginForm />
                                    </Box>
                                </Stack>
                            </Flex>
                        </Box>
                    </Stack>
                </SimpleGrid>
            </Container>
        </Box>
    )
}

export default LoginSection;
