import { Box, Button, HStack, Text, VStack } from '@chakra-ui/react'
import { garage_links } from './constants'
import { useLocation } from 'react-router-dom'

const GarageLinks = () => {
    const {pathname} = useLocation()
    const url = pathname?.split('/')[2]
  return (
    <Box w={'100%'} >
        <Box>
            <Text fontSize={'14px'} fontWeight={450} color={'#8B8BA7'} mb={1}>Mon Garage</Text>
            <VStack alignItems={'start'} gap={1} w={'100%'}>
                {garage_links?.map((activity,index)=>(
                    <Button
                        key={index}
                        px={2}
                        justifyContent={'start'}
                        w={'100%'}
                        leftIcon={<activity.icon   fontWeight={600}  fontSize={'24px'} />}
                        variant={'ghost'} _hover={{bg:'#CFE7FC'}}
                        bg={url === activity?.href ? '#CFE7FC' : ''}
                        fontWeight={600}
                        color={url === activity.href ? '#0F82EA' : '#4E5D78'}
                        rounded={'10px'}
                        fontSize={'14px'}
                    >
                        {activity.text}
                    </Button>
                ))}
            </VStack>
        </Box>
    </Box>
  )
}

export default GarageLinks