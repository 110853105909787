import { useState } from 'react';
import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

const StripeForm: React.FC<{ successRoute: string, type: string }> = (props) => {
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState<string | null>(null);
    const appointment = useSelector((state: RootState) => state.appointment.value);
    const finalPrice = useSelector((state: RootState) => state.appointment.finalPrice);
    const conveying = useSelector((state: RootState) => state.conveying.value);

    const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (props.type === 'conveying') {
            if (!conveying)
                return;
        } else if ('appointment') {
            if (!finalPrice)
                return;
        } else {
            if (!appointment)
                return;
        }

        if (!stripe || !elements) {
            return;
        }

        const result = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: props.successRoute,
            },
        });

        if (result.error && result.error.message) {
            setError(result.error.message);
            return;
        }

        setError(null);
    };

    return (
        <form onSubmit={handleFormSubmit}>
            <Heading color={'#008FE6'} fontSize={'2xl'}>Compléter votre réservation</Heading>
            <Box my='6'>
                <Text fontSize={'sm'}>
                    Cher client, merci de votre confiance envers Dadycar.
                    Veuillez noter que vous devez payer le prix du service maintenant pour confirmer votre réservation,
                    Nous vous remercions pour votre compréhension et nous sommes impatients de vous fournir un service de qualité.
                </Text>
                {props.type === 'conveying' &&
                    <>
                        {conveying &&
                            <>
                                <Flex mt={4}>
                                    <Text>Montant à payer : &nbsp;</Text>  <Text color={'#008FE6'}> {conveying.priceToPay} €</Text>
                                </Flex>
                            </>
                        }
                    </>
                }
                {props.type === 'appointment' ?
                    <>
                        {finalPrice &&
                            <>
                                <Flex mt={4}>
                                    <Text>Montant à payer : &nbsp;</Text>  <Text color={'#008FE6'}> {finalPrice} €</Text>
                                </Flex>
                            </>
                        }
                    </>
                    :
                    <>
                        {appointment &&
                            <>
                                <Flex mt={4} justifyContent={'space-between'} pr={'4'}>
                                    <Text>Prix total : {appointment.priceTTC} €</Text>
                                    <Text>Remise : {appointment.totalDiscount} €</Text>
                                </Flex>
                                <Flex mt={4} justifyContent={'space-between'} pr={'4'}>
                                    <Text>Prix final : {appointment.finalPrice} €</Text>
                                </Flex>
                                <Flex mt={4}>
                                    <Text>Montant à payer : &nbsp;</Text>  <Text color={'#008FE6'}> {appointment.priceToPay} € (10% du montant total TTC)</Text>
                                </Flex>
                            </>
                        }
                    </>
                }
            </Box>
            <PaymentElement />
            {error && <Box mt='2' color='red.500'>{error}</Box>}
            <Flex justifyContent={'right'}>
                <Button w={'36'} mt={'4'} bg={'#008FE6'} color={'white'} _hover={{ bg: '#008FE6' }} type='submit'>Valider</Button>
            </Flex>
        </form>
    );
};

export default StripeForm;