import { Box, Container, Flex, Image, Text, VStack } from "@chakra-ui/react"
import { motion, useAnimation } from "framer-motion";
import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import terre from '../../../../../../assets/V2/carsharing/terre.png'
import key from '../../../../../../assets/V2/carsharing/key.png'
import share from '../../../../../../assets/V2/carsharing/share.png'
import electric from '../../../../../../assets/V2/carsharing/electric.png'
import solar from '../../../../../../assets/V2/carsharing/solar.png'
import trajet from '../../../../../../assets/V2/carsharing/trajet.png'

import car_sharing from '../../../../../../assets/V2/carsharing/car-sharing.webp'

const MotionBox = motion(Box);

const AutoPartage = () => {

    const { ref, inView } = useInView({threshold:0.1});
  const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        if (inView) {
        setIsVisible(true);
        }
    }, [inView]);
  return (
    <Box pb={20} pt={10}  bg='#101929' position='relative' >
        <MotionBox
            ref={ref} initial={{ opacity: 0, scale: 1.1}}
            animate={{ opacity: inView ? 1 : 0, scale: inView ? 1 : 1.1 }}
            transition={{ duration: 0.5 }}
        >
            <Container maxW={'95%'} w={{ lg: '1400px' }}>
                <Text textAlign={'center'} fontSize={{base:'30px',sm:'40px',md:'45px'}} color={'white'} fontWeight={600}>Découvrez l'autopartage <br /> nouvelle génération</Text>
                <Flex flexDirection={{base:'column',lg:'row'}} gap={4} mt={6} >
                    <VStack alignItems={'start'} flex={1} gap={4} >
                        <Flex alignItems={'start'} gap={4} >
                            <Image src={terre} />
                            <VStack alignItems={'start'}>
                                <Text fontSize={{base:'22px',lg:'18px',xl:'22px'}} fontWeight={600} color={'white'}>Réservation simplifiée</Text>
                                <Text fontSize={{base:'18px',lg:'16px',xl:'18px'}} color={'#CAC9D0'} fontWeight={400}>Trouvez et réservez le véhicule idéal directement depuis votre smartphone.</Text>
                            </VStack>
                        </Flex>
                        <Flex alignItems={'start'} gap={4} >
                            <Image src={key} />
                            <VStack alignItems={'start'}>
                                <Text fontSize={{base:'22px',lg:'18px',xl:'22px'}} fontWeight={600} color={'white'}>Accès sans clé</Text>
                                <Text fontSize={{base:'18px',lg:'16px',xl:'18px'}} color={'#CAC9D0'} fontWeight={400}>Pas de clés à gérer, votre smartphone devient la clé de votre voiture.</Text>
                            </VStack>
                        </Flex>
                        <Flex alignItems={'start'} gap={4} >
                            <Image src={share} />
                            <VStack alignItems={'start'}>
                                <Text fontSize={{base:'22px',lg:'18px',xl:'22px'}} fontWeight={600} color={'white'}>Localisation en temps réel</Text>
                                <Text fontSize={{base:'18px',lg:'16px',xl:'18px'}} color={'#CAC9D0'} fontWeight={400}>Gardez un œil sur vos voitures et optimisez vos déplacements.</Text>
                            </VStack>
                        </Flex>
                    </VStack>
                    <Flex flex={1.5} justifyContent={'center'} alignItems={'end'} >
                        <Image src={car_sharing} />
                    </Flex>
                    <VStack alignItems={'start'} flex={1} gap={4}>
                        <Flex alignItems={'start'} gap={4} >
                            <Image src={electric} />
                            <VStack alignItems={'start'}>
                                <Text fontSize={{base:'22px',lg:'18px',xl:'22px'}} fontWeight={600} color={'white'}>Gestion de carburant intégrée</Text>
                                <Text fontSize={{base:'18px',lg:'16px',xl:'18px'}} color={'#CAC9D0'} fontWeight={400}>Gérez vos dépenses de carburant directement sur la plateforme.</Text>
                            </VStack>
                        </Flex>
                        <Flex alignItems={'start'} gap={4} >
                            <Image src={solar} />
                            <VStack alignItems={'start'}>
                                <Text fontSize={{base:'22px',lg:'18px',xl:'22px'}} fontWeight={600} color={'white'}>Suivi éco-conduite</Text>
                                <Text fontSize={{base:'18px',lg:'16px',xl:'18px'}} color={'#CAC9D0'} fontWeight={400}>Passages of Lorem Ipsum available, but the majority have suffered.</Text>
                            </VStack>
                        </Flex>
                        <Flex alignItems={'start'} gap={4} >
                            <Image src={share} />
                            <VStack alignItems={'start'}>
                                <Text fontSize={{base:'22px',lg:'18px',xl:'22px'}} fontWeight={600} color={'white'}>Historique des trajets</Text>
                                <Text fontSize={{base:'18px',lg:'16px',xl:'18px'}} color={'#CAC9D0'} fontWeight={400}>Gardez une trace de vos voyages et optimisez votre utilisation du véhicule.</Text>
                            </VStack>
                        </Flex>
                    </VStack>
                </Flex>
            </Container>
        </MotionBox>
    </Box>
  )
}

export default AutoPartage