import { Box, Modal, ModalContent, ModalOverlay } from '@chakra-ui/react';
import { CompleteVehicleDataForm } from '../forms/_index';

const CompleteVehicleDataModal: React.FC<{ isCompleteVehicleDataOpen: boolean, onCompleteVehicleDataClose: () => void, onUserLoginOpen: () => void }> = (props) => {
    return (
        <>
            <Modal isOpen={props.isCompleteVehicleDataOpen} onClose={props.onCompleteVehicleDataClose} size={'md'}>
                <ModalOverlay />
                <ModalContent borderRadius={'2xl'}>
                    <Box>
                        <CompleteVehicleDataForm onCompleteVehicleDataClose={props.onCompleteVehicleDataClose} onUserLoginOpen={props.onUserLoginOpen} />
                    </Box>
                </ModalContent>
            </Modal>
        </>
    )
}

export default CompleteVehicleDataModal;