import { Box, Button, Center, Container, Heading, Link, SimpleGrid, Stack, Text } from '@chakra-ui/react'
import serviceBg from '../../../../assets/images/dadycar-vendor-login-bg.webp';
import SectionHeader from '../../../../components/sections/SectionHeader';

const HelpSection: React.FC = () => {
    return (
        <Box position={'relative'} bg={'white'}>
            <Container >
                <SectionHeader title={"Comment Dadycar m’aide à développer mon activité ?"} />
            </Container>
            {/* <Box position={'absolute'} zIndex={-1} w={'100%'}>
                <picture>
                    <source media="(min-width:900px)" srcSet={`${serviceBg}`} />
                    <source media="(min-width:30em)" srcSet={`${serviceBg1}`} />
                    <Image src={serviceBg2} width="100%" />
                </picture>
            </Box> */}
            <SimpleGrid px={4} py={{ lg: '10%', md: '10%', base: 10 }}
                templateColumns={{ sm: '1fr', md: '2fr 2fr', lg: '2fr 2fr' }}
                spacingY={7}
                backgroundImage={serviceBg}
                backgroundSize={{ lg: 'cover' }}
                backgroundRepeat={'no-repeat'}
                backgroundPosition={{ base: 'top', lg: 'center' }}>
                <Stack justify={'center'}>
                    <Center justifyContent={{ base: 'start', md: 'center' }}>
                        <Box >
                            <Heading as={'h2'} size={'md'} color={'white'} mb={3}>Mon espace Dadycar</Heading>
                            <Box mb={6}>
                                <Text color={'white'}>
                                    Votre espace personnalisé</Text>
                                <Text color={'white'}>
                                    Gestion automatisée des devis et des Rdvs</Text>
                            </Box>
                            <Box mb={6}>
                                <Text color={'white'}>
                                    Gestion et contrôle sur vos prix</Text>
                            </Box>
                            <Box mb={6}>
                                <Text color={'white'}>
                                    Choix des prestations
                                </Text>
                            </Box>
                            <Box mb={6}>
                                <Text color={'white'}>
                                    Rapports d’activité
                                </Text>
                            </Box>
                        </Box>
                    </Center>
                </Stack>
                <Stack justify={'center'}>
                    <Center justifyContent={{ base: 'start', md: 'center' }}>
                        <Box >
                            <Heading as={'h2'} size={'md'} color={'white'} mb={3}>Ma visibilité</Heading>
                            <Box mb={6}>
                                <Text color={'white'}>
                                    Dadycar booste votre visibilité par :
                                </Text>
                                <Text color={'white'}>
                                    Des milliers d’utilisateurs
                                </Text>
                                <Text color={'white'}>
                                    Un marketing pointu
                                </Text>
                            </Box>
                            <Box mb={6}>
                                <Text color={'white'}>
                                    Gestion des avis clients</Text>
                            </Box>
                            <Box mb={6}>
                                <Text color={'white'}>
                                    Gestion et contrôle sur vos prix
                                </Text>
                            </Box>
                            <Box mb={6}>
                                <Text color={'white'}>
                                    Visibilité et référencement Google
                                </Text>
                            </Box>

                        </Box>
                    </Center>
                </Stack>
            </SimpleGrid>
            <Center bg={'white'} py={10}>
                <Link href='/garage/inscription'><Button fontSize={'small'} w={'127px'} color={'white'} bg={'#1385EB'} _hover={{ bg: '#1385EB' }}>
                    JE M’INSCRIS
                </Button></Link>
            </Center>
        </Box>
    )
}

export default HelpSection;
