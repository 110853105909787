import HttpClient from '../services/HttpClient'

type StoreAppointmentType = {
    provider_id: number,
    sub_service_id: number,
    appointment_date: string,
    appointment_time: string,
    going_address: string,
    coming_address: string,
    search_type: string,
    vehicle: {
        vehicle_id?: number,
        vehicle_numberplate: string
    }
}

type AcceptQuoteModalDataType = {
    vehicle: string,
    distance: string,
    conveyingPrice: number,
    service: string,
    servicePrice: number,
    totalPrice: number
}

class Appointment {

    public static store = async (body: StoreAppointmentType) => {
        return await HttpClient.post('/appointments/store', body);
    }

    public static getUserAppointments = async () => {
        return HttpClient.get('/appointments');
    }

    public static getAppointmentClientSecret = async (appointmentId: number) => {
        return HttpClient.get('/appointments/client-secret/' + appointmentId);
    }
}

export { type AcceptQuoteModalDataType };
export { type StoreAppointmentType };
export default Appointment;