import wheel from '../../../../../../assets/V2/prestation/wheel.webp'
import brake1 from '../../../../../../assets/V2/prestation/brake1.webp'
import brake2 from '../../../../../../assets/V2/prestation/brake1.webp'
import spark from '../../../../../../assets/V2/prestation/spark.webp'
import radiator from '../../../../../../assets/V2/prestation/radiator.webp'
import transmission from '../../../../../../assets/V2/prestation/transmission.webp'
import wiper from '../../../../../../assets/V2/prestation/wiper.webp'
import power from '../../../../../../assets/V2/prestation/power.webp'
import cabin from '../../../../../../assets/V2/prestation/cabin.webp'
import axle from '../../../../../../assets/V2/prestation/axle.webp'
import control1 from '../../../../../../assets/V2/prestation/control1.webp'
import control2 from '../../../../../../assets/V2/prestation/control2.webp'
import serpentine from '../../../../../../assets/V2/prestation/serpentine.webp'
import shock from '../../../../../../assets/V2/prestation/shock.webp'
import thermostat from '../../../../../../assets/V2/prestation/thermostat.webp'

export const items = [
    {
        id:1,
        img:wheel,
        name:"Wheel Bearing"
    },
    {
        id:2,
        img:brake1,
        name:"Brake Pads"
    },
    {
        id:3,
        img:spark,
        name:"Spark Plugs"
    },
    {
        id:4,
        img:radiator,
        name:"Radiator Hose"
    },
    {
        id:5,
        img:transmission,
        name:"Transmission Fluid"
    },
    {
        id:6,
        img:wiper,
        name:"Wiper Blade"
    },
    {
        id:7,
        img:brake2,
        name:"Brake Caliper"
    },
    {
        id:8,
        img:power,
        name:"Power Steering Pump"
    },
    {
        id:9,
        img:cabin,
        name:"Cabin Filter"
    },
    {
        id:10,
        img:axle,
        name:"Axle Shaft"
    },
    {
        id:11,
        img:control1,
        name:"Control Arm"
    },
    {
        id:12,
        img:serpentine,
        name:"Serpentine Belt"
    },
    {
        id:13,
        img:shock,
        name:"Shock Absorber"
    },
    {
        id:14,
        img:thermostat,
        name:"Thermostat Housing"
    },
    {
        id:15,
        img:control2,
        name:"Control Module"
    },
]