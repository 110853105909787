import { useRef, useState } from 'react';
import { ErrorMessage, useField } from 'formik';
import { FormControl, FormLabel, IconButton, Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import { BsFillEyeSlashFill } from 'react-icons/bs';
import styled from '@emotion/styled';

interface InputFieldProps {
    label?: string;
    name: string;
    type: string;
    placeholder: string;
    borderRadius?: string;
    maxLength?: number;
    minLength?: number;
    isUppercase?: boolean;
    bg?: string;
    size?: string;
}

const StyledErrorMessage = styled(ErrorMessage)`
  color: #f00;
  margin-top: 5px;
  font-size: 14px;
  padding-left: 7px;
`;

const InputField: React.FC<InputFieldProps> = ({ label, name, type, placeholder, borderRadius = '2xl',
    minLength = 0, maxLength = 255, isUppercase = false, bg = "transparent", size = "md" }) => {
    const [field, meta] = useField(name);
    const [showPassword, setShowPassword] = useState(false);
    const [text, setText] = useState('');
    const dateRef = useRef<HTMLInputElement>(null);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value.toUpperCase();
        setText(newValue);
        field.onChange({ target: { name, value: newValue } });
    };

    return (
        <FormControl id={name}>
            {label && <FormLabel>{label}</FormLabel>}
            <InputGroup display="block">
                {type === 'date' ?
                    <>
                        <Input
                            type={'text'}
                            ref={dateRef} 
                            placeholder={placeholder}
                            borderRadius={borderRadius}
                            {...field}
                            color={'black'}
                            isInvalid={meta.touched && !!meta.error}
                            autoFocus={false}
                            maxLength={maxLength}
                            bg={bg}
                            size={size}
                            onFocus={() => (dateRef.current!.type = 'date')} 
                            onBlur={() => (dateRef.current!.type = 'text')}
                        />
                    </>
                    :
                    <>
                        {isUppercase ?
                            <Input
                            color={'black'}
                                type={showPassword && type === 'password' ? 'text' : type}
                                placeholder={placeholder}
                                borderRadius={borderRadius}
                                {...field}
                                isInvalid={meta.touched && !!meta.error}
                                autoFocus={false}
                                maxLength={maxLength}
                                minLength={minLength}
                                onChange={handleChange}
                                value={text}
                                bg={bg}
                                size={size}
                            />
                            :
                            <Input
                            color={'black'}
                                type={showPassword && type === 'password' ? 'text' : type}
                                placeholder={placeholder}
                                borderRadius={borderRadius}
                                {...field}
                                isInvalid={meta.touched && !!meta.error}
                                autoFocus={false}
                                maxLength={maxLength}
                                bg={bg}
                                size={size}
                            />}
                    </>
                }
                {type === 'password' && (
                    <InputRightElement width='4.5rem'>
                        <IconButton
                            aria-label='See password'
                            h='1.75rem'
                            size='md'
                            bg={bg}
                            color={showPassword ? '#5393EC' : 'grey'}
                            onClick={() => setShowPassword((showPassword) => !showPassword)}
                        >
                            <BsFillEyeSlashFill />
                        </IconButton>
                    </InputRightElement>
                )}
                {meta.touched && meta.error && <StyledErrorMessage name={field.name} component="div" />}
            </InputGroup>
        </FormControl>
    );
};

export default InputField;