import { Box, Button, Text, VStack } from '@chakra-ui/react'
import { MdOutlineDashboard } from "react-icons/md";
import Activities from './Activities';
import { useLocation } from 'react-router-dom';
import GarageLinks from './GarageLinks';

const SideLinks = () => {
    const {pathname} = useLocation()
    const url = pathname?.split('/')[2]
    console.log(url)
  return (
    <VStack alignItems={'start'} gap={4} >
        <Button 
            px={2}
            justifyContent={'start'}
            w={'100%'}
            leftIcon={<MdOutlineDashboard fontSize={'24px'} />}
            variant={'ghost'} _hover={{bg:'#CFE7FC'}}
            bg={url === 'dashboard'? '#CFE7FC' : 'none'}
            fontWeight={'700'}
            color={url=== 'dashboard' ? '#0F82EA' : '#4E5D78'}
            rounded={'10px'}
        >
            Dashboard
        </Button>
        <Activities />
        <GarageLinks />
    </VStack>
  )
}

export default SideLinks