import React, { useEffect } from 'react'
import Head from '../../components/blocks/Head'
import { ContentSection } from './components/_index'

const title = "Marques";

const BrandsPage: React.FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Head title={title} />
            <ContentSection />
        </>
    )
}

export default BrandsPage