import Slider from "react-slick"; 
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'
import { Box, Button, Center, Heading, SimpleGrid, Text } from '@chakra-ui/react';
import { ScheduleType, SelectedDateType } from '../../../../../../../../store/slices/vendor-slice';
import { RootState } from '../../../../../../../../store';
import { useSelector } from 'react-redux';
import VendorViewModel from '../../../../../../../../view-models/VendorViewModel';

const DatePicker: React.FC<{ schedule: ScheduleType, vendorId: number }> = (props) => {
    const selectDate = (new VendorViewModel).selectDate;
    const selectedDate = useSelector((state: RootState) => state.vendor.selectedDate);
    const onClickHandeler = (date: SelectedDateType) => {
        selectDate(date)
    }

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
    };


    return (
        <Box w={'90%'} h={'150px'} position={'absolute'} px={2}>
            <Slider {...settings}>
                {props.schedule.map((date, index) => (
                    <Box key={index}>
                        <Box>
                            <Center>
                                <Box>
                                    <Heading size={'xs'}>{date.week_day_name_fr}</Heading>
                                    <Text fontSize={'xx-small'}>{date.date}</Text>
                                </Box>
                            </Center>
                            <SimpleGrid templateColumns={'1fr'}>
                                {date.closed ?
                                    <Button _hover={{ bg: '#d2d4da' }} size={'sm'} fontSize='sm' key={index} color={'#F6416C'} bg={'#E8EAF1'} m={1} p={1}>Fermer</Button>
                                    :
                                    date.hours.map((hour, index) => (
                                        <Button
                                            _hover={{ bg: '#1385EB', color: 'white' }}
                                            size={'sm'} m={1} p={1}
                                            key={props.vendorId + date.date + index}
                                            bg={selectedDate?.key == (props.vendorId + date.date + index) ? '#1385EB' : '#DFF7FC'}
                                            color={selectedDate?.key == (props.vendorId + date.date + index) ? 'white' : 'black'}
                                            onClick={() => { onClickHandeler({ vendorId: props.vendorId, key: props.vendorId + date.date + index, date: date.date, hour: hour }) }}>{hour}</Button>
                                    ))
                                }
                            </SimpleGrid>
                        </Box>
                    </Box>
                ))}
            </Slider>
        </Box>

    )
}


const NextArrow = (props: any) => {
    const { onClick } = props
    return (
        <Box position={'absolute'} zIndex={5} right={'-30px'} top={'0'}>
            <Button _hover={{ bg: 'transparent' }} onClick={onClick} color={'#1385EB'} borderRadius={'full'} bg={'transparent'}>
                <IoIosArrowForward />
            </Button>
        </Box>
    )

}

const PrevArrow = (props: any) => {
    const { onClick } = props
    return (
        <Box position={'absolute'} zIndex={5} left={'-30px'} top={'0'}>
            <Button _hover={{ bg: 'transparent' }} onClick={onClick}
                borderRadius={'full'} color={'#1385EB'} bg={'transparent'}>
                <IoIosArrowBack />
            </Button>
        </Box>
    )

}

export default DatePicker;