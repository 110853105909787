import { Box, Center, Container, Flex, Heading, Image, SimpleGrid, Spinner, Stack, Text } from '@chakra-ui/react'
import checkbox from '../../../../../assets/images/dadycar-checkbox-green.webp'
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import ServiceViewModel from '../../../../../view-models/ServiceViewModel';
import { useSelector } from 'react-redux';
import { OptionType } from '../../../../../models/Service';

const SubServiceSection: React.FC = () => {
    const { serviceParams, subServiceParams } = useParams();
    const searchSubService = (new ServiceViewModel).searchSubService;
    const [isLoading, setIsLoading] = useState(false);
    const [initialized, isInitialized] = useState(false);
    const subService = useSelector((state: any) => state.service.subService);
    const navigate = useNavigate();

    useEffect(() => {
        const search = async () => {
            if (serviceParams && subServiceParams) {
                setIsLoading(true);
                await searchSubService(serviceParams, subServiceParams);
                setIsLoading(false);
                isInitialized(true);
            }
        }
        search();
    }, [])

    useEffect(() => {
        if (initialized && !subService) {
            navigate('/prestations', { replace: true })
        }
    }, [initialized])

    return (
        <>
            <Box py={5} bg={'#F6FAFC'}>
                <Container maxW={'80%'} w={{ lg: '1400px' }} mt={{ base: 10, lg: 20 }}>
                    {
                        isLoading ?
                            <Center mb={20}>
                                <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
                            </Center>
                            :
                            <> {
                                subService &&
                                <>
                                    <Heading size={'md'} color={'#1385EB'}>{subService.name.toUpperCase()}</Heading>
                                    <Box py={5}>
                                        <SimpleGrid templateColumns={{ base: '1fr', lg: '2fr 2fr' }} spacing={6}>
                                            <Stack>
                                                <Image borderRadius={'2xl'} boxShadow={'md'} src={subService.image} />
                                            </Stack>
                                            <Stack>
                                                <Box py={5}>
                                                    {subService.description &&
                                                        <>
                                                            <Box py={3} dangerouslySetInnerHTML={{ __html: `${subService.description}` }}></Box>
                                                        </>
                                                    }
                                                </Box>
                                            </Stack>
                                        </SimpleGrid>
                                    </Box>
                                    {subService.options.length > 0 ?
                                        <Box p={3}>
                                            <Heading size={'md'} color={'#1385EB'}>{`${subService.name} OPTIONS`.toUpperCase()}</Heading>
                                            <SimpleGrid templateColumns={{ base: '1fr', md: '2fr 2fr', lg: '2fr 2fr 2fr' }}>
                                                {subService.options?.map((option: OptionType, index: number) => (
                                                    <Stack key={index}>
                                                        <Flex alignItems={'center'}>
                                                            <Image h={'20px'} src={checkbox} />
                                                            <Text p={2}>{option.name}</Text>
                                                        </Flex>
                                                    </Stack>
                                                ))}
                                            </SimpleGrid>
                                        </Box>
                                        : null
                                    }
                                </>
                            }
                            </>
                    }
                </Container>
            </Box>
        </>
    )
}

export default SubServiceSection;