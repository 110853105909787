import { Box, Center, Container, Text } from '@chakra-ui/layout';
import { SectionHeader } from '../../../components/sections/_index'
import dotBg from '../../../assets/images/dadycar-dots-bg.webp';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Image } from '@chakra-ui/react';

const SliderElement: React.FC = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    const reviews = [{
        clientImage: "",
        clientName: "",
        clientJob: "",
        clientNotice: `"Travailler avec Dadycar est un véritable plaisir. L'entreprise prend soin de ses chauffeurs partenaires et offre un support de qualité."`
    }, {
        clientImage: "",
        clientName: "",
        clientJob: "",
        clientNotice: `“Grâce à Dadycar, j'ai pu travailler avec des clients réputés et réaliser des trajets prestigieux. Je suis fier de faire partie de cette entreprise.”`
    }, {
        clientImage: "",
        clientName: "",
        clientJob: "",
        clientNotice: `“Je suis reconnaissant envers Dadycar pour les revenus complémentaires qu'ils m'ont permis de gagner. C'est une entreprise sérieuse et professionnelle.”`
    }, {
        clientImage: "",
        clientName: "",
        clientJob: "",
        clientNotice: `“Je recommande vivement Dadycar à tous les chauffeurs professionnels. Vous serez entre de bonnes mains avec cette entreprise.”`
    }, {
        clientImage: "",
        clientName: "",
        clientJob: "",
        clientNotice: `“En tant que chauffeur partenaire de Dadycar, j'apprécie la flexibilité des horaires qui me permet de travailler selon mes disponibilités.”`
    },{
        clientImage: "",
        clientName: "",
        clientJob: "",
        clientNotice: `“Dadycar est une entreprise de convoyage de véhicules de particuliers et d'entreprises sérieuse et fiable. Je suis fier de faire partie de cette équipe.”`
    },{
        clientImage: "",
        clientName: "",
        clientJob: "",
        clientNotice: `“Grâce à Dadycar, j'ai rencontré des clients exigeants mais passionnants. Cela m'a permis de développer mes compétences en tant que chauffeur professionnel.”`
    },{
        clientImage: "",
        clientName: "",
        clientJob: "",
        clientNotice: `“Je recommande fortement Dadycar à tous les chauffeurs professionnels en quête de nouveaux défis et d'une collaboration fructueuse.”`
    },{
        clientImage: "",
        clientName: "",
        clientJob: "",
        clientNotice: `“Travailler avec Dadycar m'a permis d'élargir mon réseau professionnel et de découvrir de nouveaux horizons dans le domaine de la conduite.”`
    },{
        clientImage: "",
        clientName: "",
        clientJob: "",
        clientNotice: `“Dadycar est une entreprise innovante et en constante évolution. Je suis fier de faire partie de cette aventure passionnante.”`
    },]
    return (
        <div>
            <Slider {...settings}>
                {reviews.map((item, idx) => (
                    <Box as='div' p={8} key={idx}>
                        <Center bg={'#5393EC'} borderRadius={'2xl'} h={'260px'} >

                            <Box p={8}>
                                <Center>
                                    <Box>
                                        <Center>
                                            <Image alt={item.clientName} w={'70px'} htmlHeight={"100%"} htmlWidth={"100%"} src={item.clientImage} />
                                        </Center>
                                        <Center>
                                            <Text color={'white'}>{item.clientName}</Text>
                                        </Center>
                                        <Center py={2} color={'white'}>
                                            {item.clientJob}
                                        </Center>
                                    </Box>


                                </Center>
                                <Text color={'white'} py={3} fontSize={'md'} textAlign={'center'}>
                                    {item.clientNotice}</Text>
                            </Box>
                        </Center>
                    </Box>
                ))}

            </Slider>
        </div>
    );
}

const SliderSection: React.FC = () => {
    return (
        <Box bg={'white'}
            py={5}
            pb={10}
            overflowX={'hidden'}
            backgroundImage={dotBg}
            backgroundSize={{ lg: 'cover' }}
            backgroundRepeat={'no-repeat'}
            backgroundPosition={{ base: 'top', lg: 'center' }} >
            
            <Container mb={20}>
            <SectionHeader title={"Ce que nos Chauffeurs Partenaires disent de Dadycar"} />
                <SliderElement />
            </Container>
        </Box>
    )
}

export default SliderSection;
