import { Box, Container, Flex, HStack, Text } from '@chakra-ui/react'
import ContactForm from './ContactForm'
import { FiMapPin } from "react-icons/fi";
import { FaPhoneAlt } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";

const Contact = () => {
  return (
    <Box bg='#0D1829'  pb={20} pt={10}  color='white' id='contact' >
        <Container maxW={'90%'} w={{ lg: '1200px' }}>
          <Text fontSize={'20px'} fontWeight={600}>Contactez-nous</Text>
          <Text fontSize={{base:'20px',md:'40px'}} textTransform={'uppercase'} fontWeight={700}>Avez-vous d'autres questions ?</Text>
          <Flex flexDirection={{base:'column',md:'row'}} mt={4} alignItems={{base:'start',md:'center'}} gap={8} >
            <Flex flex={1}  flexDirection='column' gap={4} >
              <HStack>
                <Flex justifyContent='center' alignItems='center' w={8} h={8} bg='#0F84EA' rounded={3}>
                  <FiMapPin />
                </Flex>
                <Text>36 Rue Raspail, 92300 Levallois-Perret</Text>
              </HStack>
              <HStack>
                <Flex justifyContent='center' alignItems='center' w={8} h={8} bg='#0F84EA' rounded={3}>
                  <FaPhoneAlt />
                </Flex>
                <Text>01 76 44 05 11</Text>
              </HStack>
              <HStack>
                <Flex justifyContent='center' alignItems='center' w={8} h={8} bg='#0F84EA' rounded={3}>
                  <HiOutlineMail />
                </Flex>
                <Text>Support@dadycar.fr</Text>
              </HStack>
              <HStack>
                <Flex justifyContent='center' alignItems='center' w={8} h={8} bg='#0F84EA' rounded={3}>
                  <HiOutlineMail />
                </Flex>
                <Text>Contact@dadycar.fr</Text>
              </HStack>
            </Flex>
            <ContactForm />
          </Flex>
        </Container>
    </Box>
  )
}

export default Contact