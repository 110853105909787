import {
  Box,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerContent,
  VStack,
  ButtonGroup,
  Container,
  Flex,
  HStack,
  IconButton,
  Image,
  useBreakpointValue,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
} from '@chakra-ui/react'
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { FaArrowRight } from "react-icons/fa";
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { RxHamburgerMenu } from 'react-icons/rx';
import logo from '../../../assets/V2/logo.png';
import UserLoginModal from '../../modals/UserLoginModal';
import UserRegisterModal from '../../modals/UserRegisterModal';
import { RootState } from '../../../store';
import { useSelector } from 'react-redux';
import UserViewModel from '../../../view-models/UserViewModel';
import { useEffect } from 'react';
import Avatar from 'react-avatar';

const routes = [
  {
    name: '/features',
    label: 'Features',
    type: 'link'
  },
  {
    name: '/convoyage',
    label: 'Convoyage',
    type: 'link'
  },
  // {
  //   name: '/professionnel',
  //   label: 'Professionnel',
  //   type: 'link'
  // },

  {
    name: '/services',
    label: 'DadyCar services',
    type: 'link'
  },
  {
    name: 'contact',
    label: 'Contactez-nous',
    type: 'href'
  },
];

const SidebarContent: React.FC<{ onClick: () => void, onUserLoginOpen: () => void }> = ({ onClick, onUserLoginOpen }) => {
  const getCurrentUser = (new UserViewModel()).getCurrentUser;
  const user = useSelector((state: RootState) => state.user.current);
  useEffect(() => {
    getCurrentUser();
  }, [])

  return (
    <VStack position={'relative'} alignItems={'flex-start'}>
      <Menu>
      {({ isOpen }) => (
        <>
          <MenuButton w='100%' fontWeight={500} as={Button}  rightIcon={isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}>
            Solutions
          </MenuButton>
          <MenuList p={4} >
              <VStack>
                <MenuItem bg='transparent'>
                  <Link to={'https://fleet.dadycar.fr'} >
                    <Button px={0} variant={'ghost'} _hover={{bg:'transparent',color:'#0F82EA'}} rightIcon={<FaArrowRight />}>DadyCar Fleet</Button>
                  </Link>
                </MenuItem>
                <MenuItem bg='transparent'>
                  <Link to={'https://fleet.dadycar.fr/certify'}>
                    <Button px={0} variant={'ghost'} _hover={{bg:'transparent',color:'#0F82EA'}} rightIcon={<FaArrowRight />}>DadyCar Certify</Button>
                  </Link>
                </MenuItem>
                <MenuItem bg='transparent'>
                  <Link to={'https://dadycar.fr/garage'}>
                    <Button px={0} variant={'ghost'} _hover={{bg:'transparent',color:'#0F82EA'}} rightIcon={<FaArrowRight />}>Dadycar Garage</Button>
                  </Link>
                </MenuItem>
                <MenuItem bg='transparent'>
                  <Link to={'https://dadycar.fr/chauffeur'}>
                    <Button px={0} variant={'ghost'} _hover={{bg:'transparent',color:'#0F82EA'}} rightIcon={<FaArrowRight />}>DadyCar Driver</Button>
                  </Link>
                </MenuItem>
              </VStack>
          </MenuList>
        </>
      )}
      </Menu>
      <Menu>
      {({ isOpen }) => (
        <>
          <MenuButton w='100%' fontWeight={500} as={Button}  rightIcon={isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}>
            Services
          </MenuButton>
          <MenuList p={4}>
              <VStack>
                <MenuItem bg='transparent'>
                  <Link to={'/convoyage'} onClick={onClick}>
                    <Button px={0} variant={'ghost'} _hover={{bg:'transparent',color:'#0F82EA'}} rightIcon={<FaArrowRight />}>Convoyage</Button>
                  </Link>
                </MenuItem>
                <MenuItem bg='transparent'>
                  <Link to={'https://dadycar.fr/prestations'}>
                    <Button px={0} variant={'ghost'} _hover={{bg:'transparent',color:'#0F82EA'}} rightIcon={<FaArrowRight />}>Entretien et réparation </Button>
                  </Link>
                </MenuItem>
                <MenuItem bg='transparent'>
                  <Link to={'/'} onClick={onClick}>
                    <Button px={0} variant={'ghost'} _hover={{bg:'transparent',color:'#0F82EA'}} rightIcon={<FaArrowRight />}>Autopartage  entreprise</Button>
                  </Link>
                </MenuItem>
              </VStack>
          </MenuList>
        </>
      )}

      </Menu>
      {user ?
        <Button w="100%">{user.first_name + user.last_name}</Button>
        :
        // <Button w="100%" onClick={() => { onClick(); onUserLoginOpen(); }}>Se connecter</Button>
        <Link style={{ width: '100%' }} to={'https://fleet.dadycar.fr/client/login'}>
          <Button w='100%'>
            Login
          </Button>
        </Link>
      }
    </VStack>
  )
}

const Sidebar: React.FC<{ onClose: () => void, onUserLoginOpen: () => void, isOpen: boolean, variant: string }> = ({ onUserLoginOpen, isOpen, variant, onClose }) => {
  return variant === 'sidebar' ? (
    <Box position="fixed" left={0} p={5} w="200px" top={0} h="100%" bg="#dfdfdf">
      <SidebarContent onUserLoginOpen={onUserLoginOpen} onClick={onClose} />
    </Box>
  ) : (
    <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <Image src={logo} w={'100px'} />
          </DrawerHeader>
          <DrawerBody>
            <SidebarContent onUserLoginOpen={onUserLoginOpen} onClick={onClose} />
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  )
}


const Navbar: React.FC = () => {
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const [indexPage, setIndexPage] = React.useState(window.location.pathname);
  const {pathname} = useLocation()
  const [isOpen, setIsOpen] = React.useState(false);
  const { isOpen: isUserLoginOpen, onOpen: onUserLoginOpen, onClose: onUserLoginClose } = useDisclosure()
  const { isOpen: isUserRegisterOpen, onOpen: onUserRegisterOpen, onClose: onUserRegisterClose } = useDisclosure()
  React.useEffect(() => { }, [indexPage])
  const userViewModel = new UserViewModel();
  const getCurrentUser = userViewModel.getCurrentUser;
  const logout = userViewModel.logout;
  const user = useSelector((state: RootState) => state.user.current);

  const scrollToSection = (label:string) => {
    const section = document.getElementById(label);
    section!.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    getCurrentUser();
  }, [])

  const openToggle = () => (setIsOpen(!isOpen));

  return (
    <>
      <UserLoginModal isUserLoginOpen={isUserLoginOpen} onUserRegisterOpen={onUserRegisterOpen} onUserLoginClose={onUserLoginClose} />
      <UserRegisterModal isUserRegisterOpen={isUserRegisterOpen} onUserRegisterClose={onUserRegisterClose} />

      <Sidebar onUserLoginOpen={onUserLoginOpen} isOpen={isOpen} onClose={openToggle} variant='' />
      <Box as="section" bg={'transparent'} position={'absolute'} w={'100%'} zIndex={100} borderBottom='1px'>
        <Box as="nav" bg="bg-surface">
          <Container py={{ base: '4', lg: '3' }}  maxW={'90%'} w={{ lg: '1200px' }}>
            <Flex alignItems={'center'}  justifyContent={{base:'space-between',lg:'flex-start'}} >
              <Box>
                <Link to={'/'}><Image src={logo} w={"99px"} /></Link>
              </Box>
              <Flex w={{lg:'100%'}} alignItems='center' justifyContent="space-between">
                {isDesktop ? (
                  <Flex alignItems={'center'} justify="space-between" flex="1">
                        <HStack ml={40} alignItems={'center'}>
                          <Menu>
                          {({ isOpen }) => (
                              <>
                                <MenuButton fontWeight={500} as={Button} variant={'ghost'} color={'white'} _hover={{bg:'transparent'}} bg='transparent' _expanded={{ bg: 'transparent' }} rightIcon={isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}>
                                  Solutions
                                </MenuButton>
                                <MenuList p={8}>
                                  <HStack alignItems='flex-start'>
                                    <VStack>
                                      <MenuItem bg='transparent'>
                                        <Link to={'https://fleet.dadycar.fr'}>
                                          <Button px={0} variant={'ghost'} _hover={{bg:'transparent',color:'#0F82EA'}} rightIcon={<FaArrowRight />}>DadyCar Fleet</Button>
                                        </Link>
                                      </MenuItem>
                                      <MenuItem bg='transparent'>
                                        <Link to={'https://fleet.dadycar.fr/certify'}>
                                          <Button px={0} variant={'ghost'} _hover={{bg:'transparent',color:'#0F82EA'}} rightIcon={<FaArrowRight />}>DadyCar Certify</Button>
                                        </Link>
                                      </MenuItem>
                                      <MenuItem bg='transparent'>
                                        <Link to={'https://dadycar.fr/garage'}>
                                          <Button px={0} variant={'ghost'} _hover={{bg:'transparent',color:'#0F82EA'}} rightIcon={<FaArrowRight />}>Dadycar Garage</Button>
                                        </Link>
                                      </MenuItem>
                                    </VStack>
                                    <VStack>
                                      <MenuItem bg='transparent'>
                                        <Link to={'https://dadycar.fr/chauffeur'}>
                                          <Button px={0} variant={'ghost'} _hover={{bg:'transparent',color:'#0F82EA'}} rightIcon={<FaArrowRight />}>DadyCar Driver</Button>
                                        </Link>
                                      </MenuItem>
                                    </VStack>
                                  </HStack>
                                </MenuList>
                              </>
                            )}
                          </Menu>
                          <Menu>
                          {({ isOpen }) => (
                            <>
                              <MenuButton fontWeight={500} as={Button} variant={'ghost'} color={'white'} _hover={{bg:'transparent'}} bg='transparent' _expanded={{ bg: 'transparent' }} rightIcon={isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}>
                                Services
                              </MenuButton>
                              <MenuList p={8}>
                                  <VStack>
                                    <MenuItem bg='transparent'>
                                      <Link to={'/convoyage'}>
                                        <Button px={0} variant={'ghost'} _hover={{bg:'transparent',color:'#0F82EA'}} rightIcon={<FaArrowRight />}>Convoyage</Button>
                                      </Link>
                                    </MenuItem>
                                    <MenuItem bg='transparent'>
                                      <Link to={'https://dadycar.fr/prestations'}>
                                        <Button px={0} variant={'ghost'} _hover={{bg:'transparent',color:'#0F82EA'}} rightIcon={<FaArrowRight />}>Entretien et réparation </Button>
                                      </Link>
                                    </MenuItem>
                                    <MenuItem bg='transparent'>
                                      <Link to={'/'}>
                                        <Button px={0} variant={'ghost'} _hover={{bg:'transparent',color:'#0F82EA'}} rightIcon={<FaArrowRight />}>Autopartage  entreprise</Button>
                                      </Link>
                                    </MenuItem>
                                  </VStack>
                              </MenuList>
                            </>
                          )}
                          </Menu>
                        </HStack>
                        <Box>
                          {user ?
                            <>
                              <Menu>
                                <MenuButton as={Button}>
                                  <Avatar size="36" textSizeRatio={2.2} color={'#319795'} round={true} name={user.first_name + user.last_name} />
                                </MenuButton>
                                <MenuList>
                                  <MenuItem gap={2}>
                                    <Link to={'/user'}>
                                      <Flex gap={2}>
                                        <Avatar size="36" textSizeRatio={2} color={'#319795'} round={true} name={user.first_name + user.last_name} />
                                        <Text mt={1.5}>{user.first_name + ' ' + user.last_name}</Text>
                                      </Flex>
                                    </Link>
                                  </MenuItem>
                                  <MenuItem mt={2} onClick={() => logout()}>Déconnexion</MenuItem>
                                </MenuList>
                              </Menu>
                            </>
                            :
                            // <Button size={{ lg: 'sm' }} bg={"white"} borderRadius={'lg'} variant="ghost" onClick={onUserLoginOpen}>Se connecter</Button>
                          <HStack>
                              {pathname === '/convoyage' || pathname === '/auto-partage' ? (
                                <Link to={'https://fleet.dadycar.fr/client/login'}>
                                  <Button variant={'ghost'} color='white' _hover={{bg:'transparent'}}>Se connecter</Button>
                                </Link>
                              ) : null}
                              <Link to={'https://meetings-eu1.hubspot.com/meetings/amine-yahia-cherif/dadycar'} target="_blank"><Button  bg='#0F82EA' _hover={{bg:'#3563E9'}} rounded='30px' px='24px' py='13px' color='white' >Prendre RDV</Button></Link>
                          </HStack>
                          }
                        </Box>
                    </Flex>
                ) : (
                  <IconButton
                    variant="ghost"
                    color={'white'}
                    fontSize={'xx-large'}
                    icon={<RxHamburgerMenu />}
                    aria-label="Open Menu"
                    onClick={openToggle}
                  />
                )}
              </Flex>
            </Flex>
          </Container>
        </Box>
      </Box>
    </>
  )
}

export default Navbar;