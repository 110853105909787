import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Flex,
  Box,
  Center,
} from '@chakra-ui/react'
import { MdInfo } from 'react-icons/md'

const DadycarAlert: React.FC<{ type?: string, children: any }> = ({ children, type }) => {
  return (
    <Flex bg={'#F6FAFC'} borderRadius={'2xl'}  p={5}>
    {type === 'alert' ? <Box fontSize={'3xl'}><MdInfo color='#0F61AC' /></Box> : null}
    <Box px={3}>
      {children}
    </Box>
  </Flex>
  )
}

export default DadycarAlert