import { Box, Button, Container, Grid, GridItem, Stack } from "@chakra-ui/react";
import { AddressViewModel, DistanceViewModel } from "../../../view-models/_index";
import { SearchAddressAutocomplete } from '../../../components/sectors/V2/_index';
import { useSelector } from "react-redux";
import { RootState } from "../../../store/index";
import { SelectVehicle } from "../../sectors/_index";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// import prestationIcon from '../../assets/images/dadycar-Icon-metro-local-service.webp';

const ConveyingBookingSearch: React.FC = () => {
    const addressViewModel = new AddressViewModel();
    const selectConveyingDepartureAddress = addressViewModel.selectConveyingDepartureAddress;
    const selectConveyingReturnAddress = addressViewModel.selectConveyingReturnAddress;
    const conveyingDepartureAddress = useSelector((state: RootState) => state.address.conveyingDepartureAddress);
    const conveyingReturnAddress = useSelector((state: RootState) => state.address.conveyingReturnAddress);
    const version = useSelector((state: RootState) => state.version.selected);
    const vehicle = useSelector((state: RootState) => state.vehicle.value);
    const [isDisabled, setIsDisabled] = useState(false);

    const distanceViewModel = new DistanceViewModel();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if ((version || vehicle) && conveyingDepartureAddress && conveyingReturnAddress)
            setIsDisabled(false);
        else
            setIsDisabled(true);
    }, [version, conveyingDepartureAddress, conveyingReturnAddress, vehicle]);

    const HandleSubmit = () => {
        if ((version || vehicle) && conveyingDepartureAddress && conveyingReturnAddress) {
            distanceViewModel.getConveyingDistanceWithPrice({ conveyingDepartureAddress, conveyingReturnAddress }, version?.id, vehicle?.type!);
            if (location.pathname !== '/convoyage/reservation') {
                navigate('/convoyage/reservation', { replace: true });
            }
        }
    }

    return (
        <Container backdropFilter={'blur(10px)'} bg={'rgba(255, 255, 255, 0.25)'}  maxW={'90%'} w={{ lg: '1200px' }} borderRadius={'3xl'}
            boxShadow={'0px 0px 15px rgba(0, 0, 0, 0.203922)'} p={'5'} my={'4'}>
            <Box display={{ lg: 'flex', base: 'block' }} justifyContent={'center'}>
                <Grid alignItems='center' templateColumns={{ lg: 'repeat(3, 1fr)' }} gap={2}>
                    <GridItem  >
                        <SelectVehicle />
                    </GridItem>
                    <GridItem>
                        <SearchAddressAutocomplete
                            placeholder="Point de départ"
                            address={conveyingDepartureAddress}
                            selectAddress={selectConveyingDepartureAddress}
                        />
                    </GridItem>
                    <GridItem>
                        <SearchAddressAutocomplete
                            placeholder="Point d’arrivée"
                            address={conveyingReturnAddress}
                            selectAddress={selectConveyingReturnAddress}
                        />
                    </GridItem>
                </Grid>

                <Stack m={'2'} align={'center'}>
                    <Button
                        bg={'#0F82EA'}
                        color={'white'}
                        borderRadius={'30px'}
                        h='100%'
                        py={'13px'}
                        w='177px'
                        fontSize={'sm'}
                        
                        _hover={{ bg: '#3563E9' }}
                        onClick={HandleSubmit}
                        isDisabled={isDisabled}
                    >
                        Search
                    </Button>
                </Stack>
            </Box>
        </Container>
    )
}

export default ConveyingBookingSearch;
