import { Box, Flex, HStack, Text, VStack } from '@chakra-ui/react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';

ChartJS.register(ArcElement, Tooltip, Legend);
const data = {
    labels: ['RDV sans réponse', 'RDV confirme', 'RDV Annule'],
    datasets: [
      {
        label: 'Totale',
        data: [12, 19, 3],
        backgroundColor: ['#006AFF','#52C93F','#FF2727'],
     
        borderWidth: 1,
      },
    ],
  };
const RDV = () => {
  return (
    <Box bg='#FDFDFD' rounded={'6px'} my={2}>
        <Box p={4} >
            <Flex justifyContent={'space-between'} alignItems={'center'} mb={4}>
                <Text fontSize={'18px'} fontWeight={500} color={'#656575'}>Demander RDV</Text>
                <Text bg={'#F4F5F7'} rounded={'2px'} py={'4px'} px={'8px'} fontSize={'12px'} fontWeight={400} color={'#656575'}>Mois</Text>
            </Flex>
            <Flex justifyContent="center">
                <Doughnut
                    data={data}
                    options={{
                        plugins: {
                        legend: {
                            display: false,
                        },
                        },
                        maintainAspectRatio: false }}
                    width={'125px'}
                    height={'125px'}
                />
            </Flex>
            <VStack alignItems={'start'} gap={2} mt={2} >
                <Flex alignItems={'center'} justifyContent={'space-between'} gap={4} w='100%'>
                    <HStack>
                        <Box w={'16px'} h='16px' rounded={'100%'} bg={'#006AFF'} />
                        <Text>RDV sans réponse</Text>
                    </HStack>
                    <HStack fontSize={'16px'} fontWeight={400}>
                        <Text color={'#1A1919'} >25</Text>
                        <FaArrowUp color='#52C93F' />
                    </HStack>
                </Flex>
                <Flex alignItems={'center'} justifyContent={'space-between'} gap={4} w='100%' >
                    <HStack>
                        <Box w={'16px'} h='16px' rounded={'100%'} bg={'#52C93F'} />
                        <Text>RDV confirme</Text>
                    </HStack>
                    <HStack fontSize={'16px'} fontWeight={400}>
                        <Text color={'#1A1919'} >60</Text>
                        <FaArrowUp color='#52C93F' />
                    </HStack>
                </Flex>
                <Flex alignItems={'center'} justifyContent={'space-between'} gap={4} w='100%' >
                    <HStack>
                        <Box w={'16px'} h='16px' rounded={'100%'} bg={'#FF2727'} />
                        <Text>RDV Annule</Text>
                    </HStack>
                    <HStack fontSize={'16px'} fontWeight={400}>
                        <Text color={'#1A1919'} >05</Text>
                        <FaArrowDown color='#FF2727' />
                    </HStack>
                </Flex>
            </VStack>
        </Box>
    </Box>
  )
}

export default RDV