import { BiCalendar } from "react-icons/bi";
import { AiOutlinePlusCircle } from "react-icons/ai";

export const activities = [
    {
        icon:BiCalendar,
        text:'Rendez-vous',
        href:'calendar'
    },
]

export const garage_links = [
    {
        icon:AiOutlinePlusCircle,
        text:'Mes clients',
        href:''
    },
    {
        icon:AiOutlinePlusCircle,
        text:'Historique',
        href:'historique'
    },
    {
        icon:AiOutlinePlusCircle,
        text:'Mes Factures',
        href:''
    },
    {
        icon:AiOutlinePlusCircle,
        text:'Mes Devis',
        href:'devis'
    },
    {
        icon:AiOutlinePlusCircle,
        text:'Statistiques',
        href:''
    },
    {
        icon:AiOutlinePlusCircle,
        text:'Mes Tarif',
        href:''
    },
    
]