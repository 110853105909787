import { Box, Button, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import { Link } from 'react-router-dom'

const Header = () => {
  return (
    <Flex justifyContent={'space-between'} alignItems={'start'} mt={4} >
        <Box flex={1} >
            <Text fontSize={'24px'} fontWeight={600} color={'#222D50'} >Mes devis</Text>
            <Text fontWeight={400} maxW={'80%'} color={'#4E5D78'} >Consultez ci-dessous les devis  en compte pour avril. Si une erreur s’y est glissée, cliquez sur « Réclamation » pour nous la signaler.</Text>
        </Box>
        <Link to={'/test/devis/new'}>
          <Button _hover={{bg:'#0F82FA'}} fontSize={'15px'} bg='#0F82EA' color={'white'} rounded={'5px'} w={'219px'} h='48px' leftIcon={<AiOutlinePlusCircle size={20}/>}>
              Cree un devis
          </Button>
        </Link>
    </Flex>
  )
}

export default Header