import { Box, Flex, Text } from '@chakra-ui/react'
import React from 'react'

const DevisResume = () => {
  return (
    <Box flex={1} bg='#EFF2FF' rounded={'8px'} p='16px'>
        <Text fontSize={'18px'} fontWeight={600} color={'#222D50'} mb={2}>Total devis</Text>
        <Flex justifyContent={'space-between'}  >
            <Text flex={1} fontSize={'18px'} fontWeight={500} color={'#4E5D78'}>Total hors remise € HT</Text>
            <Flex flex={1} justifyContent={'flex-end'}>
                <Text fontSize={'22px'} fontWeight={500} color={'#333333'}>1024 €</Text>
            </Flex>
        </Flex>
        <Flex justifyContent={'space-between'}  my={2} >
            <Text flex={1} fontSize={'18px'} fontWeight={500} color={'#4E5D78'}>Total remise € HT</Text>
            <Flex flex={1} justifyContent={'flex-end'} >
                <Text fontSize={'22px'} fontWeight={500} color={'#333333'}>00 €</Text>
            </Flex>
        </Flex>
        <Flex justifyContent={'space-between'}  mb={2} >
            <Text flex={1} fontSize={'18px'} fontWeight={500} color={'#4E5D78'}>TOTAL € HT</Text>
            <Flex flex={1} justifyContent={'flex-end'} >
                <Text fontSize={'22px'} fontWeight={500} color={'#333333'}>1024 €</Text>
            </Flex>
        </Flex>
        <Flex justifyContent={'space-between'}  pb={2} borderBottom={'1px'} borderColor={'#4E5D78'}>
            <Text flex={1} fontSize={'18px'} fontWeight={500} color={'#4E5D78'}>TVA €</Text>
            <Flex flex={1} justifyContent={'flex-end'} >
                <Text fontSize={'22px'} fontWeight={500} color={'#333333'}>440 €</Text>
            </Flex>
        </Flex>
        <Flex mt={2} justifyContent={'space-between'}  mb={2} >
            <Text flex={1} fontSize={'18px'} fontWeight={700} color={'#182F45'}>Montant à facturer au client € TTC</Text>
            <Flex flex={1} justifyContent={'flex-end'} >
                <Text fontSize={'22px'} fontWeight={700} color={'#0C7FDA'}>1464 €</Text>
            </Flex>
        </Flex>
    </Box>
  )
}

export default DevisResume