import { useDispatch } from 'react-redux';
import { userActions, UserType } from '../store/slices/user-slice';
import { User } from '../models/_index';
import { UserLoginType, UserRegisterType } from '../models/User';
import { toastActions } from '../store/slices/toast-slice';

const LoginToast = {
    title: "Connexion réussie",
    message: "Vous êtes maintenant connecté.",
    status: "success",
}

const RegisterToast = {
    title: "Inscription réussie",
    message: "Vous êtes maintenant inscrit.",
    status: "success",
}

const LogoutToast = {
    title: "Déconnexion réussie",
    message: "Vous êtes maintenant déconnecté.",
    status: "success",
}

class UserViewModel {

    dispatch = useDispatch();

    public getCurrentUser = () => {
        const userData = localStorage.getItem("userData");
        const user: UserType | null = userData ? JSON.parse(userData) : null;

        if (user && user.ttl && user.ttl > Date.now())
            this.dispatch(userActions.updateCurrent(user));
        else {
            localStorage.removeItem("userData");
            this.dispatch(userActions.updateCurrent(null));
        }
    }

    public login = async (userLoginData: UserLoginType): Promise<{ status: string; }> => {
        this.dispatch(userActions.updateLoginError(null));
        localStorage.removeItem("userData");
        localStorage.removeItem("token");
        const response = await User.login(userLoginData);


        if (response.status === 200) {
            const user = { ...response.data.data.user, ttl: Date.now() + User.TTL };
            const token = response.data.data.token;
            this.dispatch(userActions.updateCurrent(user));
            localStorage.setItem("userData", JSON.stringify(user));
            localStorage.setItem("token", JSON.stringify(token));
            this.dispatch(toastActions.display(LoginToast));
            return { status: 'success' };
        } else if (response.status === 422) {
            this.dispatch(userActions.updateLoginError(response.data.message));
        } else {
            this.dispatch(userActions.updateLoginError("Une erreur s'est produite. Veuillez réessayer."));
        }
        return { status: 'error' };
    }

    public register = async (userRegisterData: UserRegisterType): Promise<{ status: string; }> => {
        this.dispatch(userActions.updateRegisterError(null));
        localStorage.removeItem("userData");
        localStorage.removeItem("token");
        const response = await User.register(userRegisterData);

        if (response.status === 200) {
            const user = { ...response.data.data.user, ttl: Date.now() + User.TTL };
            const token = response.data.data.token;
            this.dispatch(userActions.updateCurrent(user));
            localStorage.setItem("userData", JSON.stringify(user));
            localStorage.setItem("token", JSON.stringify(token));
            this.dispatch(toastActions.display(RegisterToast));
            return { status: 'success' };
        } else if (response.status === 422) {
            this.dispatch(userActions.updateRegisterError(response.data.message));
        } else {
            this.dispatch(userActions.updateRegisterError("Une erreur s'est produite. Veuillez réessayer."));
        }
        return { status: 'error' };
    }

    public logout = () => {
        localStorage.removeItem("userData");
        localStorage.removeItem("token");
        this.dispatch(userActions.updateCurrent(null));
        this.dispatch(toastActions.display(LogoutToast));
    }
}

export default UserViewModel;