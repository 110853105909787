import { Container, Heading, Text } from "@chakra-ui/react";

const DadyCarServiceDescription: React.FC = () => {
    return <>
        <Container maxW={'80%'} w={{ lg: '1000px' }} py={10}>
            <Heading textAlign={'center'}>Merci d'avoir choisi Dadycar.</Heading>
            <Text textAlign={'center'} my={5}>
                Merci d'avoir choisi Dadycar pour vos besoins en entretien automobile. Pour finaliser votre rendez-vous, veuillez confirmer votre demande ci-dessous. Une fois confirmé,
                vous recevrez un devis personnalisé dans un délai de moins de 12 heures. Nous sommes impatients de vous fournir des services de qualité adaptés à vos besoins. Confirmez votre rendez-vous dès maintenant et laissez-nous prendre soin de votre véhicule.</Text>
            <Text textAlign={'center'} my={1}>
                Un chauffeur qualifié vient collecter votre véhicule à votre adresse pour l’apporter au garage.
            </Text>
            <Text textAlign={'center'} my={1}>
                Une fois l’entretien terminé, notre chauffeur vous restituera votre véhicule à l’adresse de votre choix.
            </Text>
        </Container>
    </>
}

export default DadyCarServiceDescription;