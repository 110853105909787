export const modeleOptions = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' }
]

export const brandOptions = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' }
]

export const versionOptions = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' }
]

export const customStyles = {
    control: (provided : any) => ({
      ...provided,
      height: '47px',
      minHeight: '47px',
      borderColor:'#E1E6EF',
      borderRadius:'8px'
    }),
    valueContainer: (provided : any) => ({
      ...provided,
      height: '47px',
      display: 'flex',
      alignItems: 'center',
      padding: '0 8px',
    }),
    input: (provided : any) => ({
      ...provided,
      margin: 0,
      padding: 0,
    }),
    indicatorsContainer: (provided : any) => ({
      ...provided,
      height: '47px',
    }),
    placeholder: (provided : any) => ({
      ...provided,
      margin: 0,
      padding: 0,
    }),
    indicatorSeparator: () => ({
        display: 'none',
      }),
  };

export interface FormValues {
immatricule?:string,
modele?:string,
brand?:string,
version?:string,
n_serie?:string,
anne_circulation?:string,
carburant?:string,
prestations : string[]
}