import HttpClient from '../services/HttpClient'

type RequestBodyType = {
    car_id?: number | undefined,
    vehicle_type?: string | undefined,
    departure_point: {lat: number, lng: number},
    arrival_point: {lat: number, lng: number},
    coming_point: {lat: number, lng: number}
}

type ConveyingRequestBodyType = {
    car_id?: number | undefined,
    vehicle_type?: string | undefined,
    departure_point: {lat: number, lng: number},
    arrival_point: {lat: number, lng: number},
}

class Distance {

    public static getDistanceWithPrice = async (RequestBody: RequestBodyType) => {
        return HttpClient.post('/distance-price', RequestBody);
    }

    public static getConveyingDistanceWithPrice = async (RequestBody: ConveyingRequestBodyType) => {
        return HttpClient.post('/distance-price', RequestBody);
    }
}

export default Distance;