import { Box, Button, Container, FormLabel, HStack, Stack, Textarea, VStack } from '@chakra-ui/react'
import * as yup from 'yup';
import { Form, Formik, FormikHelpers } from 'formik';
import { useEffect, useRef, useState } from 'react';
import FirstStep from './first-step/FirstStep';
import { FormValues } from '../constants';
import SecondStep from './second-step/SecondStep';
import ThirdStep from './third-step/ThirdStep';
import FourthStep from './fourth-step/FourthStep';



const formScehma = yup.object({
  immatricule:yup.string().optional(),
  modele:yup.string().optional(),
  brand:yup.string().optional(),
  version:yup.string().optional(),
  n_serie:yup.string().optional(),
  anne_circulation:yup.string().optional(),
  carburant:yup.string().optional(),
  prestations: yup.array()
    .of(yup.string())
    .min(1, "Vous devez selectionner au moins une prestation")
})



type Props = {
  activeStep:number,
  setActiveStep:any
}

const NewDevisForm = ({activeStep,setActiveStep}:Props) => {
  const initialValues: FormValues = { immatricule:"",modele:"",brand:"",version:"",n_serie:"",anne_circulation:"",carburant:"",prestations:[]};

    const onSubmitHandler = async (values: FormValues,resetForm:any) => {
      console.log(values)
    }

  return (
    <Box w={'100%'} mt={4} >
        <Formik
            initialValues={initialValues}
            validationSchema={formScehma}
            onSubmit={(values,{resetForm})=>{onSubmitHandler(values,resetForm)}}
        >
            {({ handleSubmit,setFieldValue,handleBlur,values }) => (
                <Form onSubmit={handleSubmit}>
                    {activeStep === 0 ? (
                      <FirstStep values={values} setFieldValue={setFieldValue} handleBlur={handleBlur} />
                    ) : activeStep === 1 ? (
                      <SecondStep values={values} setFieldValue={setFieldValue} handleBlur={handleBlur} />
                    ) : activeStep === 2 ? (
                      <ThirdStep />
                    ) : activeStep === 3 ? (
                      <FourthStep />
                    ) : null}
                    <HStack gap={4} w='100%' mt={2} my={4}>
                      {activeStep !== 0 ? (
                        <Button h={'67px'} flex={1} variant='ghost' borderWidth={'1px'} rounded={'94px'} borderColor={'#0F82EA'} color={'#0F82EA'} onClick={() => setActiveStep((prev : number)=>prev - 1)}>
                          Retour
                        </Button>
                      ) : null}
                      {activeStep !== 5 ? (
                        <Button h='67px' type='button' flex={1} rounded={'94px'} bg='#0F82EA' color={'white'} _hover={{ bg: '#0F82BA' }} onClick={() => setActiveStep((prev : number)=>prev + 1)}>
                          Suivant
                        </Button>
                      ) : (
                        <Button h='67px' type='submit' flex={1} rounded={'94px'} bg='#0F82EA' color={'white'} _hover={{ bg: '#0F82BA' }}>
                          Envoyer au client
                        </Button>
                      )}
                    </HStack>
                </Form>
            )}
        </Formik>
    </Box>
  )
}

export default NewDevisForm