import React, { useState, useCallback } from 'react'
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { VendorType } from '../../store/slices/vendor-slice';

const containerStyle = {
    width: '100%',
    height: '100%'
};

const key = 'AIzaSyAb-BWFEW91txKjDdja7vKhMWQyXseMtGw';

const Map: React.FC<{ vendors?: VendorType[], center: { lat: number, lng: number } }> = (props) => {
    const center = {
        lat: +props.center.lat,
        lng: +props.center.lng
    };

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: key
    })

    const [map, setMap] = useState<google.maps.Map | null>(null)

    const onLoad = useCallback(function callback(map: google.maps.Map) {
        setMap(map)
    }, [])

    const onUnmount = useCallback(function callback(map: google.maps.Map) {
        setMap(null)
    }, [])

    const renderMarkerIcon = () => {
        const iconColor = 'Gray';
        const iconSize = 36;

        const icon = {
            url: `data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="${iconColor}" width="${iconSize}" height="${iconSize}"><path d="M12 2C7.031 2 3 6.031 3 11.083c0 5.146 7.323 11.693 8.193 12.854.155.188.415.188.57 0C13.677 22.776 21 16.229 21 11.083 21 6.031 16.969 2 12 2zm0 9.542c-1.455 0-2.625-1.168-2.625-2.625S10.545 6.292 12 6.292s2.625 1.168 2.625 2.625S13.455 11.542 12 11.542z"/></svg>`,
            scaledSize: new window.google.maps.Size(iconSize, iconSize),
            anchor: new window.google.maps.Point(iconSize / 2, iconSize),
        };

        return icon;
    };

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={10}
            onLoad={onLoad}
            onUnmount={onUnmount}
        >
            <>
                <Marker position={{ lat: center.lat, lng: center.lng }} icon={renderMarkerIcon()} />
                {props.vendors?.map((vendor, index) => (
                    <>
                        {(vendor.lat && vendor.lng) && <Marker key={index} position={{ lat: vendor.lat, lng: vendor.lng }} />}
                    </>
                ))}
            </>
        </GoogleMap>
    ) : <></>
}


export default React.memo(Map);



{/* <InfoWindow options={{}} key={index} position={{ lat: vendor.lat, lng: vendor.lng }}>
    <Box>
        <Heading as='h4' fontSize='16px' fontWeight='800'>{vendor.price} €</Heading>
    </Box>
</InfoWindow> */}