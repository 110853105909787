import { HeaderSection, ServicesSection, ContentSection, SliderSection } from './components/_index'
import { Head } from '../../../components/blocks/_index'
import { useEffect } from 'react';
import About from './components/About';
import Convoyage from './components/Convoyage';
import DadyServices from './components/DadyServices';
import Sponsors from './components/Sponsors';
import FAQ from './components/FAQ';
import Contact from './components/Contact';
import { Box } from '@chakra-ui/react';

const title = 'DadyCar - Simplifiez la Gestion de votre Flotte Automobile';
const description = "DadyCar transforme la gestion de flottes avec une solution tout-en-un pour le transport, la maintenance et le car sharing. Optimisez vos coûts et améliorez l'efficacité opérationnelle avec notre logiciel intuitif et connecté.";
const keywords = 'gestion de flotte, maintenance véhicule, software de gestion, car sharing, optimisation de flotte, transport de véhicules';
const robots = 'index, follow';

const ProFleetPage: React.FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Head title={title} description={description} keywords={keywords} robots={robots} />
            <Box overflowX='hidden'>
                <HeaderSection />
                <Sponsors />
                <ServicesSection />
                <About />
                <Convoyage />
                <DadyServices />
                <FAQ />
                <Contact />
            </Box>
            {/* <ContentSection />
            <SliderSection /> */}
        </>
    )
}

export default ProFleetPage