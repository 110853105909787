import { useToast } from "@chakra-ui/react";
import { useEffect } from "react";
import { RootState } from "../../store";
import { useSelector } from "react-redux";

const Toast: React.FC = () => {
    const toast = useToast();
    const toastData = useSelector((state: RootState) => state.toast.value);
    const toastIndex = useSelector((state: RootState) => state.toast.index);

    useEffect(() => {
        if (toastData)
            toast({
                title: toastData.title,
                description: toastData.message,
                status: toastData.status,
                duration: 9000,
                isClosable: true,
                position: "top-right",
            });
    }, [toastIndex]);

    return null;
};

export default Toast;




