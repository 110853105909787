import { Box, Container, Flex, Heading, Image, Stack, Text } from '@chakra-ui/react'
import cardGraphic from '../../../../../assets/images/dadycar-checkout-graphic.webp'

const SummarySection: React.FC = () => {

    return (
        <>
            <Container maxW={'90%'} w={{ lg: '800px' }} py={16}>
                <Stack bg={'white'} borderRadius={'30px'} boxShadow={'md'}>
                    <Flex position={'relative'} justifyContent={{ base: 'center', lg: 'unset' }}>
                        <Box h={'100%'} display={{ base: 'none', lg: 'block' }}>
                            <Image src={cardGraphic} h={'310px'} />
                        </Box>
                        <Box mt={20} padding={5}>
                            <Heading fontSize={'2xl'} color={'#07D0C1'}>Félicitations ! Votre rendez-vous a été confirmé</Heading>
                            <Text my="2">
                                Félicitations ! Votre rendez-vous a été confirmé avec succès. Un conseiller de Dadycar  vous contactera sous peu pour discuter des détails de votre demande.
                            </Text>
                        </Box>
                    </Flex>
                </Stack>
            </Container >
        </>
    )
}

export default SummarySection;