import { Box, Button, Center, Select, SimpleGrid, Spinner, Stack, Textarea } from '@chakra-ui/react'
import { Form, Formik, FormikHelpers } from 'formik';
import InputField from './fields/InputField';
import * as yup from 'yup';
import { useState, useRef } from 'react';
import ExpertContactViewModel from '../../view-models/ExpertContactViewModel';
import { StoreExpertContactType } from '../../models/ExpertContact';
import { useNavigate } from 'react-router-dom';


const ExpertContactSchemaValidation = yup.object({
    first_name: yup.string().required("Veuillez insérer votre nom.").matches(/^[A-Za-z ]*$/, 'Veuillez entrer un nom valide').min(2, 'Le nom doit contenir au moins 2 caractères').max(255, 'Le nom doit comporter au maximum 255 caractères'),
    last_name: yup.string().required("Veuillez insérer votre prénom.").matches(/^[A-Za-z ]*$/, 'Veuillez entrer un prénom valide').min(2, 'Le prénom doit contenir au moins 2 caractères').max(255, 'Le prénom doit comporter au maximum 255 caractères'),
    company_name: yup.string().required("Veuillez insérer le nom de votre entrepris.").min(2, "Le nom de l'entrepris doit contenir au moins 2 caractères").max(255, "Le nom de l'entrepris doit comporter au maximum 255 caractères"),
    email: yup.string().required("Veuillez insérer votre email.").email('Veuillez insérer un e-mail valide.').max(255, 'Le nom doit comporter au maximum 255 caractères'),
    phone: yup.string().required("Veuillez insérer votre numéro de téléphone.").matches(/^[0-9]*$/, 'Veuillez entrer un numéro de téléphone valide').length(10, 'Le numéro de téléphone doit comporter 10 chiffres'),
})


const ExpertContactForm: React.FC<{ onExpertContactClose: () => void }> = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const companyProfileRef = useRef<HTMLSelectElement>(null);
    const messageRef = useRef<HTMLTextAreaElement>(null);
    const storeContact = (new ExpertContactViewModel()).store;
    const navigate = useNavigate();

    const onSubmitHandler = async (values: StoreExpertContactType) => {
        setIsLoading(true);
        values.company_profile = companyProfileRef.current?.value!;
        values.message = messageRef.current?.value!;
        const response = await storeContact(values);
        if (response.status === 'success') {
            setIsLoading(false);
            props.onExpertContactClose();
            navigate('/coordonnees-recues', { replace: true });
        } else
            setIsLoading(false);
    }

    return (
        <>
            <Box p={10}>
                <Formik
                    initialValues={{ first_name: '', last_name: '', company_name: '', company_profile: '', email: '', phone: '', message: '' }}
                    validationSchema={ExpertContactSchemaValidation}
                    onSubmit={(values: StoreExpertContactType, { setSubmitting }: FormikHelpers<StoreExpertContactType>) => {
                        setTimeout(() => {
                            onSubmitHandler(values);
                            setSubmitting(false);
                        }, 500);
                    }}
                >
                    <Form>
                        <Stack spacing={4}>
                            <SimpleGrid templateColumns={{ base: '1fr', lg: '2fr 2fr' }} spacing={6}>
                                <InputField name="first_name" type="text" placeholder="Prénom" bg={'#E8EAF1'} />
                                <InputField name="last_name" type="text" placeholder="Nom" bg={'#E8EAF1'} />
                                <InputField name="company_name" type="text" placeholder="Nom de l’entreprise" bg={'#E8EAF1'} />
                                <Select name="company_profile" ref={companyProfileRef} placeholder="Profil de l’entreprise" bg={'#E8EAF1'} required>
                                    <option value="C">Loueur courte durée</option>
                                    <option value="L">Loueur longue durée</option>
                                    <option value="F">Entreprise qui détient une flotte</option>
                                    <option value="A">Autre</option>
                                </Select>
                                <InputField name="email" type="email" placeholder="Email" bg={'#E8EAF1'} />
                                <InputField name="phone" type="text" placeholder="Téléphone" maxLength={10} bg={'#E8EAF1'} />
                            </SimpleGrid>
                            <Textarea name='message' ref={messageRef} borderRadius={'2xl'} bg={'#E8EAF1'} placeholder={'Laisser un commentaire'}></Textarea>

                            <Button type='submit' bg={'#07D0C1'} color={'white'} borderRadius={'xl'} _hover={{ bg: '#07D0C1' }}>
                                {isLoading ?
                                    <Center>
                                        <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='md' />
                                    </Center>
                                    :
                                    <>
                                        Etre contacté par votre expert Dadycar
                                    </>
                                }
                            </Button>

                        </Stack>
                    </Form>
                </Formik>
            </Box>
        </>
    )
}

export default ExpertContactForm;