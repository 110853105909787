import { Box, FormControl, FormLabel, Grid, Input, Text } from '@chakra-ui/react'
import { Field, FieldProps } from 'formik'

const ClientForm = () => {
  return (
    <Box flex={1.5}>
        <Text fontSize={'22px'} fontWeight={700} color={'#222D50'} mb={4}>Client</Text>
        <Grid templateColumns={`repeat(2,1fr)`} gap={4}>
            <Box>
                <Field name={'immatricule'}>
                    {({ field }: FieldProps) => (
                    <FormControl>
                        <FormLabel fontWeight={600} color={'#1D2433'}>Nom</FormLabel>
                        <Input
                            py={'8px'}
                            px={'16px'}
                            outline={'none'}
                            borderColor={'#E1E6EF'}
                            _focus={{borderColor:'#2F6FED'}}
                            rounded={'8px'}
                            type='text'
                            {...field} 
                            id={'immatricule'}
                            placeholder='Nom'
                            h={'47px'}
                        />
                    </FormControl>
                    )}
                </Field>
            </Box>
            <Box>
                <Field name={'immatricule'}>
                    {({ field }: FieldProps) => (
                    <FormControl>
                        <FormLabel fontWeight={600} color={'#1D2433'}>Prénom</FormLabel>
                        <Input
                            py={'8px'}
                            px={'16px'}
                            outline={'none'}
                            borderColor={'#E1E6EF'}
                            _focus={{borderColor:'#2F6FED'}}
                            rounded={'8px'}
                            type='text'
                            {...field} 
                            id={'immatricule'}
                            placeholder='Prénom'
                            h={'47px'}
                        />
                    </FormControl>
                    )}
                </Field>
            </Box>
        </Grid>
        <Box>
            <Field name={'immatricule'}>
                {({ field }: FieldProps) => (
                <FormControl>
                    <FormLabel fontWeight={600} color={'#1D2433'} my={2}>Numéro Telephone</FormLabel>
                    <Input
                        py={'8px'}
                        px={'16px'}
                        outline={'none'}
                        borderColor={'#E1E6EF'}
                        _focus={{borderColor:'#2F6FED'}}
                        rounded={'8px'}
                        type='text'
                        {...field} 
                        id={'immatricule'}
                        placeholder='Ex : Renault'
                        h={'47px'}
                    />
                </FormControl>
                )}
            </Field>
        </Box>
        <Box>
            <Field name={'immatricule'}>
                {({ field }: FieldProps) => (
                <FormControl>
                    <FormLabel fontWeight={600} color={'#1D2433'} mt={2}>Email</FormLabel>
                    <Input
                        py={'8px'}
                        px={'16px'}
                        outline={'none'}
                        borderColor={'#E1E6EF'}
                        _focus={{borderColor:'#2F6FED'}}
                        rounded={'8px'}
                        type='text'
                        {...field} 
                        id={'immatricule'}
                        placeholder='Ex : Renault'
                        h={'47px'}
                    />
                </FormControl>
                )}
            </Field>
        </Box>
        <Box>
            <Field name={'immatricule'}>
                {({ field }: FieldProps) => (
                <FormControl>
                    <FormLabel fontWeight={600} color={'#1D2433'} my={2}>Nom Enterprise</FormLabel>
                    <Input
                        py={'8px'}
                        px={'16px'}
                        outline={'none'}
                        borderColor={'#E1E6EF'}
                        _focus={{borderColor:'#2F6FED'}}
                        rounded={'8px'}
                        type='text'
                        {...field} 
                        id={'immatricule'}
                        placeholder='Ex : Renault'
                        h={'47px'}
                    />
                </FormControl>
                )}
            </Field>
        </Box>
    </Box>
  )
}

export default ClientForm