import { Card, CardBody } from '@chakra-ui/card'
import { Box, Container, Heading, SimpleGrid, Stack } from '@chakra-ui/layout'
import { CardHeader } from '@chakra-ui/react'
import SectionHeader from '../../../components/sections/SectionHeader'

const CarModels = () => {
    return (
        <Box mt={12} bg={'#F6FAFC'} py={12}>
            <SectionHeader title={`Modèles populaires`} />
            

            <Container
                maxW={'80%'}
                w={{ lg: '1000px' }}
                py={10}
                px={{ lg: 12 }}
            >
                <Box py={6}>
                    <SimpleGrid templateColumns={{base:'1fr' , lg: '2fr 2fr 2fr 2fr ',md:'2fr 2fr 2fr' }} spacing={6}>
                        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14].map(item => (
                            <Stack key={item}>
                                <Card align='center'  borderRadius={'20px'} border={'2px solid transparent'} h={'100%'} _hover={{ border: '2px solid #1385EB' }} bg={{ base: 'transparent', lg: 'white' }} >
                                    <CardHeader
                                        backgroundImage={'https://www.vroomly.com/media/thumbnails/modeldisplay/77/jfWfHBmxDASK_150x150_mtdhGWCw.jpg'}
                                        backgroundRepeat={'no-repeat'}
                                        backgroundSize={'contain'}
                                        backgroundPosition={'center'}
                                        h={'150px'}
                                        w={'100%'}>

                                    </CardHeader>
                                    <CardBody p={4}>
                                        <Heading textAlign={'center'} color={'#1385EB'} my={3} size={'xs'}>Dacia Duster</Heading>
                                    </CardBody>
                                </Card>
                            </Stack>
                        ))}
                    </SimpleGrid>
                </Box>
            </Container>
        </Box>

    )
}

export default CarModels