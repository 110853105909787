import { useDispatch } from 'react-redux';
import { Appointment } from '../models/_index';
import { StoreAppointmentType } from '../models/Appointment';
import { toastActions } from '../store/slices/toast-slice';
import { appointmentActions } from '../store/slices/appointment-slice';

const StoreAppointmentToast = {
    title: "Rendez-vous créé",
    message: "Votre rendez-vous a été créé avec succès.",
    status: "success",
}

const StoreAppointmentErrorToast = {
    title: "Votre rendez-vous n'a pas pu être créé",
    message: "Quelque chose s'est mal passé, Veuillez réessayer ou contacter le support.",
    status: "error",
}

class VendorViewModel {

    dispatch = useDispatch();

    public storeAppointment = async (body: StoreAppointmentType) => {
        this.dispatch(toastActions.reset());
        const response = await Appointment.store(body);

        if (response.status === 200) {
            this.dispatch(appointmentActions.updateValue(response.data.data.appointment));
            this.dispatch(appointmentActions.updateClientSecret(response.data.data.clientSecret));
            this.dispatch(toastActions.display(StoreAppointmentToast));
            return 'success';
        } else {
            this.dispatch(toastActions.display(StoreAppointmentErrorToast));
            return 'error';
        }
    }

    public getUserAppointments = async () => {
        const response = await Appointment.getUserAppointments();
        if (response.status === 200) {
            this.dispatch(appointmentActions.updateList(response.data.data));
            return 'success';
        } else {
            return 'error';
        }
    }

    public getAppointmentClientSecret = async (appointmentId: number): Promise<{ status: string }> => {
        const response = await Appointment.getAppointmentClientSecret(appointmentId);

        if (response.status === 200) {
            this.dispatch(appointmentActions.updateClientSecret(response.data.data.clientSecret));
            this.dispatch(appointmentActions.updateFinalPrice(response.data.data.finalPrice));
            return { status: 'success' };
        } else {
            return { status: 'error' };
        }
    }
}

export default VendorViewModel;