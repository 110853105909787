import { Card, CardBody } from '@chakra-ui/card'
import { Box, Container, Heading, SimpleGrid, Stack } from '@chakra-ui/layout'
import { CardHeader } from '@chakra-ui/react'
import SectionHeader from '../../../components/sections/SectionHeader'

const BrandsSection = () => {
    const brands = [
        {
            picture: "https://www.vroomly.com/static/images/brandlogos/bmw.webp",
            name: "BMW"
        },
        {
            picture: "https://www.vroomly.com/static/images/brandlogos/alfa-romeo.webp",
            name: "ALPHA ROMEO"
        },
        {
            picture: "https://www.vroomly.com/static/images/brandlogos/audi.webp",
            name: "AUDI"
        },
        {
            picture: "https://www.vroomly.com/static/images/brandlogos/austin.webp",
            name: "AUSTIN"
        },
        {
            picture: "https://www.vroomly.com/static/images/brandlogos/chrysler.webp",
            name: "CHRYSLER"
        },
        {
            picture: "https://www.vroomly.com/static/images/brandlogos/chevrolet.webp",
            name: "CHEVROLET"
        },
        {
            picture: "https://www.vroomly.com/static/images/brandlogos/citroen.webp",
            name: "CITROEN"
        },
        {
            picture: "https://www.vroomly.com/static/images/brandlogos/dacia.webp",
            name: "DACIA"
        },
        {
            picture: "https://www.vroomly.com/static/images/brandlogos/fiat.webp",
            name: "FIAT"
        },
        {
            picture:"https://www.vroomly.com/static/images/brandlogos/jaguar.webp",
            name: "JAGUAR"
        },
        {
            picture:"https://www.vroomly.com/static/images/brandlogos/land-rover.webp",
            name: "LAND ROVER"
        },
        {
            picture:"https://www.vroomly.com/static/images/brandlogos/peugeot.webp",
            name: "PEUGEOT"
        },
    ]
    return (
        <Box mt={12} bg={'whiteAlpha.700'}  py={12}>
            <SectionHeader title={`Marques populaires`} />
            

            <Container
                maxW={'80%'}
                w={{ lg: '1000px' }}
                py={10}
                px={{ lg: 12 }}
            >
                <Box as='div' py={6}>
                    <SimpleGrid templateColumns={{base:'1fr', lg: '1fr 1fr 1fr 1fr 1fr 1fr', md:'2fr 2fr 2fr'}} spacing={6}>
                        {brands.map((item,idx) => (
                            <Stack key={idx}>
                                <Card  align='center' boxShadow={'none'} h={'100%'}  bg={{ base: 'transparent' }} >
                                    <CardHeader
                                        backgroundImage={item.picture}
                                        backgroundRepeat={'no-repeat'}
                                        backgroundSize={'contain'}
                                        backgroundPosition={'center'}
                                        h={'150px'}
                                        w={'100px'}>

                                    </CardHeader>
                                    <CardBody p={4}>
                                        <Heading textAlign={'center'}  my={3} size={'xs'}>{item.name}</Heading>
                                    </CardBody>
                                </Card>
                            </Stack>
                        ))}
                    </SimpleGrid>
                </Box>
            </Container>
        </Box>

    )
}

export default BrandsSection