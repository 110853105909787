import { Image } from '@chakra-ui/image'
import { Box, Center, Container, Heading, SimpleGrid, Stack, Text } from '@chakra-ui/layout'
import debitCardIcon from '../../../assets/images/dadycar-debit-card.webp'
import calendarIcon from '../../../assets/images/dadycar-calendar-icon.webp'
import cashPaymentIcon from '../../../assets/images/dadycar-cash-payment.webp'
import reliabilityIcon from '../../../assets/images/dadycar-reliability.webp'
import trustIcon from '../../../assets/images/dadycar-trust.webp'
import loveIcon from '../../../assets/images/dadycar-love.webp'
import dots from '../../../assets/images/dadycar-dots-graphics.webp'

const AdventageSection: React.FC = () => {
    const data = [{
        image: debitCardIcon,
        title: 'Rémunération engageante',
        text: 'Bénéficiez de revenus en travaillant à votre gré.'
    }, {
        image: calendarIcon,
        title: 'Selon votre envie',
        text: "Acceptez les missions que vous souhaitez accomplir"
    }, {
        image: cashPaymentIcon,
        title: "Paiement tous les 15 jours",
        text: "Recevez votre paiement deux fois par mois"
    }, {
        image: reliabilityIcon,
        title: "Multiples missions",
        text: "Profitez d’un éventail de missions (véhicule, distance…)"
    }, {
        image: trustIcon,
        title: "Vous êtes assuré",
        text: "Soyez tranquille, notre partenaire MMA vous protège"
    }, {
        image: loveIcon,
        title: "Une équipe solide",
        text: "Intégrez une merveilleuse équipe soudée  "
    }]
    return (
        <Box  py={5}>

            <Container 
                maxW={'80%'}
                py={{ base: 0, lg: 12 }}
                w={{ lg: '1000px' }}
                position={'relative'}
            >
                <Box  position={'absolute'} bottom={0} left={-10} zIndex={-1} display={{base:'none',lg:'unset'}}>
                    <Image alt='Dadycar element' src={dots} htmlHeight={"100%"} htmlWidth={"100%"} h={100} w={'auto'} />
                </Box>
                <Box position={'absolute'} top={0} right={-10} zIndex={-1} display={{base:'none',lg:'unset'}}>
                    <Image alt='Dadycar element' src={dots} htmlHeight={"100%"} htmlWidth={"100%"} h={100} w={'auto'} />
                </Box>
                <Box
                    px={5}
                    py={{ base: 0, lg: 12 }}
                    bg={'white'}
                    boxShadow={'md'}
                    borderRadius={'2xl'}>
                    <Center my={0} mx={10} py={10}>
                        <Heading textAlign={'center'}>Les avantages d’être chauffeur Dadycar :</Heading>
                    </Center>
                    <SimpleGrid templateColumns={{ base: '2fr', lg: '2fr 2fr 2fr' }} spacing={12}>
                        {data.map((item, idx) => (
                            <Stack key={idx}>
                                <Center>
                                    <Image alt={item.title} src={item.image} h={'70px'} htmlHeight={"100%"} htmlWidth={"100%"} w={'auto'}/>
                                </Center>
                                <Center>
                                    <Text color={'#0F82EA'} textAlign={'center'}>{item.title}</Text>
                                </Center>
                                <Center py={5}>
                                    <Text textAlign={'center'}>{item.text}</Text>
                                </Center>
                            </Stack>
                        ))}
                    </SimpleGrid>
                </Box>
            </Container>
        </Box>

    )
}

export default AdventageSection;