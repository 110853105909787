import React, { useEffect } from "react";
import { AboutSection, HelpSection, InformationSection, LoginSection, PartenerSection, RegisterSection, ServicesSection } from "./components/_index";
import Head from "../../../components/blocks/Head";

const title = "Garagistes";
const description = "Vous êtes un garagiste à la recherche de nouveaux clients ? Inscrivez-vous sur Dadycar pour les garagistes et rejoignez notre réseau de professionnels de la maintenance automobile en ligne. Grâce à notre plateforme de qualité, vous pourrez offrir vos services à une clientèle en recherche d'un garage de confiance et développer votre entreprise.";
const keywords = "garagiste, nouveaux clients, inscription, réseau, professionnels, maintenance automobile, ligne, plateforme, qualité, entreprise";

const VendorAuthPage: React.FC<{ action: string }> = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Head title={title} description={description} keywords={keywords} />
      {props.action === "login" ? <LoginSection /> : <RegisterSection />}
      <InformationSection />
      <ServicesSection />
      <AboutSection />
      <HelpSection />
      <PartenerSection />
    </>
  )
}

export default VendorAuthPage;