import { Card, CardBody, Heading, Stack } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { CityType } from "../../../../models/City";

const CityCard: React.FC<{ city: CityType }> = ({ city }) => {
    return <>
        <Stack>
            <Link to={`/garages/ville/${city.slug}`} >
                <Card align='center' borderRadius={'20px'} border={'2px solid transparent'} h={'100%'} _hover={{ border: '2px solid #1385EB' }} bg={{ base: 'transparent', hover: 'white' }} >
                    <CardBody p={4}>
                        <Heading textAlign={'center'} my={3} size={'xs'}>{city.name}</Heading>
                    </CardBody>
                </Card>
            </Link>
        </Stack>
    </>
}

export default CityCard;