import { useEffect } from 'react';
import { useParams } from 'react-router-dom'
import { LayoutSection, SubServiceSection } from './components/_index'

const SubServicePage: React.FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const subServiceParams = useParams();

    return (
        <>
            <LayoutSection subService={subServiceParams["subServiceParams"]}>
                <SubServiceSection />
            </LayoutSection>
        </>
    )
}

export default SubServicePage;