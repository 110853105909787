import { Box, Button, Center, Image, Spinner, Stack, Text, Link as ChakraLink } from '@chakra-ui/react';
import { BsArrowRight } from 'react-icons/bs';
import logo from '../../assets/images/dadycar-logo.webp';
import { Form, Formik, FormikHelpers } from 'formik';
import InputField from './fields/InputField';
import * as yup from 'yup';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { DriverLoginType } from '../../models/Driver';
import DriverViewModel from '../../view-models/DriverViewModel';
import HttpClient from '../../services/HttpClient';

const loginSchemaValidation = yup.object({
  email: yup.string().required("Veuillez insérer votre email.").email('Veuillez insérer un e-mail valide.'),
  password: yup.string().required('Veuillez insérer votre mot de passe.')
})

const DriverLoginForm: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const loginDriver = (new DriverViewModel()).login;

  const onSubmitHandler = async (values: DriverLoginType) => {
    setIsLoading(true);
    const response = await loginDriver(values);
    if (response.status === 'success') {
      window.location.href = HttpClient.host  + 'driver';
    }
    setIsLoading(false);
  }

  const redirectionToResetPassword = () => {
    window.location.href = HttpClient.host + 'driver/password/reset';
  }

  return (
    <>
      <Box>
        <Stack align={'center'}>
          <Image src={logo} width={'100px'} />
        </Stack>
        <Stack spacing={4}>
          <Formik
            initialValues={{ email: '', password: '' }}
            validationSchema={loginSchemaValidation}
            onSubmit={(values: DriverLoginType, { setSubmitting }: FormikHelpers<DriverLoginType>) => {
              setTimeout(() => {
                onSubmitHandler(values);
                setSubmitting(false);
              }, 500);
            }}
          >
            <Form>
              <Stack spacing={4}>
                <InputField label="Adresse mail" name="email" type="email" placeholder="...@example.com" />
                <InputField label="Mot de passe" name="password" placeholder="Mot de passe" type="password" />
                <Button type="submit" borderRadius={'lg'} bg={'#5393EC'} color={'white'} _hover={{ bg: 'blue.500' }} disabled={isLoading}>
                  {isLoading ?
                    <Center>
                      <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='md' />
                    </Center>
                    :
                    <>
                      Se connecter
                      < BsArrowRight />
                    </>
                  }
                </Button>

              </Stack>
            </Form>
          </Formik>

          <Stack spacing={6}>
            <Stack direction={{ base: 'column', sm: 'row' }} align={'start'} justify={'space-between'}>
              <ChakraLink color={'blue.400'} onClick={redirectionToResetPassword}>Mot de passe oublié ?</ChakraLink>
            </Stack>
            <Stack>
              <Text textAlign={'center'}>Vous n’avez pas de compte ?</Text>
            </Stack>
            <Stack>
              <Link to='/chauffeur/inscription'>
                <Button w={'100%'} borderRadius={'lg'} bg={'#07D0C1'} color={'white'} _hover={{ bg: '#07D0C1' }}>
                  S’enregistrer
                </Button>
              </Link>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </>
  )
}

export default DriverLoginForm;