import { useDispatch } from 'react-redux';
import { versionActions, VersionType } from '../store/slices/version-slice';
import { Version } from '../models/_index';
import { vehicleActions } from '../store/slices/vehicle-slice';

class VersionViewModel {

    dispatch = useDispatch();

    public getModelVersions = async (vehicleModelId: number) => {
        const response = await Version.getModelVersions(vehicleModelId);
        if (response.status === 200)
            this.dispatch(versionActions.updateList(response.data));
    }

    public selectVersion = (version: VersionType) => {
        this.dispatch(versionActions.updateSelected(version));
    }

    public removeSelectedVersion = () => {
        this.dispatch(versionActions.removeSelected());
    }
}

export default VersionViewModel;