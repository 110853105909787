import { useEffect } from "react";

const UserProfilePage: React.FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    
    return <>
    </>
}

export default UserProfilePage;