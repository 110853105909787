import axios, { AxiosError, AxiosResponse } from 'axios';

class HttpClient {

    public static version = 'api/v1';
    public static host = process.env.REACT_APP_HOST!;
    private readonly token: string | null;

    constructor() {
        this.token = localStorage.getItem('token');
    }
    public get postConfig() {
        return {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.token?.replace(/"/g, '')}`,
            },
            withCredentials: true
        };
    }

    public get getConfig() {
        return {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.token?.replace(/"/g, '')}`,
            }
        };
    }

    public static defaultError = {
        status: 500,
        message: "Une erreur s'est produite. Veuillez réessayer",
        data: {}
    }

    public static post = async (apiUrl: string, requestBody: object) => {
        const httpClient = new HttpClient();
        let fullUrl = HttpClient.host + HttpClient.version + apiUrl;

        return await axios.post(fullUrl, requestBody, httpClient.postConfig)
            .then((response: AxiosResponse) => response)
            .catch((error: AxiosError) => {
                if (!error.response)
                    return HttpClient.defaultError;

                switch (error.response?.status) {
                    case 419:
                        return axios.get(HttpClient.host + 'sanctum/csrf-cookie', httpClient.postConfig).then(() => {
                            return axios.post(fullUrl, requestBody, httpClient.postConfig).catch((error: any) => error.response);
                        }).catch((error: any) => error.response);
                    case 422:
                        return error.response;
                    default: return error.response;
                }
            });
    }

    public static get = async (apiUrl: string, host = HttpClient.host + HttpClient.version, config?: {}) => {
        const httpClient = new HttpClient();
        const getConfig = config ? config : httpClient.getConfig;
        return await axios.get(host + apiUrl, getConfig)
            .then((response: AxiosResponse) => response)
            .catch((error: AxiosError) => {
                if (!error.response)
                    return HttpClient.defaultError;

                return error.response;
            });
    }
}

export default HttpClient;