import { Box, Flex, SimpleGrid, Stack, Text } from '@chakra-ui/layout'
import workerBg from '../../../../assets/images/dadycar-worker-bg.webp'
import { useColorModeValue } from '@chakra-ui/color-mode'
import { VendorLoginForm } from '../../../../components/forms/_index';

const LoginSection: React.FC = () => {
    return (
        <Box backgroundImage={workerBg}
            backgroundSize={{ lg: 'cover' }}
            backgroundRepeat={'no-repeat'}
            backgroundPosition={{ base: 'top', lg: 'center' }}>
            <SimpleGrid templateColumns={{ base: '2fr', lg: '2fr 2fr' }}>
                <Stack display={{ base: 'none', lg: 'unset' }}></Stack>
                <Stack py={{ base: 24, lg: 24, md: 20 }}>
                    <Box w={'100%'} >
                        <Flex
                            align={'center'}
                            justify={'center'}
                        >
                            <Stack w={{ base: '90%', lg: '60%' }} maxH={'90%'}>
                                <Box borderRadius={'3xl'} bg={useColorModeValue('white', 'gray.700')} boxShadow={'md'} p={8}>
                                    <VendorLoginForm />
                                </Box>
                            </Stack>
                        </Flex>
                    </Box>
                </Stack>
            </SimpleGrid>
        </Box>
    )
}
export default LoginSection