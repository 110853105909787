import React, { useEffect } from 'react'
import { LayoutSection, QuestionsSection } from './components/_index'

const QuestionsPage: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <LayoutSection>
      <QuestionsSection />
    </LayoutSection>
  )
}

export default QuestionsPage