import { useEffect } from 'react';
import { BookingSection, SuggestionsSection, VendorsSection } from './components/_index'

const VendorsSearchPage: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <BookingSection />
      <SuggestionsSection />
      <VendorsSection />
    </>
  )
}

export default VendorsSearchPage;