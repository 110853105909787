import { Box, Button, Center, Container, Text } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import SectionHeader from '../../../components/sections/SectionHeader'

const ContentSection = () => {
    return (
        <>
            <Box mt={12} bg={'#F6FAFC'} py={20}>
                <SectionHeader title={`Merci !`} color={'#5393EC'} />
                <Container maxW={'90%'} w={'1000px'} p={5}>
                    <Text fontSize={'xl'} textAlign={'center'}>
                        Un expert Dadycar Fleet vous contactera sous peu pour discuter de la façon dont nous pouvons vous aider.
                    </Text>
                    <br />
                    <Center>
                        <Link to={'/professionnel'}>
                            <Button transition={'ease-in'} w={{ base: '100%', lg: 'initial' }} color={'white'} borderRadius={'xl'} width={200} _hover={{ bg: '#5393EC' }} bg={{ base: 'linear-gradient(90deg, #00B1B7 0%, #0075F5 100%)', lg: '#5393EC' }}>
                                Retour
                            </Button>
                        </Link>
                    </Center>
                </Container >
            </Box >
        </>
    )
}

export default ContentSection