import { Box, Button, Center, Container, Flex, Heading, Image, Stack, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom';
import cardGraphic from '../../../../../assets/images/dadycar-checkout-graphic.webp'

const SummarySection: React.FC = () => {
    const navigate = useNavigate();
    const handleReturnBack = () => {
        navigate(-1);
    }

    return (
        <>
            <Container maxW={'90%'} w={{ lg: '800px' }} py={16}>
                <Stack bg={'white'} borderRadius={'30px'} boxShadow={'md'}>
                    <Flex position={'relative'} justifyContent={{ base: 'center', lg: 'unset' }}>
                        <Box h={'100%'} display={{ base: 'none', lg: 'block' }}>
                            <Image src={cardGraphic} h={'310px'} />
                        </Box>
                        <Box mt={20} padding={5}>
                            <Heading fontSize={'2xl'} color={'#07D0C1'}>Félicitations vous avez confirmé votre rendez-vous</Heading>
                            <Text my="2">
                                Nous vous contacterons bientôt pour discuter de la façon dont nous récupérerons votre véhicule.
                            </Text>
                            <br />
                            <Center>
                                <Button onClick={handleReturnBack} transition={'ease-in'} w={{ base: '100%', lg: 'initial' }} color={'white'} borderRadius={'xl'} width={200} _hover={{ bg: '#07D0C1' }} bg={{ base: 'linear-gradient(90deg, #00B1B7 0%, #0075F5 100%)', lg: '#07D0C1' }}>
                                    Retour
                                </Button>
                            </Center>
                        </Box>
                    </Flex>
                </Stack>
            </Container >
        </>
    )
}

export default SummarySection;