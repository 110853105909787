import React from 'react';
import { Box, Modal, ModalContent, ModalOverlay } from '@chakra-ui/react';
import { StripeElements } from '../stripe/_index';

const StripeModal: React.FC<{ isStripeOpen: boolean, onStripeClose: () => void, clientSecret: string, successRoute: string, type?: string }> = (props: any) => {
    const type = props.type ? props.type : 'appoitment';
    return (
        <>
            <Modal isOpen={props.isStripeOpen} onClose={props.onStripeClose} size={'lg'}>
                <ModalOverlay />
                <ModalContent borderRadius={'2xl'}>
                    <Box p={10}>
                        <StripeElements clientSecret={props.clientSecret} successRoute={props.successRoute} type={type} />
                    </Box>
                </ModalContent>
            </Modal>
        </>
    )
}

export default StripeModal;