import HttpClient from '../services/HttpClient'


type ConveyingType = {
    id?: number,
    client_id: number,
    from_date: string,
    to_date: string,

    price?: string,
    discount?: string,
    priceToPay?: string,
    leftToPay?: string,
    status?: StatusType,

    vehicle_id: number,
    search_type: string,
    vehicle_numberplate?: string,

    vehicle_mark?: string,
    vehicle_model?: string,

    owner_first_name: string,
    owner_last_name: string,
    company_name: string,
    owner_email: string,
    owner_phone: string,
    owner_address: string,

    departure_contact_first_name: string,
    departure_contact_last_name: string,
    departure_contact_email: string,
    departure_contact_phone: string,
    departure_address: string,

    arrival_contact_first_name: string,
    arrival_contact_last_name: string,
    arrival_contact_email: string,
    arrival_contact_phone: string,
    arrival_address: string,
};

type StatusType = {
    title: string,
    description: string,
    status_date: string,
    is_last: boolean,
}

class Conveying {

    public static getClientConveyings = async () => {
        return HttpClient.get('/conveying/list');
    }

    public static store = async (RequestBody: ConveyingType) => {
        return HttpClient.post('/conveying/store', RequestBody);
    }

}

export { type ConveyingType };
export default Conveying;