import { Outlet } from "react-router-dom";
import Navbar from "../../../components/blocks/V2/Navbar";
import Footer from "../../../components/blocks/V2/Footer";

const RootLayout: React.FC = () => {
    return <>
        <Navbar />
        <Outlet />
        <Footer />
    </>
}

export default RootLayout;