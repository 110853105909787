import { Box, Flex, Text, useSteps } from "@chakra-ui/react"
import NewDevisForm from "./components/NewDevisForm"
import StepsBox from "./components/StepsBox"

const steps = [
    { title: 'Sélection du véhicule '},
    { title: 'Prestations'},
    { title: 'Devis'},
    { title: 'Client'},
]

const NewDevis = () => {
    const { activeStep ,setActiveStep} = useSteps({
        index: 0,
        count: steps.length,
    })

  return (
    <Box bg='#fafafa'>
        <Box px={4} py={2} gap={4}>
          <Box bg={'white'} p={4} boxShadow={'sm'} >
            <Text fontSize={'24px'} fontWeight={600} color={'#222D50'} mb={2} >Cree Devis</Text>
            <Box maxW={'1300px'} mx={'auto'}>
                <StepsBox steps={steps} activeStep={activeStep} setActiveStep={setActiveStep} />
                <NewDevisForm activeStep={activeStep} setActiveStep={setActiveStep}/>
            </Box>
          </Box>
        </Box>
    </Box>
  )
}

export default NewDevis