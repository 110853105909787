import { Box, Heading, ListItem, OrderedList, Text } from '@chakra-ui/react'
import SectionHeader from '../../../components/sections/SectionHeader'

const LegalNoticeSection = () => {
    return (
        <>
            <Box mt={12} bg={'#F6FAFC'} py={12}>
                <SectionHeader title={`mentions légales`} />
                <Box p={5}>
                    <Heading color={'#1385EB'}>Conditions Générales d’Utilisation et de Services</Heading>
                    <Text >
                        Date de dernière mise à jour : 09/06/2022
                    </Text>
                    <br />
                    <Text>
                        Bienvenue sur le site Internet Dadycar.fr https://www.Dadycar.fr/ (le « Site » ou « Site Internet ») édité par la société Dadycar.fr dont le siège social est situé au 36 Rue Raspail, 92300 Levallois-Perret, immatriculée au RCS de Nanterre sous le numéro 920955366(« Dadycar.fr » ou « Dadycar») (« Nous », « Notre » ou « Nos »). Dans les Conditions Générales d'Utilisation et de Services (“CGV”), « Vous » « Votre » et « Vos » désignent la personne qui utilise ou visite le Site.
                    </Text>
                    <br />
                    <Text>
                        Les CGV définissent la manière dont vous pouvez utiliser le Site Internet et les services d'entretien automobile accessibles par le biais du Site (les "Services").
                    </Text>
                    <br />
                    <Text>
                        Veuillez lire attentivement ces CGV avant de Nous soumettre votre commande. Notre Politique de Confidentialité est une partie essentielle de ces CGV et en acceptant ces CGV, Vous reconnaissez avoir pris connaissance de Notre Politique de Confidentialité.
                    </Text>
                    <br />
                    <Text>
                        Les CGV entrent en vigueur et sont opposables à compter de Votre acceptation des présentes et ce, pendant toute la durée d’utilisation du Site Internet et des Services.
                    </Text>
                    <br />
                    <Text>
                        Nous pouvons être amenés à modifier les CGV. Toutefois, si tel est le cas, Nous Vous informerons de toutes les modifications que Nous allons apporter, dans un délai raisonnable avant la date à laquelle ces modifications prendront effet. Nous Vous inviterons également à accepter Nos nouvelles CGV. Si Vous n'acceptez pas ces modifications, Vous ne pourrez plus accéder ni utiliser le Site ou ses Services.
                    </Text>
                    <br />
                    <Box px={4}>
                        <Heading size={'xs'} color={'#1385EB'}>1. A PROPOS DE NOUS ET RÉCLAMATIONS</Heading>
                        <Box>
                            <OrderedList px={10}>
                                <ListItem>
                                    Le Site Internet est détenu et exploité par la société Dadycar.fr dont le siège social est situé au 36 Rue Raspail, 92300 Levallois-Perret, immatriculée au RCS de Nanterre sous le numéro 920955366.
                                </ListItem>
                                <ListItem>
                                    Si Vous souhaitez Nous contacter ou formuler une réclamation concernant tout élément contenu sur le Site Internet, veuillez Nous contacter en utilisant les coordonnées suivantes :
                                    Courriel : contact@dadycar.fr Adresse : 36 Rue Raspail, 92300 Levallois-Perret Numéro de téléphone :01 76 39 05 37
                                </ListItem>
                            </OrderedList>
                        </Box>
                        <Heading size={'xs'} color={'#1385EB'}>
                            2. ACCES AU SITE ET RESTRICTIONS DE LICENCE
                        </Heading>
                        <Box>
                            <OrderedList px={10}>
                                <ListItem>
                                    Le Site Internet et les Services sont destinés à être utilisés en France métropolitaine par des personnes résidant en France.
                                </ListItem>
                                <ListItem>
                                    Si vous êtes âgé de moins de 18 ans, veuillez ne pas utiliser le Site Internet. En utilisant le Site Internet, Vous Nous confirmez que Vous avez 18 ans ou plus et que Vous résidez en France.
                                </ListItem>
                                <ListItem>
                                    Le Site Internet est actuellement mis à Votre disposition pour Votre usage personnel et non commercial, gratuitement, bien que Nous facturions les Services que Vous commandez via le Site Internet. Vous acceptez que Nous puissions modifier cette configuration à une date ultérieure, à condition que Nous Vous en informions suffisamment longtemps en avance avant de commencer à facturer l'utilisation du Site Internet.
                                </ListItem>
                                <ListItem>
                                    Sous réserve de Votre respect permanent des GCV, Nous Vous accordons par les présentes une licence non exclusive, non cessible, non susceptible de sous-licence, révocable, pendant toute la durée d’utilisation du Site Internet et des Services, pour accéder au Site et l'utiliser sur tout appareil compatible (un « Appareil »), pour votre usage personnel et non commercial.
                                </ListItem>
                                <ListItem>
                                    Si Vous enfreignez une quelconque stipulation des GCV, cette licence prendra fin immédiatement et Vous devrez cesser immédiatement d'utiliser le Site.
                                </ListItem>
                                <ListItem>
                                    Vous êtes responsable de l'obtention (à vos propres frais) de tous les appareils et services de télécommunications nécessaires pour accéder au Site Internet. Il Vous incombe également de veiller à ce que personne n'utilise Votre Compte pour accéder au Site Internet et à ses Services sans Votre autorisation. Nous serons en droit de présumer que toute personne qui accède au Site Internet en utilisant Votre Compte a Votre permission de le faire et Vous serez responsable de tous les frais, coûts ou responsabilités qui peuvent être encourus par ces personnes lors de l'utilisation de Votre Compte.
                                </ListItem>
                                <ListItem>
                                    Sauf dans les cas expressément prévus dans les CGV ou autorisés par la loi applicable, Vous acceptez de :
                                    <br />
                                    <Box px={5}>
                                        <Text>1.  ne pas copier le Site Internet ;</Text>
                                        <Text>2.  ne pas louer, donner en crédit-bail, accorder une sous-licence, prêter, traduire, fusionner, adapter, varier ou modifier le Site Internet ; de ne pas apporter de modifications à tout ou partie du Site Internet, ni de permettre que le Site Internet ou une partie de celui-ci soit combiné ou intégré à d'autres programmes ; et</Text>
                                        <Text>3.  ne pas faire d'ingénierie inverse, décompiler, désassembler ou tenter de toute autre manière d'obtenir le code source du Site Internet.</Text>
                                    </Box>
                                </ListItem>
                            </OrderedList>
                        </Box>

                        <Heading size={'xs'} color={'#1385EB'}>
                            3.  UTILISATION DU SITE
                        </Heading>
                        <Box >
                            <OrderedList px={10}>
                                <ListItem>
                                    Vous ne devez pas :
                                    <OrderedList px={5}>
                                        <ListItem>
                                            renseigner de fausses informations lors de la création et de la mise à jour du profil de Votre Compte sur le Site Internet ;
                                        </ListItem>
                                        <ListItem>
                                            utiliser le Site Internet d'une manière inappropriée ou illégale ou en violation de toute législation ou licence qui s'applique à Vous ;
                                        </ListItem>
                                        <ListItem>
                                            enfreindre Nos Droits de Propriété Intellectuelle (tels que définis à l’article 12.1 ci-dessous) ou ceux d'un tiers dans le cadre de Votre utilisation du Site Internet ;
                                        </ListItem>
                                        <ListItem>
                                            transmettre de contenu diffamatoire, offensant ou autrement répréhensible en relation avec Votre utilisation du Site Internet ; et
                                        </ListItem>
                                        <ListItem>
                                            utiliser le Site Internet d'une manière susceptible d'endommager, de désactiver, de surcharger, d'altérer ou de compromettre Nos systèmes ou Notre sécurité ou d'interférer avec d'autres utilisateur
                                        </ListItem>
                                        <ListItem>
                                            transmettre de contenu diffamatoire, offensant ou autrement répréhensible en relation avec Votre utilisation du Site Internet ; et
                                        </ListItem>
                                        <ListItem>
                                            utiliser le Site Internet d'une manière susceptible d'endommager, de désactiver, de surcharger, d'altérer ou de compromettre Nos systèmes ou Notre sécurité ou d'interférer avec d'autres utilisateurs.
                                        </ListItem>
                                    </OrderedList>
                                </ListItem>
                                <ListItem>
                                    Par les présentes, vous nous accordez une licence non exclusive, gratuite, pouvant faire l'objet d'une sous-licence, transférable, pour toute la durée de protection des droits de propriété intellectuelle en vertu de la loi applicable pour héberger, reproduire, distribuer, communiquer, afficher publiquement, modifier ou utiliser les contenus originaux dont Vous êtes l’auteur, comme les avis que Vous rédigez à propos de Nos Services
                                </ListItem>
                                <ListItem>
                                    Vous êtes informés que Nous pouvons utiliser les données à caractère personnel que Vous Nous fournissez via le Site. Pour plus d'informations sur la manière dont Nous utiliserons Vos données, veuillez consulter notre Politique de Confidentialité (ajouter le lien).
                                </ListItem>
                                <ListItem>
                                    Vous acceptez de Vous conformer à toutes les instructions raisonnables que Nous pouvons Vous donner concernant Votre utilisation du Site.
                                </ListItem>
                            </OrderedList>
                        </Box>

                        <Heading size={'xs'} color={'#1385EB'}>
                            4.  VOTRE COMPTE
                        </Heading>
                        <Box>
                            <OrderedList px={10}>
                                <ListItem>
                                    Si Vous souhaitez commander des Services auprès de Nous, Vous devez d'abord créer un compte via notre Site Internet (Nous l’appelons Votre « Compte »).
                                </ListItem>
                                <ListItem>
                                    Lors de l'enregistrement d'un Compte, Vous devez Nous fournir des données exactes et complètes Vous concernant et votre voiture et maintenir ces informations à jour, sinon Nous pourrions ne pas être en mesure de traiter Votre Réservation.
                                </ListItem>
                                <ListItem>
                                    Votre Compte Vous est personnel et Vous devez donc garder Votre nom d'utilisateur et Votre mot de passe confidentiels, et ne pas les divulguer à toute autre personne ou entité.
                                </ListItem>
                                <ListItem>
                                    Si Vous pensez que quelqu'un d'autre que Vous a accédé à Votre Compte sans Votre autorisation ou si Vous perdez Votre nom d'utilisateur ou Votre mot de passe, Vous devez Nous en informer immédiatement en utilisant les coordonnées indiquées à l’article 1.2 ci-dessus.
                                </ListItem>
                            </OrderedList>
                        </Box>

                        <Heading size={'xs'} color={'#1385EB'}>
                            5.  COMMANDER NOS SERVICES
                        </Heading>
                        <Box>
                            <OrderedList px={10}>
                                <ListItem>
                                    Nous ne fournissons des Services que dans certaines régions de France. Pour savoir si Nous livrons dans Votre région, Vous pouvez consulter la rubrique « Ville » de Notre Site. Si Nous ne couvrons pas Votre région, Vous ne pourrez pas demander un devis ou une estimation ou commander des Services auprès de Nous.
                                </ListItem>
                                <ListItem>
                                    Vous pouvez demander un devis ou une estimation en indiquant Votre plaque d’immatriculation, Votre code postal et les Services que Vous souhaitez. Vous recevrez le devis détaillé par email qui comprendra une description des Services demandés, une liste des pièces nécessaires pour chaque Service, le prix des pièces, le prix de la main d’œuvre et les frais de transport et de service ainsi que le montant total à payer toutes taxes comprises.
                                </ListItem>
                                <ListItem>
                                    Si Vous acceptez le devis, Vous pouvez commander Nos Services directement via Votre Compte en Nous fournissant toutes les informations nécessaires pour confirmer Votre commande (Votre « Réservation »).
                                </ListItem>
                                <ListItem>
                                    Vous pouvez payer Votre Réservation en utilisant les moyens de paiement autorisés indiqués sur le Site au fur et à mesure du temps, et Vous devrez Nous indiquer la méthode de paiement choisie lorsque Vous Nous soumettrez Votre Réservation. Vous trouverez plus de détails sur les méthodes de paiement dans la section Centre d'aide du Site.
                                </ListItem>
                                <ListItem>
                                    En fonction de Votre mode de paiement, Vous pourrez être dirigé vers une passerelle de paiement, par exemple Stripe, et Vous pourrez vérifier le montant total à payer de Votre réservation avant de cliquer sur « Confirmer » pour autoriser le paiement et effectuer Votre Réservation (une fois cette opération terminée, Vous retournerez sur le Site (le cas échéant)).
                                </ListItem>
                                <ListItem>
                                    Vous Nous confirmez que la carte de paiement que Vous utilisez est la Vôtre ou que Vous avez été spécifiquement autorisé à l'utiliser pour effectuer Votre Réservation.
                                </ListItem>
                                <ListItem>
                                    En effectuant une Réservation, Vous Nous autorisez à débiter Votre carte de paiement à la fin de l’exécution des Services pour le prix d'achat total et Nous sommes en droit de considérer que le fait d'effectuer une Réservation constitue une autorisation Nous permettant de procéder à Votre/Vos paiement(s). Le paiement intégral de Votre Réservation est dû à la fin de l’exécution des Services. Si Nous ne sommes pas en mesure de prendre le paiement ou à défaut de paiement intégral à cette date, les sommes restantes dues seront majorées du taux d’intérêt légal en vigueur en France à la date du Contrat.
                                </ListItem>
                                <ListItem>
                                    Une fois que Vous avez effectué une Réservation, Nous Vous enverrons un e-mail pour accuser réception de votre Réservation et Vous informer que Nous l'avons examinée, mais cela ne signifie pas que Nous avons accepté Votre Réservation pour le moment. Votre Réservation ne sera pas approuvée et un contrat de vente ne sera pas formé entre Nous avant que Nous n'ayons examiné Votre Réservation, vérifié Votre méthode de paiement et que Nous Vous envoyons une confirmation de Votre Réservation par e-mail (Nous appellerons une Réservation acceptée le « Contrat »).
                                </ListItem>
                                <ListItem>
                                    Il est possible que Nous ne soyons pas en mesure d'accepter votre Réservation en raison d’une indisponibilité des garages intervenue entre la d’émission du devis et la date de Votre Réservation ou si votre méthode de paiement n'a pas pu être vérifiée. Nous Vous en informerons par e-mail et ne Vous facturerons évidemment pas.
                                </ListItem>
                            </OrderedList>
                        </Box>

                        <Heading size={'xs'} color={'#1385EB'}>
                            6.  TARIFICATION DES SERVICES
                        </Heading>
                        <Box>
                            <OrderedList px={10}>
                                <ListItem>
                                    Tous les Services que Nous affichons sur le Site Internet sont facturés en euros, toutes taxes comprises, y compris la TVA, sauf indication contraire expresse.
                                </ListItem>
                                <ListItem>
                                    Nous faisons de Notre mieux pour Nous assurer que les Services et les informations sur les prix affichés sur le site sont à jour et complètes mais Nous ne serons pas responsables des informations inexactes ou obsolètes.
                                </ListItem>
                                <ListItem>
                                    Nous pouvons mettre à jour les prix et les frais de Nos Services de temps à autre, mais ces changements n'affecteront pas les Contrats existants.
                                </ListItem>
                                <ListItem>
                                    Le prix applicable à Votre Réservation est celui figurant sur le devis que Vous avez accepté. Le prix est indiqué en euros, toutes taxes comprises y compris la TVA. Le prix indiqué dans le devis comprend le prix des pièces détachées nécessaires à chaque Service, le prix de la main d’œuvre et les frais de transport et de service.
                                </ListItem>
                                <ListItem>
                                    Tous les devis sont valables pendant 30 jours à compter de la date d'émission, sauf s'ils incluent une offre promotionnelle spécifique dont la date limite de validité est antérieure.
                                </ListItem>
                            </OrderedList>
                        </Box>

                        <Heading size={'xs'} color={'#1385EB'}>
                            7.  FOURNITURE DES SERVICES
                        </Heading>
                        <Box>
                            <OrderedList px={10}>
                                <ListItem>
                                    Nous essaierons, dans la mesure du possible, de récupérer Votre voiture à la date et à l'adresse de collecte spécifiées dans la confirmation de Réservation, sauf accord contraire avec Vous par écrit (« Point de Collecte »).
                                </ListItem>
                                <ListItem>
                                    La date estimative d’exécution des Services Vous est donnée avant que Vous ne confirmiez Votre Réservation et que Vous choisissiez Votre mode de paiement. Elle Vous est également indiquée dans Votre confirmation de Réservation, à moins que des modifications de Votre Réservation ne soient nécessaires (voir l’article 8 ci-dessous). Vous êtes en mesure de suivre l'état de Votre Réservation sur le Site dans la rubrique « Suivi de ma Commande ». Si Vous avez besoin de Nous contacter à tout moment pendant la prestation des Services, veuillez utiliser la fonction « Chat » sur le Site Internet ou Nous contacter en utilisant les coordonnées indiquées à l’article 1.2.
                                </ListItem>
                                <ListItem>
                                    Si Votre voiture est considérée en état de marche, Nous essaierons, dans la mesure du possible, de mettre à Votre disposition un agent qui Vous remettra Votre voiture à la date et à l'adresse de livraison indiquées dans la confirmation de Réservation, sauf accord écrit contraire passé avec Vous (« Point de Restitution »). Toutefois, si un défaut est découvert par Nos Services qui rend la conduite de Votre voiture dangereuse, Vous devrez prendre des dispositions pour la récupérer. Nous pouvons fournir un service de camionnette pour livrer Votre voiture à un endroit de Votre choix, mais ce coût devra être supporté par Vous et le paiement effectué à l'avance, comme convenu entre Nous.
                                </ListItem>
                                <ListItem>
                                    Nous Vous rappellerons via le Site Internet et/ou en Vous envoyant un message sur votre Appareil enregistré 2 heures avant la prise en charge et la restitution de Votre voiture.
                                </ListItem>
                                <ListItem>
                                    Si Vous n'êtes pas au Point de Collecte et si Nous ne parvenons pas à Vous contacter après plusieurs tentatives, Nous annulerons Votre Réservation. Si Vous n'êtes pas au Point de Restitution et si Nous ne parvenons pas à Vous contacter après plusieurs tentatives, il est possible que Nous devions organiser le remisage de Votre voiture dans un garage local ou dans d'autres installations de remisage. Dans ce cas, Nous Vous en informerons et Vous facturerons les frais de remisage associés et les frais de livraison encourus pour Vous restituer Votre voiture.
                                </ListItem>
                                <ListItem>
                                    Lors de la collecte et la restitution de Votre voiture, il Vous sera demandé par un de Nos agents désignés de signer un reçu, et il pourra également Vous être demandé de fournir des documents d'identification (permis de conduire avec photo ou passeport) afin que Nous puissions Nous assurer que Vous êtes bien celui que Vous prétendez être.
                                </ListItem>
                                <ListItem>
                                    Nous ne pouvons pas être responsables des retards causés par un événement de force majeure tel que défini par la loi applicable et la jurisprudence française. Dans une telle situation, Nous Vous informerons qu'il existe un risque important de retard dans la fourniture des Services et si Vous ne pouvez pas attendre, Vous pouvez Nous contacter pour mettre fin à Votre Contrat et demander le remboursement des Services non exécutés ou non fournis.
                                </ListItem>
                            </OrderedList>
                        </Box>

                        <Heading size={'xs'} color={'#1385EB'}>
                            8.  MODIFICATIONS DE VOTRE RÉSERVATION
                        </Heading>
                        <Box>
                            <OrderedList px={10}>
                                <ListItem>
                                    Si vous Souhaitez apporter une modification à Votre Réservation, veuillez Nous contacter. Nous Vous ferons savoir si la modification est possible. Si cela est le cas, Nous Vous informerons de toute modification éventuelle du prix de Votre Réservation, du délai de livraison ou de tout autre élément qui serait nécessaire en raison de la modification demandée et Nous Vous demanderons de confirmer si Vous souhaitez procéder à la modification. Si Nous ne pouvons pas effectuer la modification ou si les conséquences de cette modification ne sont pas acceptables pour Vous, Vous pouvez mettre fin au Contrat (voir l’article 9 pour plus d'informations).
                                </ListItem>
                                <ListItem>
                                    Nous pouvons être amenés à modifier Votre Réservation :
                                    <OrderedList px={5}>
                                        <ListItem>
                                            si les informations que Vous Nous avez fournies dans la Réservation ne sont pas exactes ou sont incomplètes ;
                                        </ListItem>
                                        <ListItem>
                                            pour refléter les changements dans les lois pertinentes et les exigences réglementaires ; et
                                        </ListItem>
                                        <ListItem>
                                            si d'autres travaux essentiels ont été identifiés sur Votre voiture lors de l'inspection ou du début des Services ; mais dans tous les cas, Nous Vous informerons et obtiendrons Votre approbation avant d'apporter des modifications (y compris le prix) à Votre Réservation. Si Vous n'êtes pas satisfait de ces changements, Vous pouvez alors Nous contacter pour mettre fin au Contrat avant que les changements ne prennent effet et recevoir un remboursement pour tous les Services non fournis.
                                        </ListItem>
                                    </OrderedList>
                                </ListItem>
                            </OrderedList>
                        </Box>

                        <Heading size={'xs'} color={'#1385EB'}>
                            9.  CHANGEMENT D'AVIS SUR VOTRE RÉSERVATION
                        </Heading>
                        <Box>
                            <OrderedList px={10}>
                                <ListItem>
                                    Conformément à la réglementation applicable, Vous disposez d’un droit de rétractation, c’est-à-dire que Vous avez le droit d'annuler Votre Réservation et de recevoir un remboursement complet pour n'importe quelle raison dans un délai de 14 jours à compter du jour où le Contrat a été conclu (c'est-à-dire le jour où Vous avez reçu un e-mail de Notre part acceptant votre Réservation). Toutefois, Vous ne pourrez obtenir un quelconque remboursement si les Services ont été entièrement exécutés avant la fin du délai de 14 jours. Une fois que Nous avons commencé à exécuter les Services (généralement un jour avant le jour de Collecte, lorsque Nous avons attribué le garage et l’agent), Vous pouvez exercer Votre droit de rétractation mais Vous devez Nous payer les Services fournis jusqu'au moment où Vous nous informez que Vous avez changé d'avis.
                                </ListItem>
                                <ListItem>
                                    Si Vous souhaitez annuler Votre Réservation, Vous pouvez Nous le faire savoir en utilisant la fonction « Chat » du Site, Vous pouvez Nous envoyer le formulaire de rétractation en ligne accessible ici ou en Nous contactant à l'aide des coordonnées indiquées à l’article 1.2 plus haut.
                                </ListItem>
                                <ListItem>
                                    Si Vous annulez Votre Réservation et que Vous avez droit à un remboursement, Nous Vous rembourserons par la méthode que vous avez utilisée pour payer les Services, dès que possible et au plus tard 14 jours à compter de la date à laquelle Vous Nous informez que Vous annulez Votre Réservation.
                                </ListItem>
                            </OrderedList>
                        </Box>

                        <Heading size={'xs'} color={'#1385EB'}>
                            10.  PROBLÈMES AVEC LES SERVICES ET GARANTIES
                        </Heading>
                        <Box>
                            <OrderedList px={10}>
                                <ListItem>
                                    Nous Nous engageons à mettre en œuvre Nos meilleurs efforts commerciaux   pour Vous fournir des Services conformes au Contrat.
                                </ListItem>
                                <ListItem>
                                    Conformément à la loi applicable, Vous pouvez bénéficier d’une garantie légale de conformité (articles L.217-1 et suivants du Code de la consommation) qui couvre les pièces détachées que Nous utilisons pour exécuter les Services.
                                </ListItem>
                                <ListItem>
                                    Si les pièces s'avèrent défectueuses, Vous pouvez nous demander de les réparer ou les remplacer ou selon certaines conditions, Vous pouvez obtenir le rembourser intégral du prix des pièces défectueuses. La garantie de conformité dure 2 ans à compter de la date où Nous vous retournons Votre voiture.
                                </ListItem>
                                <ListItem>
                                    Vous pouvez également bénéficier d'une garantie des vices cachés (articles 1641 et suivants du Code civil) qui peut être mise en œuvre lorsque les pièces détachées présentent un défaut caché, c’est-à-dire non apparent, rendant ces pièces inutilisables ou diminuant très fortement leur usage, défaut qui existait au moment de la livraison de Votre voiture. Cette garantie Vous permet de conserver les pièces et d'obtenir une compensation ou de Nous retourner les pièces et d'obtenir un remboursement intégral. La garantie des vices cachés dure 2 ans à compter de la date à laquelle Vous découvrez le défaut ou à compter de la date à laquelle Vous étiez censé en avoir connaissance.
                                </ListItem>
                                <ListItem>
                                    Si Vous avez des questions ou des réclamations concernant les Services qui Vous ont été fournis, si des pièces sont défectueuses ou si les Services fournis ne sont pas ceux que Vous avez commandés, veuillez Nous contacter immédiatement en utilisant les coordonnées indiquées ci-dessus avec Vos coordonnées et une description du problème.
                                </ListItem>
                                <ListItem>
                                    Pour une information détaillée concernant Vos garanties, veuillez consulter https://www.service-public.fr/particuliers/vosdroits/F18954.
                                </ListItem>
                                <ListItem>
                                    Sans préjudice de Vos droits en vertu de la loi, si les Services ont été fournis conformément au Contrat, et si les pièces montées étaient correctes et non défectueuses, Nous ne pourrons pas Vous rembourser les Services fournis ou échanger les pièces montées sur Votre voiture.
                                </ListItem>
                                <ListItem>
                                    Nous pouvons mettre fin au Contrat dans le cas où Vous ne nous avez pas fourni des informations exactes ou complètes, concernant notamment Vos données personnelles, les détails de Votre voiture ou d'autres informations de Réservation, ou si Vous violez les présentes. Nous Vous contacterons d'abord pour essayer d'obtenir ou de corriger les informations en question ou de résoudre le problème, mais si Nous ne parvenons pas à Vous contacter après un certain nombre de tentatives ou si Nous ne parvenons pas à résoudre le problème, Nous pouvons résilier le Contrat et Vous rembourser les Services non fournis.
                                </ListItem>
                            </OrderedList>
                        </Box>

                        <Heading size={'xs'} color={'#1385EB'}>
                            11.  SITES INTERNET DE TIERS
                        </Heading>
                        <Box>
                            <OrderedList px={10}>
                                <ListItem>
                                    Le Site Internet peut contenir des contenus provenant de divers sites internet et des liens vers ces derniers. Les CGV ne s'appliquent pas à votre utilisation de tout site internet auquel Vous accédez par l'intermédiaire de Notre Site Internet. Votre utilisation d'un tel site internet peut être soumise à des conditions supplémentaires, que Nous Vous suggérons de lire attentivement avant de poursuivre.
                                </ListItem>
                                <ListItem>
                                    Les sites internet de tiers ne sont en aucun cas approuvés, contrôlés, vérifiés ou avalisés par Nous et Vous acceptez que Nous ne soyons pas responsables, de quelque manière que ce soit, du contenu, de l'exactitude, de la conformité aux lois applicables ou de l'accessibilité de toute information, donnée, conseil ou déclaration ou de la qualité de tout produit ou service disponible sur ces sites internet. Les liens n'impliquent pas nécessairement que Nous sommes ou que le Site Internet est affilié ou associé à ces sites internet tiers. Si vous décidez de visiter un autre site internet, Vous le faites à Vos propres risques.
                                </ListItem>
                            </OrderedList>
                        </Box>

                        <Heading size={'xs'} color={'#1385EB'}>
                            12.  PROPRIÉTÉ INTELLECTUELLE
                        </Heading>
                        <Box>
                            <OrderedList px={10}>
                                <ListItem>
                                    Aux fins des CGV , l'expression « Droits de Propriété Intellectuelle » désigne les brevets, les marques, les noms de domaine, les dessins et modèles, les droits d'auteur (y compris les droits sur les logiciels et les bases de données), les droits sur les bases de données et les droits moraux (dans chaque cas, pour toute leur durée et leurs extensions, renouvellements et révisions), les demandes de ce qui précède et le droit de demander ce qui précède partout dans le monde, ainsi que tous les droits similaires partout dans le monde, y compris les droits sur les inventions, les conceptions, dessins et les programmes informatiques et de manière générale, que ces Droits de Propriété Intellectuelle soient enregistrés ou non.
                                </ListItem>
                                <ListItem>
                                    Tous les Droits de Propriété Intellectuelle relatifs au Site Internet et à l'ensemble des caractéristiques, du contenu, des éléments et des informations disponibles sur le Site Internet sont détenus et resteront détenus par Nous ou Nos concédants de licence à tout moment.
                                </ListItem>
                                <ListItem>
                                    Les CGV ne sont pas destinées à Vous empêcher de faire des copies personnelles de toute information provenant du Site Internet ou de divulguer ces informations gratuitement à des amis ou à des relations à des fins non commerciales.
                                </ListItem>
                            </OrderedList>
                        </Box>

                        <Heading size={'xs'} color={'#1385EB'}>
                            13.  GARANTIES ET EXONÉRATIONS DE RESPONSABILITÉ
                        </Heading>
                        <Box>
                            <OrderedList px={10}>
                                <ListItem>
                                    Nous mettons en œuvre Nos meilleurs efforts commerciaux pour Vous fournir le Site Internet et les Services. Toutefois, Nous ne sommes pas en mesure de garantir la disponibilité du Site Internet ou l'exactitude, l'exhaustivité, l'actualité ou la fiabilité des caractéristiques, du contenu, des éléments ou des informations du Site Internet qui proviennent de tiers (y compris de nos concédants de licence ou de nos partenaires tiers).
                                </ListItem>
                                <ListItem>
                                    Sauf stipulation expresse dans les CGV, le Site Internet et l'ensemble des caractéristiques, du contenu, des éléments et des informations fournis par leur intermédiaire sont fournis en l'état, sans garantie d'aucune sorte, et toutes les conditions, déclarations et garanties (y compris toute garantie de fiabilité, d'exhaustivité, d'exactitude ou de non-contrefaçon) sont exclues dans la mesure où la loi le permet.
                                </ListItem>
                                <ListItem>
                                    Nous ferons tout Notre possible pour vérifier que Nos services conviennent à Votre Réservation, mais Nous ne pouvons pas garantir que Nos services répondront toujours à vos besoins. Par conséquent, Nous Vous conseillons de vérifier toutes les caractéristiques, le contenu, les éléments et/ou les informations qui vous sont fournis par le biais du Site Internet, car toute confiance que Vous accordez à l'exactitude, l'exhaustivité, l'actualité ou la fiabilité de ces informations est à Vos propres risques.
                                </ListItem>
                            </OrderedList>
                        </Box>

                        <Heading size={'xs'} color={'#1385EB'}>
                            14.  LIMITATION DE LA RESPONSABILITÉ
                        </Heading>
                        <Box>
                            <OrderedList px={10}>
                                <ListItem>
                                    Rien dans les CGV ne limite ou n'exclut Notre responsabilité pour tout ce qui ne peut être exclu en vertu de la loi applicable.
                                </ListItem>
                                <ListItem>
                                    Sous réserve de l’article 14.1, Nous sommes seulement responsables des dommages directs que Vous subissez, résultant d’une inexécution de Nos obligations au titre des CGV et qui Nous est imputable.
                                </ListItem>
                                <ListItem>
                                    Sous réserve de l’article 14.1, Vous acceptez que Nous ne soyons pas responsables de toute perte, réclamation ou tout dommage indirect de quelque nature que ce soit (y compris les pertes de profit ou la perte ou la corruption de données)
                                </ListItem>
                                <ListItem>
                                    Sans limiter l'effet de l’article 14.3, en raison des risques inhérents à l'utilisation d'Internet, Nous ne pouvons être tenus responsables de tout dommage ou virus pouvant infecter votre Appareil ou tout autre bien lorsque Vous utilisez le Site Internet. Le téléchargement ou toute autre acquisition de toute fonction, contenu, élément ou information mis à disposition via le Site Internet se fait à Votre propre discrétion et à Vos risques et avec Votre accord que Vous serez seul responsable de tout dommage à votre Appareil ou de toute perte de données résultant du téléchargement ou de l'acquisition d'un tel élément.
                                </ListItem>
                                <ListItem>
                                    Toute demande de maintenance et/ou d'assistance doit Nous être adressée en utilisant les coordonnées indiquées à l’article 1.2. Nous répondrons aux demandes de support client dans un délai de trois (3) jours ouvrables.
                                </ListItem>
                            </OrderedList>
                        </Box>

                        <Heading size={'xs'} color={'#1385EB'}>
                            15.  CESSATION
                        </Heading>
                        <Box>
                            <OrderedList px={10}>
                                <ListItem>
                                    Nous pouvons supprimer le Site Internet ou cesser de fournir l'une des caractéristiques, le contenu, les éléments, les informations ou les Services fournis par le biais du Site Internet à tout moment à Notre absolue discrétion et pour quelque raison que ce soit.
                                </ListItem>
                                <ListItem>
                                    Ni l’article 15.1 ni l’article 15.2 n'affecteront un Contrat existant ni tous les droits et obligations acquis par Vous ou par Nous au moment de la suppression ou de la désinstallation.
                                </ListItem>
                            </OrderedList>
                        </Box>

                        <Heading size={'xs'} color={'#1385EB'}>
                            16.  PROTECTION DES DONNEES ET CONFIDENTIALITE
                        </Heading>
                        <Box>
                            <OrderedList px={10}>
                                <ListItem>
                                    Nous n'utiliserons les informations personnelles que Nous pourrions recueillir à Votre sujet que conformément à Notre Politique de Confidentialité. Notre Politique de Confidentialité constitue une partie essentielle des Conditions d'Utilisation et il est important que Vous la lisiez. En acceptant les Conditions d'Utilisation, Vous reconnaissez avoir pris connaissance de notre Politique de Confidentialité.
                                </ListItem>
                            </OrderedList>
                        </Box>

                        <Heading size={'xs'} color={'#1385EB'}>
                            17.  CESSION ET DROITS DES TIERS
                        </Heading>
                        <Box>
                            <OrderedList px={10}>
                                <ListItem>
                                    Nonobstant toute stipulation contraire des Conditions d’Utilisation, si, à tout moment, Nous souhaitons nommer une autre personne (« la Nouvelle Entité Cessionnaire ») à Notre place aux fins des Conditions d’Utilisation :
                                    <OrderedList px={5}>
                                        <ListItem>
                                            Nous Vous adresserons une notification écrite Vous informant de ce fait et de la date (la Date de la Nouvelle Entité) à laquelle la nomination de la Nouvelle Entité Cessionnaire doit prendre effet ;
                                        </ListItem>
                                        <ListItem>
                                            Nous ferons en sorte que la Nouvelle Entité Cessionnaire Vous fournisse, au plus tard à la Date de la Nouvelle Entité, une confirmation écrite selon laquelle la Nouvelle Entité Cessionnaire assumera Nos obligations et responsabilités en vertu des Conditions d'Utilisation à compter de la Date de la Nouvelle Entité ;
                                        </ListItem>
                                        <ListItem>
                                            la Nouvelle Entité Cessionnaire deviendra partie aux Conditions d'Utilisation à Notre place à compter de la Date de la Nouvelle Entité et bénéficiera, à compter de cette date, de tous Nos droits, recours et avantages en vertu des Conditions d'Utilisation et sera tenue d'exécuter ou de s'acquitter de toutes Nos obligations et responsabilités en cours en vertu des Conditions d'Utilisation et toutes les mentions qui Nous concernent dans les Conditions d'Utilisation seront lues et interprétées comme si elles faisaient référence à la Nouvelle Entité Cessionnaire comme si, dans chaque cas, la Nouvelle Entité Cessionnaire avait toujours été partie aux Conditions d'Utilisation en tant que partie contractante initiale ; et
                                        </ListItem>
                                        <ListItem>
                                            à compter de la Date de la Nouvelle Entité, nous serons inconditionnellement et irrévocablement libérés de toute obligation et responsabilité en vertu des Conditions d'Utilisation survenant à partir de la Date de la Nouvelle Entité.
                                        </ListItem>
                                    </OrderedList>
                                </ListItem>
                            </OrderedList>
                        </Box>

                        <Heading size={'xs'} color={'#1385EB'}>
                            18.  GÉNÉRALITÉS
                        </Heading>
                        <Box>
                            <OrderedList px={10}>
                                <ListItem>
                                    Tout manquement ou retard de Notre part à faire valoir l'un de Nos droits en vertu des Conditions d'Utilisation ne sera pas considéré comme une renonciation à ce droit ou à tout autre droit, sauf si Nous reconnaissons et acceptons une telle renonciation par écrit.
                                </ListItem>
                                <ListItem>
                                    À l'exception des stipulations de l’article 17, les Conditions d'Utilisation ne sont pas destinées à bénéficier à toute personne qui n'est pas partie aux Conditions d'Utilisation et ne pourront être exercées par elle.
                                </ListItem>
                                <ListItem>
                                    Si un article ou une partie d'article des Conditions d'Utilisation est ou devient invalide, illégale ou inapplicable, le reste des Conditions d'Utilisation restera valide et applicable.
                                </ListItem>
                                <ListItem>
                                    Ces Conditions d’Utilisation sont régies par le droit français et vous pouvez engager des procédures judiciaires devant les tribunaux français.
                                </ListItem>
                                <ListItem>
                                    Le règlement extrajudiciaire des litiges est un processus par lequel un organisme indépendant examine les faits d'un litige et cherche à le résoudre, sans que Vous ayez à saisir un tribunal. Si Vous n'êtes pas satisfait de la manière dont Nous avons traité une plainte, Vous pouvez soumettre le litige à une résolution en ligne sur la plateforme de résolution des litiges en ligne de la Commission européenne (cliquez ici).
                                </ListItem>
                                <ListItem>
                                    Par ailleurs, Vous êtes informé que Vous pouvez, dans tous les cas, saisir le centre de médiation afin de résoudre tout litige entre Vous et Nous.
                                </ListItem>
                                <ListItem>
                                    Si Vous n'êtes pas satisfait du résultat, Vous pouvez toujours engager une procédure judiciaire.
                                </ListItem>
                            </OrderedList>
                        </Box>

                    </Box>
                </Box >
            </Box >
        </>
    )
}

export default LegalNoticeSection