import { useEffect } from 'react';
import Head from '../../../../components/blocks/Head';
import BecomePartener from '../../../../components/sections/BecomePartener';
import { BookingSection, OurServicesSection } from './components/_index';
import Avantage from './components/avantage/Avantage';
import Reduction from './components/reduction/Reduction';
import GainTemp from './components/gain-temp/GainTemp';
import Garage from './components/garage/Garage.tsx';
import Transparence from './components/transparence/Transparence';
import Revolution from './components/revolution/Revolution';
import Prestations from './components/prestations/Prestations';
import FAQ from '../../pro-fleet/components/FAQ'
import Contact from '../../pro-fleet/components/Contact'
import { Box } from '@chakra-ui/react';

const title = "Nos Prestations";
const description = "Besoin d'un entretien automobile de qualité ? Découvrez les prestations proposées par Dadycar, la plateforme de maintenance automobile en ligne qui vous simplifie la vie. Trouvez des professionnels de confiance pour des prestations telles que la vidange, le remplacement des filtres à air, à huile, à carburant, le contrôle de la pression des pneus, le remplacement des pièces défectueuses, la réparation des systèmes de freinage, de suspension et d'échappement, le remplacement de pneus ou le diagnostic électronique.";
const keywords = "entretien, automobile, qualité, prestations, professionnels, confiance, vidange, pneus, diagnostic électronique";

const ServicesPage: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  

  return (
    <>
      <Head title={title} description={description} keywords={keywords} />
      <BookingSection >
        <Box overflowX={'hidden'}>
          <Avantage />
          <Reduction />
          <GainTemp />
          <Garage />
          <Transparence />
          <Box bg='#0D1829'>
            <Revolution />
          </Box>    
          <Prestations />
          <FAQ />
          <Contact />
        </Box>
        
      </BookingSection>
    </>
  )
}

export default ServicesPage;