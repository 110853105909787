import { createSlice } from '@reduxjs/toolkit';

type ScheduleType = {
  date: string;
  opening_time: string;
  closing_time: string;
  week_day_name_fr: string;
  closed: boolean;
  hours: [];
}[] | [];

type SelectedDateType = {
  vendorId: number,
  key: string,
  date: string,
  hour: string
}

type VendorType = {
  id: number;
  name: string;
  address: string;
  description?: string;
  distance?: string;
  email?: string;
  price?: number;
  image?: string;
  reviews?: number;
  siret_number?: string;
  rcs_number?: string;
  phone_number?: number;
  website?: string;
  lat: number;
  lng: number;
  services?: {
    id: number;
    name: string;
    description?: string;
  }[];
  schedule: ScheduleType;
  week_schedule: ScheduleType;
};

type SliceState = { list: VendorType[] | null, cityVendors: VendorType[] | null, selected: VendorType | null, details: VendorType | null, selectedDate: SelectedDateType | null, isLoading: boolean };

const initialState: SliceState = { list: null, selected: null, selectedDate: null, details: null, cityVendors: null, isLoading: false }

const vendorSlice = createSlice({
  name: 'vendor',
  initialState,
  reducers: {
    updateList(state, action) {
      state.list = action.payload;
    },
    updateVendorDetails(state, action) {
      state.details = action.payload;
    },
    updateCityVendors(state, action) {
      state.cityVendors = action.payload;
    },
    selectDate(state, action) {
      state.selectedDate = action.payload;
    },
    updateSelected(state, action) {
      state.selected = action.payload;
    },
    updateIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    removeSelected(state) {
      state.selected = null;
    },
  },
});

export { type VendorType };
export { type ScheduleType };
export { type SelectedDateType };
export const vendorActions = vendorSlice.actions;
export default vendorSlice.reducer;