import { Box, Container, Grid, GridItem, Stack } from "@chakra-ui/react";
import { AddressViewModel } from "../../../view-models/_index";
import { SubmitButton } from './components/_index';
import { SearchAddressAutocomplete } from '../../../components/sectors/_index';
import { useSelector } from "react-redux";
import { RootState } from "../../../store/index";
import { SelectVehicle, SelectService } from "../../sectors/_index";

const Search: React.FC<{ text: string }> = (props) => {
    const addressViewModel = new AddressViewModel();
    const selectClientAddress = addressViewModel.selectClientAddress;
    const departureAddress = useSelector((state: RootState) => state.address.departureAddress);

    return (
        <Container bg={'white'} maxW={'80%'} w={{ lg: '1200px' }} borderRadius={'3xl'}
            boxShadow={'0px 0px 15px rgba(0, 0, 0, 0.203922)'} p={'5'} my={'4'}>
            <Box display={{ lg: 'flex', base: 'block' }} justifyContent={'center'}>
                <Grid templateColumns={{ lg: 'repeat(3, 1fr)' }} gap={2}>
                    <GridItem>
                        <SelectVehicle />
                    </GridItem>
                    <GridItem>
                        <SearchAddressAutocomplete
                            placeholder="Votre address"
                            address={departureAddress}
                            selectAddress={selectClientAddress}
                        />
                    </GridItem>
                    <GridItem>
                        <SelectService />
                    </GridItem>
                </Grid>

                <Stack m={'2'} mt={{ base: '4', lg: '2' }} align={'center'}>
                    <SubmitButton text={props.text} />
                </Stack>
            </Box>
        </Container>
    )
}

export default Search;
