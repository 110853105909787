import HttpClient from '../services/HttpClient'

type AddressType = {
    value: number;
    label: string;
    city: string;
    postcode: string;
    lat: number;
    lng: number;
};

class Address {

    public static host = 'https://api-adresse.data.gouv.fr/search/?q=';

    public static getAddress = async (address: string) => {
        return HttpClient.get(address, Address.host, {'Accept': 'application/json'});
    }
}

export { type AddressType };
export default Address;