import { Box, Center, Heading, Image, Link, Text } from "@chakra-ui/react";
import vendorsBgxl from '../../../../../assets/images/dadycar-checkout-bg-cropped.webp';
import vendorsBg from '../../../../../assets/images/dadycar-checkout-bg.webp';
import vendorsBg1 from '../../../../../assets/images/dadycar-checkout-bg-md.webp';
import vendorsbg2 from '../../../../../assets/images/dadycar-checkout-bg-sm.webp';

const HeaderSection: React.FC = () => {
    return (
        <Box>
            <Box position={'absolute'} zIndex={-1} >
                <picture>
                    <source media="(min-width:62em)" srcSet={`${vendorsBgxl}`} />
                    <source media="(min-width:48em)" srcSet={`${vendorsBg}`} />
                    <source media="(min-width:31em)" srcSet={`${vendorsBg1}`} />
                    <Image src={vendorsbg2} width="100vw"
                        backgroundRepeat={'no-repeat'}
                        backgroundSize={'contain'}
                        objectFit={'cover'} />
                </picture>
            </Box>
            <Box pt={32} mb={100}>
                <Box py={{ lg: 7, sm: 10 }}>
                    <Heading color={'white'} textAlign={'center'}>Convoyage</Heading>
                    <Center>
                        <Box>
                            <Text color={'white'} textAlign={'center'}>Les chauffeurs Dadycar sont à votre service.</Text>
                            <Text textAlign={'center'} color={'white'}><Link color={'#0F82EA'}>Convoyage</Link>/ Reservation</Text>
                        </Box>
                    </Center>
                </Box>
            </Box>

        </Box>
    )
}

export default HeaderSection;
