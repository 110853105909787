import { Box, Button, Center, Container, Heading, Image, useDisclosure, Text, Flex, HStack, Stack } from "@chakra-ui/react";
import dadycarFleetBg from '../../../../assets/images/dadycar-fleet-pro.webp';
import car_header from '../../../../assets/V2/car_header.webp';
import driving_header from '../../../../assets/V2/driving_header.png';
import km_header from '../../../../assets/V2/km_header.png';
import service_header from '../../../../assets/V2/service_header.png';
import header_right from '../../../../assets/V2/header_right.png';
import header_left from '../../../../assets/V2/header_left.png';
import sponsors from '../../../../assets/V2/sponsors.png';
import { useInView } from 'react-intersection-observer'

import dadycarPc from '../../../../assets/images/dadycar-fleet-pro-pc.webp';
import { ExpertContactModal } from "../../../../components/modals/_index";
import { Link } from "react-router-dom";
import { motion, useAnimation } from "framer-motion";
import { useEffect, useState } from "react";

const MotionBox = motion(Box);
const HeaderSection: React.FC = () => {
    const { isOpen: isExpertContactOpen, onOpen: onExpertContactOpen, onClose: onExpertContactClose } = useDisclosure()
    const { ref, inView } = useInView();
    const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (inView) {
      setIsVisible(true);
    }
  }, [inView]);

    return (
        <Box  id='hero' bg='#0D121F' pb={20} pos='relative'>
            <Box
                position='absolute'
                bottom={0}
                left={0}
                w='300px'
                h='200px'
                backgroundImage={header_right}
                backgroundSize={'cover'}
                backgroundRepeat={'no-repeat'}
                backgroundPosition={{ base: 'top', lg: 'center' }}>   
            </Box>
            <Box
                position='absolute'
                top={0}
                right={0}
                w='300px'
                h='300px'
                backgroundImage={header_left}
                backgroundSize={'cover'}
                backgroundRepeat={'no-repeat'}
                backgroundPosition={{ base: 'top', lg: 'center' }}>   
            </Box>
            <MotionBox 
                ref={ref} initial={{ scale: 0.8, opacity: 0 }}
                animate={{ scale: inView ? 1 : 0.8, opacity: inView ? 1 : 0 }}
                transition={{ duration: 0.5 }}
            >
                <Container maxW={'90%'} w={{ lg: '1200px' }}>   
                    <Flex flexDirection={{base:'column',lg:'row'}} pt={32} pb={10}  color='white' justifyContent='space-between' gap={8} >
                        <Flex flexDirection='column' gap={4} flex={1} >
                            <Heading as='h1'>
                                Gérer et entretenir sa flotte n’a jamais été aussi simple !
                            </Heading>
                            <Text fontSize={{base:'14px',md:'16px'}} >Le seul logiciel pour la gestion de flottes innovantes, incluant le transport et la maintenance de véhicules.</Text>
                            <Text fontSize={{base:'14px',md:'16px'}}>Contrôlez et gérez en un clic votre flotte</Text>
                            <Text fontSize={{base:'14px',md:'16px'}}>Gérez l’entretien et la réparation de vos véhicules</Text>
                            <Text fontSize={{base:'14px',md:'16px'}}>Gérez le déplacement de vos véhicules</Text>
                            <Text fontSize={{base:'14px',md:'16px'}}>Connectez vos véhicules pour le car sharing et la maintenance des véhicules</Text>
                            <Stack direction={['column','row']} gap={4}>
                                <Link to={'https://meetings-eu1.hubspot.com/meetings/amine-yahia-cherif/dadycar'} target="_blank" >
                                    <Button bg='#3563E9' _hover={{bg:'#0F82EA'}} rounded='30px' fontSize={{base:'14px',md:'16px'}} w='187px' h='52px' color='white' >Prendre RDV</Button>
                                </Link>
                                <Link to={'https://meetings-eu1.hubspot.com/meetings/amine-yahia-cherif?uuid=d67be381-7bc7-461c-ab1c-f55d5ebd83c9'} target="_blank">
                                    <Button variant='outline' _hover={{bg:'#3563E9'}} rounded='30px' fontSize={{base:'14px',md:'16px'}} color='white'  w='187px' h='52px'>Organiser une démo</Button>
                                </Link>
                            </Stack>
                        </Flex>
                        <Box flex={1}>
                            <Image src={car_header} zIndex={100} objectFit={{base:'cover',md:'fill'}} w='100%' h={{base:'270p',sm:'100%',lg:'80%'}} />
                        </Box>
                    </Flex>
                </Container>
            </MotionBox>
        </Box>
    )
}

export default HeaderSection;