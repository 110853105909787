import { createBrowserRouter } from 'react-router-dom'
import {
    HomePage,

    // ProPage,
    // ProConveyingPage,
    // ProMeintenancePage,

    DriverPage,

    UserRootLayout,
    FleetRootLayout,
    NotFound,

    LegalNoticePage,
    BrandsPage,
    PrivacyPolicyPage,
    QuestionsPage,
    SuccessPage,
} from '../../pages/_index'

import {
    ConveyingPage,
    ConveyingBookingPage,
    ConveyingSuccessPage,
} from '../../pages/V2/conveying/_index';

import {
    ServicesPage,
    ServiceSuccessPage,
    SubServicePage,
} from '../../pages/V2/service/_index'

import {
    RegionsPage,
    DepartmentsPage,
    CitiesPage,
} from '../../pages/administrative/_index';

import {
    ContactPage,
    ContactSuccessPage
} from '../../pages/contact/_index';

import {
    VendorAuthPage,
    VendorBookingPage,
    VendorDetailsPage,
    VendorsFilterPage,
    VendorsSearchPage,
} from '../../pages/vendor/_index';

import {
    UserProfilePage,
    UserAppointmentsPage,
    UserConveyingsPage,
} from '../../pages/user/_index';

import {
    CarSharingPage
} from '../../pages/V2/car-sharing/_index'

import ProFleetPage from '../../pages/V2/pro-fleet/ProFleetPage';
import RootLayout from '../../pages/root-layout/V2/RootLayout';
import GarageLayout from '../../pages/V2/garage/GarageLayout';
import HistoriquePage from '../../pages/V2/garage/historique/HistoriquePage';
import MyCalendar from '../../pages/V2/garage/rendez-vous/components/MyCalendar';
import MesDevis from '../../pages/V2/garage/devis/MesDevis';
import NewDevis from '../../pages/V2/garage/devis/new/NewDevis';
import GaragePage from '../../pages/V2/garage/dashboard/GaragePage';

const RouterConfig = createBrowserRouter([
    {
        path: '/',
        element: <RootLayout />,
        errorElement: <NotFound />,
        children: [
            { index: true, element: <ProFleetPage /> },
            { path: 'coordonnees-recues', element: <SuccessPage /> },
            {
                path: 'prestations',
                children: [
                    { index: true, element: <ServicesPage /> },
                    { path: ':serviceParams/:subServiceParams', element: <SubServicePage /> },
                ]
            },
            {
                path: 'garage',
                children: [
                    { index: true, element: <VendorAuthPage action={"login"} /> },
                    { path: ':garageSlug/:garageId', element: <VendorDetailsPage /> },
                    { path: 'reservation', element: <VendorBookingPage /> },
                    { path: 'reservation/:appointmentID/complete', element: <ServiceSuccessPage /> },
                ]
            },
            {
                path: 'garages',
                children: [
                    { index: true, element: <RegionsPage /> },
                    { path: 'region/:regionParams', element: <DepartmentsPage /> },
                    { path: 'departement/:departmentParams', element: <CitiesPage /> },
                    { path: 'ville/:cityParams', element: <VendorsFilterPage /> },
                    { path: 'recherche', element: <VendorsSearchPage /> },
                    { path: ':garageSlug/:garageId', element: <VendorDetailsPage /> },
                ]
            },
            {
                path: 'chauffeur',
                children: [
                    { index: true, element: <DriverPage action={"login"} /> },
                    { path: 'inscription', element: <DriverPage action={"register"} /> },
                ]
            },
            // {
            //     path: 'pro',
            //     children: [
            //         { index: true, element: <ProPage /> },
            //         { path: 'maintenance', element: <ProMeintenancePage /> },
            //         { path: '/convoyage', element: <ProConveyingPage /> },
            //     ]
            // },
            {
                path: '/convoyage',
                children: [
                    { index: true, element: <ConveyingPage /> },
                    { path: 'reservation', element: <ConveyingBookingPage /> },
                    { path: 'reservation/:conveyingID/complete', element: <ConveyingSuccessPage /> },
                ]
            },
            {
                path: 'mentions-legales',
                children: [
                    { index: true, element: <LegalNoticePage /> },
                ]
            },
            {
                path: 'politique-de-confidentialite',
                children: [
                    { index: true, element: <PrivacyPolicyPage /> },
                ]
            },
            {
                path: 'marques',
                children: [
                    { index: true, element: <BrandsPage /> },
                ]
            },
            {
                path: 'contact',
                children: [
                    { index: true, element: <ContactPage /> },
                    { path: 'coordonnees-recues', element: <ContactSuccessPage /> },
                ]
            },
            {
                path: 'faq',
                children: [
                    { index: true, element: <QuestionsPage /> },
                ]
            },
            {
                path:'auto-partage',
                element:<CarSharingPage />
            },
        ],
    },{
        path:'/test/',
        element:< GarageLayout/>,
        errorElement:<NotFound />,
        children:[
            { path: 'dashboard', element: <GaragePage /> },
            { path: 'historique', element: <HistoriquePage /> },
            { path: 'calendar', element: <MyCalendar /> },
            { path: 'devis', element: <MesDevis /> },
            { path: 'devis/new', element: <NewDevis /> },
        ]
    },
    {
        path: '/user',
        element: <UserRootLayout />,
        errorElement: <NotFound />,
        children: [
            { index: true, element: <UserProfilePage /> },
            { path: 'services', element: <UserAppointmentsPage /> },
            { path: 'convoyages', element: <UserConveyingsPage /> },
        ]
    },
    // {
    //     path: '/pro',
    //     element: <FleetRootLayout />,
    //     errorElement: <NotFound />,
    //     children: [
    //         { index: true, element: <ProFleetPage /> },
    //         { path: 'coordonnees-recues', element: <SuccessPage /> },
    //     ]
    // },
    // {
    //     path: '/professionnel',
    //     element: <FleetRootLayout />,
    //     errorElement: <NotFound />,
    //     children: [
    //         { index: true, element: <ProFleetPage /> },
    //         { path: 'coordonnees-recues', element: <SuccessPage /> },
    //     ]
    // },

]);

export default RouterConfig;