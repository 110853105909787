import { Box, FormLabel, HStack, Text } from "@chakra-ui/react"
import { FormValues, customStyles, modeleOptions } from "../../constants"
import Select, { components } from 'react-select';
import { useState } from "react";
import { FaTimes } from "react-icons/fa";

type Props = {
    values:FormValues
    setFieldValue:any,
    handleBlur:any
}

const Option = (props : any) => {
    return (
        <components.Option {...props}>
            <input
                style={{cursor:'pointer'}}    
                type="checkbox"
                onChange={() => null}
            />{' '}
            <label style={{cursor:'pointer'}}>{props.label}</label>
      </components.Option>
    );
  };

const MultiValue = (props : any) => {
    return (
      <components.MultiValue {...props}>
        <span>{props.data.label}</span>
      </components.MultiValue>
    );
  };

const SecondStep = ({values,setFieldValue}:Props) => {
  const [availableOptions, setAvailableOptions] = useState(modeleOptions.filter(option => !values?.prestations?.find((s:string)=>s === option.value) ));;
  const [selectedOptions,setSelectedOptions] = useState(modeleOptions.filter(option => values.prestations?.find((s:string)=>s === option.value) ))
  
  const handleChange = (selected :any) => {
    const lastOption = selected?.find((s:any,index:number)=>selected?.length -1 === index)
    setFieldValue("prestations",[...values.prestations,lastOption.value])
    setSelectedOptions((prev)=>[...prev,lastOption])
    setAvailableOptions(availableOptions?.filter((option)=>!selected?.find((s:{value:string,label:string})=>s?.value === option.value)))

  };

  const removeItem = (item:{value:string,label:string}) => {
    setFieldValue("prestations",values.prestations.filter((f)=>f !== item.value))
    setSelectedOptions(selectedOptions?.filter((option)=>option.value !== item.value))
    setAvailableOptions((prev)=>[...prev,item])
  }

  return (
    <Box>
        <Text mb={2} fontSize={'18px'} fontWeight={600} color={'#222D50'} >Sélectionner Prestation(s)</Text>
        <Box>
            <FormLabel fontWeight={600} color={'#1D2433'}>Prestations</FormLabel>
            <Box h='47px' >
                <Select
                    isMulti
                    isClearable={false}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{ Option, MultiValue }}
                    styles={customStyles}
                    id={"prestations"}
                    controlShouldRenderValue={false}
                    placeholder='Choisir une ou plusieurs prestations'
                    onChange={handleChange}
                    value={selectedOptions}
                    options={availableOptions}
                    noOptionsMessage={() => "No prestations available"}
                />
            </Box>
            <HStack mt={2}>
                {selectedOptions?.map((option:{value:string,label:string},index:number)=>(
                    <HStack key={index} bg='#CFE7FC' p={2} rounded={'8px'}>
                        <Text fontSize={'14px'} color={'#222D50'} fontWeight={600}>{option.value}</Text>
                        <FaTimes color="#EB5757" cursor={'pointer'} onClick={()=>removeItem(option)} />
                    </HStack>
                ))}
            </HStack>
        </Box>
    </Box>
  )
}

export default SecondStep