import { Box, Modal, ModalContent, ModalOverlay} from '@chakra-ui/react';
import { UserRegisterForm } from '../forms/_index';

const UserRegisterModal : React.FC<{ isUserRegisterOpen: boolean, onUserRegisterClose: () => void }> = (props) => {
    return (
        <>
            <Modal isOpen={props.isUserRegisterOpen} onClose={props.onUserRegisterClose} size={'md'}>
                <ModalOverlay />
                <ModalContent borderRadius={'2xl'}>
                    <Box>
                        <UserRegisterForm onUserRegisterClose={props.onUserRegisterClose}/>
                    </Box>
                </ModalContent>
            </Modal>
        </>
    )
}

export default UserRegisterModal