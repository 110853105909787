import {
    Box,
    Button,
    Center,
    Container,
    Heading,
    Image,
    SimpleGrid,
    Stack,
    Text,
    Link
} from '@chakra-ui/react';
import AproposPic from '../../../../assets/images/dadycar-man-teaching.webp'

const AboutSection: React.FC = () => {
    return (
    <Box bg={'#F6FAFC'}>
        <Container maxW={'80%'} w={{ lg: '1400px' }} >
            <Box py={4}>
                <SimpleGrid templateColumns={{ base: '1fr', lg: '2fr 2fr' }} spacing={6}>
                    <Stack order={2}>
                        <Center>
                            <Box py={4}>
                                <Heading textAlign={{ base: 'center', lg: 'start' }} size={{ base: 'lg' }}>
                                    Développez votre activité à moindre coût
                                </Heading>
                                <Box py={3}>
                                    <Center display={{ base: 'unset', lg: 'none' }}>
                                        <Image my={6} src={AproposPic} />
                                    </Center>
                                    
                                    <Box>
                                        <Text color={'#0F82EA'}>Abonnement 20€ / mois</Text>
                                        <Text>sans engagement</Text>
                                    </Box>

                                    <Box  p={6}>
                                        <Text >Conditions Dadycar.com :</Text>
                                        <Text>RDV chiffrable: 10% HT du montant total.</Text>
                                        <Text>RDV non chiffrable: 10€ HT.</Text>
                                        <Text>Zéro commision sur les ventes additionneles.</Text>
                                    </Box>
                                    <Box py={2}>
                                        <Heading size={{ base: 'sm', lg: 'md' }} textAlign={{ base: 'center', lg: 'start' }}>3 mois offerts</Heading>
                                        <Text color={'#0F82EA'} textAlign={{ base: 'center', lg: 'start' }} fontSize={{ base: 'smaller' }}>
                                        Pour confirmer nos services et déjà générer de l’argent
                                        </Text>
                                    </Box>
                                    <Link href='#'><Button fontSize={'x-small'} w={'70%'} color={'white'} bg={'#1385EB'} _hover={{ bg: '#1385EB' }}>
                                    J’EN PROFITE MAINTENANT!</Button></Link>
                                </Box>

                            </Box>
                        </Center>
                    </Stack>
                    <Stack display={{ base: 'none', lg: 'unset' }} >
                        <Center>
                            <Image src={AproposPic} />
                        </Center>
                    </Stack>
                </SimpleGrid>
            </Box>
        </Container>
    </Box>
    )
}

export default AboutSection;