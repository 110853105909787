import {
    Box,
    Step,
    StepDescription,
    StepIcon,
    StepIndicator,
    StepNumber,
    StepSeparator,
    StepStatus,
    StepTitle,
    Stepper,
    Text,
    VStack,
  } from '@chakra-ui/react'


type Props = {
    steps :any[],
    activeStep:number,
}

const FormSteps = ({steps,activeStep}:Props) => {
  return (
    <Box mt={4}>
            <Stepper index={activeStep}>
        {steps.map((step, index) => (
            <Step key={index}>
                <VStack alignItems='center' >
                    <StepIndicator>
                        <StepStatus
                        complete={<StepNumber />}
                        incomplete={<StepNumber />}
                        active={<StepNumber />}
                        />
                    </StepIndicator>

                    <Box flexShrink='0'>
                        <StepDescription>
                            <Text textAlign='center'>{step.description}</Text>
                        </StepDescription>
                    </Box>
                </VStack>

            <StepSeparator />
            </Step>
        ))}
        </Stepper>
    </Box>
  )
}

export default FormSteps