import HttpClient from '../services/HttpClient'

type UserRegisterType = {
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    password: string;
    password_confirmation: string;
}

type UserLoginType = {
    email: string;
    password: string;
}

class User {
    static TTL = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

    public static register = async (userRegisterData: UserRegisterType) => {
        return HttpClient.post('/register', userRegisterData);
    }

    public static login = async (userLoginData: UserLoginType) => {
        return HttpClient.post('/login', userLoginData);
    }
}

export { type UserRegisterType };
export { type UserLoginType };

export default User;