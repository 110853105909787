import { Avatar, HStack, Text } from '@chakra-ui/react'
import { FaChevronDown } from "react-icons/fa";

const UserMenu = () => {
  return (
    <HStack alignItems='center' className='group' position='relative'>
        <Avatar size='sm' bg='blue.300' color='white' name={`AB`} />
        <Text fontWeight={500} color={'#2A2D37'}>{'Mohamed'} {'Abdelbasset'}</Text>
        <FaChevronDown color='#828A9D' cursor='pointer' />

    </HStack>
  )
}

export default UserMenu