import { Box, Flex, Text } from "@chakra-ui/react"

const TotalDevis = () => {
  return (
    <Box mb={4} >
        <Text mb={2} fontSize={'22px'} fontWeight={700} color={'#222D50'}>Total du Devis</Text>
        <Box bg='#EFF2FF' rounded={'8px'} p='16px'>
            <Flex justifyContent={'space-between'} alignItems={'center'} >
                <Text fontSize={'22px'} fontWeight={500} color={'#4E5D78'}>Total hors remise € HT</Text>
                <Text fontSize={'22px'} fontWeight={500} color={'#333333'}>1024 €</Text>
            </Flex>
            <Flex justifyContent={'space-between'} alignItems={'center'} my={2} >
                <Text fontSize={'22px'} fontWeight={500} color={'#4E5D78'}>Total remise € HT</Text>
                <Text fontSize={'22px'} fontWeight={500} color={'#333333'}>00 €</Text>
            </Flex>
            <Flex justifyContent={'space-between'} alignItems={'center'} mb={2} >
                <Text fontSize={'22px'} fontWeight={500} color={'#4E5D78'}>TOTAL € HT</Text>
                <Text fontSize={'22px'} fontWeight={500} color={'#333333'}>1024 €</Text>
            </Flex>
            <Flex justifyContent={'space-between'} alignItems={'center'} pb={2} borderBottom={'1px'} borderColor={'#4E5D78'}>
                <Text fontSize={'22px'} fontWeight={500} color={'#4E5D78'}>TVA €</Text>
                <Text fontSize={'22px'} fontWeight={500} color={'#333333'}>440 €</Text>
            </Flex>
            <Flex mt={2} justifyContent={'space-between'} alignItems={'center'} mb={2} >
                <Text fontSize={'28px'} fontWeight={700} color={'#182F45'}>Montant à facturer au client € TTC</Text>
                <Text fontSize={'28px'} fontWeight={700} color={'#0C7FDA'}>1464 €</Text>
            </Flex>
        </Box>

    </Box>
  )
}

export default TotalDevis